import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ItemContainer as Container } from '../styled';
import { stepMap } from '../utils/getConditionInput';

interface IProps {
  title: string;
  type: string;
  index: number;
}

const Cover: FC<IProps> = (props) => {
  const { title, type, index } = props;

  return (
    <Container justifyContent="center" spacing={1} width="100%" mb="12px">
      {type !== 'header' && <span className="stepCount">{index}</span>}
      <div className="type">
        {stepMap(type)?.icon}{' '}
        <Typography variant="caption" fontWeight={600} fontSize="13px">
          {stepMap(type)?.label}
        </Typography>
      </div>
      <Typography
        variant="caption"
        fontWeight={500}
        fontSize="14px"
        sx={{ marginTop: 0 }}
      >
        {title}
      </Typography>
    </Container>
  );
};

export default Cover;
