import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { LOCATIONS, TABS_LIST } from './constants';

const TabsList: FC<any> = (props) => {
  const { data, onChangeCallback, selectedTabOption } = props;
  console.log('data:', data);
  const [selectedTab, setSelectedTab] = useState(LOCATIONS);

  const getTotalSiteLocationCount = (location) => {
    if (!location || !Array.isArray(location)) return 0;
    return location.reduce((count, loc) => {
      const isSite = loc.Level?.isSite ? 1 : 0;
      const subLocationCount = getTotalSiteLocationCount(
        loc.Sublocations || [],
      );
      return count + isSite + subLocationCount;
    }, 0);
  };
  useEffect(() => {
    setSelectedTab(selectedTabOption);
  }, [selectedTabOption]);

  const handleSelectTab = (option) => {
    setSelectedTab(option);
    onChangeCallback?.(option);
  };

  return (
    <div className="tabsCnt">
      {TABS_LIST?.map((t) => {
        let count = data?.[t]?.length || 0;
        if (t === 'Locations') {
          count = getTotalSiteLocationCount(data?.[t]);
        }
        return (
          <div
            className={classNames(
              { tab: true },
              { selectedTab: t === selectedTab },
            )}
            onClick={() => handleSelectTab(t)}
          >
            {t?.charAt(0)?.toUpperCase() + t?.slice(1)}&nbsp;
            <span className="tabCount">{count}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TabsList;
