import config from 'config/index';

export const COLORS_LIST = [
  {
    color: '#C5CAE9',
    borderColor: '#9FA8DA',
    textColor: '#2134AF',
  },
  {
    color: '#B2DFDB',
    borderColor: '#80CBC4',
    textColor: '#2A6D67',
  },
  {
    color: '#D1C4E9',
    borderColor: '#B39DDB',
    textColor: '#4C0EBF',
  },
  {
    color: '#FFD54F',
    borderColor: '#FFC107',
    textColor: '#695518',
  },
  {
    color: '#F8BBD0',
    borderColor: '#F48FB1',
    textColor: '#77334B',
  },
  {
    color: '#FFCCBC',
    borderColor: '#FFAB91',
    textColor: '#8D5D4D',
  },
  {
    color: '#B3E5FC',
    borderColor: '#81D4FA',
    textColor: '#3F6A7E',
  },
  {
    color: '#C8E6C9',
    borderColor: '#A5D6A7',
    textColor: '#3A5F3C',
  },
];

export const GRIDS_LAYOUTS = {
  SUMMARY_LAYOUT: 'a6i1tbjzlbk',
  TASK_SUMMARY_LAYOUT: 'TASK_SUMMARY_LAYOUT',
  PULSE_DASHBOARD_LAYOUT: 'PULSE_DASHBOARD_LAYOUT',
};

export const PERMISSIONS_ENTITY_KEYWORDS = {
  WORKSPACE: 'Workspace',
  USER: 'Users, Teams and Roles',
  TASK: 'Tasks & Work Orders',
  CHECKLIST: 'Operations Templates',
  TASK_REUQEST: 'Work Requests',
  LOCATION: 'Locations',
  APPROVALS: 'Approvals',
  REPORTING: 'Reporting',
  PROJECT: 'Projects',
  OTHER: 'Others',
};

export const PERMISSIONS = {
  // Workspaces
  CAN_MANAGE_WORKSPACES: 'CAN_MANAGE_WORKSPACES',
  CAN_MANAGE_WORKSPACE_CONFIG: 'CAN_MANAGE_WORKSPACE_CONFIG',
  CAN_MANAGE_BILLING: 'CAN_MANAGE_BILLING',
  CAN_TOGGLE_KIOSK_MODE: 'CAN_TOGGLE_KIOSK_MODE',
  CAN_MANAGE_ACTIVITY_LOGS: 'CAN_MANAGE_ACTIVITY_LOGS',

  // Users, Teams and Roles
  CAN_MANAGE_USERS: 'CAN_MANAGE_USERS',
  CAN_MANAGE_USERS_PASSWORD: 'CAN_MANAGE_USERS_PASSWORD',
  CAN_MANAGE_TEAMS: 'CAN_MANAGE_TEAMS',
  CAN_MANAGE_ADMINS: 'CAN_MANAGE_ADMINS',
  CAN_MANAGE_OWNERS: 'CAN_MANAGE_OWNERS',
  CAN_MANAGE_ROLES: 'CAN_MANAGE_ROLES',

  // Tasks & Work Orders
  CAN_VIEW_TASKS: 'CAN_VIEW_TASKS',
  CAN_ONLY_VIEW_OWN_TASKS: 'CAN_ONLY_VIEW_OWN_TASKS',
  CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS:
    'CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS',
  CAN_VIEW_OTHERS_TASKS: 'CAN_VIEW_OTHERS_TASKS',
  CAN_MANAGE_TASKS: 'CAN_MANAGE_TASKS',
  CAN_SELECT_ALL_LOCATIONS: 'CAN_SELECT_ALL_LOCATIONS',
  CAN_MANAGE_CATEGORIES: 'CAN_MANAGE_CATEGORIES',
  CAN_CHANGE_TASK_STATUS: 'CAN_CHANGE_TASK_STATUS',
  CAN_CHANGE_OTHERS_TASK_STATUS: 'CAN_CHANGE_OTHERS_TASK_STATUS',
  CAN_MANAGE_WORK_ORDERS: 'CAN_MANAGE_WORK_ORDERS',

  // Checklists and Submissions
  CAN_MANAGE_LOG_SUBMISSION: 'CAN_MANAGE_LOG_SUBMISSION',
  CAN_MANAGE_CHECKLIST: 'CAN_MANAGE_CHECKLIST',
  CAN_VIEW_ALL_SUBMISSIONS: 'CAN_VIEW_ALL_SUBMISSIONS',
  CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS:
    'CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS',
  CAN_VIEW_OWN_SUBMISSIONS: 'CAN_VIEW_OWN_SUBMISSIONS',

  // Tasks Requests
  CAN_VIEW_TASK_REQUESTS: 'CAN_VIEW_TASK_REQUESTS',
  CAN_VIEW_OWN_TASK_REQUESTS_ONLY: 'CAN_VIEW_OWN_TASK_REQUESTS_ONLY',
  CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS:
    'CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS',
  CAN_VIEW_ALL_TASK_REQUESTS: 'CAN_VIEW_ALL_TASK_REQUESTS',
  CAN_REQUEST_TASK: 'CAN_REQUEST_TASK',
  CAN_ACCEPT_DECLINE_TASK_REQUEST: 'CAN_ACCEPT_DECLINE_TASK_REQUEST',
  CAN_CONFIGURE_PUBLIC_TASK_REQUEST: 'CAN_CONFIGURE_PUBLIC_TASK_REQUEST',

  // Locations
  CAN_MANAGE_LOCATIONS: 'CAN_MANAGE_LOCATIONS',

  // Approvals
  CAN_VIEW_ALL_APPROVALS: 'CAN_VIEW_ALL_APPROVALS',
  CAN_VIEW_ALL_APPROVALS_IN_AUTHORIZED_LOCATIONS:
    'CAN_VIEW_ALL_APPROVALS_IN_AUTHORIZED_LOCATIONS',
  CAN_MANAGE_APPROVALS: 'CAN_MANAGE_APPROVALS',

  // Projects
  CAN_CREATE_PROJECT: 'CAN_CREATE_PROJECT',
  CAN_EDIT_PROJECT: 'CAN_EDIT_PROJECT',
  CAN_PAUSE_RESUME_PROJECT: 'CAN_PAUSE_RESUME_PROJECT',
  CAN_DELETE_PROJECT: 'CAN_DELETE_PROJECT',

  CAN_MANAGE_SSO: 'CAN_MANAGE_SSO',
  // Reporting and Dashboards
  CAN_VIEW_REPORTING: 'CAN_VIEW_REPORTING',
  CAN_MANAGE_DASHBOARDS: 'CAN_MANAGE_DASHBOARDS',

  // Others
  CAN_MANAGE_ASSETS: 'CAN_MANAGE_ASSETS',
  CAN_USE_CHAT: 'CAN_USE_CHAT',
  CAN_VIEW_SOP_AND_FILES: 'CAN_VIEW_SOP_AND_FILES',

  CAN_CONFIGURE_REPORTING: 'CAN_CONFIGURE_REPORTING',
  CAN_PUBLISH_CHECKLIST: 'CAN_PUBLISH_CHECKLIST',
  CAN_MANAGE_KIOSK_CONFIG: 'CAN_MANAGE_KIOSK_CONFIG',
};

export const TASKS_PERMISSIONS_ORDER = {
  [PERMISSIONS.CAN_VIEW_TASKS]: {
    disableBy: [],
    enableBy: [],
  },
  [PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS]: {
    disableBy: [
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
    enableBy: [PERMISSIONS.CAN_VIEW_TASKS],
    type: 'radio',
  },
  [PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS]: {
    disableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
    enableBy: [PERMISSIONS.CAN_VIEW_TASKS],
    type: 'radio',
  },
  [PERMISSIONS.CAN_VIEW_OTHERS_TASKS]: {
    enableBy: [PERMISSIONS.CAN_VIEW_TASKS],
    disableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
    ],
    type: 'radio',
  },
  [PERMISSIONS.CAN_MANAGE_TASKS]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },
  [PERMISSIONS.CAN_MANAGE_CATEGORIES]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },
  [PERMISSIONS.CAN_CHANGE_TASK_STATUS]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },

  [PERMISSIONS.CAN_CHANGE_OTHERS_TASK_STATUS]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },
  [PERMISSIONS.CAN_SELECT_ALL_LOCATIONS]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },
  [PERMISSIONS.CAN_MANAGE_WORK_ORDERS]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    ],
  },
};

export const TEMPLATES_PERMISSIONS_ORDER = {
  [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION]: {
    disableBy: [],
    enableBy: [],
  },
  [PERMISSIONS.CAN_MANAGE_CHECKLIST]: {
    disableBy: [],
    enableBy: [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION],
  },
  [PERMISSIONS.CAN_VIEW_ALL_SUBMISSIONS]: {
    disableBy: [],
    enableBy: [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION],
    type: 'radio',
  },
  [PERMISSIONS.CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS]: {
    disableBy: [],
    enableBy: [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION],
    type: 'radio',
  },
  [PERMISSIONS.CAN_VIEW_OWN_SUBMISSIONS]: {
    disableBy: [],
    enableBy: [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION],
    type: 'radio',
  },
};

export const USER_PERMISSIONS_ORDER = {
  [PERMISSIONS.CAN_MANAGE_USERS]: {
    disableBy: [],
    enableBy: [],
  },
  [PERMISSIONS.CAN_MANAGE_USERS_PASSWORD]: {
    disableBy: [],
    enableBy: [PERMISSIONS.CAN_MANAGE_USERS],
  },
  [PERMISSIONS.CAN_MANAGE_TEAMS]: { disableBy: [], enableBy: [] },
  [PERMISSIONS.CAN_MANAGE_ADMINS]: { disableBy: [], enableBy: [] },
  [PERMISSIONS.CAN_MANAGE_OWNERS]: { disableBy: [], enableBy: [] },
  [PERMISSIONS.CAN_MANAGE_ROLES]: { disableBy: [], enableBy: [] },
};

export const APPROVALS_PERMISSIONS_ORDER = {
  [PERMISSIONS.CAN_VIEW_ALL_APPROVALS]: {
    disableBy: [PERMISSIONS.CAN_VIEW_ALL_APPROVALS_IN_AUTHORIZED_LOCATIONS],
    enableBy: [],
  },
  [PERMISSIONS.CAN_VIEW_ALL_APPROVALS_IN_AUTHORIZED_LOCATIONS]: {
    disableBy: [PERMISSIONS.CAN_VIEW_ALL_APPROVALS],
    enableBy: [],
  },
  [PERMISSIONS.CAN_MANAGE_APPROVALS]: {
    disableBy: [],
    enableBy: [],
  },
};

export const WORK_REQUESTS_PERMISSIONS_ORDER = {
  [PERMISSIONS.CAN_VIEW_TASK_REQUESTS]: {
    disableBy: [],
    enableBy: [],
  },

  [PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY]: {
    disableBy: [
      PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS,
      PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS,
    ],
    enableBy: [PERMISSIONS.CAN_VIEW_TASK_REQUESTS],
    type: 'radio',
  },

  [PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS]: {
    disableBy: [
      PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY,
      PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS,
    ],
    enableBy: [PERMISSIONS.CAN_VIEW_TASK_REQUESTS],
    type: 'radio',
  },

  [PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS]: {
    disableBy: [
      PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY,
      PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS,
    ],
    enableBy: [PERMISSIONS.CAN_VIEW_TASK_REQUESTS],
    type: 'radio',
  },

  [PERMISSIONS.CAN_REQUEST_TASK]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY,
      PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS,
      PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS,
    ],
  },

  [PERMISSIONS.CAN_ACCEPT_DECLINE_TASK_REQUEST]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY,
      PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS,
      PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS,
    ],
  },

  [PERMISSIONS.CAN_CONFIGURE_PUBLIC_TASK_REQUEST]: {
    disableBy: [],
    enableBy: [
      PERMISSIONS.CAN_VIEW_OWN_TASK_REQUESTS_ONLY,
      PERMISSIONS.CAN_VIEW_TASK_REQUESTS_IN_AUTHORIZED_LOCATIONS,
      PERMISSIONS.CAN_VIEW_ALL_TASK_REQUESTS,
    ],
  },
};

export const taskStatusColors = {
  Open: {
    light: '#dcebfc',
    dark: '#4f9af1',
  },
  'In Progress': {
    light: '#fde9d1',
    dark: '#f58500',
  },
  'On Hold': {
    light: '#e3e3e5',
    dark: '#64636d',
  },
  Completed: {
    light: '#d2f2e4',
    dark: '#04b86c',
  },
};

export const USERS_TEAMS_TEXT = 'All Users & Teams';
export const USERS_TEAMS_LOCATIONS_TEXT = 'All Users, Teams & Locations';

export const TALK_TO_SALES_URL =
  'https://xenia.na.chilipiper.com/book/me/kumeil-hosain?type=upgrade-inquiry';

export const TALK_TO_SALES_URL_NEIL =
  'https://xenia.na.chilipiper.com/book/me/neil-sareen?type=my-meeting';

export const isMobileApp = globalThis?.platform === 'mobile';
export const mobileOS: 'IOS' | 'ANDROID' | 'WEB' = globalThis?.device || 'WEB';

export const NUMBER_UNIT_OPTIONS = [
  {
    id: 'mass/weight',
    label: 'Mass/Weight',
    child: [
      { id: 'oz', label: 'oz (ounce)' },
      { id: 'lb', label: 'lb (pound)' },
      { id: 'T', label: 'T (ton)' },
      { id: 'mg', label: 'mg (milligram)' },
      { id: 'g', label: 'g (gram)' },
      { id: 'kg', label: 'kg (kilogram)' },
    ],
  },
  {
    id: 'length',
    label: 'Length',
    child: [
      { id: 'in', label: 'in (inch)' },
      { id: 'ft', label: 'ft (foot)' },
      { id: 'yd', label: 'yd (yard)' },
      { id: 'mi', label: 'mi (mile)' },
      { id: 'mm', label: 'mm (millimeter)' },
      { id: 'cm', label: 'cm (centimeter)' },
      { id: 'm', label: 'm (meter)' },
      { id: 'km', label: 'km (kilometer)' },
    ],
  },
  {
    id: 'time',
    label: 'Time',
    child: [
      { id: 's', label: 's (second)' },
      { id: 'min', label: 'min (minute)' },
      { id: 'hr', label: 'hr (hour)' },
      { id: 'd', label: 'd (day)' },
      { id: 'wk', label: 'wk (week)' },
      { id: 'mo', label: 'mo (month)' },
      { id: 'yr', label: 'yr (year)' },
    ],
  },
  {
    id: 'pressure',
    label: 'Pressure',
    child: [
      { id: 'Pa', label: 'Pa (pascal)' },
      { id: 'bar', label: 'bar' },
      { id: 'kPa', label: 'kPa (kilopascal)' },
      { id: 'MPa', label: 'MPa (megapascal)' },
      { id: 'kg/cm', label: 'kg/cm^2 (kilogram per square centimeters)' },
      { id: 'inHg', label: 'inHg (inch of mercury column)' },
    ],
  },
  {
    id: 'volumeFlow',
    label: 'Volume Flow',
    child: [{ id: 'mL/min', label: 'mL/min' }],
  },
  {
    id: 'pH',
    label: 'pH',
    child: [{ id: 'pH', label: 'pH' }],
  },
  {
    id: 'decibel',
    label: 'Decibel',
    child: [
      { id: 'dB', label: 'dB' },
      { id: 'dBA', label: 'dBA' },
      { id: 'dBC', label: 'dBC' },
      { id: 'dBZ', label: 'dBZ' },
    ],
  },
  {
    id: 'electricity',
    label: 'Electricity',
    child: [
      { id: 'amps', label: 'amps' },
      { id: 'watts', label: 'watts' },
      { id: 'volts', label: 'volts' },
      { id: 'ohms', label: 'ohms' },
    ],
  },
];

export const S3_BUCKET_ASSETS =
  'https://xeniateampublicassets.s3.us-east-2.amazonaws.com/web-assets/';

export const uploadCarePublicKey =
  config.REACT_APP_ENVIRONMENT === 'production'
    ? '7b60c59560328b44c055'
    : '82c86c6e51787da266ab';

export const TEXT_BASED_PROMPT = 'TEXT_BASED_PROMPT';
export const DOCUMENT_BASED_PROMPT = 'DOCUMENT_BASED_PROMPT';

export const INDEXED_DB_NAME = 'xenia-webapp';
export const INDEXED_STORE_NAME = 'ai-prompt';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBNqA6bKknZuU00R2sUeQh0lk94ESyLS1k';
