import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getHotelId, getItem } from 'utils/globalFunction';

export const createWorkspace = async (data): Promise<any> => {
  try {
    const createdWorkspace = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.createWorkspace(data.hotelId),
      data,
      'post',
      true,
      false,
      null,
      '',
    );

    if (createdWorkspace !== null) {
      return createdWorkspace?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const editWorkspace = async (data): Promise<any> => {
  try {
    const editedWorkspace = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.editWorkspace(data.hotelId),
      data,
      'patch',
      true,
      false,
      null,
      'Workspace updated!',
    );

    if (editedWorkspace !== null) {
      return editedWorkspace?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteWorkspace = async (data): Promise<any> => {
  try {
    const editedWorkspace = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.deleteWorkspace(data.hotelId),
      data,
      'delete',
      true,
      false,
      null,
      'Workspace deleted!',
    );

    if (editedWorkspace !== null) {
      return editedWorkspace?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getWorkspacesUnreadCount = async (): Promise<any> => {
  try {
    const unreadCount = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${API_ROUTES.workspacesUnreadCount}`,
      null,
      'get',
      true,
      false,
      null,
      '',
    );

    if (unreadCount !== null) {
      return unreadCount?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const createShift = async (data): Promise<any> => {
  const hotelId = getHotelId() as string;

  try {
    const updatedShift = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/shifts`,
      data,
      'post',
      true,
      false,
      null,
      '',
    );

    if (updatedShift !== null) {
      return updatedShift?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const updateShift = async (data): Promise<any> => {
  const hotelId = getHotelId() as string;
  const { id, ...rest } = data;
  try {
    const updatedShift = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/shifts/${id}`,
      rest,
      'patch',
      true,
      false,
      null,
      '',
    );

    if (updatedShift !== null) {
      return updatedShift?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const deleteShift = async (id): Promise<any> => {
  const hotelId = getHotelId() as string;

  try {
    const updatedShift = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/shifts/${id}`,
      null,
      'delete',
      true,
      false,
      null,
      '',
    );
    if (updatedShift) {
      return true;
    }
  } catch (error: any) {
    return null;
  }
};

export const getWorkspaceListWithPermissions = async (): Promise<any> => {
  try {
    const createdWorkspace = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        '/mine/remaining-limits',
      null,
      'get',
      true,
      false,
      null,
      '',
    );

    if (createdWorkspace !== null) {
      return createdWorkspace?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const trackingEventsOnWorkspace = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/hotels/${hotelId}/onboarding`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getWorkspaceActivityLogs = async (data = {}): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/workspaceLogs`,
      data,
      'post',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const getSSOConfigurations = async (): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/sso/configuration`,
      null,
      'get',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const configureSSO = async (data = {}): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/sso/configuration`,
      data,
      'post',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const getVerificationLink = async (type): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/sso/setup-link?${type}`,
      null,
      'get',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const enableDisableSSO = async (data = {}): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/sso/enable`,
      data,
      'post',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const updateDefaultSSORole = async (data = {}): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/sso/configuration`,
      data,
      'put',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

// This is the lookup data
export const lookupData = async (hotelId: string): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId ?? getHotelId()}/lookup-hash`,
      {},
      'get',
      true,
      false,
      null,
      '',
      false,
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
