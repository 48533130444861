// React
import { useEffect, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';

// Custom hooks.ts
import { useTaskEdit } from 'controller/useTaskEdit';

// Styled components
import { StatusWrapper } from 'components/Dropdown/Task/Status/taskStatusDropdown.style';

// Utilities
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { statusDropdownData } from 'helper/task/dropdownData';
import xeniaApi from 'api/index';
import { TASK_STATUS } from 'utils/globalVariable';
import { PostApiCallbackRequestType } from 'models/taskModel';
import DIALOGS from 'utils/dialogIds';
import useTaskList from 'utils/CustomHooks/useTasksList';
import useAggridSSRM from 'utils/CustomHooks/useAggridSSRM';

function StatusList(props) {
  const { closeAction, selectedOption, setSelectedOption, onChangeCallback } =
    props;
  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={false}
      isMulti={false}
      options={statusDropdownData.filter((s) => s.id !== 'Missed')}
      handleChangeCallback={async (values) => {
        await setSelectedOption(values);
        onChangeCallback && onChangeCallback(values);
        closeAction();
      }}
    />
  );
}
function StatusDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    filters = null,
    children,
    task = null,
    editable = false,
    viewType = 'taskView',
    onlyReturnValue,
    customRenderer,
    onChangeCallback,
    readOnly = false,
  } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);
  const [confirmationData, setConfirmationData] = useState(null);
  const { handleUpdateTask } = useTaskEdit(task);
  const handleDropdownOpen = (event) => {
    if (readOnly) return;
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleTaskReOpen = (taskid, reload = false) => {
    new Promise((resolve, reject) => {
      const getTaskSingleReopenData: PostApiCallbackRequestType = {
        callbackResolve: resolve,
        callbackReject: reject,
        postData: taskid,
      };
      dispatch(actions.setTaskReopen(getTaskSingleReopenData));
    }).then(() => {
      if (reload) {
        dispatch(
          actions.setForceUpdate({ forceUpdate: true, pushUpdate: false }),
        );
      } else {
        refreshServerSide([taskid]);
      }
    });
  };
  const handleTaskStatusChange = (selected) => {
    const taskReopening =
      selected[0].label === TASK_STATUS.Open &&
      task?.taskStatus === TASK_STATUS.Completed;

    const isTasksTableURL =
      window?.location?.href.includes('tasks/list') ||
      window?.location?.href.includes('filtered-tasks');

    const reload =
      isTasksTableURL &&
      globalThis?.isTableView &&
      globalThis?.groupBy === 'taskStatus';

    if (taskReopening) {
      handleTaskReOpen(task?.id, reload);
      return;
    }
    const statusPostData = {
      status: selected[0].label,
      id: task?.id,
    };
    dispatch(
      actions.changeTaskStatus({
        postData: statusPostData,
        callbackResolve: (res) => {
          if (res !== null) {
            dispatch(
              actions.editSingleTask({
                taskId: task?.id,
                task: { ...task, taskStatus: selected[0]?.value },
              }),
            );
          } else {
            dispatch(
              actions.editSingleTask({
                taskId: task?.id,
                task: { ...task },
              }),
            );
          }

          if (reload) {
            dispatch(
              actions.setForceUpdate({ forceUpdate: true, pushUpdate: false }),
            );
          } else {
            console.log('refresh 3');
            refreshServerSide([task?.id]);
          }
        },
      }),
    );
    dispatch(actions.setChangeTaskStatusSuccess(false));
  };
  const updateTaskHelper = async (data) => {
    setSelectedOption(data);
    editable && handleTaskStatusChange(data);
    // dispatch(
    //   actions.editSingleTask({
    //     taskId: task?.id,
    //     task: { ...task, taskStatus: data[0]?.value },
    //   }),
    // );
    // editable && (await handleUpdateTask({ taskStatus: data[0]?.value }));
  };

  const handleChangeSelectedOption = async (values) => {
    if (values.length) {
      if (values[0]?.value === 'Completed') {
        setConfirmationData(values);
        const taskChecklist = await xeniaApi.taskDetailChecklistData(task.id);
        const count = taskChecklist?.data?.progress?.count || 0;
        const completedCount =
          taskChecklist?.data?.progress?.completedCount || 0;
        const itemsCount = taskChecklist?.data?.TaskChecklistItems?.length || 0;
        if ((itemsCount && !count) || (count && count > completedCount)) {
          dispatch(
            actions.setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                title: 'Complete task',
                description:
                  'Are you sure you want to mark this task as completed?',
                onConfirmCallback: updateTaskHelper,
                onConfirmCallbackParams: values,
                onCancelCallback: () => setConfirmationData(null),
              },
            }),
          );
        } else {
          updateTaskHelper(values);
        }
      } else {
        updateTaskHelper(values);
      }
    }
  };

  const handleOnlyReturnValue = (values) => {
    onChangeCallback?.(values);
    handleDropdownClose();
  };

  const dropdownProps = {
    setSelectedOption: handleChangeSelectedOption,
    ...(onlyReturnValue && { setSelectedOption: handleOnlyReturnValue }),
  };

  const { refreshServerSide } = useAggridSSRM();

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        {customRenderer ? (
          <span onClick={handleDropdownOpen}>{customRenderer}</span>
        ) : (
          <>
            {viewType === 'taskView' && (
              <>
                {/* TODO please use the appropriate jsx button here for rendering other in taskView */}
              </>
            )}
            {viewType === 'tableView' && (
              <StatusWrapper
                onClick={handleDropdownOpen}
                color={selectedOption[0]?.color}
                bg={selectedOption[0]?.backgroundColor}
              >
                <span className={'statusText'}>{selectedOption[0]?.label}</span>
                {!readOnly && <ArrowDropDownIcon />}
              </StatusWrapper>
            )}
          </>
        )}

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <StatusList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default StatusDropdown;
