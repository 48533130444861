import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';

import { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import LongArrowIcon from 'components/Icons/longArrowIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import CustomButton from 'components/Button/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import Trigger from './trigger';
import Actions from './actions';
import BuilderHeader from './header';
import useTemplateWorkflows from 'utils/CustomHooks/useTemplateWorkflows';
import {
  LoaderCnt,
  MainContainer,
  SaveChangesPrompt,
  StepsContainer,
} from './style';
import { generateOrder, getChecklistItemsHash } from './utils';
import xeniaApi from 'api/index';
import CustomInput from 'components/Form/TextField/TextField.cmp';

function useBeforeUnload(when) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
}

const TemplateWorkflowBuilder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.TEMPLATE_WORKFLOW_BUILDER_DIALOG];
  const { checklistId, workflowId, noURLUpdate } = modalState?.data || {};
  const [showExitPrompt, setShowExitPrompt] = useState(false);

  const [responseData, setResponseData] = useState<any>(null);

  const {
    getTemplateWorkflow,
    createTemplateWorkflow,
    editTemplateWorkflow,
    transformWorkflowData,
    transformResponseToWorkflowData,
  } = useTemplateWorkflows();

  const [isDirtyChanges, setIsDirtyChanges] = useState(false);

  const [checklist, setChecklist] = useState<any>(null);
  const [checklistItemsHash, setChecklistItemsHash] = useState<any>({});
  const [itemOrdersHash, setItemOrdersHash] = useState<any>({});

  const [initialData, setInitialData] = useState<any>({
    name: '',
    description: '',
    isActive: true,
    operator: 'AND',
    conditions: [],
    actions: [],
    notification: {
      users: [],
      roles: [],
      teams: [],
      emails: [],
      enable: false,
    },
  });

  const [updatedData, setUpdatedData] = useState<any>(initialData);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [automations, setAutomations] = useState<any>({});

  useBeforeUnload(isDirtyChanges);

  const { open } = modalState || {};

  useEffect(() => {
    if (open) {
      setUpdatedData(initialData);
    }
  }, [initialData]);

  const getWorkflowDetails = async (workflowId) => {
    try {
      const response = await getTemplateWorkflow(workflowId);
      if (response) {
        setResponseData(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('error:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (responseData) {
      try {
        if (responseData) {
          let transformedResponse = cloneDeep(responseData);
          transformedResponse = transformResponseToWorkflowData(
            transformedResponse,
            checklistItemsHash,
          );
          setUpdatedData(transformedResponse);
          setInitialData(transformedResponse);
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  }, [responseData, checklistItemsHash]);

  const fetchAndSetChecklistAutomations = async () => {
    const automations = await xeniaApi.getChecklistAutomations({
      checklistId,
    });
    if (automations) {
      const groupedAutomations = automations?.reduce((acc, automation) => {
        const { EntityId } = automation;
        if (!acc[EntityId]) {
          acc[EntityId] = [];
        }
        acc[EntityId].push({
          id: automation.id,
          conditions: automation.conditions,
          actions: automation.actions,
          EntityId: automation.EntityId,
          entityType: automation.entityType,
          operator: automation.operator,
        });
        return acc;
      }, {});
      setAutomations(groupedAutomations);
    }
  };

  const updateTitle = (name) => {
    if (name?.length < 50) {
      setUpdatedData({
        ...updatedData,
        name,
      });
    }
  };

  const handleClose = () => {
    dispatch(closeDialog(DIALOGS.TEMPLATE_WORKFLOW_BUILDER_DIALOG));
  };

  const verifyUnSavedChanges = () => {
    if (isDirtyChanges && updatedData?.name) {
      setShowExitPrompt(true);
    } else {
      handleClose();
    }
  };

  const cancelHandler = () => {
    verifyUnSavedChanges();
  };

  const isDirtyChangesForBtn = useMemo(() => {
    const changes = !isEqual(initialData, updatedData);
    if (changes && !isDirtyChanges) {
      setIsDirtyChanges(true);
    }

    if (!changes && isDirtyChanges) {
      setIsDirtyChanges(false);
    }
    return changes;
  }, [initialData, updatedData]);

  const createHandler = async () => {
    try {
      setIsSaving(true);
      let clonedData = cloneDeep(updatedData);
      clonedData = transformWorkflowData(clonedData);
      clonedData.ChecklistId = checklistId;

      const data = await createTemplateWorkflow(clonedData);
      setIsSaving(false);

      if (data) {
        setUpdatedData({
          ...updatedData,
          id: data?.id,
        });

        setInitialData({
          ...updatedData,
          id: data?.id,
        });

        const params = new URLSearchParams(location.search);
        params.set('workflowId', data?.id);

        if (!noURLUpdate) {
          navigate(`${location.pathname}?${params.toString()}`);
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const editHandler = async (exit = false) => {
    setIsSaving(true);
    let clonedData = cloneDeep(updatedData);
    clonedData = transformWorkflowData(clonedData);
    clonedData.ChecklistId = checklistId;
    const data = await editTemplateWorkflow(clonedData);
    setIsSaving(false);

    if (data) {
      setInitialData({
        ...updatedData,
        id: data?.id,
      });
    }

    if (exit) {
      handleClose();
    }
  };

  const getChecklist = async () => {
    const response = await xeniaApi.getChecklistItems(checklistId);
    if (response?.data) {
      setChecklist(response?.data);
      const items = response?.data ?? [];
      const hashedItems = getChecklistItemsHash(items);
      setChecklistItemsHash(hashedItems);
      const orderHash = generateOrder(items);
      setItemOrdersHash(orderHash);

      fetchAndSetChecklistAutomations();
      if (workflowId) {
        getWorkflowDetails(workflowId);
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getChecklist();
  }, []);

  const defaultInputStyle: React.CSSProperties = {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32.02px',
    color: '#000000',
    paddingLeft: 0,
  };
  const mergedInputStyle = { ...defaultInputStyle };

  return (
    <>
      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            maxWidth: 'unset',
            margin: 0,
            maxHeight: 'unset',
            backgroundColor: 'rgba(245, 245, 245, 1)',
          },
        }}
        fullWidth
        open={open}
        handleClose={handleClose}
      >
        <MainContainer>
          {isLoading && (
            <LoaderCnt>
              <CircularProgress />
            </LoaderCnt>
          )}

          {!isLoading && (
            <>
              <BuilderHeader
                cancelHandler={cancelHandler}
                data={updatedData}
                createHandler={createHandler}
                editHandler={editHandler}
                isDirtyChanges={isDirtyChangesForBtn}
                isSaving={isSaving}
                handleClose={handleClose}
              />
              <StepsContainer>
                <Box className="innerBox">
                  <CustomInput
                    className="title"
                    underlineInput={true}
                    suppressErrorSpace={true}
                    fieldProps={{
                      autoFocus: true,
                      value: updatedData?.name,
                      inputProps: { style: mergedInputStyle },
                      placeholder: 'Give your workflow a name',
                      onChange: (e) => {
                        updateTitle(e.target.value);
                      },
                    }}
                    sx={{ width: '100%', margin: '-20px 0px 20px 0px' }}
                  />
                  <Trigger
                    data={updatedData}
                    setData={setUpdatedData}
                    checklist={checklist}
                    checklistItemsHash={checklistItemsHash}
                    orderHash={itemOrdersHash}
                    automations={automations}
                  />
                  <LongArrowIcon className="arrowIcon" />
                  <Actions data={updatedData} setData={setUpdatedData} />
                </Box>
              </StepsContainer>
            </>
          )}
        </MainContainer>
      </CustomModal>

      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '24px',
            width: '600px',
            height: 'auto',
            padding: '20px',
          },
        }}
        open={showExitPrompt}
        handleClose={() => {
          setShowExitPrompt(false);
        }}
      >
        <SaveChangesPrompt>
          <Box className="header">
            <Typography className="title">Save Changes?</Typography>
            <CloseIcon
              onClick={() => setShowExitPrompt(false)}
              sx={{ fontSize: '22px', cursor: 'pointer', color: '#1C1B1F' }}
            />
          </Box>
          <Typography className="description">
            You have unsaved changes. Would you like to discard or save them?
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomButton
              variant="text"
              onClick={() => {
                setShowExitPrompt(false);
              }}
            >
              Cancel
            </CustomButton>
            <Stack direction="row" alignItems="center" gap="10px">
              <CustomButton
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                Discard
              </CustomButton>
              <CustomButton
                variant="contained"
                buttonLoader={isSaving}
                onClick={() =>
                  !updatedData?.id ? createHandler() : editHandler(true)
                }
              >
                Save Changes
              </CustomButton>
            </Stack>
          </Stack>
        </SaveChangesPrompt>
      </CustomModal>
    </>
  );
};

export default TemplateWorkflowBuilder;
