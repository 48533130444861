import {
  AuthState,
  ChecklistManagementState,
  cumulManagementState,
  CommonPayload,
  MessagesManagementState,
  NotificationStoreData,
  PropertyLayoutManagementAdminState,
  ServiceAdminState,
  TagAdminState,
  TaskManagementStateModel,
  RequestsManagementStateModel,
  LoaderStateModel,
  UserManagementAdminState,
} from 'models/index.m';
import { TopAlertState } from 'reducers/topAlertReducer';

export const initialAuthStateData: AuthState = {
  user: null,
  status: '',
  roleType: null,
  isLoggedOutStatus: false,
  loginAttemptError: false,
  streamUser: null,
  billingInfo: null,
  stripeProducts: null,
};
export const initialTeamsStateData = [];
export const initialLocationsStateData = {
  locations: [],
  taskHistory: [],
  fetched: {
    locations: false,
    authorizedLocations: false,
  },
};

export const initialLocationGroupsStateData = {
  fetched: {
    initialLoad: false,
    loading: true,
  },
  groups: [],
};

export const initialLocationsLevelsStateData = {
  levels: [],
};

export const initialWorkflowsStateData = {
  workflows: [],
  loading: true,
  initialLoad: false,
};

export const initialRoutingRulesStateData = {
  routingRules: [],
  loading: true,
  initialLoad: false,
};

export const initialTaskTemplatesStateData = {
  list: [],
  loading: true,
};

export const initialFlaggedItemsCategoriesStateData = {
  list: [],
  initialLoad: false,
  loading: true,
};

export const forceUpdateStateData = {
  forceUpdate: false,
  pushUpdate: false,
};

export const initialLookupStateData = {
  data: [],
  initialLoad: false,
  loading: true,
};

export const initialPermissionsStateData = {
  list: {},
  initialLoad: false,
  loading: true,
};

export const initialRolesStateData = {
  list: [],
  initialLoad: false,
  loading: true,
};

export const initialProjectsStateData = {
  list: [],
};

export const initialSchedulesStateData = {
  list: [],
};

export const initialNavigation = {
  selected: 'HOME',
};

export const initialAssetsStateData = { assets: [], taskHistory: [] };
export const initialSavedOptionsData = { savedOptions: [] };
export const initialPaywallState = {};

export const initialGeneralStateData: CommonPayload = {
  pageTitles: {},
  isMenuOpen: true,
  sidebarConfig: {},
};

export const initialLoaderStateData: LoaderStateModel = {
  tasksList: {
    isLoading: null,
  },
  filters: {
    isLoading: false,
  },
  tasksDetail: {
    isLoading: false,
  },
  checkList: {
    isLoading: false,
  },
  checkListLogs: {
    isLoading: false,
  },
  checkListLogsFilters: {
    isLoading: false,
  },
};

export const initialDialogStateData = {};
export const initialUploadingStateData = {};
export const topAlertInitialState: TopAlertState = {
  show: false,
};

export const initialTagAdminStateData: TagAdminState = {
  isUpdateSuccess: false,
  isAddSuccess: false,
  isUpdateing: false,
  isRemoving: false,
  isAdding: false,
  isFetching: false,
  isFetchingSingle: false,
  tagAdminList: null,
  tagAssignAdminList: null,
};

export const initialServiceAdminStateData: ServiceAdminState = {
  isAddSuccess: false,
  isUpdateSuccess: false,
  isUpdateing: false,
  isAdding: false,
  isRemoving: false,
  isFetching: false,
  isFetchingSingle: false,
  serviceAdminList: null,
  servicesHash: {},
};

export const initialUserManagementAdminStateData: UserManagementAdminState = {
  isUpdateSuccess: false,
  isAddSuccess: false,
  isUpdateing: false,
  isAdding: false,
  isRemoving: false,
  isFetching: false,
  isSingleFetching: false,
  total: 0,
  current_page: 1,
  last_page: 1,
  per_page: 10,
  active: 0,
  inactive: 0,
  adminCount: 1,
  userDepartmentWiseList: null,
  userManagementAdminList: null,
  hashedUserList: null,
  allUsers: [],
  userMetaInfo: { fetching: true },
  authorizedUsers: [],
};

export const initialPropertyLayoutManagementAdminStateData: PropertyLayoutManagementAdminState =
  {
    isAddFloorSuccess: false,
    isUpdateFloorSuccess: false,
    isAddAreaSuccess: false,
    isUpdateAreaSuccess: false,
    isAddRoomSuccess: false,
    isUpdateRoomSuccess: false,

    isFloorUpdateing: false,
    isAreaUpdateing: false,
    isRoomUpdateing: false,

    isFloorRemoving: false,
    isAreaRemoving: false,
    isRoomRemoving: false,

    isFloorAdding: false,
    isAreaAdding: false,
    isRoomAdding: false,

    isFloorFetching: false,
    isAreaFetching: false,
    isRoomFetching: false,

    isFloorFetchingSingleLoading: false,
    isAreaFetchingSingleLoading: false,
    isRoomFetchingSingleLoading: false,

    floorTotal: 0,
    areaTotal: 0,
    roomTotal: 0,
    current_page_floor: 1,
    last_page_floor: 1,
    per_page_floor: 1,
    current_page_area: 1,
    last_page_area: 1,
    per_page_area: 1,
    floorAdminList: null,
    areaAdminList: null,
    roomAdminList: null,
    bedTypeList: null,
    // eslint-disable-next-line prettier/prettier
    propertyName: '',
  };

export const initialTaskManagementStateData: TaskManagementStateModel = {
  isUpdateing: false,
  isAdding: false,
  isRemoving: false,
  isFetching: false,
  isSingleFetching: false,
  isTaskAssigning: false,
  meta: null,
  notification: false,
  statusObject: null,
  data: null,
  searchText: '',
  isFiltersORSearch: false,
  taskCommentIsFetching: false,
  taskCommentIsAdding: false,
  taskCommentIsUpdating: false,
  taskCommentIsRemoving: false,
  isSetTaskDetailFromNotification: false,
  isNotificationMarkAsRead: false,
  isUpdateTaskStatusSuccess: false,
  taskColumns: [],
  isSetTaskIdFromNotification: '',
  taskCommentChannelsHash: {},
  taskListFilters: null,
  taskCalendarFilters: null,
};

export const initialRequestsManagementStateData: RequestsManagementStateModel =
  {
    isFiltersORSearch: false,
    requestsListFilters: null,
  };

export const initialMessagingStateData: MessagesManagementState = {
  isUpdateing: false,
  isRemoving: false,
  isAdding: false,
  isFetching: false,
  isRefreshing: false,
  isJoinedChannel: null,
  isLoadFirst: false,
  membersList: null,
  streamChannelList: [],
  streamChannelHash: {},
  streamChannelsSortedList: [],
  streamChannelsSectionList: {},
  allUsersList: null,
  isShowGroupTrigger: false,
  scroll_to_channel_message: null,
  channels: {},
  channelsList: [],
  channelSections: {},
  channelCounts: {},
  activeStreamChannel: null,
  channelsRefreshRequest: null,
  messagesUnreadCount: 0,
};

export const initialCumulData: cumulManagementState = {
  token: '',
};

export const initialNotificationData: NotificationStoreData = {
  notificationsUnreadCount: 0,
  isFetchingNotificationCount: false,
  notifications: [],
  isFetchingNotification: false,
};

export const initialTimezoneStateData: any = {
  timezoneList: [],
  loadingTimezones: false,
  timezoneLoadingError: null,
};

export const initialUserConfigs: any = {
  configs: [],
};

export const initialWorkspaceStateData: any = {
  activeWorkspace: null,
  workspacesUnreadCount: null,
};
export const initialAppStateData: any = {
  appState: {},
};

export const initialBillingStateData: any = {
  billingInfo: null,
  stripeProducts: null,
  stripeProductsPaymentFrequency: {},
};

export const initialCalendlyStateData: any = {
  calendlyInfo: {},
};

export const initialReportsStateData = {
  fetched: {
    initialLoad: false,
    loading: true,
  },
  reportsList: [],
};
