import useResponsive from 'utils/CustomHooks/useResponsive';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import {
  TimelineContainer,
  TimelineBordered,
  TimelineItem,
  DotIcon,
  TimelineDate,
} from './style';
import { getTimeZone } from 'utils/globalFunction';

const getAction = (action: string) => {
  switch (action) {
    case 'Changes Requested':
      return 'Request Changes';
    case 'approved':
      return 'Approved by';
    case 'rejected':
      return 'Rejected by';
    case 'submitted':
      return 'Submitted by';
    case 'Skipped':
      return 'Skipped';
    default:
      return action;
  }
};

const Timeline = ({ timeline }) => {
  const { isMobileDeviceSm } = useResponsive();

  const tz: string = getTimeZone();

  return (
    <TimelineContainer>
      <TimelineBordered mobile={isMobileDeviceSm ? 'true' : ''}>
        <TimelineItem
          sx={{
            ...(timeline?.action === 'Changes Requested' && {
              '& .dot-circle': {
                fill: 'rgba(237, 108, 2, 1)',
              },
              '& .dot-rect': {
                stroke: 'rgba(237, 108, 2, 1)',
              },
            }),
            ...(timeline?.action === 'Rejected' && {
              '& .dot-circle': {
                fill: 'red',
              },
              '& .dot-rect': {
                stroke: 'red',
              },
            }),
            ...(timeline?.action === 'Approved' && {
              '& .dot-circle': {
                fill: 'rgba(76, 175, 80, 1)',
              },
              '& .dot-rect': {
                stroke: 'rgba(76, 175, 80, 1)',
              },
            }),
            ...(timeline?.action === 'Skipped' && {
              '& .dot-circle': {
                fill: 'rgb(105, 4, 120)',
              },
              '& .dot-rect': {
                stroke: 'rgb(105, 4, 120)',
              },
            }),
          }}
        >
          <DotIcon mobile={isMobileDeviceSm ? 'true' : ''} />
          <Stack
            direction="column"
            alignItems="start"
            gap="8px"
            marginBottom={'15px'}
          >
            {timeline?.action === 'Skipped' ? (
              <Typography className="label">{`${getAction(
                timeline?.action,
              )}`}</Typography>
            ) : (
              <Typography className="label">{`${getAction(timeline?.action)}: ${
                timeline?.Creator?.fullName ?? ''
              }`}</Typography>
            )}
            <TimelineDate>
              {moment
                .tz(timeline?.createdAt, tz)
                .format('MMM DD, YYYY • hh:mm A')}
            </TimelineDate>

            {timeline?.Comment?.text && (
              <Box className="messageBox">
                <Typography className="message">
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: 13,
                      lineHeight: '16.39px',
                      color: 'rgba(0, 0, 0, 1)',
                    }}
                  >
                    Message:{' '}
                  </span>
                  {timeline?.Comment?.text}
                </Typography>
              </Box>
            )}
          </Stack>
        </TimelineItem>
      </TimelineBordered>
    </TimelineContainer>
  );
};

export default Timeline;
