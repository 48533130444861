import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import socketIOClient, { Socket } from 'socket.io-client';
import { isFunction } from 'lodash';

import config from 'config/index';
import selectors from 'store/selectors';
import actions from 'store/actions';

const { REACT_APP_SOCKET_URL } = config;

const SocketConnect = () => {
  const dispatch = useDispatch();

  const [socket, setSocket] = useState<Socket | null>(null);
  const userProfileData = useSelector(selectors.getUserProfile);
  const activeWorkspace = useSelector(selectors.getActiveWorkspace);
  const activeWorkspaceRef = useRef<any>({});

  useEffect(() => {
    activeWorkspaceRef.current = activeWorkspace;
  }, [activeWorkspace]);

  const updateUserStatus = (data) => {
    if (userProfileData?.id && data.UserId === userProfileData?.id) {
      // update logged in user status
      dispatch(actions.setLoggedInUserAvailability(data));
    }
    // update matched user status in userList
    dispatch(actions.setUserAvailabilityStatus(data));
  };

  const handleSocketEvents = (_payload) => {
    try {
      const ws = activeWorkspaceRef?.current;
      const { type, data } = _payload;
      const { storeAction, payload, workspaceId } = data;
      if (workspaceId && workspaceId !== ws?.Hotel?.id) {
        return;
      }
      if (storeAction?.web) {
        if (storeAction?.web === 'createTaskHotel') {
          dispatch(
            actions.setForceUpdate({ forceUpdate: false, pushUpdate: true }),
          );
        }
        const action = actions[storeAction?.web];
        if (isFunction(action)) {
          dispatch(action(payload));
          return;
        }
      }
      switch (type) {
        case 'user:status:updated':
          updateUserStatus(data);
          break;
        case 'hotel:paywall:remaining:usage':
          break;
        default:
          break;
      }
    } catch (error) {
      // report error here
    }
  };

  const connectSocket = () => {
    if (!socket && userProfileData?.verificationToken) {
      const _socket = socketIOClient(REACT_APP_SOCKET_URL, {
        auth: {
          token: userProfileData.verificationToken,
        },
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      _socket
        .on('connect', () => {
          console.log('connected!');
        })
        .on('connect_error', (error) => {
          console.log('connect_error : ', error);
        })
        .on('disconnect', (reason) => {
          console.log('disconnected!!!!', reason);
        })
        .on('ping', () => {
          console.log('ping backend server');
        })
        .on('pong', (latency) => {
          console.log('pong received from backend', latency);
        })
        .on('reconnect', () => {
          console.log('reconnected!');
        })
        .on('reconnect_error', (error) => {
          console.log('reconnect_error : ', error);
        })
        .on('reconnect_attempt', () => {
          console.log('reconnecting attempt...');
        })
        .on('event:app', (data, meta) => {
          console.log('event:app event:', data, meta);
        })
        .on('event:hotel', (data, meta) => {
          handleSocketEvents(data);
        })
        .on('event:user', (data, meta) => {
          handleSocketEvents(data);
        })
        .on('event:socket', (data, meta) => {
          console.log('event:socket event:', data, meta);
        });
      setSocket(_socket);
    }
  };

  const disconnectSocket = () => {
    if (socket && socket.disconnect && isFunction(socket.disconnect)) {
      socket.disconnect();
    }
    setSocket(null);
  };

  useEffect(() => {
    const { verificationToken } = userProfileData || {};
    if (verificationToken) {
      connectSocket();
    } else {
      disconnectSocket();
    }

    return () => {
      disconnectSocket();
    };
  }, [userProfileData?.verificationToken]);

  return null;
};

export default SocketConnect;
