import { Stack, styled } from '@mui/material';

export const InfoBanner = styled(Stack)(() => ({
  borderRadius: 10,
  height: 38,
  padding: '10px',
  width: '100%',
  margin: '0px auto 20px auto',
  backgroundColor: 'rgba(225, 245, 254, 1)',
  '& .icon': {
    marginLeft: 4,
    height: 20,
    width: 20,
    fill: 'rgba(2, 136, 209, 1)',
    '& path': {
      color: '#0288D1',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 5,
    lineHeight: '17.76px',
    color: 'rgba(33, 33, 33, 1)',
  },
}));
