export const TASKS_VIEW_TYPES = {
  TABLE: 'table',
  LIST: 'list',
  CALENDAR: 'calendar',
  KANBAN: 'kanban',
};

export const REQUESTS_VIEW_TYPES = {
  TABLE: 'table',
  LIST: 'list',
  INBOX: 'inbox',
};

export const GROUP_BY_PROPERTIES = {
  status: 'taskStatus',
  assigned_to: 'assignees',
  asset: 'AssetId',
  location: 'LocationId',
  priority: 'priority',
};

export const SORTING_PROPERTIES = {
  title: 'title',
  status: 'taskStatus',
  assigned_to: 'assignees',
  asset: 'AssetId',
  location: 'LocationId',
  priority: 'priority',
  end_date: 'dueTime',
  completed_date: 'endTime',
  completed_by: 'CompletedBy',
  taskTemplate: 'TaskTemplateId',
  created_at: 'createdAt',
  created_by: 'CreatedBy',
  category: 'ServiceTypeId',
  timeSpent: 'timeSpent',
  project: 'ProjectId',
  schedule: 'taskUniqueId',
  description: 'description',
  start_date: 'startTime',
  id: 'id',
  'schedule frequency': 'recurringByEvery',
  template_attached: 'ChecklistId',
};

export const DEFAULT_TASKS_GROUP_BY_PROPERTY = 'taskStatus';
