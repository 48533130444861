// MUI
import { Box, Chip } from '@mui/material';
import { snakeCase } from 'lodash';

// Custom components
import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import ChecklistLogItem from 'components/ChecklistReport/ChecklistLogItem';
import { AccordionContentCnt } from 'components/ChecklistReport/HeaderItem/headerItem.style';
import { checkVisibilityWithText } from 'components/TaskChecklist/utils';
import CheckListHiddenItem from '../ChecklistLogItem/ChecklistHiddenItem';
import { useMemo } from 'react';

const HeaderItem = ({
  headerItem,
  headerIndex,
  logItems,
  sectionRefs,
  template,
  checklistLog,
  isPublic,
  isScoring,
  flaggedCategories = [],
  conditions = null,
  checkListLogHashes,
  locationGroupHash = {},
}) => {
  const isSectionHidden = useMemo(() => {
    return checkVisibilityWithText(
      conditions,
      headerItem,
      checkListLogHashes,
      true,
      locationGroupHash,
    );
  }, [conditions, headerItem]);
  return (
    <Box
      className="mb-3"
      id={snakeCase(headerItem.description)}
      key={headerIndex}
      ref={sectionRefs[headerIndex + isScoring ? 2 : 1]}
    >
      {isSectionHidden ? (
        <CheckListHiddenItem
          info={isSectionHidden}
          type="Section"
          title={headerItem.description}
        />
      ) : (
        <AccordionWrapper
          headerName={headerItem.description}
          progress={headerItem.progress}
          headerEndRenderer={
            template?.isScoring && headerItem?.score ? (
              <Chip
                label={`Score: ${headerItem.score.percentage}% (${headerItem.score.earned} of ${headerItem.score.total} pts)`}
                size="small"
                sx={{
                  background: '#CCCBFF',
                  fontWeight: 700,
                  fontSize: '13px',
                }}
              />
            ) : null
          }
        >
          <AccordionContentCnt>
            {logItems[headerIndex]?.map((logItem: any, index: any) => {
              return (
                <Box
                  sx={{
                    borderBottom:
                      index === logItems[headerIndex].length - 1
                        ? ''
                        : '1px solid rgba(224, 224, 224, 1)',
                    padding: '10px 24px',
                    '&:hover': {
                      background: 'rgba(235, 234, 255, 1)',
                      '& div.corrective-task': {
                        display: 'flex',
                      },
                    },
                  }}
                  className="checklist-log-item"
                >
                  <ChecklistLogItem
                    isHidden={checkVisibilityWithText(
                      conditions,
                      logItem,
                      checkListLogHashes,
                      true,
                      locationGroupHash,
                    )}
                    checklistLog={checklistLog}
                    logItem={logItem}
                    template={template}
                    isPublic={isPublic}
                    flaggedCategories={flaggedCategories}
                  />
                </Box>
              );
            })}
          </AccordionContentCnt>
        </AccordionWrapper>
      )}
    </Box>
  );
};

export default HeaderItem;
