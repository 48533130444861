import { FC, useCallback, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { getEntityLogicalConditions } from '../utils/getEntityLogicalConditions';
import { getConditionInput } from '../utils/getConditionInput';
import { debounce } from 'lodash';
import DeleteIcon from 'components/Icons/deleteIcon';
import { DeleteCnt, ItemSelectionCnt } from '../styled';

interface IProps {
  onChange(item: any): void;
  id?: string;
  condition: any;
  context: string;
  checkListItems: any;
  selectedContext: any;
  disable?: boolean;
  deleteHandler?: any;
  isLocationType?: boolean;
}

const ConditionItem: FC<IProps> = (props) => {
  const {
    id,
    context,
    condition,
    onChange,
    selectedContext,
    disable,
    deleteHandler,
    isLocationType,
  } = props;

  const logicOptions = useMemo(() => {
    if (selectedContext) {
      return getEntityLogicalConditions(selectedContext.type) || null;
    }
    return null;
  }, [selectedContext]);

  const selectedLogicOption = useMemo(() => {
    return logicOptions?.find((option) => option?.value === condition?.logic);
  }, [logicOptions]);

  const changeHandler = useCallback(
    debounce((value) => {
      onChange({ id, condition: { ...condition, value } });
    }, 500),
    [condition],
  );

  return (
    <Box>
      <Stack>
        {logicOptions && context !== 'isEmpty' && (
          <ItemSelectionCnt disable={disable}>
            <CustomSelect
              style={{
                width: isLocationType ? '65px' : '130px',
                marginBottom: 0,
              }}
              grayInput={false}
              isSearchable={false}
              isMulti={false}
              closeMenuOnSelect
              selectedOption={selectedLogicOption}
              options={logicOptions}
              onChange={(item) => {
                onChange({
                  id,
                  condition: { ...condition, logic: item.value },
                });
              }}
            />
            {condition && (
              <Box style={{ minWidth: isLocationType ? '273px' : '335px' }}>
                {getConditionInput(
                  'checklistItem',
                  selectedContext.obj,
                  condition.logic,
                  context,
                )({
                  value: condition.value,
                  onChange: changeHandler,
                })}
              </Box>
            )}

            {deleteHandler && (
              <DeleteCnt onClick={() => deleteHandler(id)}>
                <DeleteIcon />
              </DeleteCnt>
            )}
          </ItemSelectionCnt>
        )}
      </Stack>
    </Box>
  );
};

export default ConditionItem;
