import { Box, Typography } from '@mui/material';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import SettingToggle from 'components/SettingToggle';
import TimeDuration from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeDuration';
import TimeInterval from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeInterval';
import OverdueTaskNotification from 'components/TaskInputs/configurations/overdueTaskNotifications.tsx';
import InfoIcon from '@mui/icons-material/Info';
import {
  DueDateCnt,
  InfoWrapper,
  InfoWrapperCnt,
  MainContainer,
  TimeDurationCnt,
  TimeIntervalCnt,
} from './style';

const Actions = (props) => {
  const {
    data,
    setData,
    updateWatchersCallback,
    switchWatchersCallback,
    switchDueDateCallback,
    isWatchersEnabled,
    isDueDateEnabled,
  } = props;

  const handleUpdateAssignee = (assignees) => {
    setData({
      ...data,
      actions: {
        ...data?.actions,
        assignees: assignees ?? [],
        assigneesRoles: null,
      },
    });
  };

  const handleUpdateRole = (assigneesRoles) => {
    setData({
      ...data,
      actions: {
        ...data?.actions,
        assignees: [],
        assigneesRoles,
      },
    });
  };

  const changeDurationHandler = (_, duration) => {
    if (duration?.length > 4) return;
    setData({
      ...data,
      dueDate: {
        ...data?.dueDate,
        value: duration,
      },
    });
  };

  const changeIntervalHandler = (_, interval) => {
    setData({
      ...data,
      dueDate: {
        ...data?.dueDate,
        unit: interval,
      },
    });
  };

  const handleWatchersToggle = () => {
    switchWatchersCallback?.(!isWatchersEnabled);
  };

  const handleDueDateToggle = () => {
    switchDueDateCallback?.(!isDueDateEnabled);
  };

  const dueDateSettings = (
    <DueDateCnt>
      <div className="inner">
        <TimeDurationCnt>
          <TimeDuration
            value={data?.dueDate?.value}
            handleUpdateSchedule={changeDurationHandler}
          />
        </TimeDurationCnt>
        <TimeIntervalCnt>
          <TimeInterval
            value={data?.dueDate?.unit}
            handleUpdateSchedule={changeIntervalHandler}
          />
        </TimeIntervalCnt>
        <Typography className="statement">
          {' '}
          after work order is created
        </Typography>
      </div>
    </DueDateCnt>
  );

  const updateWatchers = (watchers) => {
    updateWatchersCallback?.(watchers);
  };

  const watchersSettings = (
    <OverdueTaskNotification
      updateWatchers={updateWatchers}
      watchers={data?.watchers}
      notifyLabel="Send notifications to:"
    />
  );
  return (
    <MainContainer>
      <Box className="header">
        <Typography className="title">Action</Typography>
        <Typography className="subTitle">
          Then automatically assign work order to
        </Typography>
      </Box>

      <Box className="triggerContent">
        <Box sx={{ marginBottom: '20px' }}>
          <TaskAssignTo
            handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
            handleChangeRolesCallback={handleUpdateRole}
            placeholderUsersAndTeams="Users & Teams"
            hideUsersAndTeamsLocations={true}
            selectedUsersAndTeams={data?.actions?.assignees ?? []}
            selectedRole={data?.actions?.assigneesRoles}
            updateAssigneeType={null}
            rolesProps={{ hideLocations: true, hideConfig: true }}
          />
        </Box>

        <InfoWrapperCnt>
          <InfoWrapper direction="row" alignItems="center">
            <InfoIcon className="infoIcon" />
            <Typography className="infoText">
              Only selected assignee(s) will be notified about this work order.
            </Typography>
          </InfoWrapper>
        </InfoWrapperCnt>

        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '100%',
            color: 'rgba(33, 33, 33, 1)',
            margin: '10px 0px',
          }}
        >
          Additional Settings
        </Typography>
        <SettingToggle
          title={'Watchers'}
          text={
            'Auto-add users, teams, or roles as watchers and notify them via email.'
          }
          handleSettingToggle={handleWatchersToggle}
          checked={isWatchersEnabled}
          settings={watchersSettings}
        />

        <SettingToggle
          title={'Due Date'}
          text={'Set when the work order should be due after creation.'}
          handleSettingToggle={handleDueDateToggle}
          checked={isDueDateEnabled}
          settings={dueDateSettings}
        />
      </Box>
    </MainContainer>
  );
};

export default Actions;
