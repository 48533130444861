// Custom components
import FilterTerm from './FilterTerm';
import IsOrNot from './IsOrNot';
import AndOr from './AndOr';

// Styled
import {
  RowWrapper,
  DeleteIcon,
  WhereText,
  ResponsiveRowWrapper,
} from './styled';
import StatusFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/StatusFilter/StatusFilter';
import TaskTypeDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/TaskTypeFilter/TaskTypeFilter';
import TaskOverdueDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/TaskOverdueFilter/TaskOverdueFilter';
import CategoryFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/CategoryFilter/categoryFilter';
import LocationFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/LocationFilter/locationFilters';
import TemplateFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/TemplateFilter/TemplateFilter';
import AssetFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/AssetFilter/assetFilters';
import ArchiveFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/ArchiveFilter/archiveFilter';

import useDropdownData from 'utils/CustomHooks/useDropdownData';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useMemo } from 'react';
import { dateFilters } from 'utils/Task/filters';
import EmployeeFilterDropdown from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/EmployeeFilter/EmployeeFilter';
import AssigneeFilterDropdown from 'components/AssigneeFilter/AssigneeFilter';

import {
  dateRangePickerSideMenuCommon,
  dateRangePickerSideMenuFuture,
} from 'utils/datePicker';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import TaskTemplateFilterDropdown from './FilterDropdowns/TaskTemplateFilter/taskTemplateFilter';
import PriorityFilterDropdown from './FilterDropdowns/PriorityFilter/priorityFilter';
import ScheduleFrequencyFilterDropdown from './FilterDropdowns/ScheduleFrequencyFilter/ScheduleFrequencyFilter';
import ProjectsFilterDropdown from './FilterDropdowns/ProjectsFilter/ProjectFilter';
import SchedulesFilterDropdown from './FilterDropdowns/SchedulesFilter/SchedulesFilter';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface FilterRowPropTypes {
  index: number;
  filter: {
    filterName:
      | 'status'
      | 'startDate'
      | 'dueDate'
      | 'template'
      | 'assignee'
      | 'category'
      | 'location'
      | 'asset'
      | 'completedAt'
      | 'archived'
      | 'taskTypes'
      | 'isOverDue'
      | 'taskTemplates'
      | 'projects'
      | 'schedules'
      | 'priority'
      | 'createdBy'
      | '';
    comparator: 'is' | 'isNot' | '';
    value: any;
    conditional: 'and' | 'or' | '';
  };
  hideFilters?: string;
  hideWhere?: boolean;
  handleDeleteRow: (idx: number) => void;
  handleSetFilters: (
    idx: number,
    filterData: {
      filterName?:
        | 'status'
        | 'startDate'
        | 'dueDate'
        | 'template'
        | 'assignee'
        | 'category'
        | 'location'
        | 'asset'
        | 'completedAt'
        | 'taskTemplates'
        | 'projects'
        | 'schedules'
        | 'createdBy'
        | '';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => void;
  dropdownWidth?: any;
}

const FilterRow = ({
  index,
  filter,
  handleDeleteRow,
  handleSetFilters,
  hideFilters = '',
  dropdownWidth = null,
  hideWhere = false,
}: FilterRowPropTypes) => {
  const {
    selectedTasksTemplates,
    selectedProjects,
    selectedSchedules,
    selectedTaskAssignees,
    selectedStatus,
    selectPriority,
    selectFrequency,
    selectTaskLocation,
    selectTaskAsset,
    selectTaskCategories,
    selectedTaskTemplates,
    selectedArchived,
    selectedTaskTypes,
    selectedOverdue,
  } = useDropdownData();
  const { isMobileDeviceSm } = useResponsive();
  const { hasFeature, FEATURES } = useCustomPermission();

  const options = [
    {
      id: 'taskStatuses',
      key: 'taskStatuses',
      label: 'Status',
      component: () => {
        return (
          <StatusFilterDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectedStatus(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'templates',
      key: 'templates',
      label: 'Template',
      component: () => (
        <TemplateFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectedTaskTemplates(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'assignees',
      key: 'assignees',
      label: 'Assignee',
      component: () => (
        <AssigneeFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectedTaskAssignees(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'categories',
      key: 'categories',
      label: 'Category',
      component: () => (
        <CategoryFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectTaskCategories(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'locations',
      key: 'locations',
      label: 'Location',
      component: () => (
        <LocationFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectTaskLocation(filter?.value)}
          showAuthorizedLocations
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'assets',
      key: 'assets',
      label: 'Asset',
      component: () => (
        <AssetFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectTaskAsset(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'createdDate',
      key: 'createdDate',
      label: 'Created Date',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
        />
      ),
    },
    {
      id: 'startDate',
      key: 'futureStartDate',
      label: 'Start Date',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => {
            handleChangeFilter('value', date);
          }}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
        />
      ),
    },
    {
      id: 'dueDate',
      key: 'futureDueDate',
      label: 'Due Date',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
        />
      ),
    },
    {
      id: 'startDate',
      key: 'startDate',
      label: 'Start Date',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuFuture,
          }}
        />
      ),
    },
    {
      id: 'dueDate',
      key: 'dueDate',
      label: 'Due Date',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuFuture,
          }}
        />
      ),
    },
    {
      id: 'completedDate',
      key: 'completedDate',
      label: 'Completed At',
      component: () => (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
        />
      ),
    },
    {
      id: 'completedBy',
      key: 'completedBy',
      label: 'Completed By',
      component: () => (
        <EmployeeFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectedTaskAssignees(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
    {
      id: 'archived',
      key: 'archived',
      label: 'Archived',
      component: () => {
        return (
          <ArchiveFilterDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectedArchived(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'taskTypes',
      key: 'taskTypes',
      label: 'Task Type',
      component: () => {
        return (
          <TaskTypeDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectedTaskTypes(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'isOverDue',
      key: 'isOverDue',
      label: 'Overdue',
      component: () => {
        return (
          <TaskOverdueDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectedOverdue(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'priorities',
      key: 'priorities',
      label: 'Priority',
      component: () => {
        return (
          <PriorityFilterDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectPriority(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'scheduleFrequency',
      key: 'scheduleFrequency',
      label: 'Frequency',
      component: () => {
        return (
          <ScheduleFrequencyFilterDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectFrequency(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'creators',
      key: 'creators',
      label: 'Created By',
      component: () => {
        return (
          <EmployeeFilterDropdown
            onChangeCallback={(option) => handleChangeFilter('value', option)}
            selected={selectedTaskAssignees(filter?.value)}
            buttonWidth={dropdownWidth?.mainDropdownWidth}
          />
        );
      },
    },
    {
      id: 'taskTemplates',
      key: 'taskTemplates',
      label: 'Task Template',
      component: () => (
        <TaskTemplateFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectedTasksTemplates(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },

    ...(hasFeature(FEATURES.PROJECTS)
      ? [
          {
            id: 'projects',
            key: 'projects',
            label: 'Projects',
            component: () => (
              <ProjectsFilterDropdown
                onChangeCallback={(option) =>
                  handleChangeFilter('value', option)
                }
                selected={selectedProjects(filter?.value)}
                buttonWidth={dropdownWidth?.mainDropdownWidth}
              />
            ),
          },
        ]
      : []),
    {
      id: 'schedules',
      key: 'schedules',
      label: 'Schedules',
      component: () => (
        <SchedulesFilterDropdown
          onChangeCallback={(option) => handleChangeFilter('value', option)}
          selected={selectedSchedules(filter?.value)}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      ),
    },
  ];

  const optionsAfterHideFilter = useMemo(
    () => options.filter((option) => !hideFilters?.includes(option.key)),
    [hideFilters, filter],
  );

  const getFilterValues = (type, data) => {
    switch (type) {
      case 'comparator':
      case 'conditional':
      case 'filterName':
        return data;
      default:
        return dateFilters.includes(filter.filterName)
          ? data
          : data?.map((item) => item.id);
    }
  };

  const handleChangeFilter = (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    value: string | string[] | { from: Date | null; to: Date | null },
  ) => {
    handleSetFilters(index, {
      [filterType]: getFilterValues(filterType, value),
      ...(filterType === 'filterName' && { value: [] }),
    });
  };

  return !isMobileDeviceSm ? (
    <RowWrapper>
      {!hideWhere && index === 0 && <WhereText>Where</WhereText>}

      {index > 0 && (
        <AndOr
          disabled={index > 1}
          value={filter.conditional}
          onSelect={handleChangeFilter}
        />
      )}
      <FilterTerm
        value={filter.filterName}
        options={optionsAfterHideFilter}
        onSelect={handleChangeFilter}
        buttonWidth={dropdownWidth?.mainDropdownWidth}
      />
      {filter.filterName && (
        <IsOrNot
          value={filter.comparator}
          onSelect={handleChangeFilter}
          buttonWidth={dropdownWidth?.isDropdownWidth}
        />
      )}
      {optionsAfterHideFilter.map(
        (option) =>
          option.id === filter.filterName && (
            <div className="contentDiv" key={option.key}>
              {option.component()}
            </div>
          ),
      )}
      {filter?.filterName ? (
        <DeleteIcon
          onClick={() => handleDeleteRow(index)}
          sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
        />
      ) : null}
    </RowWrapper>
  ) : (
    <ResponsiveRowWrapper>
      <div className={'filterInfoCnt'}>
        {index === 0 && <WhereText>Where</WhereText>}
        {index > 0 && (
          <AndOr
            disabled={index > 1}
            value={filter.conditional}
            onSelect={handleChangeFilter}
          />
        )}
        <FilterTerm
          value={filter.filterName}
          options={optionsAfterHideFilter}
          onSelect={handleChangeFilter}
        />
        {filter.filterName && (
          <IsOrNot value={filter.comparator} onSelect={handleChangeFilter} />
        )}
      </div>
      <div className={'filterSelectCnt'}>
        {optionsAfterHideFilter.map(
          (option) =>
            option.id === filter.filterName && (
              <div className={'filterSelectInnerCnt'} key={option.id}>
                {option.component()}
              </div>
            ),
        )}
        {filter?.filterName ? (
          <DeleteIcon
            onClick={() => handleDeleteRow(index)}
            sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
          />
        ) : null}
      </div>
    </ResponsiveRowWrapper>
  );
};

export default FilterRow;
