import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';

export const createRoutingRuleCall = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/work-order-routing`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Auto Routing Rule Created Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const editRoutingRuleCall = async (
  data: any = {},
  showToast = true,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/work-order-routings/${data?.id}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      showToast ? 'Auto Routing Rule Updated Successfully!' : '',
      showToast,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getRoutingRulesCall = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/work-order-routings`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getRoutingRuleCall = async (
  data = {},
  ruleId: string,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/work-order-routings/${ruleId}`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteRoutingRuleCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/work-order-routings`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Workflow Deleted Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
