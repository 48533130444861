// React
import React, { useMemo } from 'react';

// Custom components
import Header from 'components/TemplateSubmissionPreview/Header';
import TemplateBody from 'components/TemplateSubmissionPreview/TemplateBody';

// Styled
import { Wrapper, BodyWrapper } from './styled';

// Context
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Utils
import { isEqual } from 'lodash';

// Types
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';
interface locationGroupHashInterface {
  [key: string]: string[]; // Each key is a string, and its value is an array of strings
}
const TemplateSubmissionPreview = ({
  log,
  activeSubmission,
  activeSubmissionItemsFilter,
  conditions,
  handleFilterSubmissionLogItems,
  hideCorrectiveTask = false,
  isFeedbackOnlyLogs = false,
  feedbackActions,
  hideHeader = false,
  isReviewMode = false,
  canReview = false,
  locationGroupHash = {},
}: {
  log?: SubmissionReportType;
  activeSubmission: any;
  conditions: any;
  handleFilterSubmissionLogItems: (filterName: LogFilterTypes) => void;
  activeSubmissionItemsFilter: LogFilterTypes;
  hideCorrectiveTask?: boolean;
  isFeedbackOnlyLogs?: boolean;
  feedbackActions?: any;
  hideHeader?: boolean;
  isReviewMode?: boolean;
  canReview?: boolean;
  locationGroupHash?: locationGroupHashInterface;
}) => {
  const [toggleConditionalSteps, setToggleConditionalSteps] =
    React.useState(true);
  const headerData = useMemo(() => {
    if (log)
      return {
        name: log?.name,
        attachments: log?.attachments,
      };
    return null;
  }, [log]);

  return (
    <Wrapper>
      {!hideHeader && !!headerData && (
        <Header
          activeSubmission={activeSubmission}
          handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
          activeSubmissionItemsFilter={activeSubmissionItemsFilter}
          data={headerData}
          toggleConditionalSteps={toggleConditionalSteps}
          setToggleConditionalSteps={setToggleConditionalSteps}
          canReview={canReview}
        />
      )}
      {log && (
        <BodyWrapper
          sx={{
            ...(hideHeader && {
              padding: 0,
            }),
          }}
        >
          <TemplateBody
            isPreview
            templateLog={log}
            conditions={toggleConditionalSteps ? conditions : null}
            locationGroupHash={locationGroupHash}
            hideCorrectiveTask={hideCorrectiveTask}
            isFeedbackOnlyLogs={isFeedbackOnlyLogs}
            feedbackActions={feedbackActions}
            isReviewMode={isReviewMode}
            canReview={canReview}
            logChecklistItems={log.TaskChecklistItems.map((item) => ({
              ...item,
              ...(log?.anonymousId &&
                log?.submitterName && {
                  Updater: {
                    firstName: log?.submitterName,
                  },
                }),
            }))}
          />
        </BodyWrapper>
      )}
    </Wrapper>
  );
};

const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(TemplateSubmissionPreview, arePropsEqual);
