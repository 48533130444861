import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)({
  backgroundColor: 'rgba(245, 245, 245, 1)',
});

export const StepsContainer = styled(Box)({
  margin: '100px auto',
  display: 'flex',
  justifyContent: 'center',
  '& .innerBox': {
    width: 750,
  },

  '& .arrowIcon': {
    width: '100%',
    height: 55,
    padding: '2px 0px',
    color: '#908d8d',
  },
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  height: '100%',
  width: '100%',
});

export const SaveChangesPrompt = styled(Box)({
  padding: '10px',

  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  '& .title': {
    fontWeight: 800,
    fontSize: 22,
    lineHeight: '30.05px',
  },
  '& .description': {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: '40px',
  },

  '& button': {
    borderRadius: '8px',
  },
});
