import { Box, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  margin: '20px',
  '& .iconCnt': {
    height: 36,
    width: 36,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .actionTitle': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
  },

  '& .statement': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },

  '& .boxContainer': {
    padding: 16,
    border: '1px solid #E0E0E0',
    borderRadius: 16,
    cursor: 'pointer',
  },
});

export const HeadingCnt = styled(Box)(() => ({
  margin: '5px 0px',
  textAlign: 'center',

  '& .title': {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .subTitle': {
    marginTop: '12px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },
}));
