import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { cloneDeep, filter, groupBy, map } from 'lodash';
import { validateItemAutomations } from 'xeniautils';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSelector } from 'react-redux';
import { STEP_TYPES } from 'utils/templates';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { v4 } from 'uuid';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { CHECKLIST_ITEM_TYPE } from 'utils/globalVariable';
import {
  checkVisibilityWithText,
  generateTemplateHashes,
} from 'components/TaskChecklist/utils';
import useLocationGroups from 'pages/Locations/Groups/useLocationGroups';
import { getLocationGroupsState } from 'store/selectors/locationGroups';
import { getLocationsHash } from 'store/selectors/locations';

const useChecklistLog = ({
  logId = undefined,
  taskId = undefined,
  checklistId,
  onChecklistLoadSuccess,
  handleChangeFormattedData,
}: {
  logId?: string;
  taskId?: string;
  checklistId: string;
  onChecklistLoadSuccess?: () => void;
  handleChangeFormattedData?: (data: any) => void;
}) => {
  const { workspaceId } = useWorkspaceHook();
  const currentUser = useSelector((state: any) => state?.authData?.user);
  const { getNowInUtc } = useDateTime();

  const [localChecklistLog, setLocalChecklistLog] = <any>useState(null);
  const [checkListLogHashes, setCheckListLogHashes] = <any>useState(null);
  const localChecklistLogRef = useRef<any>(null);
  const [checklistLog, setChecklistLog] = <any>useState(null);

  const [automations, setAutomations] = <any>useState(null);
  const [conditionVisibility, setConditionVisibility] = <any>useState(null);
  const [childItemsMap, setChildItemsMap] = <any>useState(null);
  const childItemsMapRef = useRef<any>(null);
  const [itemErrors, setItemErrors] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [currentItemId, setCurrentItemId] = useState<any>(null);
  const [checklistProgAndScore, setChecklistProgAndScore] = useState<any>({});
  const deletedNoteIds = useRef<any>([]);
  const deletedGlobalNoteIds = useRef<any>([]);
  const dirtyItems = useRef<any>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState<string[]>([]);
  const locationGroups = useSelector(getLocationGroupsState);
  const locationHashes = useSelector(getLocationsHash);

  const finalLogId = useMemo(() => {
    return logId ? logId : localChecklistLog ? localChecklistLog.id : null;
  }, [logId, localChecklistLog]);
  const locationGroupHash = useMemo(() => {
    const locationGroupHashes = {};
    locationGroups.forEach((item) => {
      locationGroupHashes[item?.id] = item?.LocationGroupLocations.flatMap(
        (l) => {
          const sublocations = locationHashes?.[l.LocationId]?.Sublocations;
          if (sublocations) {
            const sublocationKeys = Object.keys(sublocations);
            return [l?.LocationId, ...sublocationKeys];
          }
          return l?.LocationId;
        },
      );
    });
    return locationGroupHashes;
  }, [locationGroups]);
  useEffect(() => {
    handleChangeFormattedData?.(
      handleFormatDataForSavingState(localChecklistLogRef.current),
    );
  }, [
    localChecklistLogRef.current,
    deletedGlobalNoteIds.current,
    dirtyItems.current,
    deletedNoteIds.current,
    checklistProgAndScore,
  ]);

  // Only set item errors initially
  useEffect(() => {
    if (localChecklistLog && Object.keys(itemErrors).length === 0) {
      setItemErrors(getInitialErrors(localChecklistLog));
    }
  }, [localChecklistLog]);

  // fetch checklistLog automations on checklistId change
  useEffect(() => {
    if (checklistId) {
      getChecklistAutomations(checklistId);
      getChecklistConditionalVisibility(checklistId);
    }
  }, [checklistId]);

  // fetch checklistLog log if logId is present or start new
  useEffect(() => {
    if (!dataLoaded) {
      return;
    }
    if (finalLogId) {
      handleContinue(finalLogId);
    } else if (checklistId) {
      handleStart(checklistId);
    }
  }, [finalLogId, checklistId, dataLoaded]);

  // checks error for the current item being changed
  useEffect(() => {
    if (currentItemId && localChecklistLog) {
      const item = localChecklistLog?.TaskChecklistItems?.find(
        (item) => item.id === currentItemId,
      );
      setSingleItemError(item);
    }
  }, [currentItemId, localChecklistLog]);

  const setEssentialStates = (checklistLog) => {
    localChecklistLogRef.current = checklistLog;
    setLocalChecklistLog(checklistLog);
    const generatedTemplateLogHashes = generateTemplateHashes(
      checklistLog.TaskChecklistItems,
    );
    setCheckListLogHashes(generatedTemplateLogHashes);
    setChecklistLog(checklistLog);
    const childMap = getChildItemsMap(checklistLog);
    childItemsMapRef.current = childMap;
    setChildItemsMap(childMap);
    setChecklistProgAndScore(
      extractChecklistProgAndScore(
        calculateChecklistProgAndScore(
          checklistLog,
          generatedTemplateLogHashes,
        ),
      ),
    );
  };

  const extractChecklistProgAndScore = (log) => {
    const sections = log.TaskChecklistItems.filter(
      (item) => item.type === 'header',
    );
    const newCount = {
      progress: log.progress,
      score: log.score,
      sectionScores: sections.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: {
            progress: curr.progress,
            score: curr.score,
          },
        }),
        {},
      ),
    };
    return newCount;
  };

  const setSectionIds = (templateItems) => {
    let sectionId: string | null = null;
    return templateItems.map((item) => {
      if (item.type === STEP_TYPES.HEADER) {
        sectionId = item.id;
      } else if (sectionId) {
        item.sectionId = sectionId;
      }
      return item;
    });
  };
  // starts a new checklistLog log
  const handleStart = async (checklistId: string) => {
    setLoading(true);
    const newLog = await xeniaApi.createChecklistLog({
      checklistId,
      hotelId: workspaceId,
      postData: {
        ...(taskId ? { taskId } : {}),
      },
    });
    if (newLog?.data) {
      const items = setSectionIds(newLog.data.TaskChecklistItems);
      setEssentialStates({ ...newLog.data, TaskChecklistItems: items });
    }

    setLoading(false);
    onChecklistLoadSuccess?.();
  };

  // continue a checklistLog log
  const handleContinue = async (checklistLogId: string) => {
    const logData = await xeniaApi.getChecklistLog({
      logId: checklistLogId,
      hotelId: workspaceId,
    });
    if (logData?.data) {
      const items = setSectionIds(logData.data.TaskChecklistItems);
      setEssentialStates({ ...logData.data, TaskChecklistItems: items });
    }

    setLoading(false);
    onChecklistLoadSuccess?.();
  };

  const getChecklistAutomations = async (checklistId: string) => {
    setDataLoaded(false);
    const automations = await xeniaApi.getChecklistAutomations({
      workspaceId,
      checklistId,
    });
    const groupByAutomations = groupBy(
      map(automations, (automation) => ({
        id: automation.id,
        conditions: automation.conditions,
        actions: automation.actions,
        EntityId: automation.EntityId,
        entityType: automation.entityType,
      })),
      'EntityId',
    );
    setAutomations(groupByAutomations);
    setDataLoaded(true);
  };
  const { getLocationGroups } = useLocationGroups();

  useEffect(() => {
    getLocationGroups(); // fetch location groups
  }, []);
  const getChecklistConditionalVisibility = async (checklistId: string) => {
    setDataLoaded(false);
    const conditionalVisibility = await xeniaApi.fetchConditionalVisbility({
      checklistId,
    });
    const groudedConditions = groupBy(
      map(conditionalVisibility, (condition) => ({
        id: condition.id,
        context: condition.context,
        contextId: condition.contextId,
        itemId: condition.itemId,
        condition: condition.condition,
        action: condition.action,
        checklistId: condition.checklistId,
      })),
      'itemId',
    );
    setConditionVisibility(groudedConditions);
    setDataLoaded(true);
  };

  const getInitialErrors = (checklistLog) => {
    const errors = {};
    checklistLog?.TaskChecklistItems?.forEach(
      (cItem) => (errors[cItem.id] = []),
    );
    return errors;
  };

  const resetItemErrors = () => {
    setItemErrors(getInitialErrors(localChecklistLog));
  };

  const getChildItemsMap = (checklistLog) => {
    const keyedChildItems = groupBy(
      filter(checklistLog?.TaskChecklistItems, (item) => item?.ParentId),
      'ParentId',
    );
    return keyedChildItems;
  };

  const getFollowupQuestions = (item) => {
    if (!item?.answers?.value) return null;

    const conditionData = {
      answer: item.answers.value,
      itemType: item.type,
      automations: automations?.[item.ChecklistItemId],
    };

    const actions = validateItemAutomations(conditionData);

    const followupAction: any = actions.find(
      (action: any) => action.type === AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
    );
    const childItems = childItemsMapRef.current;
    if (!followupAction || !childItems?.[item?.ChecklistItemId]?.length)
      return null;

    const childs = childItems[item.ChecklistItemId];
    const childItemsGroupByAutomation = groupBy(childs, 'RelatedAutomationId');

    return childItemsGroupByAutomation[followupAction?.automationId];
  };

  const getVisibleChecklistItems = (items) => {
    const rootItems = items.filter((item) => !item.ParentId);
    const addStepsRecursively = (items, visibleFollowUp = false) => {
      let steps: any[] = [];
      items.forEach((item) => {
        if (
          item.type === STEP_TYPES.HEADER ||
          !item.ParentId ||
          visibleFollowUp
        ) {
          steps.push(item);
        }
        const followUpQuestions = getFollowupQuestions(item);
        if (followUpQuestions && followUpQuestions?.length) {
          steps = [...steps, ...addStepsRecursively(followUpQuestions, true)];
        }
      });
      return steps;
    };

    return addStepsRecursively(rootItems);
  };

  const getAutomationActions = (item) => {
    const conditionData = {
      answer: item?.answers?.value,
      itemType: item?.type,
      automations: automations?.[item.ChecklistItemId],
    };
    return validateItemAutomations(conditionData);
  };

  const getAutomationErrors = (item) => {
    let actions = getAutomationActions(item);
    actions = actions.filter(
      (a) => a.type !== AUTOMATION_ACTIONS.SEND_NOTIFICATION,
    );
    const errObj: {
      hasError: boolean;
      error: string | null;
      isAutomation: boolean;
    } = {
      hasError: false,
      error: null,
      isAutomation: actions?.length > 0,
    };

    for (let i = 0; i < actions.length; i++) {
      if (errObj.hasError) break;
      const element = actions[i];
      if (element.type === AUTOMATION_ACTIONS.REQUITE_TASK) {
        if (item.Tasks.length > 0) {
          errObj['hasError'] = false;
          errObj['error'] = null;
        } else {
          errObj['hasError'] = true;
          errObj['error'] =
            'Your response requires you to create a task for completion';
        }
      }
      if (element.type === AUTOMATION_ACTIONS.REQUIRE_IMAGE) {
        if (
          item.TaskChecklistItemNotes.some(
            (note) => note.attachments.length > 0,
          )
        ) {
          errObj['hasError'] = false;
          errObj['error'] = null;
        } else {
          errObj['hasError'] = true;
          errObj['error'] = 'Your response requires you to add an image';
        }
      }
    }

    return errObj;
  };

  const getItemError = (cItem: any) => {
    const automationErrors = getAutomationErrors(cItem);
    if (automationErrors.hasError) {
      return automationErrors.error;
    }
    const imageRequired =
      cItem.isImageOrNoteRequired &&
      !cItem?.TaskChecklistItemNotes?.some(
        (note) => note?.attachments?.length > 0,
      );

    const hasAnswers = Array.isArray(cItem.answers)
      ? cItem.answers?.value?.length > 0
      : !!cItem.answers?.value;

    if (!hasAnswers) {
      if (imageRequired) {
        return 'This step requires an Image';
      } else if (cItem.required) {
        return 'This step is required';
      } else if (
        cItem.type === 'instruction' &&
        cItem.options.requireAcknowledgement
      ) {
        return 'This step requires acknowledgement';
      }
    } else if (imageRequired) {
      return 'This step requires an Image';
    }
    return null;
  };

  const getCurrentItemFollowUpQuestions = (item, checklistItems) => {
    const automationActions = getAutomationActions(item);
    const followUpQuestions = checklistItems?.filter(
      (fItem) =>
        fItem.ParentId === item.ChecklistItemId &&
        automationActions?.length > 0 &&
        automationActions?.some(
          (a) => a.type === AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
        ),
    );
    return followUpQuestions || [];
  };

  const requiredItemCount = useMemo(() => {
    const parentItems = localChecklistLog?.TaskChecklistItems?.reduce(
      (acc, item) => {
        // Check if the item is hidden based on your visibility logic
        const isHidden = checkVisibilityWithText(
          conditionVisibility,
          item,
          checkListLogHashes,
          true,
          locationGroupHash,
        );

        // If the item is a header and hidden, add its ID to the hiddenHeaderIds set
        if (isHidden && item?.type === STEP_TYPES.HEADER) {
          acc?.hiddenHeaderIds?.add(item?.id);
        }

        // If the item is not hidden and is a parent item (ParentId is null)
        if (
          !isHidden &&
          !acc?.hiddenHeaderIds?.has(item?.sectionId) &&
          item?.ParentId === null
        ) {
          acc?.filteredItems?.push(item);
        }

        return acc;
      },
      { hiddenHeaderIds: new Set(), filteredItems: [] },
    ).filteredItems;
    const shouldCount = (cItem, prevCount) => {
      const isStepRequired = cItem.required;
      const isInstructionRequired =
        cItem.type === 'instruction' && cItem.options.requireAcknowledgement;
      const isImageRequired =
        cItem.isImageOrNoteRequired &&
        !cItem?.TaskChecklistItemNotes?.some(
          (note) => note?.attachments?.length > 0,
        );
      const isAnswered = !!cItem?.answers?.value;
      const automationErrors = getAutomationErrors(cItem);

      if (isImageRequired) return prevCount + 1;
      if (automationErrors.hasError) return prevCount + 1;

      const shouldCount =
        (isStepRequired ||
          isInstructionRequired ||
          isImageRequired ||
          automationErrors.hasError) &&
        !isAnswered;

      return shouldCount ? prevCount + 1 : prevCount;
    };

    const parentRequiredItemCount = parentItems?.reduce((prev, curr) => {
      return shouldCount(curr, prev);
    }, 0);

    let followUpRequiredItemCount = 0;
    localChecklistLog?.TaskChecklistItems?.forEach((cItem) => {
      const followUpQuestions: any = getFollowupQuestions(cItem);
      if (followUpQuestions?.length > 0) {
        followUpQuestions.forEach((followUp) => {
          followUpRequiredItemCount += shouldCount(followUp, 0);
        });
      }
    });

    return parentRequiredItemCount + followUpRequiredItemCount;
  }, [localChecklistLog?.TaskChecklistItems]);

  const requiredItems = useMemo(() => {
    const checkRequirementCriteria = (item, skipParentCheck = false) => {
      const itemRequired = item.required;
      const itemInstructionRequired =
        item.type === 'instruction' && item.options.requireAcknowledgement;
      const itemImageRequired = item.isImageOrNoteRequired;
      const isHeaderItem = item.type === 'header';
      const isParentItem = skipParentCheck ? true : item.ParentId === null;
      const automationData = getAutomationErrors(item);
      const isRequiredAutomation =
        automationData.isAutomation && automationData.hasError;
      if (itemImageRequired && isParentItem) {
        return true;
      }
      return (
        (itemRequired ||
          itemInstructionRequired ||
          isHeaderItem ||
          isRequiredAutomation) &&
        isParentItem
      );
    };

    return localChecklistLog?.TaskChecklistItems?.filter((item) => {
      const hasRequirementCriteria = checkRequirementCriteria(item);
      const followUpQuestions = getCurrentItemFollowUpQuestions(
        item,
        localChecklistLog?.TaskChecklistItems,
      );
      const hasRequiredFollowUp = followUpQuestions?.some((fItem) =>
        checkRequirementCriteria(fItem, true),
      );
      return hasRequirementCriteria || hasRequiredFollowUp;
    });
  }, [localChecklistLog]);

  const getRequiredStepsErrors = (log) => {
    const items = log?.TaskChecklistItems?.reduce(
      (acc, item) => {
        const isHidden = checkVisibilityWithText(
          conditionVisibility,
          item,
          checkListLogHashes,
          true,
          locationGroupHash,
        );
        if (isHidden && item?.type === STEP_TYPES.HEADER) {
          acc?.hiddenHeaderIds?.add(item.id);
        }

        if (!isHidden && !acc?.hiddenHeaderIds?.has(item?.sectionId)) {
          acc?.filteredItems?.push(item);
        }
        return acc;
      },
      { hiddenHeaderIds: new Set(), filteredItems: [] },
    ).filteredItems;
    const _errors = getInitialErrors(log);

    const appendError = (itemId: string, error: string) => {
      _errors[itemId] = [..._errors[itemId], error];
    };
    // write logic here for required checklistLog item and set errors
    // does not check for follow up item
    items.forEach((cItem) => {
      if (cItem.type !== 'header' && cItem.ParentId === null) {
        const error = getItemError(cItem);
        if (error) {
          appendError(cItem.id, error);
        }
      }
    });

    // check for follow up questions only
    items.forEach((cItem) => {
      const _followUps: any = getFollowupQuestions(cItem);
      if (cItem.type !== 'header' && _followUps?.length > 0) {
        _followUps.forEach((followUp) => {
          const error = getItemError(followUp);
          if (error) {
            appendError(followUp.id, error);
          }
        });
      }
    });

    return {
      errors: _errors,
      hasErrors: Object.values(_errors).some((e: any) => e.length > 0),
    };
  };

  const checkErrorsBeforeSubmission = () => {
    const { errors, hasErrors } = getRequiredStepsErrors(localChecklistLog);
    setItemErrors(errors);
    return !hasErrors;
  };

  const setItemError = ({ item, error }) => {
    const erroredChecklist = cloneDeep(checklistLog);
    const erroredItem = cloneDeep(item);
    erroredItem.error = error;
    erroredChecklist.TaskChecklistItems = checklistLog.TaskChecklistItems.map(
      (i) => {
        if (item.id === i.id) {
          return erroredItem;
        }
        return i;
      },
    );
    setChecklistLog(erroredChecklist);
  };

  const setSingleItemError = (item: any) => {
    const error = getItemError(item);
    setItemErrors({
      ...itemErrors,
      [item.id]: error ? [error] : [],
    });
  };

  const isItemAnswered = (item: any) => {
    const { options, type, answers } = item;

    const optionValues =
      type === STEP_TYPES.PASS_FAIL
        ? map(options, 'value')
        : map(options, 'id');

    switch (type) {
      case STEP_TYPES.PROCEDURE:
        return !!answers?.value;

      case STEP_TYPES.TAKE_PHOTO:
      case STEP_TYPES.PHOTO_ANNOTATE:
        return !!answers?.value?.length;

      case STEP_TYPES.PASS_FAIL:
        return !!optionValues.includes(answers?.value);

      case STEP_TYPES.MULTIPLE_CHOICE:
        return !!optionValues.includes(answers?.value);

      case STEP_TYPES.SIGNATURE:
        return !!(answers?.value?.text || answers?.value?.digital);

      case STEP_TYPES.INSTRUCTION:
        return !!(answers?.value?.length || !options?.requireAcknowledgement);

      case STEP_TYPES.DROPDOWN:
        return !!answers?.value?.length;

      case STEP_TYPES.GEO_STAMP:
        return !!answers?.value?.address?.length;

      case STEP_TYPES.TEXT_FIELD:
      case STEP_TYPES.DATE_TIME:
      case STEP_TYPES.NUMBER:
      case STEP_TYPES.TEMPERATURE:
      case STEP_TYPES.COST:
      case STEP_TYPES.LOCATION:
        return !!answers?.value?.length;
      default:
        return false;
    }
  };

  const getScoreMultiplicationFactor = (maxScore, weight) => {
    let factor = 1;

    if (!_.isEqual(maxScore, weight) && maxScore !== 0) {
      factor = weight / maxScore;
    }

    return factor;
  };

  const getItemScore = (item) => {
    const { type, answers, options, unit } = item;

    switch (type) {
      case STEP_TYPES.PASS_FAIL:
        return answers?.value === 'pass' ? item.score || 0 : 0;

      case STEP_TYPES.MULTIPLE_CHOICE: {
        if (item.score === null) {
          return 0;
        }
        const maxScore =
          _.maxBy(
            _.map(options, (op) => {
              if (_.has(op, 'score')) {
                return op;
              }

              return { ...op, score: 1 };
            }),
            'score',
          )?.score ?? 0;

        const selectedOption = _.find(options, { id: answers?.value });

        const multiplicationFactor = getScoreMultiplicationFactor(
          maxScore,
          item.score,
        );

        return selectedOption?.score === null
          ? null
          : _.round(selectedOption?.score ?? 0 * multiplicationFactor, 2);
      }
      case STEP_TYPES.DROPDOWN: {
        if (item.score === null) {
          return 0;
        }
        let maxScore =
          _.maxBy(
            _.map(options, (op) => {
              if (_.has(op, 'score')) {
                return op;
              }

              return { ...op, score: 1 };
            }),
            'score',
          )?.score ?? 0;

        if (unit === 'multiple') {
          maxScore = _.sumBy(options, (o: any) => (o.score > 0 ? o.score : 0));
        }
        const selectedOptions = _.filter(options, (o) =>
          answers?.value?.includes(o.id),
        );
        const multiplicationFactor = getScoreMultiplicationFactor(
          maxScore,
          item.score,
        );

        const selectedOptionsScore = _.sumBy(
          selectedOptions,
          (o) => o.score ?? 0,
        );
        const allSelectedOptionsScoreNull = _.every(
          selectedOptions,
          (o) => o.score === null,
        );

        return allSelectedOptionsScoreNull
          ? null
          : _.round((selectedOptionsScore ?? 0) * multiplicationFactor, 2);
      }

      case STEP_TYPES.PROCEDURE:
      case STEP_TYPES.TAKE_PHOTO:
      case STEP_TYPES.SIGNATURE:
      case STEP_TYPES.INSTRUCTION:
      case STEP_TYPES.GEO_STAMP:
      case STEP_TYPES.TEXT_FIELD:
      case STEP_TYPES.DATE_TIME:
      case STEP_TYPES.NUMBER:
      case STEP_TYPES.TEMPERATURE:
      case STEP_TYPES.COST:
      case STEP_TYPES.LOCATION:
      case STEP_TYPES.PHOTO_ANNOTATE:
        return item.score || 0;
      default:
        return false;
    }
  };

  const getScorableItems = (items) => {
    return items.filter((item: any) => item.type !== STEP_TYPES.HEADER);
  };

  const calculateChecklistProgress = (checklistLog) => {
    let completedCount = 0;
    const items = getScorableItems(
      getVisibleChecklistItems(checklistLog.TaskChecklistItems),
    );
    const count = items.length || 0;
    if (count) {
      items.forEach((item) => {
        if (isItemAnswered(item)) completedCount += 1;
      });
    }

    const progress = completedCount ? (completedCount / count) * 100 : 0;

    return {
      progress: parseInt(String(progress), 10),
      count,
      completedCount,
    };
  };

  const calculateChecklistScore = (checklistLog) => {
    const log = { ...checklistLog };

    let sectionId: any = null;
    const sectionProgressMap = {};
    const sectionScoreMap = {};
    let total = 0;
    let completed = 0;

    let checklistTotalWeight = 0;
    let sectionTotalWeight = 0;
    let checklistTotalScore = 0;
    let sectionTotalScore = 0;
    const visibleChecklistItems = getVisibleChecklistItems(
      log.TaskChecklistItems,
    );

    _.each(visibleChecklistItems, (item) => {
      if (item.type === STEP_TYPES.HEADER) {
        sectionId = item.id;
        total = 0;
        completed = 0;
        sectionTotalWeight = 0;
        sectionTotalScore = 0;
        sectionScoreMap[sectionId] = {
          total,
          earned: 0,
          percentage: 0,
        };
      } else if (sectionId) {
        item.sectionId = sectionId;
        total += 1;
        checklistTotalWeight += item.score || 0;
        sectionTotalWeight += item.score || 0;
        if (isItemAnswered(item)) {
          const score = getItemScore(item);
          if (
            score === null &&
            (item.type === STEP_TYPES.MULTIPLE_CHOICE ||
              item.type === STEP_TYPES.DROPDOWN)
          ) {
            sectionTotalWeight -= item.score || 0;
            checklistTotalWeight -= item.score || 0;
          }

          completed += 1;
          checklistTotalScore += score;
          sectionTotalScore += score;
          item.scoring = {
            earned: score,
            total: item.score || 0,
          };
        } else {
          item.scoring = {
            earned: 0,
            total: item.score || 0,
          };
        }

        sectionProgressMap[sectionId] = {
          total,
          completed,
        };

        sectionScoreMap[sectionId] = {
          total: sectionTotalWeight,
          earned: sectionTotalScore,
          percentage: Math.ceil(
            (sectionTotalScore / (sectionTotalWeight || 1)) * 100,
          ),
        };
      }
    });

    _.each(visibleChecklistItems, (item) => {
      if (item.type === STEP_TYPES.HEADER) {
        item.progress = sectionProgressMap[item.id];
        item.score = sectionScoreMap[item.id];
      }
    });

    return {
      total: checklistTotalWeight,
      earned: checklistTotalScore,
      percentage: Math.ceil(
        (checklistTotalScore / (checklistTotalWeight || 1)) * 100,
      ),
    };
  };

  const calculateChecklistProgAndScore = (checklistLog, logHashes) => {
    const log = { ...checklistLog };
    const conditionalVisibilityData = log?.TaskChecklistItems?.reduce(
      (acc, item) => {
        const isHidden = checkVisibilityWithText(
          conditionVisibility,
          item,
          logHashes,
          true,
          locationGroupHash,
        );
        if (isHidden && item.type === STEP_TYPES.HEADER) {
          acc.hiddenHeaderIds.add(item.id);
        }

        if (!isHidden && !acc.hiddenHeaderIds.has(item.sectionId)) {
          acc.filteredItems.push(item);
        }
        return acc;
      },
      { hiddenHeaderIds: new Set(), filteredItems: [] },
    ).filteredItems;

    log.score = calculateChecklistScore({
      ...log,
      TaskChecklistItems: conditionalVisibilityData,
    });
    log.progress = calculateChecklistProgress({
      ...log,
      TaskChecklistItems: conditionalVisibilityData,
    });
    return log;
  };

  const handleUpdateLocalChecklistItem = (item, answer) => {
    const _localChecklist = cloneDeep(localChecklistLogRef.current);
    _localChecklist.lastItemUpdatedAt = getNowInUtc();
    _localChecklist.TaskChecklistItems = _localChecklist.TaskChecklistItems.map(
      (i) => {
        if (item.id === i.id) {
          return {
            ...i,
            answers: {
              value: answer,
            },
            completedAt: answer ? new Date().toISOString() : null,
            Updater: currentUser,
          };
        }
        return i;
      },
    );
    setEssentialStates(_localChecklist);
    const updatedItem = _localChecklist.TaskChecklistItems.find(
      (i) => i.id === item.id,
    );
    return {
      updatedItem,
      updatedLog: _localChecklist,
    };
  };

  const handleRunAutomation = async (
    item,
    attachment = null,
    checklistDefaultLocation = null,
  ) => {
    const itemActions = getAutomationActions(item);
    const actions = itemActions.filter(
      (action) =>
        [
          AUTOMATION_ACTIONS.SEND_NOTIFICATION,
          AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE,
        ].includes(action.type) &&
        (action?.data?.when === 'immediate' ||
          action?.data?.sms?.when === 'immediate'),
    );
    if (actions.length === 0) {
      setPreviousAnswers([]);
      return;
    }
    if (
      item?.type === CHECKLIST_ITEM_TYPE.DROPDOWN &&
      item?.unit === 'multiple'
    ) {
      const currentAnswers: string[] = item?.answers?.value || [];
      const filteredAnswers = currentAnswers?.filter(
        (answer: string) => !previousAnswers?.includes(answer),
      );
      setPreviousAnswers(currentAnswers);
      if (filteredAnswers?.length > 0) {
        const updatedItem = {
          ...item,
          answers: {
            value: filteredAnswers,
          },
        };
        await xeniaApi.runNotificationAutomation({
          item: updatedItem,
          logId: finalLogId,
          attachment: attachment,
          checklistDefaultLocationId: checklistDefaultLocation,
        });
      }
    } else {
      await xeniaApi.runNotificationAutomation({
        item,
        logId: finalLogId,
        attachment: attachment,
        checklistDefaultLocationId: checklistDefaultLocation,
      });
    }
  };

  const handleMarkItemAsDirty = (item) => {
    dirtyItems.current[item.id] = true;
  };

  const handleFormatDataForSavingState = (checklistLog) => {
    if (!checklistLog || !checklistLog?.TaskChecklistItems) return {};

    const dirtyItemIds = Object.keys(dirtyItems.current);
    const dirtyItemsArray = checklistLog.TaskChecklistItems.filter((item) =>
      dirtyItemIds.includes(item.id),
    ).map((i) => ({
      id: i.id,
      answers: i.answers,
      completedAt: i.completedAt,
      type: i.type,
      ChecklistItemId: i.ChecklistItemId,
    }));
    let itemNotes: any[] = [];
    checklistLog.TaskChecklistItems.forEach((item) => {
      const currItemNotes = item.TaskChecklistItemNotes.map((note) => ({
        id: note.id,
        text: note.text,
        attachments: note.attachments,
        TaskChecklistItemId: item.id,
      }));
      if (currItemNotes.length) {
        itemNotes = [...itemNotes, ...currItemNotes];
      }
    });
    const logNotes: any[] = [];
    checklistLog.TaskChecklistNotes.forEach((note) => {
      logNotes.push({
        id: note.id,
        text: note.text,
        attachments: note.attachments,
      });
    });
    return {
      items: dirtyItemsArray,
      itemNotes,
      logNotes,
      logProgress: {
        count: checklistProgAndScore?.progress?.count,
        completedCount: checklistProgAndScore?.progress?.completedCount,
        progress: checklistProgAndScore?.progress?.progress,
        score: checklistProgAndScore?.score?.earned,
        totalScore: checklistProgAndScore?.score?.total,
        scorePercentage: checklistProgAndScore?.score?.percentage,
      },
      deleteItemNotes: deletedNoteIds.current,
      deleteLogNotes: deletedGlobalNoteIds.current,
      lastItemUpdatedAt: checklistLog.lastItemUpdatedAt,
    };
  };

  const handleStatus = ({
    item,
    response,
    attachment = null,
    checklistDefaultLocation = null,
  }) => {
    setCurrentItemId(item.id);
    const { updatedItem, updatedLog } = handleUpdateLocalChecklistItem(
      item,
      response?.answers?.value,
    );
    handleRunAutomation(updatedItem, attachment, checklistDefaultLocation);
    handleMarkItemAsDirty(updatedItem);
    return {
      updatedItem,
      updatedLog,
      formattedData: handleFormatDataForSavingState(updatedLog),
    };
  };

  // set error or refresh checklistLog after creation / update
  const handleNotePostToItemUpdateResponse = async ({
    res,
    item,
    response = null,
  }) => {
    if (res?.error) {
      const error = res?.error?.message || 'Something went wrong, try again.';
      setItemError({ item, error });
    } else if (res.data) {
      if (response) {
        const res = await handleStatus({ item, response });
      }
      handleContinue(checklistLog?.id);
    }
  };

  const getNewNote = (
    { note = '', attachments = [] },
    prevNote: any = undefined,
  ) => {
    return {
      id: prevNote?.id ?? v4(),
      attachments,
      text: note,
      createdAt: prevNote?.createdAt ?? moment.now(),
      updatedAt: moment.now(),
      deletedAt: null,
      // TaskChecklistItemId:"3a382770-856a-46fa-9593-1ab2464d16e9"
      CreatedBy: currentUser.id,
      // UpdatedBy:null
      Creator: currentUser,
    };
  };

  const handleMarkDeletedNote = (noteId) => {
    deletedNoteIds.current.push(noteId);
  };

  const handleMarkGlobalDeletedNote = (noteId) => {
    deletedGlobalNoteIds.current.push(noteId);
  };

  // create an item note in checklistLog
  const handleNote = useCallback(
    async ({ item, note = '', attachments = [], response = null }) => {
      if (!note?.trim().length && !attachments?.length) return null;
      const log = { ...localChecklistLogRef.current };
      const currItem = log.TaskChecklistItems.find(
        (cItem) => cItem.id === item.id,
      );
      const newNote = getNewNote({ note, attachments });
      if (currItem) {
        currItem.TaskChecklistItemNotes.push(newNote);
        log.TaskChecklistItems = log.TaskChecklistItems.map((cItem) =>
          cItem.id === item.id ? currItem : cItem,
        );
        setEssentialStates(log);
      }
    },
    [checklistLog?.id, handleStatus, setItemError, finalLogId],
  );

  // update an item note
  const handleUpdateNote = useCallback(
    async ({
      item,
      note = '',
      attachments = [],
      response = null,
      noteId = null,
    }) => {
      if (!note?.trim().length && !attachments?.length) return null;
      const log = { ...localChecklistLogRef.current };
      const currItem = log.TaskChecklistItems.find(
        (cItem) => cItem.id === item.id,
      );
      const prevNote = currItem?.TaskChecklistItemNotes.find(
        (n) => n.id === noteId,
      );
      if (currItem && prevNote) {
        const updatedNote = getNewNote({ note, attachments }, prevNote);
        currItem.TaskChecklistItemNotes = currItem.TaskChecklistItemNotes.map(
          (n) => (n.id === noteId ? updatedNote : n),
        );
        log.TaskChecklistItems = log.TaskChecklistItems.map((cItem) =>
          cItem.id === item.id ? currItem : cItem,
        );
        setEssentialStates(log);
      }
    },
    [checklistLog?.id, handleStatus, setItemError, finalLogId],
  );

  // delete single item note from checklistLog
  const handleDeleteChecklistItemNote = useCallback(
    async ({ noteId = null, item }) => {
      const log = { ...localChecklistLogRef.current };
      const currItem = log.TaskChecklistItems.find(
        (cItem) => cItem.id === item.id,
      );
      if (currItem && noteId) {
        currItem.TaskChecklistItemNotes =
          currItem.TaskChecklistItemNotes.filter((note) => note.id !== noteId);
        log.TaskChecklistItems = log.TaskChecklistItems.map((cItem) =>
          cItem.id === item.id ? currItem : cItem,
        );
        setEssentialStates(log);
        handleMarkDeletedNote(noteId);
      }
    },
    [checklistLog?.id, handleStatus, setItemError, finalLogId],
  );

  // create checklistLog main note
  const handleChecklistNote = useCallback(
    async ({ note = '', attachments = [] }) => {
      if (!note?.trim().length && !attachments?.length) return null;
      const log = { ...localChecklistLogRef.current };
      const newNote = getNewNote({ note, attachments });
      log.TaskChecklistNotes.push(newNote);
      setEssentialStates(log);
    },
    [checklistLog, setChecklistLog, logId],
  );

  // update checklistLog main note
  const handleUpdateChecklistNote = useCallback(
    async ({ note = '', attachments = [], noteId }) => {
      if (!note?.trim().length && !attachments?.length) return null;
      const log = { ...localChecklistLogRef.current };

      const prevNote = log.TaskChecklistNotes.find((n) => n.id === noteId);
      if (prevNote) {
        const newNote = getNewNote({ note, attachments }, prevNote);
        log.TaskChecklistNotes = log.TaskChecklistNotes.map((n) =>
          n.id === noteId ? newNote : n,
        );
        setEssentialStates(log);
      }
    },
    [checklistLog, setChecklistLog, logId],
  );

  // update checklistLog main note
  const handleDeleteChecklistNote = useCallback(
    async ({ noteId }) => {
      const log = { ...localChecklistLogRef.current };
      log.TaskChecklistNotes = log.TaskChecklistNotes.filter(
        (n) => n.id !== noteId,
      );
      setEssentialStates(log);
      noteId && handleMarkGlobalDeletedNote(noteId);
    },
    [checklistLog, setChecklistLog, logId],
  );

  const createCorrectiveTaskCb = useCallback(
    ({
      task,
      item,
      checklistLog,
    }: {
      task: any;
      item: any;
      checklistLog?: any;
    }) => {
      const _localChecklist = cloneDeep(
        checklistLog ?? localChecklistLogRef.current,
      );
      const _item = _localChecklist?.TaskChecklistItems?.find(
        (cItem) => cItem.id === item.id,
      );
      _item.Tasks = [..._item.Tasks, task];
      _localChecklist.TaskChecklistItems =
        _localChecklist.TaskChecklistItems.map((i) => {
          if (i.id === item.id) {
            return _item;
          }
          return i;
        });
      setEssentialStates(_localChecklist);
      setCurrentItemId(item.id);
    },
    [localChecklistLog, checklistLog],
  );

  return {
    loading,
    localChecklist: localChecklistLog,
    checkListLogHashes: checkListLogHashes,
    checklist: checklistLog,
    childItemsMap,
    automations,
    conditionVisibility,
    itemErrors,
    requiredItems,
    requiredItemCount,
    checklistProgAndScore,
    resetItemErrors,
    checkErrorsBeforeSubmission,
    handleStatus,
    handleNote,
    handleUpdateNote,
    handleDeleteChecklistItemNote,
    handleChecklistNote,
    handleUpdateChecklistNote,
    handleDeleteChecklistNote,
    createCorrectiveTaskCb,
    setSingleItemError,
  };
};

export default useChecklistLog;
