// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import Header from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/HeaderV2';
import TemplateSubmissionPreview from 'components/TemplateSubmissionPreview';

// Styled
import { Column } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';
import xeniaApi from 'api/index';

// Utils
import { groupBy, map } from 'lodash';

// Utils
import SubmissionPhotos from '../photos';
import SubmissionActivityTimeline from '../Activity';
import {
  CustomSwitch,
  DropdownWrapper,
} from 'components/TemplateSubmissionPreview/Header/styled';
import Dropdown from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/Dropdown';
import { Box, Stack, Typography } from '@mui/material';
import CTabs from 'components/CTabs';
import useLocationGroups from 'pages/Locations/Groups/useLocationGroups';
import { useSelector } from 'react-redux';
import { getLocationGroupsState } from 'store/selectors/locationGroups';
import { getLocationsHash } from 'store/selectors/locations';

interface ActiveSubmissionLogPropTypes {
  activeSubmission: SubmissionReportType;
  template?: any;
  handleArchiveLogs?: (logs: any[]) => void;
  hideCorrectiveTask?: boolean;
  feedbackActions?: any;
  isReviewMode?: boolean;
  hideHeaderactions?: boolean;
  canReview?: boolean;
  forcedUpdate?: boolean;
  setForcedUpdate?: (data: boolean) => void;
  showReviewOptions?: boolean;
}

const TABS = [
  { id: 'SUBMISSION', label: 'Submission' },
  { id: 'FEEDBACK_ONLY', label: 'Feedback Only' },
  { id: 'PHOTOS', label: 'Photos' },
  { id: 'ACTIVITY', label: 'Activity' },
];

const panelPropsStyles = {
  sx: (theme) => ({
    padding: '8px 0px',
    [theme.breakpoints.down('md')]: {
      background: '#F2F5F9',
      margin: '0 -16px',
      padding: '8px 16px',
    },
  }),
};
const ActiveSubmissionLog = ({
  activeSubmission,
  template,
  handleArchiveLogs,
  hideCorrectiveTask = false,
  feedbackActions,
  isReviewMode = false,
  hideHeaderactions = false,
  canReview = false,
  forcedUpdate = false,
  showReviewOptions = false,
  setForcedUpdate,
}: ActiveSubmissionLogPropTypes) => {
  const [activeSubmissionItemsFiltered, setActiveSubmissionItemsFiltered] =
    useState<SubmissionReportType | undefined>(undefined);
  const [activeSubmissionItemsFilter, setActiveSubmissionItemsFilter] =
    useState<LogFilterTypes>('allItems');

  const [conditions, setConditions] = useState<any>(undefined);
  const [toggleConditionalSteps, setToggleConditionalSteps] = useState(true);
  const locationGroups = useSelector(getLocationGroupsState);
  const locationHashes = useSelector(getLocationsHash);

  const handleFilterSubmissionLogItems = (
    filterName: 'allItems' | 'passed' | 'failed' | 'remaining' | 'completed',
  ) => {
    const active = activeSubmission ? { ...activeSubmission } : null;
    let newState: SubmissionReportType | undefined = undefined;
    if (active) {
      setActiveSubmissionItemsFilter(filterName);
      switch (filterName) {
        case 'allItems':
          setActiveSubmissionItemsFiltered(undefined);
          break;
        case 'passed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'pass') ||
                    (item.type !== 'passFail' && !!item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'failed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'fail') ||
                    (item.type !== 'passFail' && !item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'remaining':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !item.answers || item.type === 'header',
            ),
          };
          break;
        case 'completed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !!item.answers || item.type === 'header',
            ),
          };
          break;
        default:
          break;
      }
      setActiveSubmissionItemsFiltered(newState);
    }
  };

  const currentSubmission = useMemo(
    () => activeSubmissionItemsFiltered ?? activeSubmission,
    [
      activeSubmissionItemsFilter,
      activeSubmissionItemsFiltered,
      activeSubmission,
    ],
  );

  useEffect(() => {
    handleFilterSubmissionLogItems(activeSubmissionItemsFilter);
  }, [activeSubmission]);
  const { getLocationGroups } = useLocationGroups();

  useEffect(() => {
    getLocationGroups(); // fetch location groups
  }, []);
  const locationGroupHash = useMemo(() => {
    const locationGroupHashes = {};
    locationGroups.forEach((item) => {
      locationGroupHashes[item?.id] = item?.LocationGroupLocations.flatMap(
        (l) => {
          const sublocations = locationHashes?.[l.LocationId]?.Sublocations;
          if (sublocations) {
            const sublocationKeys = Object.keys(sublocations);
            return [l?.LocationId, ...sublocationKeys];
          }
          return l?.LocationId;
        },
      );
    });
    return locationGroupHashes;
  }, [locationGroups]);

  const fetchConditionsalVisibilityData = async () => {
    const conditionalVisibility = await xeniaApi.fetchConditionalVisbility({
      checklistId: activeSubmission?.ChecklistId,
    });
    const groudedConditions = groupBy(
      map(conditionalVisibility, (condition) => ({
        id: condition.id,
        context: condition.context,
        contextId: condition.contextId,
        itemId: condition.itemId,
        condition: condition.condition,
        action: condition.action,
        checklistId: condition.checklistId,
      })),
      'itemId',
    );
    setConditions(groudedConditions);
  };
  useEffect(() => {
    if (activeSubmission?.ChecklistId) {
      fetchConditionsalVisibilityData();
    }
  }, []);

  const tabsData = [
    {
      label: 'Submission',
      content: (
        <div>
          {currentSubmission?.id && (
            <TemplateSubmissionPreview
              conditions={toggleConditionalSteps ? conditions : null}
              locationGroupHash={locationGroupHash}
              hideCorrectiveTask={hideCorrectiveTask}
              activeSubmission={currentSubmission}
              handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
              activeSubmissionItemsFilter={activeSubmissionItemsFilter}
              log={currentSubmission}
              feedbackActions={feedbackActions}
              hideHeader={
                !currentSubmission?.TaskId &&
                currentSubmission?.status !== 'In Progress'
              }
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Feedback Only',
      content: (
        <div>
          {currentSubmission?.id && (
            <TemplateSubmissionPreview
              conditions={conditions}
              locationGroupHash={locationGroupHash}
              hideCorrectiveTask={hideCorrectiveTask}
              activeSubmission={currentSubmission}
              handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
              activeSubmissionItemsFilter={activeSubmissionItemsFilter}
              log={currentSubmission}
              isFeedbackOnlyLogs={true}
              feedbackActions={feedbackActions}
              hideHeader={true}
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Photos',
      content: (
        <div>
          {currentSubmission?.id && (
            <SubmissionPhotos
              submission={currentSubmission}
              isReviewMode={isReviewMode}
              canReview={canReview}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
    {
      label: 'Activity',
      content: (
        <div>
          {currentSubmission?.id && (
            <SubmissionActivityTimeline
              submission={currentSubmission}
              forcedUpdate={forcedUpdate}
              setForcedUpdate={setForcedUpdate}
            />
          )}
        </div>
      ),
      panelProps: { ...panelPropsStyles },
    },
  ];

  return (
    <Column>
      <Header
        template={template}
        activeSubmission={currentSubmission}
        handleArchiveLogs={handleArchiveLogs}
        hideHeaderactions={hideHeaderactions}
        canReview={canReview}
        isReviewMode={isReviewMode}
        setForcedUpdate={setForcedUpdate}
        showReviewOptions={showReviewOptions}
      />

      {/* TODO: no idea about this check, discuss with talha , this is effecting submission details for in progress submission allover the app */}
      {!currentSubmission?.TaskId &&
      currentSubmission?.status !== 'In Progress' ? (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              '& .MuiTabs-root': {
                margin: '0 -16px',
              },
            },
          })}
        >
          <CTabs
            data={tabsData}
            labelProps={{
              sx: {
                textTransform: 'capitalize',
                fontSize: 14,
                padding: 8,
                height: 40,
              },
            }}
            component={
              <Stack direction="row" alignItems="center" marginLeft="auto">
                <Stack
                  direction="row"
                  spacing={1}
                  border={'1px solid #E0E0E0'}
                  borderRadius={'8px'}
                  padding={'0 6px'}
                  alignItems={'center'}
                  height={'38px'}
                >
                  <Typography fontSize="13px" fontWeight={500}>
                    Hidden Sections/steps
                  </Typography>
                  <CustomSwitch
                    checked={toggleConditionalSteps}
                    onChange={(e) =>
                      setToggleConditionalSteps(e.target.checked)
                    }
                  />
                </Stack>
                <DropdownWrapper sx={{ marginLeft: '8px' }}>
                  <Dropdown
                    activeSubmission={activeSubmission}
                    handleFilterSubmissionLogItems={
                      handleFilterSubmissionLogItems
                    }
                    activeSubmissionItemsFilter={activeSubmissionItemsFilter}
                  />
                </DropdownWrapper>
              </Stack>
            }
          />
        </Box>
      ) : (
        <TemplateSubmissionPreview
          conditions={toggleConditionalSteps ? conditions : null}
          locationGroupHash={locationGroupHash}
          hideCorrectiveTask={hideCorrectiveTask}
          activeSubmission={currentSubmission}
          handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
          activeSubmissionItemsFilter={activeSubmissionItemsFilter}
          log={currentSubmission}
          feedbackActions={feedbackActions}
          hideHeader={
            !currentSubmission?.TaskId &&
            currentSubmission?.status !== 'In Progress'
          }
          isReviewMode={isReviewMode}
          canReview={canReview}
        />
      )}
    </Column>
  );
};

export default ActiveSubmissionLog;
