// React
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Utils
import { getUserConfig, editUserConfig } from 'api/userConfig';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from './useCustomPermission';

// Constants
import { PERMISSIONS } from 'utils/constants';

const MENU_ITEM_IDS = {
  operationsTemplates: 'operationsTemplates',
  taskAndWorkOrders: 'taskAndWorkOrders',
  scheduledWork: 'scheduledWork',
  projects: 'projects',
  requestedWorkOrders: 'requestedWorkOrders',
  requests: 'requests',
  routingRules: 'routingRules',
  reporting: 'reporting',
  locationBasedReports: 'locationBasedReports',
  dashboards: 'dashboards',
  chats: 'chats',
  locations: 'locations',
  assets: 'assets',
  settings: 'settings',
  templateExports: 'templateExports',
  customDashboards: 'customDashboards',
  usersAndTeams: 'usersAndTeams',
  workspaceSettings: 'workspaceSettings',
  userSettings: 'userSettings',
  sopWriterAndFiles: 'sopWriterAndFiles',
};

const initialVisibleConfig = [
  { id: MENU_ITEM_IDS.operationsTemplates, name: 'Operation Templates' },
  { id: MENU_ITEM_IDS.taskAndWorkOrders, name: 'Tasks & Work Orders' },
  { id: MENU_ITEM_IDS.scheduledWork, name: 'Scheduled Work' },
  { id: MENU_ITEM_IDS.sopWriterAndFiles, name: 'Documents' },
  { id: MENU_ITEM_IDS.requests, name: 'Work Requests' },
  { id: MENU_ITEM_IDS.dashboards, name: 'Dashboards' },
  { id: MENU_ITEM_IDS.chats, name: 'Chats' },
  { id: MENU_ITEM_IDS.locations, name: 'Locations' },
  { id: MENU_ITEM_IDS.assets, name: 'Assets' },
  { id: MENU_ITEM_IDS.settings, name: 'Settings' },
];

const permissionGateMap = {
  [MENU_ITEM_IDS.operationsTemplates]: [PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION],
  [MENU_ITEM_IDS.taskAndWorkOrders]: [PERMISSIONS.CAN_VIEW_TASKS],
  [MENU_ITEM_IDS.sopWriterAndFiles]: [PERMISSIONS.CAN_VIEW_SOP_AND_FILES],
  [MENU_ITEM_IDS.scheduledWork]: [PERMISSIONS.CAN_MANAGE_TASKS],
  [MENU_ITEM_IDS.requests]: [PERMISSIONS.CAN_VIEW_TASK_REQUESTS],
  [MENU_ITEM_IDS.reporting]: [PERMISSIONS.CAN_VIEW_REPORTING],
  [MENU_ITEM_IDS.dashboards]: [PERMISSIONS.CAN_VIEW_REPORTING],
  [MENU_ITEM_IDS.chats]: [PERMISSIONS.CAN_USE_CHAT],
  [MENU_ITEM_IDS.locations]: [PERMISSIONS.CAN_MANAGE_LOCATIONS],
  [MENU_ITEM_IDS.assets]: [PERMISSIONS.CAN_MANAGE_ASSETS],
  [MENU_ITEM_IDS.settings]: [],
};

const restrictedMenuItemIds = [
  MENU_ITEM_IDS.operationsTemplates,
  MENU_ITEM_IDS.taskAndWorkOrders,
  MENU_ITEM_IDS.scheduledWork,
  MENU_ITEM_IDS.requests,
  MENU_ITEM_IDS.reporting,
  MENU_ITEM_IDS.chats,
];

type MenuItem = {
  id: string;
  name: string;
};

export const useSidebarConfig = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const { FEATURES } = useCustomPermission();
  const sidebarConfig = useSelector(selectors.getSidebarConfig);
  const userId = useSelector((state: any) => state?.authData?.user?.id);
  const configKey = `sidebarConfig:${workspaceId}:${userId}`;

  const [visibleMenuItems, setVisibleMenuItems] = useState<MenuItem[]>(
    sidebarConfig?.visible,
  );
  const [hiddenMenuItems, setHiddenMenuItems] = useState<MenuItem[]>(
    sidebarConfig?.hidden,
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setVisibleMenuItems(sidebarConfig?.visible);
    setHiddenMenuItems(sidebarConfig?.hidden);
  }, [sidebarConfig]);

  const getSidebarConfig = async () => {
    const menuConfig = await getUserConfig(configKey);
    if (menuConfig?.config) {
      const visible = menuConfig?.config?.config?.visible ?? [];
      const hidden = menuConfig?.config?.config?.hidden ?? [];
      dispatch(
        actions.setSidebarConfig({
          visible,
          hidden,
        }),
      );
      return {
        visible,
        hidden,
        configFound: true,
      };
    }
    dispatch(
      actions.setSidebarConfig({
        visible: initialVisibleConfig,
        hidden: [],
      }),
    );
    return {
      visible: initialVisibleConfig,
      hidden: [],
      configFound: false,
    };
  };

  const editSidebarConfig = async (config) => {
    const configObj = {
      type: configKey,
      config,
    };
    const newMenuConfig = await editUserConfig(configObj);
    dispatch(
      actions.setSidebarConfig({
        ...newMenuConfig?.config?.config,
      }),
    );
    return (
      (newMenuConfig?.config?.config as {
        visible: MenuItem[];
        hidden: MenuItem[];
      }) ?? null
    );
  };

  const getAndSetMenu = async () => {
    const { visible, hidden, configFound } = await getSidebarConfig();
    if (configFound) {
      setVisibleMenuItems(visible);
      setHiddenMenuItems(hidden);
    } else {
      await editSidebarConfig({
        visible,
        hidden,
      });
    }
    setSaving(false);
  };

  const isConfigInSync = (sidebarConfig) => {
    const { visible, hidden } = sidebarConfig;
    const configArray: MenuItem[] = [...visible, ...hidden];

    return (
      configArray.every((configItem) =>
        initialVisibleConfig.some((item) => item.id === configItem.id),
      ) &&
      initialVisibleConfig.every((item) =>
        configArray.some((configItem) => configItem.id === item.id),
      )
    );
  };

  const handleLoadAndSyncConfig = async () => {
    const { visible, hidden, configFound } = await getSidebarConfig();

    if (!configFound) {
      await editSidebarConfig({
        visible,
        hidden,
      });
      return;
    }

    const configArray: MenuItem[] = [...visible, ...hidden];
    const isSync = isConfigInSync({
      visible,
      hidden,
    });

    if (!isSync) {
      const matchedVisible = visible.filter((v) => {
        const isMatch = initialVisibleConfig.some(
          (item) => item.id === v.id && item.name === v.name,
        );
        return isMatch;
      });
      const matchedHidden = hidden.filter((h) => {
        const isMatch = initialVisibleConfig.some(
          (item) => item.id === h.id && item.name === h.name,
        );
        return isMatch;
      });
      const newItemsToAdd = initialVisibleConfig.filter((initial) => {
        const isMatch = configArray.some(
          (item) => item.id === initial.id && item.name === initial.name,
        );
        return !isMatch;
      });

      const newVisible = [...matchedVisible, ...newItemsToAdd];
      const newHidden = [...matchedHidden];
      await editSidebarConfig({
        visible: newVisible,
        hidden: newHidden,
      });
    } else {
      const newVisible = visible?.map((visibleItem) => {
        const existingObject = initialVisibleConfig.find(
          (existingItem) => existingItem?.id == visibleItem?.id,
        );
        return {
          ...visibleItem,
          name: existingObject?.name || visibleItem?.name,
        };
      });

      const newHidden = hidden?.map((hiddenItem) => {
        const existingObject = initialVisibleConfig.find(
          (existingItem) => existingItem?.id == hiddenItem?.id,
        );
        return {
          ...hiddenItem,
          name: existingObject?.name || hiddenItem?.name,
        };
      });

      await editSidebarConfig({
        visible: newVisible,
        hidden: newHidden,
      });
    }
  };

  const featureGateMap = {
    [MENU_ITEM_IDS.operationsTemplates]: [],
    [MENU_ITEM_IDS.taskAndWorkOrders]: [],
    [MENU_ITEM_IDS.sopWriterAndFiles]: [],
    [MENU_ITEM_IDS.scheduledWork]: [],
    [MENU_ITEM_IDS.requests]: [],
    [MENU_ITEM_IDS.reporting]: [FEATURES.REPORTING_DASHBOARD],
    [MENU_ITEM_IDS.dashboards]: [],
    [MENU_ITEM_IDS.chats]: [],
    [MENU_ITEM_IDS.locations]: [],
    [MENU_ITEM_IDS.assets]: [],

    [MENU_ITEM_IDS.settings]: [],
  };

  return {
    MENU_ITEM_IDS,
    sidebarConfig,
    visibleMenuItems,
    setVisibleMenuItems,
    hiddenMenuItems,
    setHiddenMenuItems,
    saving,
    restrictedMenuItemIds,
    setSaving,
    editSidebarConfig,
    getAndSetMenu,
    handleLoadAndSyncConfig,
    permissionGateMap,
    featureGateMap,
  };
};
