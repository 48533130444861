import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import {
  createLocationGroup,
  deleteLocationGroup,
  getLocationGroupDetail,
  updateLocationGroup,
} from 'api/locations';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import {
  getLocationGroupsFetchInfo,
  getLocationGroupsState,
} from 'store/selectors/locationGroups';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function useLocationGroups() {
  const params = useParams();
  const groupfetchInfo = useSelector(getLocationGroupsFetchInfo);
  const groupList = useSelector(getLocationGroupsState);
  const { groupId } = params;

  const dispatch = useDispatch();

  const getLocationGroups = async (hotelId?: string) => {
    if (!groupfetchInfo.initialLoad && groupfetchInfo.loading) {
      const locationGroups = await xeniaApi.fetchLocationGroups(hotelId);
      dispatch(actions?.setLocationGroups(locationGroups ?? []));
      dispatch(
        actions?.setLocationFetchedInfo({
          initialLoad: true,
          loading: false,
        }),
      );

      return locationGroups;
    } else {
      return groupList;
    }
  };
  //Task creation without attachments
  const addNewGroup = (postObject) => {
    createLocationGroup(postObject).then((res) => {
      if (res) {
        dispatch(actions.addLocationGroup(res));
      }
    });
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createLocationGroup',
      }),
    );
  };

  // Find the selected location from the list
  const selectedGroup = useMemo(() => {
    // const data = groupList.find((i) => i.id === groupId);
    return groupId;
  }, [groupId, groupList]);

  const getGroupLocations = (id) => {
    return getLocationGroupDetail(id).then((res) => {
      if (res) {
        return res;
      }
    });
  };

  const updateGroup = (postObject, callBack?: any) => {
    updateLocationGroup(postObject).then((res) => {
      if (res) {
        dispatch(actions.updateLocationGroup(res));
        callBack && callBack(res);
      }
    });
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editLocationGroup',
      }),
    );
  };

  const deleteGroup = (id) => {
    deleteLocationGroup(id).then((res) => {
      if (res) {
        dispatch(actions.deleteLocationGroup(id));
      }
    });
  };

  const isLocationNameDuplicate = (name, id = null) => {
    if (id) {
      return groupList.some(
        (g) => g.name.toLowerCase() === name && g.id !== id,
      );
    } else {
      return groupList?.some((l) => l.name == name);
    }
  };

  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createLocationGroup',
      }),
    );
  };

  const closeEditAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editLocationGroup',
      }),
    );
  };

  return {
    selectedGroup,
    getLocationGroups,
    getGroupLocations,
    addNewGroup,
    updateGroup,
    deleteGroup,
    closeAction,
    closeEditAction,
    isLocationNameDuplicate,
  };
}
