// Custom components
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';

// Custom hooks.ts
import { useTaskEdit } from 'controller/useTaskEdit';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { getTimeZone } from 'utils/globalFunction';

const EditableDateCell = ({ task, dateKey }) => {
  const { handleUpdateTask } = useTaskEdit(task);
  const tz: string = getTimeZone();

  const updateTaskHelper = (value) => {
    handleUpdateTask({
      [dateKey]: value,
      ...(dateKey == 'startTime' && { startTime: value }),
      ...(dateKey == 'dueTime' && { dueTime: value }),
    });
  };

  const isDateDisabled = (day: Date) => {
    if (dateKey == 'startTime') {
      return task?.dueTime ? moment(day).isAfter(task?.dueTime, 'day') : false;
    }
    if (dateKey == 'dueTime') {
      return task?.startTime
        ? moment(day).isBefore(task?.startTime, 'day')
        : false;
    }
  };
  return (
    <div data-attribute="cell-click">
      <DatePickerDropdown
        selected={task?.[dateKey]}
        onDateSelect={updateTaskHelper}
        viewType="tableView"
        datePickerProps={{ disabled: isDateDisabled }}
        dateFormatter={(date) => (
          <Stack
            gap="4px"
            alignItems={'center'}
            direction="row"
            color="rgba(0,0,0,0.87)"
            height="50px"
            justifyContent={'center'}
          >
            <Typography fontSize={'14px'} fontWeight={'300'}>
              {moment.tz(date, tz).format('MMM DD YYYY hh:mm A')}
            </Typography>
          </Stack>
        )}
      />
    </div>
  );
};

export default EditableDateCell;
