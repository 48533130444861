import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import LogDrawer from '../LogDrawer';

// AG Grid
import {
  defaultColDef,
  getRowStyles,
  columns,
} from 'pages/Widgets/SubmissionsListing/SubmissionListView/constants';

// Utils
import xeniaApi from 'api/index';
import { TasksListSummaryCnt } from 'pages/Widgets/SubmissionsListing/SubmissionListView/styled';
import {
  WidgetLoaderCnt,
  WidgetLoaderCntAbsolute,
} from 'pages/Widgets/widgets.style';
import CircularProgress from '@mui/material/CircularProgress';
import SubmissionListWidgetEmptyState from 'pages/Widgets/SubmissionsListing/SubmissionListView/EmptyRecords/SubmissionListWidgetEmptyState.cmp';
import 'pages/Widgets/SubmissionsListing/SubmissionListView/styles.css';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getUserConfig, editUserConfig } from 'api/userConfig';
import { debounce } from 'lodash';
import { Box } from '@mui/material';

interface SubmissionListSummaryProps {
  filters: any;
  otherElementsHeight?: number;
  isLogs?: boolean;
  isScoring?: boolean;
  pagination?: boolean;
}

const SubmissionListSummary: React.FC<SubmissionListSummaryProps> = ({
  filters,
  otherElementsHeight = 0,
  isLogs = false,
  isScoring = false,
  pagination = false,
}) => {
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [activeLog, setActiveLog] = useState<SubmissionReportType>();
  const [tableConfig, setTableConfig] = useState<any>(null);
  const userId = useSelector(selectors.getUserId);
  const [loadMore, setLoadMore] = useState(false);
  const { workspaceId } = useWorkspaceHook();
  const latestRequestId = useRef(0);

  const fetchSubmissionsList = useCallback(
    debounce(async (page = 0, noLoader = false) => {
      // Fetch Report data
      if (!noLoader) {
        setLoading(true);
      }

      const requestId = ++latestRequestId.current; // Increment request ID
      const requestPayload = {
        ...filters,
      };

      try {
        if (isLogs) {
          const data = await xeniaApi.getAllChecklistLogs(
            requestPayload,
            false,
            true,
            false,
            isScoring,
          );
          setData(data?.data);
        } else {
          requestPayload.offset = page * requestPayload.limit;

          if (requestPayload?.customFromDate) {
            requestPayload.fromDate = requestPayload.customFromDate;
          }

          if (requestPayload?.customToDate) {
            requestPayload.toDate = requestPayload.customToDate;
          }

          delete requestPayload.customFromDate;
          delete requestPayload.customToDate;

          const { data: nextPageData, totalCount } =
            await xeniaApi.getSubmissionList(requestPayload);

          if (requestId === latestRequestId.current) {
            if (page === 0) {
              setCurrentPage(0);
              setData(nextPageData ?? []);
            } else {
              setData([...(data ?? []), ...nextPageData]);
            }
            setTotalCount(totalCount);
          }
        }
      } finally {
        if (!noLoader) {
          setLoading(false);
        } else {
          setLoadMore(false);
        }
      }
    }, 1000),
    [filters, data],
  );

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`templateSubmissionList:${userId}`);
    if (config?.config?.config) {
      const colConfig = config.config.config;
      setTableConfig(colConfig);
    }
  };

  useEffect(() => {
    fetchSubmissionsList();
  }, [filters]);

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  const onRowClick = async (id: string) => {
    const log = await xeniaApi.getChecklistLog({
      logId: id,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
      comments: true,
      approval: true,
    });
    if (log?.data) {
      setActiveLog(log.data);
    } else {
      setActiveLog(undefined);
    }
  };

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  const handleArchiveApi = async (logs: any[]) => {
    const logIds = logs.map((log) => log.id);
    await xeniaApi.deleteChecklistLogs({
      checklistId: activeLog?.ChecklistId,
      hotelId: workspaceId,
      postData: {
        logIds,
      },
    });
    setData((prevData) => prevData.filter((log) => !logIds.includes(log.id)));
    setActiveLog(undefined);
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({ fetchSubmissionsList });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = tableConfig?.find((c) => c.colId === col.colId);
      const index = tableConfig?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [fetchSubmissionsList, tableConfig]);

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig({
      type: `templateSubmissionList:${userId}`,
      config: columnState,
    });
    if (!updatedConfig?.config?.config) {
      setTableConfig(previousTableConfig);
    }
  };

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumns(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    updateColumns(event.columnApi.getColumnState());
  };

  const handlePagination = useCallback(
    debounce(() => {
      if (pagination && totalCount > data?.length) {
        setLoadMore(true);
        const nextPage = currentPage + 1;
        fetchSubmissionsList(nextPage, true);
        setCurrentPage(nextPage);
      }
    }, 500),
    [pagination, currentPage, data, totalCount],
  );

  return (
    <TasksListSummaryCnt
      className={'taskListSummaryCnt'}
      style={{
        height: `calc(100vh - ${otherElementsHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LogDrawer
        open={!!activeLog}
        onClose={() => setActiveLog(undefined)}
        log={activeLog as SubmissionReportType}
        handleArchiveLog={handleArchiveApi}
        setActiveLog={setActiveLog}
        showReviewOptions={true}
      />
      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <SubmissionListWidgetEmptyState />}
      {!loading && data?.length > 0 && (
        <>
          <Box
            style={{
              flex: 1,
              overflow: 'hidden',
              position: 'relative',
              ...(loadMore && {
                pointerEvents: 'none',
              }),
            }}
          >
            {loadMore && (
              <WidgetLoaderCntAbsolute>
                <CircularProgress />
              </WidgetLoaderCntAbsolute>
            )}
            <CustomListView
              rootProps={{
                style: {
                  height: '100%',
                  boxShadow: 'none',
                  ...(loadMore && {
                    opacity: 0.5,
                  }),
                },
              }}
              onRowClick={onRowClick}
              gridProps={{
                ref: (ref) => {
                  globalThis.taskSummaryListGrid = ref;
                },
                onPaginationScrollEnd: handlePagination,
                onSortChanged: handleUpdateColumnState,
                onDragStopped: handleUpdateColumnState,
                onColumnResized: onColumnResized,
                onColumnVisible: handleUpdateColumnState,
                onColumnPinned: handleUpdateColumnState,
                onColumnMoved: handleUpdateColumnState,
                columnDefs: gridColumns,
                headerHeight: 30,
                rowData: data,
                suppressChangeDetection: true,
                defaultColDef: defaultColDef,
                suppressCellFocus: true,
                getMainMenuItems: getMainMenuItems,
                getRowHeight: getRowHeight,
                getRowStyle: getRowStyles,
                suppressRowClickSelection: true,
                getRowId: (params) => params.data.id,
              }}
            />
          </Box>
        </>
      )}
    </TasksListSummaryCnt>
  );
};

export default SubmissionListSummary;
