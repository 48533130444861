/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */

// React
import { FC, Fragment, useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions/index';
import selectors from 'store/selectors';

// Custom hooks.ts
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Icons
import { Badge, Divider, Stack, Tooltip, Typography } from '@mui/material';

// Services
import { useAddEmployeeDirectChat } from 'controller/useAddEmployeeDirectChat';
import {
  getStreamChannels,
  searchMessageInChannels,
} from 'services/getStreamServices';
import DateTime from 'utils/DateTime';

// Utilities
import { getItem, getTimeZone } from 'utils/globalFunction';

import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

import { Box } from '@mui/material';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { ActionItem, MenuItemLink } from './navBar.style';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import { useLocation } from 'react-router-dom';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import ReportingIcon from 'components/Icons/sidebarIcons/ReportingIcon';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import { TOP_BAR_NAVIGATION_LINKS } from './utils';
import TelescopeIcon from 'components/Icons/sidebarIcons/telescopeIcon';
import AddonIcon from 'components/Icons/sidebarIcons/addonIcon';
import InviteUsersIcon from 'components/Icons/sidebarIcons/inviteUsersIcon';
import NotificationsIcon from 'components/Icons/sidebarIcons/notificationsIcon';
import ProfileDropdown from '../sidebar/profileDropdown/profileDropdown';
import { setNavigationSelection } from 'store/actions/navigationActions';
import XeniaLogo from 'public/assets/img/xenia-logo-symbol.svg';
import WorkspaceDropdown from 'components/Dropdown/WorkspaceDropdown';
import { getNavigationData } from 'store/selectors/navigation';
import DIALOGS from 'utils/dialogIds';
import AddNew from '../sidebar/addNew/AddNew';
import {
  permissionBasedOperationsNavigation,
  permissionBasedFacilitiesNavigation,
  permissionBasedSettingsNavigation,
} from 'utils/navigation';
import { sortBy } from 'lodash';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';
import { getNotificationUnreadCount } from 'store/selectors/notification';
import useReportList from 'pages/ReportingV2/Reports/useReportsList';
import ToolsIcon from 'components/Icons/sidebarIcons/toolsIcon';

const TopBar: FC<any> = () => {
  const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();
  const unreadCount: number = useSelector(getNotificationUnreadCount);

  const initialNotificationPostData = {
    page: 1,
    pageSize: 10,
    searchText: '',
    sort: {
      createdAt: 'DESC',
    },
  };

  const dispatch = useDispatch();
  const { getReportsList } = useReportList();
  const tz: string = getTimeZone();
  const currentUserId: any = useSelector(selectors.getUserId);
  const hotelId: string | number | null = useSelector(selectors.getUserHotelId);
  const currentPath = useLocation().pathname;
  const [showNotification, SetShowNotification] = useState(false);
  const [notificationPostData, SetNotificationPostData] = useState<any>(
    initialNotificationPostData,
  );
  const [isSearchMessages, setIsSearchMessages] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>('');
  const [searchUser, setSearchUsers] = useState<any>(null);
  const [predefinedReports, setPredefinedReports] = useState<any>([]);
  const { handleAddEmployeeDirectChatSubmit } = useAddEmployeeDirectChat();
  const { hasPermission } = useCustomPermission();
  const open = useSelector(selectors.getMenuState);

  const navigationDetails = useSelector(getNavigationData);
  const { selected } = navigationDetails || {};

  // reset search on unmount
  useEffect(() => {
    return () => {
      dispatch(actions.setSearchText(''));
    };
  }, []);

  useEffect(() => {
    if (currentUserId) {
      dispatch(actions.getNotificationUnreadCount());
    }
  }, [currentUserId, workspaceId]);

  useEffect(() => {
    if (showNotification) {
      handleShowNotification();
    }
  }, [showNotification, notificationPostData]);

  useEffect(() => {
    searchData(searchString);
  }, [searchString]);

  const handleShowNotification = () => {
    const postData = notificationPostData;
    const getNotificationData = {
      postData: postData,
    };
    dispatch(actions.taskNotificationListApiCall(getNotificationData));
  };

  useEffect(() => {
    dispatch(actions.taskNotificationListApiCallSuccess([]));
  }, [workspaceId]);

  const openChannel = async ({ id, channel }) => {
    const [channelInfo] = await getStreamChannels({
      id: channel.id,
      hotelId: channel.hotelId,
    });
    if (channelInfo) {
      dispatch(
        actions.scrollToChannelMessage({
          channel: channelInfo,
          message: { id },
        }),
      );
      setIsSearchMessages(false);
      setSearchString('');
    }
  };

  const getSearchHtmlJsx = () => {
    return (
      <Box className="search-info-wrap">
        {searchString.length > 0 ? (
          <span className="result-count-text">
            {searchUser && searchUser.length} matches found
          </span>
        ) : null}
        {searchUser &&
          searchUser.map((s, index) => {
            const isUserMessage = currentUserId === s.message?.user?.id;
            return (
              <Box className="searched-item" key={index}>
                <Box
                  className="info"
                  style={{ cursor: 'pointer' }}
                  title={'Open message channel'}
                  onClick={() => {
                    openChannel(s.message);
                  }}
                >
                  <p>{s.message.text}</p>
                  <span className="time">
                    {DateTime.fromUTCString(s.message?.created_at).toTzString(
                      tz,
                      'hh:mm a',
                    )}
                  </span>
                </Box>
                <Box
                  className="avtar-wraper msz-search-user"
                  title={isUserMessage ? '' : 'Open direct message'}
                  style={{ cursor: isUserMessage ? 'auto' : 'pointer' }}
                  onClick={async () => {
                    if (isUserMessage) {
                      await handleAddEmployeeDirectChatSubmit([
                        s.message?.user,
                      ]);
                      setSearchUsers(null);
                      setIsSearchMessages(false);
                      setSearchString('');
                      // handleSetChannel(currentClient);
                    }
                  }}
                >
                  <Box className="imgbox">
                    <img
                      src={s.message?.user?.profileImage}
                      alt={s.message?.user?.name}
                      width="30"
                      height="30"
                    />
                  </Box>
                  <Box className="namebox">
                    <h6>
                      {s.message?.user?.id ===
                      getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID)
                        ? 'You'
                        : s.message?.user?.name}
                    </h6>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    );
  };

  const searchData = async (text) => {
    if (text !== '') {
      const result = await searchMessageInChannels({
        hotelId,
        text,
        userId: currentUserId,
      });
      setSearchUsers(result?.results);
    }
  };

  const customOrder = [
    'daily-compliance-report',
    'approvals-report',
    'scheduled-work-summary-table',
    'schedule-report',
    'template-submissions',
    'template-submission',
    'task-summary',
    'employee-productivity',
    'completed-work-report',
  ];

  const getReportsData = async () => {
    const reports = await getReportsList();
    if (reports) {
      const sorted = sortBy(reports, (item) => customOrder.indexOf(item?.slug));
      setPredefinedReports(sorted);
    }
  };

  useEffect(() => {
    getReportsData();
  }, []);

  const handleNavClick = (path) => {
    navigateWithWorkspaceUrl(path);
  };

  const navigationHandler = (navigationType) => {
    switch (navigationType) {
      case TOP_BAR_NAVIGATION_LINKS.HOME:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.HOME));
        handleNavClick('/dashboard');
        break;

      case TOP_BAR_NAVIGATION_LINKS.OPERATIONS:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.OPERATIONS));
        permissionBasedOperationsNavigation(hasPermission, handleNavClick);
        break;
      case TOP_BAR_NAVIGATION_LINKS.FACILITIES:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.FACILITIES));
        permissionBasedFacilitiesNavigation(hasPermission, handleNavClick);
        break;
      case TOP_BAR_NAVIGATION_LINKS.REPORTING:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.REPORTING));
        handleNavClick('/dashboard?type=PULSE');
        break;
      case TOP_BAR_NAVIGATION_LINKS.LOCATIONS:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.LOCATIONS));
        handleNavClick('/locations');
        break;
      case TOP_BAR_NAVIGATION_LINKS.SETTINGS: {
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.SETTINGS));
        permissionBasedSettingsNavigation(hasPermission, handleNavClick);
        break;
      }

      case TOP_BAR_NAVIGATION_LINKS.ADDONS:
        dispatch(setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.ADDONS));
        handleNavClick('/addons');
        break;

      default:
        return null;
    }
  };

  const barNavigation = () => {
    return (
      <Stack direction="row" alignItems="center" gap="20px">
        <WorkspaceDropdown shrinked={!open} />
        <Divider
          orientation="vertical"
          style={{ height: 40, marginLeft: -12 }}
        />

        <MenuItemLink
          onClick={() => navigationHandler(TOP_BAR_NAVIGATION_LINKS.HOME)}
          sx={{
            paddingBottom: '11px',
            marginTop: '18px',
            ...(selected === TOP_BAR_NAVIGATION_LINKS.HOME &&
              !currentPath.includes('/addons') && {
                borderBottom: '3px solid #4E48FA',
              }),
          }}
        >
          <Stack direction="row" alignItems={'center'} gap="5px">
            <img src={XeniaLogo} height="18px" />
            <Typography fontWeight={700} fontSize={18} color="#4E48FA">
              Home
            </Typography>
          </Stack>
        </MenuItemLink>
        <MenuItemLink
          onClick={() => navigationHandler(TOP_BAR_NAVIGATION_LINKS.OPERATIONS)}
          sx={{
            ...(selected === TOP_BAR_NAVIGATION_LINKS.OPERATIONS && {
              borderBottom: '3px solid #4E48FA',
              paddingBottom: '11px',
              marginTop: '18px',
              '& p': {
                color: '#4E48FA !important',
              },
            }),
          }}
        >
          <Box className="iconCnt">
            <ChecklistIcon
              filled={selected === TOP_BAR_NAVIGATION_LINKS.OPERATIONS}
            />
          </Box>
          <Typography className="label">Operations</Typography>
        </MenuItemLink>

        <MenuItemLink
          onClick={() => navigationHandler(TOP_BAR_NAVIGATION_LINKS.FACILITIES)}
          sx={{
            ...(selected === TOP_BAR_NAVIGATION_LINKS.FACILITIES && {
              borderBottom: '3px solid #4E48FA',
              paddingBottom: '11px',
              marginTop: '18px',
              '& p': {
                color: '#4E48FA !important',
              },
            }),
          }}
        >
          <Box className="iconCnt">
            <ToolsIcon
              filled={selected === TOP_BAR_NAVIGATION_LINKS.FACILITIES}
            />
          </Box>
          <Typography className="label">Facilities</Typography>
        </MenuItemLink>

        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          <MenuItemLink
            onClick={() =>
              navigationHandler(TOP_BAR_NAVIGATION_LINKS.REPORTING)
            }
            sx={{
              ...(selected === TOP_BAR_NAVIGATION_LINKS.REPORTING && {
                borderBottom: '3px solid #4E48FA',
                paddingBottom: '11px',
                marginTop: '18px',
                '& p': {
                  color: '#4E48FA !important',
                },
              }),
            }}
          >
            <Box className="iconCnt">
              <ReportingIcon
                filled={selected === TOP_BAR_NAVIGATION_LINKS.REPORTING}
              />
            </Box>
            <Typography className="label">Reporting</Typography>
          </MenuItemLink>
        </HasPermission>
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
          <MenuItemLink
            onClick={() =>
              navigationHandler(TOP_BAR_NAVIGATION_LINKS.LOCATIONS)
            }
            sx={{
              ...(selected === TOP_BAR_NAVIGATION_LINKS.LOCATIONS && {
                borderBottom: '3px solid #4E48FA',
                paddingBottom: '11px',
                marginTop: '18px',
                '& p': {
                  color: '#4E48FA !important',
                },
              }),
            }}
          >
            <Box className="iconCnt">
              <LocationIcon
                filled={selected === TOP_BAR_NAVIGATION_LINKS.LOCATIONS}
              />
            </Box>
            <Typography className="label">Locations</Typography>
          </MenuItemLink>
        </HasPermission>
        <MenuItemLink
          onClick={() => navigationHandler(TOP_BAR_NAVIGATION_LINKS.SETTINGS)}
          sx={{
            ...(selected === TOP_BAR_NAVIGATION_LINKS.SETTINGS && {
              borderBottom: '3px solid #4E48FA',
              paddingBottom: '11px',
              marginTop: '18px',
              '& p': {
                color: '#4E48FA !important',
              },
            }),
          }}
        >
          <Box className="iconCnt">
            <SettingsIcon
              filled={selected === TOP_BAR_NAVIGATION_LINKS.SETTINGS}
            />
          </Box>
          <Typography className="label">Settings</Typography>
        </MenuItemLink>
      </Stack>
    );
  };

  const rightActions = () => {
    return (
      <Stack direction="row" alignItems="center" gap="20px">
        <AddNew sidebarOpen={Boolean(open)} />

        <Divider
          orientation="vertical"
          style={{ height: 30, marginLeft: -14 }}
        />

        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_USERS]}>
          <Tooltip title={'Invite Users'} placement="top">
            <ActionItem
              onClick={() => {
                dispatch(
                  actions.setDialog({
                    dialogId: 'inviteUserDialogV2',
                    open: true,
                  }),
                );
              }}
            >
              <InviteUsersIcon />
            </ActionItem>
          </Tooltip>
        </HasPermission>

        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CHECKLIST]}>
          <Tooltip title={'Explore Templates'} placement="top">
            <ActionItem
              onClick={() => {
                dispatch(
                  actions.setDialog({
                    dialogId: DIALOGS.TEMPLATE_LIBRARY,
                    open: true,
                  }),
                );
              }}
            >
              <TelescopeIcon />
            </ActionItem>
          </Tooltip>
        </HasPermission>

        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_BILLING]}>
          <Tooltip title={'Xenia Addons'} placement="top">
            <ActionItem
              sx={{
                ...(currentPath.includes('/addons') && {
                  borderBottom: '3px solid #4E48FA',
                  paddingBottom: '11px',
                  marginTop: '18px',
                }),
              }}
              onClick={() => {
                dispatch(
                  setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.ADDONS),
                );
                navigationHandler(TOP_BAR_NAVIGATION_LINKS.ADDONS);
              }}
            >
              <AddonIcon filled={currentPath.includes('/addons')} />
            </ActionItem>
          </Tooltip>
        </HasPermission>

        <Tooltip title={'Notifications'} placement="top">
          <ActionItem
            onClick={() => {
              dispatch(
                setNavigationSelection(TOP_BAR_NAVIGATION_LINKS.NOTIFICATIONS),
              );
              handleNavClick('/my-notifications');
            }}
          >
            <Badge badgeContent={unreadCount} color="secondary">
              <NotificationsIcon />
            </Badge>
          </ActionItem>
        </Tooltip>

        <Divider
          orientation="vertical"
          style={{ height: 30, marginRight: -14 }}
        />

        <ProfileDropdown
          open={false}
          popperCustomProps={{
            sx: {
              zIndex: 1301,
              left: '-20px !important',
            },
            placement: 'bottom',
          }}
        />
      </Stack>
    );
  };

  return (
    <Fragment>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {barNavigation()}
        {rightActions()}
      </Box>
    </Fragment>
  );
};

export default TopBar;
