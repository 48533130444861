// MUI components
import { Stack } from '@mui/material';
import { HeaderContainer } from './style';
import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';

const defaultInputStyle: React.CSSProperties = {
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32.02px',
  color: '#000000',
  paddingLeft: 0,
};
const mergedInputStyle = { ...defaultInputStyle };

const BuilderHeader = ({
  cancelHandler,
  data,
  updateTitle,
  createHandler,
  editHandler,
  isDirtyChanges,
  isSaving = false,
}) => {
  const isDataValid = () => {
    if (!data?.name) return false;
    return true;
  };

  const isDisabledBtn = !isDataValid();

  return (
    <HeaderContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: 'unset !important',
          },
        }}
      >
        <CustomInput
          className="title"
          underlineInput={true}
          suppressErrorSpace={true}
          fieldProps={{
            autoFocus: true,
            value: data?.name,
            inputProps: { style: mergedInputStyle },
            placeholder: 'Add rule name',
            onChange: (e) => {
              updateTitle(e.target.value);
            },
          }}
          sx={{ width: '700px' }}
        />
        <Stack direction="row" alignItems="center" gap="10px">
          <CustomButton
            variant="outlined"
            className="cancelBtn"
            onClick={cancelHandler}
          >
            Cancel
          </CustomButton>

          {data?.id && (
            <CustomButton
              variant="contained"
              disabled={isDisabledBtn || isSaving || !isDirtyChanges}
              className="saveChangesBtn"
              onClick={() => editHandler()}
              buttonLoader={isSaving}
            >
              Save Changes
            </CustomButton>
          )}

          {!data?.id && (
            <CustomButton
              variant="contained"
              className="createBtn"
              disabled={isDisabledBtn || isSaving || !isDirtyChanges}
              buttonLoader={isSaving}
              onClick={createHandler}
            >
              Create Rule
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default BuilderHeader;
