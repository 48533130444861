import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  border: '1px solid #E0E0E0',
  position: 'fixed',
  top: 0,
  width: '100%',
  padding: '16px 20px',
  height: 68,
  zIndex: 1,
  '& svg': {
    color: '#4E48FA',
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30.05px',
    color: '#000000',
  },

  '& .cancelBtn': { borderRadius: 8 },

  '& .saveChangesBtn': {
    borderRadius: 8,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
  },

  '& .publishBtn': {
    borderRadius: 6,
  },
  '& .createBtn': {
    borderRadius: 6,
  },
});
