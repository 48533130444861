import { createSvgIcon } from '@mui/material';

interface CheckCircleIconProps {
  fill?: string;
  stroke?: string;
  style?: any;
}

const CheckCircleIcon = (props: CheckCircleIconProps) => {
  const style = props.style || {};
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z"
        fill={props.fill || '#E8F5E9'}
      />
      <path
        d="M18.75 30L26.25 37.5L41.25 22.5M55 30C55 43.8071 43.8071 55 30 55C16.1929 55 5 43.8071 5 30C5 16.1929 16.1929 5 30 5C43.8071 5 55 16.1929 55 30Z"
        stroke={props.stroke || '#4CAF50'}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CheckCircleIcon',
  );
  return <IconCmp style={{ fill: 'none', ...style }} viewBox={'0 0 60 60'} />;
};
export default CheckCircleIcon;
