import { Box, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 10,
    height: 69,
    padding: '10px 16px 22px 16px',
    borderRadius: 12,
    backgroundColor: 'rgba(235, 234, 255, 1)',

    '& .title': {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '100%',
      color: 'rgba(78, 72, 250, 1)',
    },
    '& .subTitle': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '100%',
      color: 'rgba(33, 33, 33, 1)',
    },
  },

  '& .triggerContent': {
    marginTop: '-10px',
    backgroundColor: 'white',
    padding: '20px 16px 20px 16px',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 12,
    minHeight: 112,

    '& .contentDiv': {
      width: '100%',
      '& div': {
        width: '100%',
        '& button': {
          width: '100%',
        },
      },
    },
  },
});
