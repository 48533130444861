import React, { FC, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Cover from './components/cover';
import { Content } from './styled';
import ConditionItem from './components/conditionItem';
import { Typography } from '@mui/material';
import ContextDropdown from './components/context';
import Action from './components/action';
import { conditionalVisibilityInterface } from './utils/constants';

interface ItemOrdersHash {
  [key: string]: number | string;
}
interface IProps {
  entity: string;
  field: any;
  checkListItems: any;
  automations: conditionalVisibilityInterface;
  onChange(automations: conditionalVisibilityInterface): void;
  ordreHash: ItemOrdersHash;
}
const VisibilityContent: FC<IProps> = (props) => {
  const { field, checkListItems, automations, ordreHash, onChange } = props;
  const { condition, action, contextId, context } = automations;
  const [selectedContext, setSelectedContext] = useState<any>(null);

  const allCheckListItems = useMemo(() => {
    const checkListItemsArray = Object.values(checkListItems) as any;
    return (
      (checkListItemsArray?.length &&
        checkListItemsArray?.filter(
          (item) =>
            item?.type !== 'header' &&
            item?.type !== 'takePhoto' &&
            item?.type !== 'procedure' &&
            (item?.ParentId === null || item.ParentId === undefined) &&
            item?.id !== field.id,
        )) ||
      []
    );
  }, [checkListItems]);

  return (
    <Content>
      <Stack>
        <Typography color="#212121" fontWeight={800} fontSize="14px">
          If
        </Typography>
        {/* context  */}
        <ContextDropdown
          context={context}
          contextId={contextId || ''}
          checkListItems={allCheckListItems}
          condition={condition}
          onChange={onChange}
          ordreHash={ordreHash}
          setSelectedContext={setSelectedContext}
        />
        {/* condition item */}
        <ConditionItem
          context={context}
          field={field}
          checkListItems={allCheckListItems}
          condition={condition}
          onChange={onChange}
          selectedContext={selectedContext}
        />
        <Typography color="#212121" fontWeight={800} fontSize="14px">
          Then
        </Typography>

        <Action action={action} onChange={onChange} />
        <Cover
          title={field.description}
          type={field.type}
          index={field.index}
        />
      </Stack>
    </Content>
  );
};

export default VisibilityContent;
