import { FC, useState, useCallback, useEffect } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocationOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import GoogleMap from 'components/GoogleMap';
import axios from 'axios';
import { debounce } from 'lodash';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';
import { InfoBanner } from './style';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  value?: any;
  handleSave?(value: any): void;
  preview?: boolean;
  disableLocateMe?: boolean;
  isLocation?: boolean;
  enforceRealTimeLocation?: boolean;
}

interface LocationOption {
  label: string;
  place_id: string;
}

const GeoStamp: FC<IProps> = (props) => {
  const {
    value: initialValue,
    handleSave,
    preview = false,
    disableLocateMe = false,
    isLocation,
    enforceRealTimeLocation = false,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [currentValue, setCurrentValue] = useState(
    initialValue || { address: '' },
  );
  const [showMap, setShowMap] = useState(false);
  const [mapData, setMapData] = useState({
    address: '',
    lat: 40.71306965776129,
    lng: -74.0022139318488,
  });
  const [options, setOptions] = useState<LocationOption[]>([]);

  useEffect(() => {
    if (initialValue) {
      setCurrentValue(initialValue);
      setMapData(initialValue);
    }
  }, [initialValue]);

  const locateMe = () => {
    navigator?.geolocation?.getCurrentPosition(
      async ({ coords: { latitude: lat, longitude: lng } }) => {
        if (lat && lng) {
          const res = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`,
          );

          const address = res?.data?.results[0]?.formatted_address;
          setMapData({ address, lat, lng });
        }
      },
      (error) => {
        console.log('error', error);
      },
    );
  };

  const debouncedCallback = useCallback(
    debounce((value: any) => {
      handleSave && handleSave(value);
    }, 700),
    [handleSave],
  );

  const handleChange = (value) => {
    if (preview) return;
    if (handleSave) {
      let newValue: any = null;
      if (showMap) {
        newValue = { ...mapData, address: value };
        setMapData(newValue);
      } else {
        newValue = { ...currentValue, address: value };
        setCurrentValue(newValue);
      }
      debouncedCallback(newValue);
    }
  };

  const handleSaveLocation = () => {
    if (handleSave) {
      handleSave(mapData);
    }
  };

  const fetchPlaces = debounce((input) => {
    if (isLoaded && input) {
      const service = new (
        window as any
      ).google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input }, (predictions, status) => {
        if (status === 'OK' && predictions) {
          const suggestions = predictions.map((prediction) => ({
            label: prediction.description,
            place_id: prediction.place_id, // Store place_id for fetching details later
          }));
          setOptions(suggestions);
        } else {
          setOptions([]);
        }
      });
    } else {
      setOptions([]);
    }
  }, 300);

  const handleInputChange = (event, value: string) => {
    handleChange(value);
    fetchPlaces(value);
  };

  const handleOptionSelect = (event, value) => {
    if (value) {
      const service = new (window as any).google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails({ placeId: value.place_id }, (place, status) => {
        if (status === 'OK' && place) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const newValue = {
            address: value.label,
            lat,
            lng,
          };
          showMap ? setMapData(newValue) : setCurrentValue(newValue);
          debouncedCallback(newValue);
          setOptions([]); // Clear options after selection
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      fetchPlaces.cancel(); // Cancel any pending debounced calls on unmount
    };
  }, []);

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row" alignItems="flex-start">
        {isLocation ? (
          <Autocomplete
            options={options}
            inputValue={showMap ? mapData.address : currentValue.address}
            getOptionLabel={(option) => (option as LocationOption).label}
            onInputChange={handleInputChange}
            onChange={handleOptionSelect}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={showMap ? 'Search address' : 'Enter address'}
                value={showMap ? mapData.address : currentValue.address}
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-notchedOutline legend': {
                    width: 'auto',
                  },
                }}
              />
            )}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1.5,
              },
              '& .MuiAutocomplete-input': {
                fontSize: 13,
                padding: '4px 30px 4px 8px !important',
              },
            }}
            freeSolo // Allows input even if there's no match
          />
        ) : (
          <CustomInput
            suppressErrorSpace
            fieldProps={{
              ...(enforceRealTimeLocation && {
                style: { backgroundColor: 'rgba(245, 245, 245, 1)' },
                disabled: enforceRealTimeLocation,
              }),
              value: showMap ? mapData.address : currentValue.address,
              placeholder: 'Location',
              onChange: (e) => handleChange(e.target.value),
            }}
          />
        )}
        <CustomButton
          disabled={disableLocateMe}
          startIcon={showMap ? <LocationOutlinedIcon /> : <MapOutlinedIcon />}
          sx={{ flex: '0 0 auto', fontSize: '15px' }}
          variant={showMap ? 'outlined' : 'contained'}
          onClick={() => {
            if (showMap) {
              locateMe();
            } else {
              setShowMap(true);
            }
          }}
        >
          {showMap ? 'Locate Me' : 'Map'}
        </CustomButton>
      </Stack>

      {showMap && (
        <div>
          {enforceRealTimeLocation && (
            <InfoBanner direction="row" alignItems="center" gap="7px">
              <InfoIcon className="icon" />
              <Typography className="content">
                Manual adjustments are disabled. Click on “Locate me” button to
                capture your real-time location.
              </Typography>
            </InfoBanner>
          )}

          <GoogleMap
            lat={mapData.lat}
            lng={mapData.lng}
            preview={preview}
            onChange={(v) => {
              setMapData(v);
            }}
            disableClick={enforceRealTimeLocation}
          />
        </div>
      )}

      {showMap && (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <CustomButton
            variant="outlined"
            onClick={() => {
              setShowMap(false);
            }}
          >
            Close Map
          </CustomButton>
          <CustomButton
            disabled={!(mapData.lat || mapData.lng)}
            variant="contained"
            onClick={() => {
              setShowMap(false);
              const { address, lat, lng } = mapData;
              setCurrentValue({
                address: `${address} (${lat}, ${lng})`,
                lat,
                lng,
              });
              handleSaveLocation();
            }}
          >
            Save Location
          </CustomButton>
        </Stack>
      )}
    </Stack>
  );
};

export default GeoStamp;
