import { sortBy } from 'lodash';
import { getReports } from 'api/reporting';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsFetchInfo, getReportsState } from 'store/selectors/reports';
import actions from 'store/actions';

const customOrder = [
  'daily-compliance-report',
  'approvals-report',
  'scheduled-work-summary-table',
  'schedule-report',
  'template-submissions',
  'template-submission',
  'task-summary',
  'employee-productivity',
  'completed-work-report',
];
export function useReportList() {
  const dispatch = useDispatch();

  const reportsfetchInfo = useSelector(getReportsFetchInfo);
  const reportList = useSelector(getReportsState);
  const getReportsList = async () => {
    if (!reportsfetchInfo.initialLoad && reportsfetchInfo.loading) {
      const reports = await getReports();

      if (reports) {
        const sorted = sortBy(reports, (item) =>
          customOrder.indexOf(item?.slug),
        );
        dispatch(actions?.setReportsList(sorted ?? []));
        dispatch(
          actions?.setReportsInfo({
            initialLoad: true,
            loading: false,
          }),
        );
        return sorted;
      }
    } else {
      return reportList;
    }
  };
  return {
    getReportsList,
  };
}

export default useReportList;
