import actionsTypes from 'store/actionTypes';

export const setReportsList = (data) => ({
  type: actionsTypes.SET_REPORTS,
  payload: data,
});
export const setReportsInfo = (data) => ({
  type: actionsTypes.SET_REPORTS_FETCHED_INFO,
  payload: data,
});
