// MUI components
import { Divider, Stack } from '@mui/material';
import { CrossIconBtn, HeaderContainer } from './style';
import CustomButton from 'components/Button/CustomButton';
import SaveIcon from 'components/Icons/saveIcon';
import GlobeIcon from 'components/Icons/globeIcon';
import DisabledGlobeIcon from 'components/Icons/disabledGlobeIcon';
import CrossIcon from 'components/Icons/crossIcon';

const WorkflowBuilderHeader = ({
  cancelHandler,
  isPublished,
  data,
  isCreate,
  createHandler,
  saveChangesHandler,
  publishHandler,
  UnpublishHandler,
  isDirtyChangesForEdit,
  selectedChecklistId,
  selectedApprovalId,
  isSaving = false,
  handleClose,
}) => {
  return (
    <HeaderContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: 'unset !important',
          },
        }}
      >
        <CrossIconBtn className="closeIcon" onClick={handleClose}>
          <CrossIcon style={{ height: 20 }} />
        </CrossIconBtn>
        <Stack direction="row" alignItems="center" gap="10px">
          <CustomButton
            variant="text"
            className="cancelBtn"
            onClick={cancelHandler}
          >
            Cancel
          </CustomButton>

          <Divider
            orientation="vertical"
            sx={{ height: '30px', marginRight: '10px' }}
          />

          {isDirtyChangesForEdit && data?.checklist?.id && (
            <CustomButton
              variant="outlined"
              disabled={isSaving}
              className="saveChangesBtn"
              startIcon={
                <SaveIcon
                  sx={{
                    path: {
                      stroke: '#4E48FA',
                      fill: 'white',
                    },
                  }}
                />
              }
              onClick={isCreate ? createHandler : saveChangesHandler}
            >
              Save Changes
            </CustomButton>
          )}
          {isPublished ? (
            <CustomButton
              variant="contained"
              disabled={isSaving}
              className="publishBtn"
              startIcon={
                <DisabledGlobeIcon fontSize="small" style={{ color: 'fff' }} />
              }
              onClick={UnpublishHandler}
            >
              Unpublish
            </CustomButton>
          ) : (
            <CustomButton
              variant="contained"
              className="publishBtn"
              startIcon={
                <GlobeIcon fontSize="small" style={{ color: 'fff' }} />
              }
              onClick={
                isCreate
                  ? () => createHandler(true)
                  : () =>
                      publishHandler(
                        false,
                        selectedChecklistId,
                        selectedApprovalId,
                      )
              }
              disabled={!data?.checklist?.id || isSaving}
            >
              Publish
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default WorkflowBuilderHeader;
