// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Utilities
import xeniaApi from 'api/index';
import DIALOGS from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';

export const useTemplatePreview = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.TEMPLATE_PREVIEW;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId]?.data;
  const { selectedTemplate, listofTemplatesId } = modalState || {};
  const [isLoading, setIsLoading] = useState(false);

  const [templateDetails, setTemplateDetails] = useState<any>(null);

  const [activeTemplateIndex, setActiveTemplateIndex] = useState<number>(-1);

  // Get Current Template Details
  const getCurrentTemplate = async (id) => {
    setIsLoading(true);
    const response = await xeniaApi.getPublicChecklistById(id);
    setTemplateDetails(response?.data);
    const activeIndex = listofTemplatesId?.findIndex(
      (item) => item == response?.data?.id,
    );
    setActiveTemplateIndex(activeIndex);

    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedTemplate) getCurrentTemplate(selectedTemplate?.id);
  }, [selectedTemplate]);

  // close template preview modal & library modal
  const handleCloseAllModals = () => {
    handleCloseModal(); // close template preview modal
    dispatch(actions.closeDialog(DIALOGS.TEMPLATE_LIBRARY)); // close template library modal
  };

  // handle open the preview template
  const handleOpenPreviewChecklist = (tab, checklistData) => {
    // if we dont want to open any modal
    if (tab !== '') {
      dispatch(actions.setPreviewChecklistData(checklistData));
      dispatch(
        actions.setPreviewChecklistModalConfig({
          open: true,
          activeTab: tab,
          checklistScope: 'myTemplates',
        }),
      );
    }
    navigateWithWorkspaceUrl('/checklist'); // go to checklist page
    handleCloseAllModals();
  };

  // listen to back and next buttons
  const handleChangeNavigation = (direction: 'back' | 'next') => {
    let nextIndex = -1;
    if (direction === 'back') {
      nextIndex =
        activeTemplateIndex === 0
          ? listofTemplatesId?.length - 1
          : activeTemplateIndex - 1;
    } else if (direction === 'next') {
      nextIndex =
        activeTemplateIndex === listofTemplatesId?.length - 1
          ? 0
          : activeTemplateIndex + 1;
    }

    getCurrentTemplate(listofTemplatesId?.[nextIndex]);
  };

  // duplicate template to myTemplates
  const handleSaveTemplate = async () => {
    setIsLoading(true);
    const duplicateResponse = await xeniaApi.duplicateChecklist({
      checklistId: templateDetails?.id,
      disableToast: true,
      saveToLibrary: true,
    });
    setIsLoading(false);
    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    handleCloseAllModals();
    navigateWithWorkspaceUrl(`/checklist/${duplicateResponse.data.id}`);
  };

  // use template function
  const handleUseTemplate = async () => {
    setIsLoading(true);

    const duplicateResponse = await xeniaApi.duplicateChecklist({
      checklistId: templateDetails?.id as string,
      disableToast: true,
      saveToLibrary: true,
    });

    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    dispatch(
      actions.getChecklistListApiCall({ detailed: true, folderId: 'root' }),
    );
    setIsLoading(false);
    if (isMobileApp) {
      postToMobileApp('closeWebView', '');
    } else {
      handleOpenPreviewChecklist('', duplicateResponse.data);
    }
  };

  const handleCloseModal = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  return {
    open: dialogState?.[dialogId]?.open,
    isLoading,
    template: templateDetails,
    handleChangeNavigation,
    handleSaveTemplate,
    handleUseTemplate,
    handleCloseModal,
  };
};
