import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import ChecklistIcon from 'components/Icons/checklistIcon';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import {
  CheckListSeparateAssigneeText,
  CheckListSeparateBox,
  Checkbox,
} from 'components/AllDialogs/tasks/createTask/createTask.style';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';
import actions from 'store/actions';
import { OperationTemplateCnt, SelectedChecklist } from './style';

interface TaskOperationTemplateProps {
  attachedChecklist: any; // Replace 'any' with a proper type
  removeChecklistCallback: () => void;
  hideElements?: {
    isRequiredChecklistCheckbox?: boolean;
  };
  isChecklistRequired: boolean;
  handleChecklistRequiredCallback: (checked: boolean) => void;
  handleAttachChecklistCallback: (checklist: any) => void; // Replace 'any' with a proper type
  isMultiTasks?: boolean;
  showLess?: boolean;
}

const TaskOperationTemplate: FC<TaskOperationTemplateProps> = ({
  attachedChecklist,
  removeChecklistCallback,
  hideElements,
  isChecklistRequired,
  handleChecklistRequiredCallback,
  handleAttachChecklistCallback,
  isMultiTasks,
  showLess,
}) => {
  const dispatch = useDispatch();
  const { PAYWALL_LIMITS } = useCustomPermission();

  const MuiIcon = useCallback(({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  }, []);

  const openAttachChecklistModal = useCallback(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.ATTACH_CHECKLIST,
        open: true,
        data: {
          onClickAttach: handleAttachChecklistCallback,
        },
      }),
    );
  }, [dispatch, handleAttachChecklistCallback]);

  return (
    <>
      {attachedChecklist ? (
        <div>
          <SelectedChecklist>
            <div className="leftCnt">
              <span
                className="checklistIcon"
                style={{ background: attachedChecklist?.icon?.color }}
              >
                <MuiIcon
                  name={attachedChecklist?.icon?.icon}
                  sx={{ fontSize: '18px' }}
                />
              </span>

              <div className="checklistNameCnt">
                <span className="checklistName">{attachedChecklist?.name}</span>
              </div>
            </div>
            <IconButton
              className={'removeIconBtn'}
              onClick={removeChecklistCallback}
            >
              <RemoveCircleIcon className="removeIcon" />
            </IconButton>
          </SelectedChecklist>
          {!hideElements?.isRequiredChecklistCheckbox && (
            <>
              <Stack direction={'row'} mb={'20px'} alignItems="center">
                <Checkbox
                  checked={isChecklistRequired}
                  disableRipple
                  onChange={(e) =>
                    handleChecklistRequiredCallback(e?.target?.checked)
                  }
                />
                <Typography fontWeight={'600'} fontSize={'14px'} color="#000">
                  Completion of the template is required to finish this task.{' '}
                </Typography>
              </Stack>
              <Divider sx={{ marginBottom: '15px' }} />
            </>
          )}
          {isMultiTasks && (
            <CheckListSeparateBox
              style={{
                ...(!showLess && {
                  marginBottom: 12,
                }),
              }}
            >
              <InfoIcon className="icon" />
              <CheckListSeparateAssigneeText>
                All assignees will be assigned to this operation template as
                well.
              </CheckListSeparateAssigneeText>
            </CheckListSeparateBox>
          )}
        </div>
      ) : (
        <VerifyFeatureAccess
          paywallUsageId={PAYWALL_LIMITS.OPERATIONS_TEMPLATES}
        >
          <OperationTemplateCnt onClick={openAttachChecklistModal}>
            <ChecklistIcon className="icon" primary="#6868FE" />
            <Typography className="title">Attach Checklist Template</Typography>
          </OperationTemplateCnt>
        </VerifyFeatureAccess>
      )}
    </>
  );
};

export default TaskOperationTemplate;
