import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';
import 'ag-grid-enterprise';
import './listview.style.css';
import { rowHeight } from 'pages/task/TaskListView/constants';
import NoRecordsFound from './EmptyRecords/noRecordsFound.cmp';

const CustomListView = ({ onRowClick, rootProps, gridProps }) => {
  const { className, ...restRootProps } = rootProps;
  //When Row Is Click
  const onRowClicked = (event: any) => {
    //Ignore the row click action when a cell is clicked with a date-attribute=cell-click
    //cell-click means ignore the row click and call the events that's performed on the cell elements
    if (event.event.target.closest('[data-attribute=cell-click]')) {
      return;
    }
    //Group rows should not be clickable
    if (event.node.group) return;
    onRowClick && onRowClick(event?.data?.id, 'detail', event);
  };

  const onGridScroll = (params) => {
    const { api, direction } = params;
    const isScrollingVertically = direction === 'vertical';

    if (!isScrollingVertically) return;

    const lastRow = api.getDisplayedRowCount();
    const lastVisibleRow = api.getLastDisplayedRow();

    // Check if we're near the bottom (within last 20 rows)
    if (lastVisibleRow && lastRow && lastVisibleRow >= lastRow - 20) {
      // Call the pagination handler from props if provided
      gridProps.onPaginationScrollEnd?.();
    }
  };

  return (
    <div
      className={`ag-theme-material ag-theme-custom ${className}`}
      {...restRootProps}
    >
      <AgGridReact
        rowHeight={rowHeight}
        onRowClicked={onRowClicked}
        onBodyScroll={
          gridProps?.onPaginationScrollEnd ? onGridScroll : undefined
        }
        suppressCellFocus
        suppressColumnSelectAll
        suppressScrollOnNewData
        suppressContextMenu
        frameworkComponents={{
          customNoRowsOverlay: NoRecordsFound,
        }}
        noRowsOverlayComponent="customNoRowsOverlay"
        {...gridProps}
      ></AgGridReact>
    </div>
  );
};

export default CustomListView;
