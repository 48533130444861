import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import TemplateSelection from './steps/template';
import { LoaderCnt, SaveChangesPrompt, StepsContainer } from './style';
import ApprovalsSelection from './steps/approvals';
import WorkflowBuilderHeader from './header';
import { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import LongArrowIcon from 'components/Icons/longArrowIcon';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEqual, keyBy, omit } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { publishWorkflowCall, UnpublishWorkflowCall } from 'api/workflows';
import CustomButton from 'components/Button/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import CompletionNotification from './steps/notifications';
import CustomInput from 'components/Form/TextField/TextField.cmp';

function useBeforeUnload(when) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
}

const WorkflowBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.WORKFLOW_BUILDER_DIALOG];
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);
  const roles = useSelector(selectors.getRoles);
  const { hashRoles } = roles;
  const [redirectionType, setRedirectionType] = useState<any>(null);
  const { getWorkflow, batchOperationsWorkflow } = useWorkflows();
  const [isDirtyChanges, setIsDirtyChanges] = useState(false);

  const [initialData, setInitialData] = useState<any>(null);

  const [updatedData, setUpdatedData] = useState<any>(null);

  const [selectedChecklistId, setSelectedChecklistId] = useState<string | null>(
    null,
  );
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | null>(
    null,
  );

  const [isPublished, setIsPublished] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useBeforeUnload(isDirtyChanges);

  const { open, data } = modalState || {};
  const { noURLUpdate } = data || {};

  useEffect(() => {
    if (open) {
      setUpdatedData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    const result = initialData?.notifications?.recipients
      ?.map((recipient) => {
        if (recipient?.type === 'user') {
          const Assigned = usersHash?.[recipient?.id];
          return Assigned ? { Assigned } : undefined;
        }
        if (recipient?.type === 'role') {
          const Role = hashRoles?.[recipient?.id];
          return Role ? { Role } : undefined;
        }
        if (recipient?.type === 'team') {
          const Team = teamsHash?.[recipient?.id];
          return Team ? { Team } : undefined;
        }
        return undefined;
      })
      .filter(Boolean);
    setInitialData({
      ...initialData,
      isCompletionNotificationEnabled: !!result?.length,
    });
  }, [initialData?.notifications, usersHash, hashRoles, teamsHash]);

  const transformResponse = (response) => {
    const transformedResponse: any = {};
    transformedResponse.title = response?.title
      ? { id: response?.id, name: response?.title }
      : {};
    transformedResponse.checklist = response?.Checklist
      ? {
          id: response?.Checklist?.id,
          name: response?.Checklist?.name,
        }
      : {};
    transformedResponse.notifications = {};
    if (response?.ChecklistApprovalWorkflows?.length > 0) {
      transformedResponse.approvalSteps = [];

      response?.ChecklistApprovalWorkflows?.forEach((approval) => {
        if (approval?.type === 'REQUIRE_APPROVAL') {
          const approvalData = approval?.actions?.[0]?.data?.[0];
          transformedResponse?.approvalSteps?.push({
            id: approvalData?.id,
            type: approvalData?.type,
            actionId: approval?.id,
          });
        } else {
          const notificationsData = approval?.actions?.[0]?.data;
          transformedResponse.notifications = {
            recipients: notificationsData,
            actionId: approval?.id,
          };
        }
      });
    }

    return transformedResponse;
  };

  const getWorkflowDetails = async (checklistId, approvalId) => {
    const response = await getWorkflow({}, checklistId, approvalId);
    if (response?.PublishedChecklistApprovalId) {
      setIsPublished(true);
    } else {
      setIsPublished(false);
    }
    const transformedResponse = transformResponse(response);
    setInitialData(transformedResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const approvalId = params.get('checklistApprovalId');
    const checklistId = params.get('checklistId');

    if (approvalId && checklistId) {
      setSelectedChecklistId(checklistId);
      setSelectedApprovalId(approvalId);
      getWorkflowDetails(checklistId, approvalId);
    } else {
      setIsLoading(false);
    }
  }, [location?.search]);

  const updateTitle = (title) => {
    if (title?.length < 50) {
      setUpdatedData({
        ...updatedData,
        title: {
          ...updatedData?.title,
          name: title,
        },
      });
    }
  };

  const updateChecklist = (checklist) => {
    setUpdatedData({ ...updatedData, checklist });
  };

  const updateRecipients = (recipients) => {
    setUpdatedData({
      ...updatedData,
      notifications: {
        recipients,
        ...(updatedData?.notifications?.actionId
          ? { actionId: updatedData?.notifications?.actionId }
          : {}),
      },
    });
  };

  const updateCompletionNotificationToggle = (
    isCompletionNotificationEnabled,
  ) => {
    setUpdatedData({ ...updatedData, isCompletionNotificationEnabled });
  };

  // Add New Approval Step
  const addApprovalStep = (approval) => {
    setUpdatedData({
      ...updatedData,
      approvalSteps: [...(updatedData?.approvalSteps ?? []), approval ?? {}],
    });
  };

  // Edit Approval Step
  const editApprovalStep = (approval, row) => {
    const updatedApprovalSteps = updatedData?.approvalSteps?.map(
      (item, index) => {
        if (index === row) {
          return approval;
        }
        return item;
      },
    );

    setUpdatedData({
      ...updatedData,
      approvalSteps: updatedApprovalSteps,
    });
  };

  const removeApprovalStep = (row) => {
    const updatedApprovalSteps = updatedData?.approvalSteps?.filter(
      (_, index) => index !== row,
    );

    const updated = { ...updatedData, approvalSteps: updatedApprovalSteps };
    if (updated?.approvalSteps?.length === 0) {
      delete updated?.approvalSteps;
    }
    setUpdatedData(updated);
  };

  const generateCreatePayload = () => {
    const payload: any = [];
    payload.push({
      data: {
        title:
          updatedData?.title?.name ||
          `${updatedData?.checklist?.name} Approval Workflow`,
      },
      table: 'ChecklistApproval',
      type: 'CREATE',
    });

    if (updatedData?.approvalSteps?.length > 0) {
      updatedData?.approvalSteps?.forEach((approval) => {
        payload.push({
          data: {
            actions: [
              {
                data: [
                  {
                    id: approval?.id,
                    type: approval?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'CREATE',
        });
      });
    }

    if (updatedData?.notifications?.recipients?.length > 0) {
      payload.push({
        data: {
          actions: [
            {
              data: updatedData?.notifications?.recipients,
              type: 'EMAIL_REPORT_NOTIFICATION',
            },
          ],
          type: 'EMAIL_REPORT_NOTIFICATION',
        },
        table: 'ChecklistApprovalWorkflow',
        type: 'CREATE',
      });
    }

    return payload;
  };

  const generateSaveChangesPayload = () => {
    const payload: any = [];

    if (initialData?.title?.name !== updatedData?.title?.name) {
      payload.push({
        data: {
          title:
            updatedData?.title?.name ||
            `${updatedData?.checklist?.name} Approval Workflow`,
        },
        id: updatedData?.title?.id,
        table: 'ChecklistApproval',
        type: 'UPDATE',
      });
    }

    // Process approval steps
    const initialSteps = keyBy(initialData?.approvalSteps, 'actionId'); // Map by 'id' for quick lookup
    const updatedSteps = keyBy(updatedData?.approvalSteps, 'actionId');

    // Process completion notifications
    const initialNotifications = keyBy(initialData?.notifications, 'actionId'); // Map by 'id' for quick lookup
    const updatedNotifications = keyBy(updatedData?.notifications, 'actionId');

    const createActions: any = [];
    const updateActions: any = [];
    const deleteActions: any = [];

    // Identify new and updated steps
    updatedData?.approvalSteps?.forEach((step) => {
      if (!step.actionId) {
        // New step
        createActions.push({
          data: {
            actions: [
              {
                data: [
                  {
                    id: step?.id,
                    type: step?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'CREATE',
        });
      } else if (
        !isEqual(
          omit(initialSteps[step.actionId], 'actionId'), // Exclude 'id' from comparison
          omit(step, 'actionId'),
        )
      ) {
        // Updated step
        updateActions.push({
          id: step?.actionId,
          data: {
            actions: [
              {
                id: uuidV4(),
                data: [
                  {
                    id: step?.id,
                    type: step?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'UPDATE',
        });
      }
    });

    // Identify new and updated notifications
    if (
      updatedData?.notifications?.recipients?.length > 0 &&
      updatedData?.isCompletionNotificationEnabled
    ) {
      if (!updatedData?.notifications?.actionId) {
        // New notification
        createActions.push({
          data: {
            actions: [
              {
                data: updatedData?.notifications?.recipients,
                type: 'EMAIL_REPORT_NOTIFICATION',
              },
            ],
            type: 'EMAIL_REPORT_NOTIFICATION',
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'CREATE',
        });
      } else if (
        !isEqual(
          omit(
            initialNotifications[updatedData?.notifications?.actionId],
            'actionId',
          ), // Exclude 'id' from comparison
          omit(updatedData?.notifications, 'actionId'),
        )
      ) {
        // Updated notification
        updateActions.push({
          id: updatedData?.notifications?.actionId,
          data: {
            actions: [
              {
                data: updatedData?.notifications?.recipients,
                type: 'EMAIL_REPORT_NOTIFICATION',
              },
            ],
            type: 'EMAIL_REPORT_NOTIFICATION',
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'UPDATE',
        });
      }
    }

    // Identify deleted steps
    initialData?.approvalSteps?.forEach((step) => {
      if (!updatedSteps[step?.actionId]) {
        deleteActions.push({
          id: step?.actionId,
          table: 'ChecklistApprovalWorkflow',
          type: 'DELETE',
        });
      }
    });

    // Identify deleted notifications
    if (
      initialData?.notifications?.recipients?.length > 0 &&
      !updatedData?.isCompletionNotificationEnabled
    ) {
      if (!updatedNotifications[initialData?.notifications?.actionId]) {
        deleteActions.push({
          id: initialData?.notifications?.actionId,
          table: 'ChecklistApprovalWorkflow',
          type: 'DELETE',
        });
      }
    }

    updateActions?.forEach((action) => {
      payload.push(action);
    });

    createActions?.forEach((action) => {
      payload.push(action);
    });

    deleteActions?.forEach((action) => {
      payload.push(action);
    });

    return payload;
  };

  const handleClose = () => {
    dispatch(closeDialog(DIALOGS.WORKFLOW_BUILDER_DIALOG));
  };

  const verifyUnSavedChanges = (redirectionType) => {
    if (isDirtyChanges) {
      setShowExitPrompt(true);
      setRedirectionType(redirectionType);
    } else {
      redirectToWorkflows();
    }
  };

  const redirectToWorkflows = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.WORKFLOW_BUILDER_DIALOG,
      }),
    );
    if (!noURLUpdate) {
      navigateWithWorkspaceUrl('/checklist?redirectTo=workflows');
    }
  };

  const createHandler = async (publish = false) => {
    const transformed = generateCreatePayload();
    setIsSaving(true);
    const response = await batchOperationsWorkflow(
      transformed,
      updatedData?.checklist?.id,
      null,
      false,
    );
    if (response?.id) {
      const params = new URLSearchParams(location.search);
      params.set('checklistApprovalId', response?.id);
      params.set('checklistId', response?.ChecklistId);

      setSelectedChecklistId(response?.ChecklistId);
      setSelectedApprovalId(response?.id);
      if (!noURLUpdate) navigate(`${location.pathname}?${params.toString()}`);

      const transformedResponse = transformResponse(response);
      setInitialData(transformedResponse);

      setTimeout(async () => {
        if (publish) {
          await publishHandler(true, response?.ChecklistId, response?.id);
        }
        setIsSaving(false);
      }, 1000);

      setShowExitPrompt(false);
    } else {
      setIsSaving(false);
    }
  };

  const saveChangesHandler = async () => {
    setIsSaving(true);
    const transformed = generateSaveChangesPayload();
    const response = await batchOperationsWorkflow(
      transformed,
      selectedChecklistId,
      selectedApprovalId,
      true,
    );

    if (response) {
      const transformedResponse = transformResponse(response);
      setInitialData(transformedResponse);

      // Auto Publishing
      if (isPublished) {
        await publishHandler(true, selectedChecklistId, selectedApprovalId);
      }
      setIsSaving(false);
      setShowExitPrompt(false);
    }
  };

  const publishHandler = async (
    suppressMessage = false,
    selectedChecklistId,
    selectedApprovalId,
  ) => {
    setIsSaving(true);
    const response = await publishWorkflowCall(
      {},
      selectedChecklistId,
      selectedApprovalId,
      suppressMessage,
    );
    if (response) {
      setIsPublished(true);
    }
    setIsSaving(false);
  };

  const UnpublishHandler = async () => {
    setIsSaving(true);
    const response = await UnpublishWorkflowCall(
      {},
      selectedChecklistId,
      selectedApprovalId,
    );

    if (response) {
      setIsPublished(false);
    }
    setIsSaving(false);
  };

  const cancelHandler = () => {
    verifyUnSavedChanges('REDIRECT_TO_WORKFLOWS');
  };

  const isDirtyChangesForEdit = useMemo(() => {
    const changes = !isEqual(initialData, updatedData);
    if (changes && !isDirtyChanges) {
      setIsDirtyChanges(true);
    }

    if (!changes && isDirtyChanges) {
      setIsDirtyChanges(false);
    }
    return changes;
  }, [initialData, updatedData]);

  const isCreate = useMemo(() => {
    const { isCompletionNotificationEnabled, ...filteredInitialData } =
      initialData || {};
    return Object.keys(filteredInitialData).length === 0;
  }, [initialData]);

  const defaultInputStyle: React.CSSProperties = {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32.02px',
    color: '#000000',
    paddingLeft: 0,
  };
  const mergedInputStyle = { ...defaultInputStyle };

  return (
    <>
      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            maxWidth: 'unset',
            margin: 0,
            maxHeight: 'unset',
          },
        }}
        fullWidth
        open={open}
        handleClose={handleClose}
      >
        {isLoading && (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        )}

        {!isLoading && (
          <>
            <WorkflowBuilderHeader
              cancelHandler={cancelHandler}
              data={updatedData}
              isPublished={isPublished}
              isCreate={isCreate}
              createHandler={createHandler}
              saveChangesHandler={saveChangesHandler}
              publishHandler={publishHandler}
              UnpublishHandler={UnpublishHandler}
              selectedChecklistId={selectedChecklistId}
              selectedApprovalId={selectedApprovalId}
              isDirtyChangesForEdit={isDirtyChangesForEdit}
              isSaving={isSaving}
              handleClose={handleClose}
            />

            <StepsContainer>
              <CustomInput
                className="title"
                underlineInput={true}
                suppressErrorSpace={true}
                fieldProps={{
                  autoFocus: true,
                  value: data?.title?.name,
                  inputProps: { style: mergedInputStyle },
                  placeholder: 'Give your workflow a name',
                  onChange: (e) => {
                    updateTitle(e.target.value);
                  },
                }}
                sx={{ width: '100%', margin: '-20px 0px 20px 0px' }}
              />
              <TemplateSelection
                data={updatedData}
                updateChecklist={updateChecklist}
              />
              <LongArrowIcon className="arrowIcon" />

              <ApprovalsSelection
                data={updatedData}
                addApprovalStep={addApprovalStep}
                editApprovalStep={editApprovalStep}
                removeApprovalStep={removeApprovalStep}
              />

              <LongArrowIcon className="arrowIcon" />
              <CompletionNotification
                data={updatedData}
                updateRecipients={updateRecipients}
                updateCompletionNotificationToggle={
                  updateCompletionNotificationToggle
                }
              />
            </StepsContainer>
          </>
        )}
      </CustomModal>

      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '24px',
            width: '600px',
            height: 'auto',
            padding: '20px',
          },
        }}
        open={showExitPrompt}
        handleClose={() => {
          setShowExitPrompt(false);
        }}
      >
        <SaveChangesPrompt>
          <Box className="header">
            <Typography className="title">Save Changes?</Typography>
            <CloseIcon
              onClick={() => setShowExitPrompt(false)}
              sx={{ fontSize: '22px', cursor: 'pointer', color: '#1C1B1F' }}
            />
          </Box>
          <Typography className="description">
            You have unsaved changes. Would you like to discard or save them?
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomButton
              variant="text"
              onClick={() => {
                setShowExitPrompt(false);
              }}
            >
              Cancel
            </CustomButton>
            <Stack direction="row" alignItems="center" gap="10px">
              <CustomButton
                variant="outlined"
                onClick={() => {
                  redirectToWorkflows();
                }}
              >
                Discard
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() =>
                  isCreate ? createHandler() : saveChangesHandler()
                }
              >
                Save Changes
              </CustomButton>
            </Stack>
          </Stack>
        </SaveChangesPrompt>
      </CustomModal>
    </>
  );
};

export default WorkflowBuilder;
