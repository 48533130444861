// React
import { useCallback, useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// MUI
import { Badge, Divider } from '@mui/material';
import List from '@mui/material/List';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import { HasPermission } from 'components/HasPermission';
import SidebarTaskAndWorkOrdersDropdown from 'components/layout/sidebar/sidebarDropdownItems/taskAndWorkOrders/SidebarTaskAndWorkOrders';

// Icons
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import MessagingIcon from 'components/Icons/sidebarIcons/messagingIcon';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import ApprovalReportIcon2 from 'components/Icons/sidebarIcons/approvalsReportIcon2';
import ProjectIcon from 'components/Icons/projectIcon';

// Utils
import { appRoutes } from 'utils/globalVariable';
import appConstants from 'utils/appConstants';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSidebarConfig } from 'utils/CustomHooks/useSidebarConfig';
import { getReportsState } from 'store/selectors/reports';

// Constants
import DocumentsIcon from 'components/Icons/documents';
import SidebarScheduledWorkDropdown from '../sidebarDropdownItems/scheduledWork/scheduledWork';
import SidebarWorkRequests from '../sidebarDropdownItems/workReqests/SidebarWorkrequests';
import SidebarDashboards from '../sidebarDropdownItems/dashboards/SidebarDasboard';
import SidebarWorkspaceSettings from '../sidebarDropdownItems/workspaceSettings/SidebarWorkspaceSettings';
import SidebarUserMySettings from '../sidebarDropdownItems/userSettings/SidebarUserMySettings';
import CustomDashboardIcon from 'components/Icons/sidebarIcons/customdashboardIcon';
import PulseDashboardIcon from 'components/Icons/sidebarIcons/pulseDashboardIcon';
import GalleryViewIcon from 'components/Icons/sidebarIcons/galleryViewIcon';
import TemplateExportIcon from 'components/Icons/sidebarIcons/templateExportIcon';
import { SidebarItemHeading } from './sidebar.style';
import RequestsIcon from 'components/Icons/sidebarIcons/requestsIcon';
import FileCheckO3 from 'components/Icons/fileCheck03';
import FlashIcon from 'components/Icons/sidebarIcons/flashIcon';

const CustomMenu = ({ handleNavClick, sidebarOpen, messagesUnreadCount }) => {
  const location = useLocation();
  const currentPath = `${location?.pathname}${location?.search}`;

  const [predefinedReports, setPredefinedReports] = useState<any>([]);

  const userRoletype: any = useSelector(selectors.getAuthRole);
  const navigationDetails = useSelector(selectors.getNavigationData);
  const reportList = useSelector(getReportsState);
  const { selected } = navigationDetails || {};

  const { PERMISSIONS, hasPermission, hasFeature, FEATURES } =
    useCustomPermission();

  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageRoles = hasPermission(PERMISSIONS.CAN_MANAGE_ROLES);
  const { MENU_ITEM_IDS, visibleMenuItems, sidebarConfig } = useSidebarConfig();

  const OPERATIONS = {
    [MENU_ITEM_IDS.operationsTemplates]: MENU_ITEM_IDS.operationsTemplates,
    [MENU_ITEM_IDS.taskAndWorkOrders]: MENU_ITEM_IDS.taskAndWorkOrders,
    [MENU_ITEM_IDS.scheduledWork]: MENU_ITEM_IDS.scheduledWork,
    [MENU_ITEM_IDS.projects]: MENU_ITEM_IDS.projects,
    [MENU_ITEM_IDS.chats]: MENU_ITEM_IDS.chats,
    [MENU_ITEM_IDS.sopWriterAndFiles]: MENU_ITEM_IDS.sopWriterAndFiles,
  };

  const FACILITIES = {
    [MENU_ITEM_IDS.requestedWorkOrders]: MENU_ITEM_IDS.requestedWorkOrders,
    [MENU_ITEM_IDS.requests]: MENU_ITEM_IDS.requests,
    [MENU_ITEM_IDS.routingRules]: MENU_ITEM_IDS.routingRules,
    [MENU_ITEM_IDS.assets]: MENU_ITEM_IDS.assets,
  };

  const REPORTING = {
    [MENU_ITEM_IDS.locationBasedReports]: MENU_ITEM_IDS.locationBasedReports,
    [MENU_ITEM_IDS.dashboards]: MENU_ITEM_IDS.dashboards,
    [MENU_ITEM_IDS.customDashboards]: MENU_ITEM_IDS.customDashboards,
    [MENU_ITEM_IDS.templateExports]: MENU_ITEM_IDS.templateExports,
  };

  const SETTINGS = {
    [MENU_ITEM_IDS.usersAndTeams]: MENU_ITEM_IDS.usersAndTeams,
    [MENU_ITEM_IDS.workspaceSettings]: MENU_ITEM_IDS.workspaceSettings,
    [MENU_ITEM_IDS.userSettings]: MENU_ITEM_IDS.userSettings,
  };

  const VISIBLE_SIDE_MENU_ACTIONS = {
    OPERATIONS: OPERATIONS,
    FACILITIES: FACILITIES,
    REPORTING: REPORTING,
    SETTINGS: SETTINGS,
  };

  useEffect(() => {
    setPredefinedReports(reportList);
  }, [reportList]);

  const sidebarItemsMap = {
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.operationsTemplates
    ] && {
      [MENU_ITEM_IDS.operationsTemplates]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION]}>
          <SidebarItem
            title={appConstants.checklist.plural.base}
            icon={<ChecklistIcon filled={currentPath.includes('/checklist')} />}
            onClick={() => handleNavClick('/checklist?view=grid')}
            isSelected={currentPath.includes('/checklist')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.taskAndWorkOrders
    ] && {
      [MENU_ITEM_IDS.taskAndWorkOrders]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASKS]}>
          <SidebarTaskAndWorkOrdersDropdown sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.scheduledWork
    ] && {
      [MENU_ITEM_IDS.scheduledWork]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
          <SidebarScheduledWorkDropdown sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.projects] && {
      [MENU_ITEM_IDS.projects]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
          <SidebarItem
            title={'Projects'}
            icon={<ProjectIcon filled={currentPath.includes('/projects')} />}
            onClick={() => handleNavClick('/projects')}
            isSelected={currentPath.includes('/projects')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.requestedWorkOrders
    ] && {
      [MENU_ITEM_IDS.requestedWorkOrders]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASKS]}>
          <SidebarItem
            title={'Work Orders'}
            icon={
              <FileCheckO3
                style={{
                  fill: 'none',
                  color: currentPath.includes(appRoutes.requestedWorkOrders)
                    ? '4E48FA'
                    : 'rgba(97,97,97,1)',
                  fontSize: '21px',
                }}
              />
            }
            onClick={() =>
              handleNavClick('/filtered-tasks/?filters=taskTypes|is|requested')
            }
            isSelected={currentPath.includes(appRoutes.requestedWorkOrders)}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.requests] && {
      [MENU_ITEM_IDS.requests]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASK_REQUESTS]}>
          <SidebarItem
            title={'Work Order Requests'}
            icon={
              <RequestsIcon filled={currentPath.includes(appRoutes.requests)} />
            }
            onClick={() => handleNavClick(appRoutes.requests)}
            isSelected={currentPath.includes(appRoutes.requests)}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.routingRules] && {
      [MENU_ITEM_IDS.routingRules]: (
        <SidebarItem
          title={'Routing Rules'}
          icon={
            <FlashIcon filled={currentPath.includes(appRoutes.routingRules)} />
          }
          onClick={() => handleNavClick(appRoutes.routingRules)}
          isSelected={currentPath.includes(appRoutes.routingRules)}
          sidebarOpen={sidebarOpen}
        />
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.locationBasedReports
    ] && {
      [MENU_ITEM_IDS.locationBasedReports]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          {sidebarOpen && (
            <SidebarItemHeading>Location Based Reports</SidebarItemHeading>
          )}
          <SidebarItem
            title={'Location Pulse Dashboard'}
            icon={
              <PulseDashboardIcon
                filled={currentPath.includes('/dashboard?type=PULSE')}
              />
            }
            onClick={() => handleNavClick('/dashboard?type=PULSE')}
            isSelected={currentPath.includes('/dashboard?type=PULSE')}
            sidebarOpen={sidebarOpen}
          />
          <SidebarItem
            title={'Gallery View'}
            icon={<GalleryViewIcon filled={currentPath.includes('/gallery')} />}
            onClick={() => handleNavClick('/gallery')}
            isSelected={currentPath.includes('/gallery')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.customDashboards
    ] && {
      [MENU_ITEM_IDS.customDashboards]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_DASHBOARDS]}>
          {hasFeature(FEATURES.REPORTING_DASHBOARD) ? (
            <>
              {sidebarOpen ? (
                <SidebarItemHeading>Custom Dashboards</SidebarItemHeading>
              ) : (
                <Divider style={{ width: 40, margin: '10px 0px' }} />
              )}
              <SidebarItem
                title={'View Dashboards'}
                icon={
                  <CustomDashboardIcon
                    filled={currentPath.includes(
                      '/dashboards?tab=custom-dashboards',
                    )}
                  />
                }
                onClick={() =>
                  handleNavClick('/dashboards?tab=custom-dashboards')
                }
                isSelected={currentPath.includes(
                  '/dashboards?tab=custom-dashboards',
                )}
                sidebarOpen={sidebarOpen}
              />
            </>
          ) : null}
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.dashboards] && {
      [MENU_ITEM_IDS.dashboards]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          {predefinedReports?.length ? (
            sidebarOpen ? (
              <SidebarItemHeading>Task Compliance Reports</SidebarItemHeading>
            ) : (
              <Divider style={{ width: 40, margin: '10px 0px' }} />
            )
          ) : null}
          <SidebarDashboards sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.templateExports
    ] && {
      [MENU_ITEM_IDS.templateExports]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          {sidebarOpen ? (
            <SidebarItemHeading>Template Submissions</SidebarItemHeading>
          ) : (
            <Divider style={{ width: 40, margin: '10px 0px' }} />
          )}
          {predefinedReports
            ?.filter((report) => report.slug === 'template-submissions')
            ?.map((item) => (
              <SidebarItem
                title={
                  item?.name?.length > 22
                    ? `${item?.name?.slice(0, 22)}...`
                    : item?.name
                }
                icon={<ChecklistIcon filled={currentPath.includes(item?.id)} />}
                onClick={() =>
                  handleNavClick(`/dashboards/template/${item?.id}`)
                }
                isSelected={currentPath.includes(item?.id)}
                sidebarOpen={sidebarOpen}
              />
            ))}
          <SidebarItem
            title={'Template Exports'}
            icon={
              <TemplateExportIcon
                filled={currentPath.includes(
                  '/dashboards?tab=template-exports',
                )}
              />
            }
            onClick={() => handleNavClick('/dashboards?tab=template-exports')}
            isSelected={currentPath.includes(
              '/dashboards?tab=template-exports',
            )}
            sidebarOpen={sidebarOpen}
          />
          {predefinedReports
            ?.filter((report) => report.slug === 'approvals-report')
            ?.map((item) => (
              <SidebarItem
                title={
                  item?.name?.length > 22
                    ? `${item?.name?.slice(0, 22)}...`
                    : item?.name
                }
                icon={
                  <ApprovalReportIcon2
                    filled={currentPath.includes(item?.id)}
                  />
                }
                onClick={() =>
                  handleNavClick(`/dashboards/template/${item?.id}`)
                }
                isSelected={currentPath.includes(item?.id)}
                sidebarOpen={sidebarOpen}
              />
            ))}
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.chats] && {
      [MENU_ITEM_IDS.chats]: (
        <HasPermission permissions={[PERMISSIONS.CAN_USE_CHAT]}>
          <SidebarItem
            title={'Chats'}
            icon={
              sidebarOpen ? (
                <MessagingIcon filled={currentPath.includes('/messages')} />
              ) : (
                <Badge badgeContent={messagesUnreadCount}>
                  <MessagingIcon filled={currentPath.includes('/messages')} />
                </Badge>
              )
            }
            onClick={() => handleNavClick('/messages')}
            isSelected={currentPath.includes('/messages')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.locations] && {
      [MENU_ITEM_IDS.locations]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
          <SidebarItem
            title={'Locations'}
            icon={<LocationIcon filled={currentPath.includes('/locations')} />}
            onClick={() => handleNavClick('/locations')}
            isSelected={currentPath.includes('/locations')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.assets] && {
      [MENU_ITEM_IDS.assets]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
          <SidebarItem
            title={'Assets'}
            icon={
              <AssetsIcon filled={currentPath.includes(appRoutes.assets)} />
            }
            onClick={() => handleNavClick(appRoutes.assets)}
            isSelected={currentPath.includes('/assets')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.sopWriterAndFiles
    ] && {
      [MENU_ITEM_IDS.sopWriterAndFiles]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_SOP_AND_FILES]}>
          <SidebarItem
            title={'Documents'}
            icon={
              <DocumentsIcon
                filled={
                  currentPath.includes('/documents/my-documents') ||
                  currentPath.includes('/documents/archive')
                }
              />
            }
            onClick={() => handleNavClick('/documents/my-documents')}
            isSelected={
              currentPath.includes('/documents/my-documents') ||
              currentPath.includes('/documents/archive')
            }
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.settings] && {
      [MENU_ITEM_IDS.settings]: (
        <SidebarItem
          title={'Settings'}
          icon={<SettingsIcon filled={currentPath.includes('/settings')} />}
          onClick={() => {
            const pathToNavigate = hasPermission(
              PERMISSIONS.CAN_MANAGE_WORKSPACES,
            )
              ? '/settings/organization-management'
              : '/settings/my-profile';
            handleNavClick(pathToNavigate);
          }}
          isSelected={currentPath.includes('/settings')}
          sidebarOpen={sidebarOpen}
        />
      ),
    }),

    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.usersAndTeams
    ] && {
      [MENU_ITEM_IDS.usersAndTeams]:
        canManageUsers || canManageTeams || canManageRoles ? (
          <SidebarItem
            title={'Users & Teams'}
            icon={
              <UsersAndTeamsIcon
                filled={currentPath.includes('/users-and-teams')}
              />
            }
            onClick={() => handleNavClick('/users-and-teams')}
            isSelected={currentPath.includes('/users-and-teams')}
            sidebarOpen={sidebarOpen}
          />
        ) : null,
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.workspaceSettings
    ] && {
      [MENU_ITEM_IDS.workspaceSettings]: (
        <SidebarWorkspaceSettings sidebarOpen={sidebarOpen} />
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.userSettings] && {
      [MENU_ITEM_IDS.userSettings]: (
        <SidebarUserMySettings sidebarOpen={sidebarOpen} />
      ),
    }),
  };

  const getSidebarComponent = useCallback(
    (menuItemId: string) => sidebarItemsMap[menuItemId],
    [
      sidebarConfig,
      userRoletype,
      sidebarOpen,
      messagesUnreadCount,
      visibleMenuItems,
      currentPath,
      predefinedReports,
    ],
  );

  return (
    <List sx={{ pt: 0, pb: 0 }} className="sidebarMenuList">
      {Object.keys(MENU_ITEM_IDS)?.map((menuItem) =>
        getSidebarComponent(menuItem),
      )}
    </List>
  );
};

export default CustomMenu;
