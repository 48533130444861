// React
import React, { useState, useEffect, useMemo } from 'react';

import { Divider } from '@mui/material';

// Custom components
import Header from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/Header';
import FilterRow from './FilterRow';
// import ItemFilterRow from './FilterRow/ItemFilterRow';
import ItemFilterRow from 'components/SubmissionsFilters/ItemFilterRow';

import TemplateFilterDropdown from './FilterRow/FilterDropdowns/TemplateFilter/TemplateFilter';

// Icons
import { AddRounded } from '@mui/icons-material';

// Styled
import {
  Wrapper,
  FilterRowsWrapper,
  AddFilterButton,
  BottomRowWrapper,
} from './styled';
import { HeaderText } from './Header/styled';
import { RowWrapper, WhereText } from './FilterRow/styled';

// Utils
import { cloneDeep, isEqual } from 'lodash';
import useDropdownData from 'utils/CustomHooks/useDropdownData';

export type FilterType = {
  filterName:
    | 'status'
    | 'startDate'
    | 'dueDate'
    | 'template'
    | 'assignee'
    | 'category'
    | 'location'
    | 'asset'
    | 'completedAt'
    | 'archived'
    | 'taskTypes'
    | 'isOverDue'
    | 'taskTemplates'
    | 'projects'
    | 'schedules'
    | 'priority'
    | 'createdBy'
    | '';
  comparator: 'is' | 'isNot' | '';
  value: any[];
  conditional: 'and' | 'or' | '';
};
const filterTypes = [
  'header',
  'signature',
  'takePhoto',
  'photoAnnotate',
  'instruction',
  'geoStamp',
  'dateTime',
];

interface AdvancedFilterBuilderPropTypes {
  renderCloseIcon?: boolean;
  hideFilters?: string;
  filters: any[];
  handleClose?: () => void;
  handleSetFiltersCb: (filters: FilterType[]) => void;
  handleClearFilters?: () => void | undefined;
  dropdownWidth?: DropdownWidth | null;
  templateItems?: any[];
  selectedFilters?: any;
  additonalFilters?: any;
  showResponseFilter?: boolean;
  addAnotherLabel?: string;
  hideHeader?: boolean;
  hideWhere?: boolean;
  handleSetItemFiltersCb?: (
    filters: ItemFilterType[],
    empty?: boolean,
    key?: string,
  ) => void;
}

interface DropdownWidth {
  mainDropdownWidth?: string | null;
  isDropdownWidth?: string | null;
}

const defaultFilterState: FilterType[] = [
  {
    filterName: '',
    comparator: 'is',
    value: [],
    conditional: 'and',
  },
];
export type ItemFilterType = {
  item?: string;
  comparator: string;
  value: any[];
  filterName?: string;
};

const defaultItemFilterState: ItemFilterType[] = [
  {
    item: '',
    comparator: '',
    value: [],
  },
];

const AdvancedFilterBuilder = ({
  renderCloseIcon = true,
  filters,
  handleClose,
  handleSetFiltersCb,
  hideFilters = '',
  dropdownWidth = null,
  handleClearFilters,
  hideHeader = false,
  addAnotherLabel = '',
  templateItems = [],
  additonalFilters = {},
  selectedFilters,
  handleSetItemFiltersCb,
  showResponseFilter = false,
  hideWhere = false,
}: AdvancedFilterBuilderPropTypes) => {
  const { selectedTaskTemplates } = useDropdownData();

  const [filterData, setFilterData] = useState<FilterType[]>(
    filters?.length ? filters : defaultFilterState,
  );
  const [itemFilterData, setItemFilterData] = useState<any[]>(
    additonalFilters && additonalFilters?.submissionFilters?.filters
      ? additonalFilters?.submissionFilters?.filters
      : defaultItemFilterState,
  );
  useEffect(() => {
    setItemFilterData(
      additonalFilters && additonalFilters?.submissionFilters?.filters
        ? additonalFilters?.submissionFilters?.filters
        : defaultItemFilterState,
    );
  }, [additonalFilters, templateItems]);

  const handleDeleteRow = (idx: number) => {
    if (
      filterData.length === 1 &&
      filterData[0].filterName &&
      filterData[0].value.length
    ) {
      handleClearFilters?.();
    }
    if (filterData.length === 1) {
      setFilterData(defaultFilterState);
      handleSetFiltersCb(defaultFilterState);
      return;
    }
    const filters = [...filterData];
    filters.splice(idx, 1);

    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  const setConditionForFilters = (filters: FilterType[]) => {
    const condition =
      filters.length >= 2 ? filters[1].conditional : filters[0].conditional;
    return filters.map((f) => ({ ...f, conditional: condition }));
  };

  const handleSetFilters = (
    idx: number,
    newFilters: {
      filterName?:
        | 'status'
        | 'startDate'
        | 'dueDate'
        | 'template'
        | 'assignee'
        | 'category'
        | 'location'
        | 'asset'
        | 'completedAt'
        | 'archived'
        | 'taskTypes'
        | 'isOverDue'
        | 'taskTemplates'
        | 'projects'
        | 'schedules'
        | 'priority'
        | 'createdBy'
        | '';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => {
    const filters = [...filterData];
    filters[idx] = { ...filters[idx], ...newFilters };
    const newFilterState = setConditionForFilters(filters);
    setFilterData(newFilterState);
    handleSetFiltersCb(newFilterState);
  };

  const handleAddFilter = () => {
    const filters = [
      ...filterData,
      {
        ...defaultFilterState[0],
        conditional: filterData[filterData.length - 1].conditional,
      },
    ];
    setFilterData(filters);
    handleSetFiltersCb(filters);
  };
  const transformedTemplateItems = useMemo(
    () =>
      templateItems?.filter(
        (item) => !filterTypes?.includes(item.type) && item?.description,
      ) ?? [],
    [templateItems],
  );
  const templateItemsHash = useMemo(
    () =>
      transformedTemplateItems?.reduce(
        (prev, curr: any) => ({ ...prev, [curr.id]: curr }),
        {},
      ),
    [transformedTemplateItems],
  );

  const handleAddItemFilter = () => {
    const filters = [...itemFilterData, defaultItemFilterState[0]];
    setItemFilterData(filters);
    handleSetItemFiltersCb?.(filters, false, 'submissionFilters');
  };

  const handleDeleteItemRow = (idx: number) => {
    if (itemFilterData.length === 1) {
      setItemFilterData([]);
      handleSetItemFiltersCb?.([], false, 'submissionFilters');
      return;
    }
    const filters = cloneDeep(itemFilterData);
    filters.splice(idx, 1);

    setItemFilterData(filters);
    handleSetItemFiltersCb?.(filters, false, 'submissionFilters');
  };
  const handleSetTemplateSourceItem = (idx: number, newFilters: any) => {
    const itemsFiltersClone = cloneDeep(itemFilterData);
    itemsFiltersClone[idx] = { ...itemsFiltersClone[idx], ...newFilters };
    setItemFilterData(itemsFiltersClone);
    handleSetItemFiltersCb?.(itemsFiltersClone, false, 'submissionFilters');
  };
  const getFilterValues = (data) => {
    return data?.map((item) => item.id);
  };
  const handleSetTemplateSource = (newFilters: any) => {
    const filterData = {
      comparator: 'is',
      conditional: 'and',
      filterName: 'template',
      value: getFilterValues(newFilters),
    };
    if (getFilterValues(newFilters).length) {
      handleSetItemFiltersCb?.([filterData], false, 'associatedLogFilters');
    } else {
      handleSetItemFiltersCb?.([filterData], true, 'associatedLogFilters');
    }
  };

  const isTaskTypeCorrective = useMemo(() => {
    return selectedFilters?.condition === 'AND'
      ? selectedFilters?.filters.filter(
          (widgetFilter) =>
            widgetFilter.filterName === 'taskTypes' &&
            widgetFilter.comparator === 'is' &&
            widgetFilter.value.length === 1 &&
            widgetFilter.value[0] === 'corrective',
        ).length === 1
      : selectedFilters?.condition === 'OR' &&
          selectedFilters?.filters.length === 1 &&
          selectedFilters?.filters[0].filterName === 'taskTypes' &&
          selectedFilters?.filters[0].comparator === 'is' &&
          selectedFilters?.filters[0].value.length === 1 &&
          selectedFilters?.filters[0].value[0] === 'corrective';
  }, [filters]);

  return (
    <Wrapper>
      {!hideHeader && (
        <Header renderCloseIcon={renderCloseIcon} handleClose={handleClose} />
      )}
      <FilterRowsWrapper>
        {filterData.map((f, idx) => (
          <FilterRow
            hideWhere={hideWhere}
            handleDeleteRow={handleDeleteRow}
            key={idx}
            index={idx}
            handleSetFilters={handleSetFilters}
            filter={filterData[idx]}
            hideFilters={hideFilters}
            dropdownWidth={dropdownWidth}
          />
        ))}
      </FilterRowsWrapper>
      <BottomRowWrapper>
        <AddFilterButton
          onClick={handleAddFilter}
          fullWidth={false}
          startIcon={<AddRounded />}
          sx={{
            ...(addAnotherLabel && {
              width: 'unset',
            }),
          }}
        >
          {addAnotherLabel || 'Add Filter'}
        </AddFilterButton>
      </BottomRowWrapper>

      {isTaskTypeCorrective && showResponseFilter && (
        <>
          <Divider style={{ margin: '10px 0px' }} />
          <HeaderText>Template Source</HeaderText>
          <RowWrapper>
            <WhereText>Where</WhereText>
            {/* <Box width={'152px'}>Template Source</Box> */}
            <TemplateFilterDropdown
              onChangeCallback={(option) => handleSetTemplateSource(option)}
              selected={selectedTaskTemplates(
                additonalFilters?.associatedLogFilters?.filters[0].value || [],
              )}
            />
            {/* <Box width={'100px'}>Is</Box> */}
          </RowWrapper>
          {transformedTemplateItems?.length > 0 && (
            <>
              <Divider style={{ margin: '10px 0px' }} />
              <HeaderText>Template Item</HeaderText>
              <RowWrapper>
                {/* <WhereText>Item</WhereText> */}
                <FilterRowsWrapper>
                  {itemFilterData?.map((f, idx) => (
                    <ItemFilterRow
                      handleDeleteRow={handleDeleteItemRow}
                      key={Math.random()}
                      index={idx}
                      handleSetFilters={handleSetTemplateSourceItem}
                      filter={f}
                      templateItems={transformedTemplateItems}
                      templateItemsHash={templateItemsHash}
                    />
                  ))}
                </FilterRowsWrapper>
              </RowWrapper>
              <BottomRowWrapper>
                <AddFilterButton
                  onClick={handleAddItemFilter}
                  fullWidth={false}
                  startIcon={<AddRounded />}
                >
                  Add Filter
                </AddFilterButton>
              </BottomRowWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const arePropsEqual = (
  prevProps: AdvancedFilterBuilderPropTypes,
  nextProps: AdvancedFilterBuilderPropTypes,
) =>
  isEqual(
    {
      filters: prevProps.filters,
      templateItems: prevProps.templateItems,
      additonalFilters: prevProps.additonalFilters,
    },
    {
      filters: nextProps.filters,
      templateItems: nextProps.templateItems,
      additonalFilters: nextProps.additonalFilters,
    },
  );

export default React.memo(AdvancedFilterBuilder, arePropsEqual);
