import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';

import { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import LongArrowIcon from 'components/Icons/longArrowIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import CustomButton from 'components/Button/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import useRoutingRules from 'utils/CustomHooks/useRoutingRules';
import Trigger from './trigger';
import Actions from './actions';
import BuilderHeader from './header';

import {
  LoaderCnt,
  MainContainer,
  SaveChangesPrompt,
  StepsContainer,
} from './style';

const DUE_DATE_INITIAL_DATA = {
  unit: 'day',
  field: 'createdAt',
  value: '2',
};

function useBeforeUnload(when) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
}

const RoutingRulesBuilder = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.ROUTING_RULES_BUILDER_DIALOG];
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const {
    createRoutingRule,
    editRoutingRule,
    getRoutingRule,
    transformFilters,
    transformWatchers,
    reverseTransformFilters,
    reverseTransformWatchers,
  } = useRoutingRules();
  const [isDirtyChanges, setIsDirtyChanges] = useState(false);
  const [isWatchersEnabled, setIsWatchersEnabled] = useState(false);
  const [isDueDateEnabled, setIsDueDateEnabled] = useState(false);

  const [initialData, setInitialData] = useState<any>({
    name: '',
    description: '',
    status: true,
    triggers: [],
    actions: {
      assignees: [],
      assigneesRoles: null,
    },
    watchers: [],
    dueDate: null,
  });

  const [updatedData, setUpdatedData] = useState<any>(initialData);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const lookupData = useSelector(selectors.getLookupData);

  useBeforeUnload(isDirtyChanges);

  const { open } = modalState || {};

  useEffect(() => {
    if (open) {
      setUpdatedData(initialData);
    }
  }, [initialData]);

  const getRoutingRulesDetails = async (ruleId) => {
    try {
      const response = await getRoutingRule({}, ruleId);
      if (response) {
        const transformedResponse = cloneDeep(response);
        transformedResponse.triggers = reverseTransformFilters(
          transformedResponse?.triggers,
        );

        transformedResponse.watchers = reverseTransformWatchers(
          transformedResponse?.watchers,
        );

        if (transformedResponse?.watchers?.length > 0) {
          setIsWatchersEnabled(true);
        }

        if (transformedResponse?.dueDate) {
          setIsDueDateEnabled(true);
        }

        setUpdatedData(transformedResponse);
        setInitialData(transformedResponse);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('error:', error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const ruleId = params.get('ruleId');

    if (ruleId) {
      getRoutingRulesDetails(ruleId);
    } else {
      setIsLoading(false);
    }
  }, [location?.search]);

  const updateTitle = (name) => {
    if (name?.length < 50) {
      setUpdatedData({
        ...updatedData,
        name,
      });
    }
  };

  const updateWatchersCallback = (watchers) => {
    setUpdatedData({
      ...updatedData,
      watchers,
    });
  };

  const handleClose = () => {
    dispatch(closeDialog(DIALOGS.ROUTING_RULES_BUILDER_DIALOG));
  };

  const verifyUnSavedChanges = () => {
    if (isDirtyChanges && updatedData?.name) {
      setShowExitPrompt(true);
    } else {
      redirectToRoutingRules();
    }
  };

  const redirectToRoutingRules = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.ROUTING_RULES_BUILDER_DIALOG,
      }),
    );
    navigateWithWorkspaceUrl('/routing-rules');
  };

  const cancelHandler = () => {
    verifyUnSavedChanges();
  };

  const isDirtyChangesForBtn = useMemo(() => {
    const changes = !isEqual(initialData, updatedData);
    if (changes && !isDirtyChanges) {
      setIsDirtyChanges(true);
    }

    if (!changes && isDirtyChanges) {
      setIsDirtyChanges(false);
    }
    return changes;
  }, [initialData, updatedData]);

  const switchWatchersCallback = (isEnabled) => {
    setIsWatchersEnabled(isEnabled);

    if (!isEnabled) {
      setUpdatedData({
        ...updatedData,
        watchers: [],
      });
    }
  };

  const switchDueDateCallback = (isEnabled) => {
    setIsDueDateEnabled(isEnabled);

    setUpdatedData({
      ...updatedData,
      dueDate: !isEnabled ? null : DUE_DATE_INITIAL_DATA,
    });
  };

  const createHandler = async () => {
    try {
      setIsSaving(true);
      const clonedData = cloneDeep(updatedData);
      clonedData.triggers = transformFilters(clonedData?.triggers);
      clonedData.watchers = transformWatchers(clonedData?.watchers, lookupData);

      delete clonedData.actions.assigneesRoles;

      const data = await createRoutingRule(clonedData);
      setIsSaving(false);

      if (data) {
        setUpdatedData({
          ...updatedData,
          id: data?.id,
        });

        setInitialData({
          ...updatedData,
          id: data?.id,
        });

        const params = new URLSearchParams(location.search);
        params.set('ruleId', data?.id);

        navigate(`${location.pathname}?${params.toString()}`);
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const editHandler = async (exit = false) => {
    setIsSaving(true);
    const clonedData = cloneDeep(updatedData);
    clonedData.triggers = transformFilters(clonedData?.triggers);
    clonedData.watchers = transformWatchers(clonedData?.watchers, lookupData);
    const data = await editRoutingRule(clonedData);
    setIsSaving(false);

    if (data) {
      setInitialData({
        ...updatedData,
        id: data?.id,
      });
    }

    if (exit) {
      redirectToRoutingRules();
    }
  };

  return (
    <>
      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            maxWidth: 'unset',
            margin: 0,
            maxHeight: 'unset',
          },
        }}
        fullWidth
        open={open}
        handleClose={handleClose}
      >
        <MainContainer>
          {isLoading && (
            <LoaderCnt>
              <CircularProgress />
            </LoaderCnt>
          )}

          {!isLoading && (
            <>
              <BuilderHeader
                cancelHandler={cancelHandler}
                data={updatedData}
                updateTitle={updateTitle}
                createHandler={createHandler}
                editHandler={editHandler}
                isDirtyChanges={isDirtyChangesForBtn}
                isSaving={isSaving}
              />
              <StepsContainer>
                <Box className="innerBox">
                  <Trigger data={updatedData} setData={setUpdatedData} />
                  <LongArrowIcon className="arrowIcon" />
                  <Actions
                    data={updatedData}
                    setData={setUpdatedData}
                    updateWatchersCallback={updateWatchersCallback}
                    switchWatchersCallback={switchWatchersCallback}
                    switchDueDateCallback={switchDueDateCallback}
                    isWatchersEnabled={isWatchersEnabled}
                    isDueDateEnabled={isDueDateEnabled}
                  />
                </Box>
              </StepsContainer>
            </>
          )}
        </MainContainer>
      </CustomModal>

      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '24px',
            width: '600px',
            height: 'auto',
            padding: '20px',
          },
        }}
        open={showExitPrompt}
        handleClose={() => {
          setShowExitPrompt(false);
        }}
      >
        <SaveChangesPrompt>
          <Box className="header">
            <Typography className="title">Save Changes?</Typography>
            <CloseIcon
              onClick={() => setShowExitPrompt(false)}
              sx={{ fontSize: '22px', cursor: 'pointer', color: '#1C1B1F' }}
            />
          </Box>
          <Typography className="description">
            You have unsaved changes. Would you like to discard or save them?
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomButton
              variant="text"
              onClick={() => {
                setShowExitPrompt(false);
              }}
            >
              Cancel
            </CustomButton>
            <Stack direction="row" alignItems="center" gap="10px">
              <CustomButton
                variant="outlined"
                onClick={() => {
                  redirectToRoutingRules();
                }}
              >
                Discard
              </CustomButton>
              <CustomButton
                variant="contained"
                buttonLoader={isSaving}
                onClick={() =>
                  !updatedData?.id ? createHandler() : editHandler(true)
                }
              >
                Save Changes
              </CustomButton>
            </Stack>
          </Stack>
        </SaveChangesPrompt>
      </CustomModal>
    </>
  );
};

export default RoutingRulesBuilder;
