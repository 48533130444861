import { Box, Typography } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { useState, useCallback, useEffect } from 'react';
import { convertAutomationIntoCondition, DEFAULT_CONDITION } from '../utils';
import { v4 as uuid } from 'uuid';
import ConnectorIcon from 'components/Icons/connectorIcon';
import CriteriaContent from '../criteria/criteriaContent';
import { AddFilterButton, MainContainer } from './style';

const ENTITY = 'checklistItem';

// Keep this helper function outside
const getParentChainRecursive = (
  itemId: string,
  checklistItemsHash: any,
  chain: any[] = [],
): string[] => {
  if (!itemId || !checklistItemsHash?.[itemId]) {
    return chain;
  }
  chain?.unshift({
    id: itemId,
    relatedAutomationId:
      checklistItemsHash?.[chain?.[0]?.id]?.RelatedAutomationId,
  });
  const currentItem = checklistItemsHash?.[itemId];

  if (currentItem?.ParentId) {
    return getParentChainRecursive(
      currentItem?.ParentId,
      checklistItemsHash,
      chain,
    );
  }
  return chain;
};

const Trigger = (props) => {
  const { data, setData, checklistItemsHash, orderHash, automations } = props;
  const [conditions, setConditions] = useState<any>([DEFAULT_CONDITION]);

  const addCondition = useCallback(() => {
    const updatedConditions = [
      ...(conditions ?? []),
      { ...DEFAULT_CONDITION, id: uuid() },
    ];
    setConditions(updatedConditions);
    setData({
      ...data,
      conditions: updatedConditions,
    });
  }, [data, conditions]);

  useEffect(() => {
    setConditions(
      data?.conditions?.length > 0 ? data?.conditions : [DEFAULT_CONDITION],
    );
  }, [data]);

  const onChangeHandler = (conditionData) => {
    setConditions((prevConditions) => {
      const updatedConditions = prevConditions?.map((condition) => {
        if (condition?.id === conditionData?.id) {
          return {
            ...condition,
            ...(conditionData ?? {}),
            ...(conditionData?.contextId && {
              parentId:
                checklistItemsHash?.[conditionData?.contextId]?.ParentId,
              relatedAutomationId:
                checklistItemsHash?.[conditionData?.contextId]
                  ?.RelatedAutomationId,
            }),
          };
        }
        return condition;
      });

      setData((prevData) => ({
        ...prevData,
        conditions: updatedConditions,
      }));

      return updatedConditions;
    });
  };

  const onOperatorChangeHandler = useCallback(
    (operator) => {
      setData((prevData) => ({
        ...prevData,
        operator,
      }));
    },
    [setData, data],
  );

  const deleteHandler = useCallback(
    (id) => {
      let updated = conditions?.filter((c) => c?.id !== id);

      if (updated?.length === 0) {
        updated = [DEFAULT_CONDITION];
      }
      setData((prevData) => ({
        ...prevData,
        conditions: updated,
      }));

      setConditions(updated);
    },
    [data, setData],
  );

  // Memoize the VisibilityChain rendering logic
  const renderCriteriaChain = (
    id: string,
    condition: any,
    index: number,
    operator: 'and' | 'or' | '',
  ) => {
    if (condition?.parentId) {
      const parentChain = getParentChainRecursive(
        condition?.contextId,
        checklistItemsHash,
        [],
      );

      return (
        <div
          className="visibility-chain"
          key={`${condition?.contextId}-${index}`}
        >
          {[...(parentChain ?? [])]?.map((item: any, chainIndex) => {
            const itemId = item?.id;
            const relatedAutomationId = item?.relatedAutomationId;

            const automation = automations?.[itemId]?.find(
              (item) => item?.id === relatedAutomationId,
            );

            return (
              <div
                key={`${itemId}-${chainIndex}`}
                className="chain-item"
                style={{
                  ...(index === 0 && {
                    paddingLeft: chainIndex === 0 ? 0 : `${chainIndex * 30}px`,
                  }),
                  ...(index > 0 && {
                    paddingLeft:
                      chainIndex === 0 ? 0 : `${chainIndex * 30 + 80}px`,
                  }),
                  marginBottom: '15px',
                  position: 'relative',
                }}
              >
                {chainIndex > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      fontSize: '28px',
                      color: 'gray',
                      top: '-10px',
                      ...(index === 0 &&
                        chainIndex > 0 && {
                          left: `${(chainIndex - 1) * 30 + 12}px`,
                        }),

                      ...(index > 0 &&
                        chainIndex > 0 && {
                          left: `${(chainIndex - 1) * 30 + 92}px`,
                        }),
                      '& svg': {
                        height: '38px',
                        marginTop: '-18px',
                      },
                    }}
                  >
                    <ConnectorIcon />
                  </Box>
                )}
                <CriteriaContent
                  id={id}
                  operator={operator}
                  index={index}
                  automations={
                    condition?.contextId === itemId
                      ? condition ?? {}
                      : convertAutomationIntoCondition(automation) ?? {}
                  }
                  checkListItems={checklistItemsHash}
                  entity={ENTITY}
                  ordreHash={orderHash}
                  onChange={onChangeHandler}
                  onOperatorChange={onOperatorChangeHandler}
                  disable={condition?.contextId !== itemId}
                  deleteHandler={
                    index > 0 && condition?.contextId === itemId
                      ? deleteHandler
                      : null
                  }
                  followUplevel={chainIndex}
                  showAddOr={chainIndex === 0 && index > 0}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <CriteriaContent
        id={id}
        operator={operator}
        index={index}
        automations={condition}
        checkListItems={checklistItemsHash}
        entity={ENTITY}
        ordreHash={orderHash}
        onChange={onChangeHandler}
        onOperatorChange={onOperatorChangeHandler}
        deleteHandler={index > 0 ? deleteHandler : null}
        showAddOr={index > 0}
      />
    );
  };

  return (
    <MainContainer>
      <Typography className="title">Trigger</Typography>
      <Box className="triggerContent">
        {[...conditions]?.map((condition, index) => (
          <Box
            sx={{
              marginBottom: conditions?.length === index + 1 ? '0px' : '15px',
            }}
          >
            {renderCriteriaChain(
              condition?.id,
              condition,
              index,
              data?.operator,
            )}
          </Box>
        ))}
        <AddFilterButton
          onClick={addCondition}
          fullWidth={false}
          startIcon={<AddRounded />}
        >
          Add another trigger
        </AddFilterButton>
      </Box>
    </MainContainer>
  );
};

export default Trigger;
