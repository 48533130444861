import { createSvgIcon } from '@mui/material';

const FlashIcon = (props) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.4982 9.58879H11.1007V1.67223C11.1007 -0.174962 10.1001 -0.5488 8.87962 0.836598L8 1.83716L0.556237 10.3035C-0.466318 11.458 -0.0375042 12.4036 1.50183 12.4036H4.89935V20.3201C4.89935 22.1673 5.89991 22.5412 7.12038 21.1558L8 20.1552L15.4438 11.6889C16.4663 10.5344 16.0375 9.58879 14.4982 9.58879Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'FlashIcon',
  );

  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M14.4982 9.58879H11.1007V1.67223C11.1007 -0.174962 10.1001 -0.5488 8.87962 0.836598L8 1.83716L0.556237 10.3035C-0.466318 11.458 -0.0375042 12.4036 1.50183 12.4036H4.89935V20.3201C4.89935 22.1673 5.89991 22.5412 7.12038 21.1558L8 20.1552L15.4438 11.6889C16.4663 10.5344 16.0375 9.58879 14.4982 9.58879Z"
        fill="#4E48FA"
      />
    </>,
    'FlashIcon',
  );

  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  ) : (
    <IconCmp
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default FlashIcon;
