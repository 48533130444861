import {
  getActionText,
  getAnswerText,
  getAnswerView,
  getConditionText,
} from 'components/common/jsxrender/checklistV2/builder/conditionalVisibility/conditionsPreview';
import _, { groupBy, map } from 'lodash';
import moment from 'moment';
// NOTE: this is a temporary file here it should go to xenia utils

const operators = {
  eq: (a, b) => a == b, // had to do simple comparison since we are saving integer values as strings. Same for the next line
  not: (a, b) => a != b,
  lt: (a, b) => a < b,
  lte: (a, b) => a <= b,
  gt: (a, b) => a > b,
  gte: (a, b) => a >= b,
  btw: (a, b, c) => a >= b && a <= c,
  nbtw: (a, b, c) => a < b || a > c,
};

const binaryOperators = ['eq', 'not', 'lt', 'lte', 'gt', 'gte'];
const ternaryOperators = ['btw', 'nbtw'];

const takePhotoOperators = {
  eq: (a) => a?.length,
  not: (a) => !a?.length,
};

const dropdownOperators = {
  eq: (a, b) => a?.includes(b),
  not: (a, b) => !a?.includes(b),
};

const textOperators = {
  eq: (a, b) => a?.toLowerCase() === b?.toLowerCase(),
  not: (a, b) => a?.toLowerCase() !== b?.toLowerCase(),
};

const dateTimeOperators = {
  eq: (a, b) => moment(a).isSame(b),
  not: (a, b) => !moment(a).isSame(b),
  lt: (a, b) => moment(a).isBefore(b),
  lte: (a, b) => moment(a).isSameOrBefore(b),
  gt: (a, b) => moment(a).isAfter(b),
  gte: (a, b) => moment(a).isSameOrAfter(b),
};

export const validateItemCondition = ({
  answer,
  itemType,
  conditions,
  contextType = '',
}) => {
  let actionsToTrigger: any = null;
  _.each(conditions, (item) => {
    const condition: any = item.condition;
    switch (itemType) {
      case 'takePhoto':
        if (takePhotoOperators[condition.logic](answer)) {
          actionsToTrigger = item.action;
        }
        break;
      case 'procedure':
        if (operators[condition.logic](answer, Boolean(condition.value))) {
          actionsToTrigger = item.action;
        }
        break;
      case 'textField':
        if (textOperators[condition.logic](answer, condition.value)) {
          actionsToTrigger = item.action;
        }
        break;
      case 'number':
      case 'cost':
      case 'temperature':
        if (
          _.includes(binaryOperators, condition.logic) &&
          operators[condition.logic](Number(answer), Number(condition.value))
        ) {
          actionsToTrigger = item.action;
        } else if (
          _.includes(ternaryOperators, condition.logic) &&
          operators[condition.logic](
            Number(answer),
            Number(_.first(condition.value)),
            Number(_.last(condition.value)),
          )
        ) {
          actionsToTrigger = item.action;
        }
        break;
      case 'dateTime':
        if (dateTimeOperators[condition.logic](answer, condition.value)) {
          actionsToTrigger = item.action;
        }
        break;

      case 'dropdown':
        if (dropdownOperators[condition.logic](answer, condition.value)) {
          actionsToTrigger = item.action;
        }
        break;
      case 'location':
        if (Array.isArray(condition.value) || contextType === 'LocationGroup') {
          if (dropdownOperators[condition.logic](condition.value, answer)) {
            actionsToTrigger = item.action;
          }
        } else {
          if (operators[condition.logic](answer, condition.value)) {
            actionsToTrigger = item.action;
          }
        }
        break;
      default:
        if (operators[condition.logic](answer, condition.value)) {
          actionsToTrigger = item.action;
        }
        break;
    }
  });
  return actionsToTrigger;
};

export const checkVisibility = (conditionVisibility, item, templateHashes) => {
  // basiccally its isHidden functionality
  if (
    conditionVisibility &&
    item?.ChecklistItemId &&
    conditionVisibility?.[item?.ChecklistItemId]
  ) {
    const thisCondition = conditionVisibility?.[item?.ChecklistItemId][0];
    const contextItem = templateHashes?.[thisCondition?.contextId];

    const defaultAction = thisCondition?.action === 'SHOW' ? true : false;

    // return false;
    if (!contextItem?.answers?.value) {
      return defaultAction;
    }
    const conditionData = {
      answer: contextItem?.answers?.value,
      itemType: contextItem?.type,
      conditions: [thisCondition],
    };
    const validation = validateItemCondition(conditionData);
    if (validation) {
      return validation !== 'SHOW' ? true : false;
    } else {
      return defaultAction;
    }
  }
  return false;
};

export const checkVisibilityWithText = (
  conditionVisibility,
  item,
  checklistItems,
  answerView = true,
  locationGroupHash = {},
) => {
  try {
    if (conditionVisibility && conditionVisibility[item.ChecklistItemId]) {
      let thisCondition = conditionVisibility?.[item.ChecklistItemId][0];
      const contextItem = checklistItems?.[thisCondition.contextId];
      const contextType = thisCondition?.context;
      const answer =
        contextItem?.answers?.value ||
        item?.checklistAnswers?.[contextItem?.description]?.answers?.value;
      const conditionInfo = {
        contextOrder: contextItem?.customOrder,
        action: getActionText(thisCondition?.action),
        contextTitle: contextItem?.description,
        answer: answerView
          ? getAnswerView(contextItem, thisCondition?.condition)
          : getAnswerText(contextItem, thisCondition?.condition),
        logic: getConditionText(
          contextType === 'LocationGroup'
            ? 'isin'
            : thisCondition?.condition?.logic,
        ),
      };

      const defaultAction =
        thisCondition.action === 'SHOW' ? conditionInfo : false;
      if (thisCondition.context === 'isEmpty') return defaultAction;
      if (!contextItem && !answer) {
        return defaultAction;
      }
      if (contextType === 'LocationGroup') {
        const locationsOfConditionalGroup =
          locationGroupHash[thisCondition?.condition?.value];
        thisCondition = {
          ...thisCondition,
          condition: {
            ...thisCondition.condition,
            value: locationsOfConditionalGroup,
          },
        }; // update location group with there locations here
      }
      const conditionData = {
        answer: answer,
        itemType: contextItem.type,
        conditions: [thisCondition],
        contextType: contextType,
      };

      const validation = validateItemCondition(conditionData);

      if (validation) {
        return validation !== 'SHOW' ? conditionInfo : false;
      } else {
        return defaultAction;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

// this function will be responsible for generating the hash of the checklist items with flatten child items
export const generateTemplateHashes = (
  templates,
  selector = 'ChecklistItemId',
  parentOrder = null,
  result = {},
) => {
  if (templates) {
    let orderCounter = 0; // Initialize a counter for sequential numbering
    templates.forEach((item) => {
      const newItem = { ...item };
      if (item.type !== 'header') {
        orderCounter++;
        if (parentOrder !== null) {
          newItem.customOrder = parseFloat(`${parentOrder}.${orderCounter}`);
        } else {
          newItem.customOrder = parseFloat(`${orderCounter}`);
        }
        result[item[selector]] = newItem;
        if (item.child) {
          item.child.forEach((childItem) => {
            generateTemplateHashes(
              childItem,
              selector,
              newItem?.customOrder,
              result,
            );
          });
        }
      }
    });
  }
  return result;
};
