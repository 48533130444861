import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import EmailInput from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/EmailTriggers/EmailInput';
import AddIcon from 'components/Icons/addIcon';
import EmailWithEdit from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/EmailTriggers/EmailWithEdit';
import AssignToUsersAndTeams from 'components/TaskInputs/assignTo/usersAndTeams';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import {
  AddEmailButton,
  AdditionalWrapper,
  NotificationsContainer,
} from './style';
import IosSwitch from 'components/IosSwitch';

const SHOW_RECEIPIENTS_LIMIT = 4;

type EntityType = 'users' | 'teams' | 'roles';

interface NotificationData {
  users?: string[];
  teams?: string[];
  roles?: string[];
}

interface NotificationState {
  notification?: NotificationData;
}

const Notifications = (props) => {
  const { data, setData } = props;
  const { notification } = data;
  const { emails, enable } = notification || {};

  const lookupData = useSelector(selectors.getLookupData);
  const {
    users: usersHash,
    teams: teamsHash,
    roles: rolesHash,
  } = lookupData?.data || {};

  const [addEmail, setAddEmail] = useState(false);

  const receipients = useMemo(() => {
    const { users, teams, roles } = notification || {};
    return [...(users || []), ...(teams || []), ...(roles || [])];
  }, [notification]);

  const toggleNotification = () => {
    setData({
      ...data,
      notification: {
        ...data?.notification,
        enable: !enable,
        ...(enable && {
          users: [],
          teams: [],
          roles: [],
          emails: [],
        }),
      },
    });
  };

  const handleAddEmail = (newEmail) => {
    const existingEmail = notification?.emails?.find(
      (existingEmail) =>
        existingEmail?.toLowerCase() === newEmail?.toLowerCase(),
    );

    if (existingEmail) {
      return;
    }

    setData({
      ...data,
      notification: {
        ...data?.notification,
        emails: [...(data.notification.emails ?? []), newEmail],
      },
    });

    setAddEmail(false);
  };

  const editEmailTrigger = (emailData, emailId) => {
    const { email: updatedEmail } = emailData;

    const existingEmail = notification?.emails?.find(
      (existingEmail) =>
        existingEmail?.toLowerCase() === updatedEmail?.toLowerCase(),
    );

    if (existingEmail) {
      return;
    }

    setData({
      ...data,
      notification: {
        ...data?.notification,
        emails: emails?.map((recipient, index) =>
          index === emailId ? updatedEmail : recipient,
        ),
      },
    });
  };

  const deleteEmailTrigger = (emailId) => {
    const filteredEmails = emails?.filter((_, index) => index !== emailId);

    setData({
      ...data,
      notification: {
        ...data?.notification,
        emails: filteredEmails,
      },
    });
  };

  const handleSelectReceipients = (selected) => {
    const users: string[] = [];
    const teams: string[] = [];
    const roles: string[] = [];

    selected?.forEach((id) => {
      if (usersHash?.[id]) {
        users.push(id);
      } else if (teamsHash?.[id]) {
        teams.push(id);
      } else if (rolesHash?.[id]) {
        roles.push(id);
      }
    });

    setData({
      ...data,
      notification: { ...data?.notification, users, teams, roles },
    });
  };

  const handleRemoveUser = useCallback(
    (_, id: string) => {
      // Early return if id is not provided
      if (!id) {
        console.warn('No ID provided to handleRemoveUser');
        return;
      }

      // Check which hash contains the ID
      if (usersHash?.[id]) {
        setData((prevData) => updateNotificationEntity(prevData, 'users', id));
      } else if (teamsHash?.[id]) {
        setData((prevData) => updateNotificationEntity(prevData, 'teams', id));
      } else if (rolesHash?.[id]) {
        setData((prevData) => updateNotificationEntity(prevData, 'roles', id));
      } else {
        console.warn(`ID ${id} not found in any hash`);
      }
    },
    [usersHash, teamsHash, rolesHash],
  );

  const updateNotificationEntity = (
    prevData: NotificationState,
    entityType: EntityType,
    idToRemove: string,
  ): NotificationState => {
    const currentItems = prevData?.notification?.[entityType] || [];

    return {
      ...prevData,
      notification: {
        ...prevData?.notification,
        [entityType]: currentItems.filter((itemId) => itemId !== idToRemove),
      },
    };
  };
  return (
    <NotificationsContainer>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="column">
          <Typography className="title">Notification Recipients</Typography>
          <Typography className="subTitle">
            Select who should receive notifications when this workflow is
            triggered.
          </Typography>
        </Stack>

        <IosSwitch
          height={20}
          width={34}
          checked={enable}
          onChange={toggleNotification}
          thumbSize={16}
        />
      </Stack>

      {enable && (
        <>
          <Divider className="separator" />

          <AssignToUsersAndTeams
            handleChangeCallback={handleSelectReceipients}
            currentSelected={receipients}
            hideUsersAndTeamsLocations={true}
            hideRoles={false}
            hideAll={true}
            hideUsersAndTeamsIcon={true}
            hideActions={true}
            popperProps={{
              disablePortal: false,
              style: {
                zIndex: 1300,
                width: 590,
              },
            }}
            assigneesProps={{
              clearOption: true,
              removeHandler: (e, user) => handleRemoveUser(e, user),
              style: {
                background: '#EBEBEB',
                border: 'none',
                padding: 5,
              },
            }}
            placeholderUsersAndTeams="Select users, teams or roles"
            showSelectedLimit={SHOW_RECEIPIENTS_LIMIT}
          />

          <Typography className="additionalTitle">
            Additional Email Addresses
          </Typography>

          <AdditionalWrapper>
            {emails?.map((receipient, index) => (
              <EmailWithEdit
                editEmailTrigger={(emailData) =>
                  editEmailTrigger(emailData, index)
                }
                deleteEmailTrigger={() => deleteEmailTrigger(index)}
                data={{ email: receipient, id: index }}
                key={receipient}
              />
            ))}
          </AdditionalWrapper>

          {addEmail && (
            <Box className="emailInputWrapper">
              <EmailInput
                onClickAdd={handleAddEmail}
                onClickCancel={() => setAddEmail(false)}
              />
            </Box>
          )}

          <AddEmailButton
            onClick={() => setAddEmail(true)}
            variant="text"
            startIcon={<AddIcon />}
          >
            Add Email Address
          </AddEmailButton>
        </>
      )}
    </NotificationsContainer>
  );
};

export default Notifications;
