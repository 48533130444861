import React, { FC, useMemo } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { Box, Stack, Typography } from '@mui/material';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import BranchIcon from 'components/Icons/branchIcon';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import RolesLocationsAssigneeDropdown from 'components/Dropdown/RolesAssigneeDropdown/locationsList';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getLocationGroupsState } from 'store/selectors/locationGroups';
import { getAuthorizedLocations } from 'store/selectors/locations';

interface IProps {
  onChange?: (value: string) => void;
  answer?: any | null;
  compact?: boolean;
}

const View = (props) => {
  const { location, readonly, compact } = props;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  return (
    <Stack
      border="1px solid #BDBDBD"
      borderRadius="6px"
      p={compact ? '8px' : '12px'}
      width="100%"
    >
      {location ? (
        <Stack direction="row" spacing={1} alignItems="center">
          {location?.ParentId && <BranchIcon style={{ fontSize: '16px' }} />}
          <Typography>{location?.name}</Typography>
          {readonly && (
            <CustomButton
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                const path = location.ParentId
                  ? `/locations/${location?.ParentId}/sl/${location?.id}`
                  : `/locations/${location?.id}`;

                navigateWithWorkspaceUrl(path);
              }}
            >
              View Location
            </CustomButton>
          )}
        </Stack>
      ) : (
        <Typography color="#9E9E9E">Select Location Group</Typography>
      )}
    </Stack>
  );
};

const LocationGroupStep: FC<IProps> = (props) => {
  const { onChange, answer, compact } = props;
  const authorizedLocations = useSelector(getAuthorizedLocations);
  const groupList = useSelector(getLocationGroupsState);

  const selectedLocationGroup = useMemo(() => {
    return groupList.find((loc) => loc.id === answer?.value);
  }, [answer]);

  if (!onChange)
    return <View compact={compact} location={selectedLocationGroup} readonly />;

  return (
    <>
      <RolesLocationsAssigneeDropdown
        data={authorizedLocations}
        locationsGroupsData={groupList}
        selected={[]}
        selectedLocationsGroups={[answer?.value]}
        selectedParents={[]}
        showTabs={false}
        defaultTab={'Groups'}
        hideFooter={true}
        buttonRenderer={
          <View compact={compact} location={selectedLocationGroup} />
        }
        handleChangeCallbackLocationsGroups={onChange}
        popperProps={{
          disablePortal: false,
          style: {
            zIndex: 1300,
            width: 415,
          },
        }}
      />
    </>
  );
};

export default LocationGroupStep;
