import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';

interface IProps {
  lat: number;
  lng: number;
  onChange?(value: any): void;
  preview: boolean;
  disableClick?: boolean;
}

const containerStyle = {
  width: '100%',
  height: '250px',
};

const GoogleMaps = (props: IProps) => {
  const {
    lat = 40.71306965776129,
    lng = -74.0022139318488,
    preview,
    onChange,
    disableClick = false,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState<google.maps.Map>();
  const [loc, setLoc] = useState({
    lat,
    lng,
  });

  const onLoad = useCallback((m: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat,
      lng,
    });
    m.fitBounds(bounds);
    m.setZoom(14);

    setMap(m);
  }, []);

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(undefined);
  }, []);

  useEffect(() => {
    if (map) {
      setLoc({ lat, lng });
      map.panTo({
        lat,
        lng,
      });
    }
  }, [lat, lng, map]);

  useEffect(() => {
    setTimeout(() => {
      map?.setZoom(18);
    }, 1000);
  }, [map]);

  const handleClick = async (e: google.maps.MapMouseEvent) => {
    const latlng = e.latLng?.toJSON();
    if (latlng?.lat && latlng?.lng) {
      setLoc({ lat: latlng.lat, lng: latlng.lng });

      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng.lat},${latlng.lng}&key=${GOOGLE_MAPS_API_KEY}`,
      );

      const address = res?.data?.results[0]?.formatted_address;

      onChange?.({ address, lat: latlng.lat, lng: latlng.lng });
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={loc}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={18}
      options={{
        disableDefaultUI: preview,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoom: 18,
      }}
      onClick={disableClick ? undefined : handleClick}
    >
      {(lat || lng) && <Marker position={loc} />}
    </GoogleMap>
  ) : (
    <Stack height="250px" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Stack>
  );
};

export default GoogleMaps;
