import { Box, Button, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  gap: 10,
  padding: '10px 16px 22px 16px',
  borderRadius: 12,
  backgroundColor: 'rgba(225, 245, 254, 1)',
  border: '1px solid rgba(225, 245, 254, 1)',

  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
    margin: '10px 0px 26px 0px',
  },
  '& .subTitle': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(33, 33, 33, 1)',
  },

  '& .triggerContent': {
    marginTop: '-10px',
    backgroundColor: 'white',
    padding: '20px 16px 20px 16px',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 12,
    minHeight: 112,
    width: '100%',

    '& .contentDiv': {
      width: '100%',
      '& div': {
        width: '100%',
        '& button': {
          width: '100%',
        },
      },
    },
  },
});

export const AddFilterButton = styled(Button)(() => ({
  width: 160,
  justifyContent: 'start',
  fontSize: '13px',
  fontWeight: '600',
  lineHeight: '100%',
  padding: '4px 0px',
  marginTop: 8,
  '& > span': {
    marginRight: 4,
  },
  '& > span > svg': {
    fontSize: '18px !important',
  },
}));
