/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import heic2any from 'heic2any';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  LOCALSTORAGE_KEYS,
  numberDays,
  taskTimeRepeat,
  DATE_FORMAT,
  streamChatType,
  ConstantChannelTypeString,
  DEFAULT_TIMEZONE,
} from 'utils/globalVariable';
import { uniqBy, map, toLower } from 'lodash';
import { requestForToken } from 'components/firebase';
import {
  getStreamChannels,
  queryStreamUsers,
} from 'services/getStreamServices';
import { logoutApi } from 'api/authApi';
import DateTime from 'utils/DateTime';
import store from 'store';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';

// Icons
import AdobeAcrobatPdfIcon from 'components/Icons/adobeAcrobatPdfIcon';
import ImageFileIcon from 'components/Icons/imageFileIcon';
import DocxFileIcon from 'components/Icons/docxFileIcon';
import VideoFileIcon from 'components/Icons/videoFileIcon';
import { isMobileApp } from 'utils/constants';
import html2canvas from 'html2canvas';
import config from 'config/index';

/*******************
@Purpose : Store Data To local Storage
@Parameter : key, value
@Author : INIC
******************/
export const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
};
/*******************
  @Purpose : Get Data From local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const getItem = (key) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
};
/*******************
  @Purpose : Remove Data in local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const removeItem = (key) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};

/*******************
  @Purpose : Clear All Data in local Storage
  @Parameter : key
  @Author : INIC
  ******************/
export const clearLocalStorage = () => {
  if (typeof window !== 'undefined') {
    localStorage.clear();
  }
};

/*******************
  @Purpose : Clear All Data in local Storage for login related only
  @Parameter : key
  @Author : INIC
  ******************/
export const removeLoginStorage = () => {
  removeItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
  removeItem(LOCALSTORAGE_KEYS.EXPIRES_IN);
  removeItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID);
  removeItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN);
};
/*******************
  @Purpose : Set after Login localstorage
  @Parameter : key
  @Author : INIC
  ******************/
export const SetAfterLoginStorage = (response) => {
  setItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN, response?.data?.extra_meta.token);
  setItem(
    LOCALSTORAGE_KEYS.EXPIRES_IN,
    new Date(response.data.data.tokenExpiryTime).valueOf(),
  );
  setItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID, response.data.data.id);
};

/*******************
@Purpose : Username Validation
@Parameter : name
@Author : INIC
******************/
export const validateName = (name: string) => {
  if (name.trim() === '') {
    return false;
  }
  const re = /^[a-zA-Z0-9 ]{0,255}$/;
  return re.test(String(name));
};

export const getSessionId = async () => {
  if (!window.Verisoul) {
    return '';
  }
  try {
    const { session_id } = await window.Verisoul.session();
    return session_id;
  } catch (error) {
    return '';
  }
};
/*******************
@Purpose : Username Validation
@Parameter : name
@Author : INIC
******************/
export const validateEmail = (email: string) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/;
  return re.test(String(email));
};

/*******************
@Purpose : Username Validation
@Parameter : name
@Author : INIC
******************/
export const validateMobileNumber = (phone: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  if (phone.split('0').length - 1 === phone.length) {
    return false;
  }
  return re.test(String(phone));
};

/*******************
@Purpose : Used for show message notification
@Parameter : text, type, autoClose, position
@Author : INIC
******************/
export const showMessageNotification = (
  text,
  type = 'success',
  autoClose = 1500,
  position = 'top-center',
  toastClassName = '',
  icon: any = '',
) => {
  toast[type](text, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    className: toastClassName,
    ...(icon && {
      icon,
    }),
  });
};

const generateText = (paywallUsageId) => {
  const featureName = paywallUsageId;
  const noAccessPaywallTitleText = `You've exceeded your free usage of ${toLower(
    featureName,
  )}`;
  const noAccessPaywallDescriptionText = `${
    store.getState().billing?.billingInfo?.Product?.Paywall?.usageLimit &&
    store.getState().billing?.billingInfo?.Product?.Paywall?.usageLimit[
      paywallUsageId
    ]
  } ${toLower(featureName)} can be created on your current plan.
  Upgrade Now to create unlimited ${toLower(featureName)}.`;
  return {
    title: noAccessPaywallTitleText,
    description: noAccessPaywallDescriptionText,
  };
};

export const openFeatureLimitPopup = (error) => {
  const dialogId = DIALOGS.UPSELL_MODAL;
  store.dispatch(
    actions.setDialog({
      dialogId,
      open: true,
      data: {
        isEscapable: true,
        primaryButtonText: 'Upgrade Now',
        secondaryButtonText: 'Talk to sales',
        ...generateText(error?.response?.data?.error?.meta?.paywall),
        primaryButtonClickCB: () => {
          store.dispatch(
            actions.setDialog({
              dialogId: DIALOGS.BILLING.PLANS_MODAL,
              open: true,
            }),
          );
        },
        secondaryButtonClickCB: () => {
          window.open(
            `https://meetings.hubspot.com/kumeil`,
            '_blank',
            'noopener',
          );
        },
        buttonDisplayDirectionCol: true,
      },
    }),
  );
};

/*******************
@Purpose : Used for for call all type of api
@Parameter :
@Author : INIC
******************/
export const callApi = async (
  url,
  data,
  method,
  isAuthorized = false,
  isForm = false,
  _token,
  SuccessMessage,
  mszRequired = true,
  toastClassName = '',
  showError = true,
  customHeaders = {},
  signal = null,
): Promise<any> => {
  const hotelId = data?.hotelId || getHotelId() || null;
  let headers = {
    platform: 'webapp',
  };
  if (hotelId) {
    headers['workspace-id'] = hotelId;
  }
  let token = _token;
  if (!token) {
    token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
  }

  // if (config.REACT_APP_ENVIRONMENT === 'production') {
  try {
    const newSessionId = await getSessionId();
    if (newSessionId) {
      headers['x-verisoul-session-id'] = newSessionId;
    }
  } catch (error) {
    console.log('error', error);
  }

  headers['Authorization'] = token;
  // }
  headers = {
    ...headers,
    ...customHeaders,
  };

  if (isAuthorized && !token) {
    removeLoginStorage();
    if (typeof window !== 'undefined') {
      // setTimeout(() => {
      //   const currentLocation = window.location.pathname;
      //   if (PUBLIC_ROUTES.includes(currentLocation)) return;
      //   window.location.href = 'sign-in';
      // }, 2000);
    }
    return;
  }

  if (isForm) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  return new Promise((resolve, reject) => {
    const requestData = {
      method,
      url,
      headers,
      signal: signal ?? undefined,
    };

    requestData[`${method === 'get' ? 'params' : 'data'}`] = data;
    axios(requestData)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          if (mszRequired) {
            if (SuccessMessage === '') {
              showMessageNotification(
                response?.data?.message
                  ? response?.data?.message
                  : response?.data?.extra_meta?.message,
                'success',
                1500,
                'top-center',
                toastClassName,
              );
            } else {
              showMessageNotification(
                SuccessMessage,
                'success',
                1500,
                'top-center',
                toastClassName,
              );
            }
          }

          resolve(response);
        }
      })
      .catch((error) => {
        if (error?.code === 'ERR_CANCELED') {
          reject({
            cancelled: true,
          });
          return;
        }

        let errorMessage = '';
        errorMessage = error?.response?.data?.error
          ? typeof error?.response?.data?.error?.message == 'string'
            ? error?.response?.data?.error?.message
            : error?.response?.data?.error?.message?.name
          : error?.response?.data?.extra_meta?.message
          ? error?.response?.data?.extra_meta?.message
          : 'Something went wrong!';

        if (error?.response?.status === 401) {
          removeLoginStorage();
          logoutApi();
        }
        if (error?.response?.status === 426) {
          openFeatureLimitPopup(error);
          reject(null);
        } else if (showError) {
          if (
            errorMessage.includes('Invalid Email or Password') ||
            errorMessage.includes('Invalid email/username/phone')
          ) {
            showMessageNotification(errorMessage, 'error');
          } else {
            showMessageNotification(errorMessage, 'error');
          }
          reject(null);
        } else {
          resolve({ error: errorMessage, errorCode: error?.response?.status });
        }
      });
  });
};

/*******************
@Purpose : Used for validate dynamic beds
@Parameter :
@Author : INIC
******************/

export const getLoadingStatus = (
  status: boolean,
  replacewith: string,
  word: string,
): string => {
  if (status) {
    return replacewith;
  } else {
    return word;
  }
};

export const getTaskListLocation = (singletask) => {
  if (singletask?.Room !== null) {
    return singletask?.Room?.roomNumber;
  }
  if (singletask?.Floor !== null) {
    return singletask?.Floor?.title;
  }
  if (singletask?.Area !== null) {
    return singletask?.Area?.name;
  }
};

const getRecurringDayLabel = (value) => {
  return numberDays.filter((sinDay) => {
    if (sinDay.value === value) {
      return sinDay;
    }
  });
};

export const getEditDetailFormatData = (
  currentEditTaskParam: any,
  initialExistData,
) => {
  return {
    ...initialExistData,
    errorObj: {
      description: '',
      location: '',
      service: '',
      department: '',
      recurringTaskTitle: '',
    },
    description: currentEditTaskParam.description as string,
    LocationId:
      currentEditTaskParam?.LocationId || currentEditTaskParam?.Locations?.id,
    title: currentEditTaskParam.title as string,
    Team: currentEditTaskParam.Team as any,
    AssignId: currentEditTaskParam.AssignId as string,
    endDate: currentEditTaskParam.endDate as string,
    endTime: currentEditTaskParam.endTime as string | null,
    startDate: currentEditTaskParam.startDate as string,
    dueDate: currentEditTaskParam.dueDate as string,
    // startTime: currentEditTaskParam.startTime
    //   ? (moment(currentEditTaskParam.startTime).format(format) as string)
    //   : '',
    startTime: currentEditTaskParam.startTime as string | null,
    // dueTime: getDueTime(type, currentEditTaskParam, format),
    dueTime: currentEditTaskParam.dueTime as string | null,
    startedAt: currentEditTaskParam.startedAt as string | null,
    pauseTime: currentEditTaskParam.pauseTime as string | null,
    recurringTask: currentEditTaskParam.recurringTask as boolean,
    intervalWeek: currentEditTaskParam.intervalWeek as string[],
    recurringByEvery: currentEditTaskParam.recurringByEvery
      ? {
          value: currentEditTaskParam?.recurringByEvery,
          label: currentEditTaskParam?.recurringByEvery,
        }
      : null,
    recurringOnDay: currentEditTaskParam?.recurringOnDay
      ? getRecurringDayLabel(currentEditTaskParam?.recurringOnDay)
      : null,
    requestThisTask: currentEditTaskParam?.requestThisTask
      ? currentEditTaskParam?.requestThisTask
      : taskTimeRepeat[0],
    isGuestRequest: currentEditTaskParam.isGuestRequest as boolean,
    serviceTypeId: currentEditTaskParam?.ServiceType
      ? {
          value: currentEditTaskParam?.ServiceType?.id,
          label: currentEditTaskParam?.ServiceType?.type,
        }
      : { value: '', label: 'Type to search categories' },
    Area: currentEditTaskParam?.Area as any,
    Assginer: currentEditTaskParam?.Assginer as any,
    Assigned: currentEditTaskParam?.Assigned as any,
    Floor: currentEditTaskParam?.Floor as any,
    Room: currentEditTaskParam?.Room as any,
    additionalDescription: currentEditTaskParam?.additionalDescription as any,
    createdAt: currentEditTaskParam?.createdAt as any,
    id: currentEditTaskParam?.id as any,
    taskNumber: currentEditTaskParam?.taskNumber as any,
    taskStatus: currentEditTaskParam?.taskStatus as any,
    priority: currentEditTaskParam?.priority as string,
    attachment: currentEditTaskParam?.attachment as string[],
    checklistId: currentEditTaskParam?.Checklist?.id,
    checkListData: currentEditTaskParam?.Checklist,
    statusTransitionDate: currentEditTaskParam?.statusTransitionDate,
    timeSpent: currentEditTaskParam?.timeSpent,
    isPaused: currentEditTaskParam?.isPaused,
    ChecklistLog: currentEditTaskParam?.ChecklistLog,
  };
};

export const getFullName = (data) => {
  return `${data?.firstName} ${data?.lastName}`;
};

// Find the selected location from the list
export const getLocationById = (id, locationsList) => {
  const flatAllLocations = locationsList?.reduce((r, cv) => {
    r.push(cv);
    if (cv?.Sublocations?.length) {
      r.push(...cv.Sublocations);
    }
    return r;
  }, []);
  const selectedSubLocation = flatAllLocations?.find((a) => a.id == id);
  return selectedSubLocation;
};
// Find the selected location from the list
export const getAssetById = (id, assetsList) => {
  const flatAllAssets = assetsList?.reduce((r, cv) => {
    r.push(cv);
    if (cv?.SubAssets?.length) {
      r.push(...cv.SubAssets);
    }
    return r;
  }, []);
  const selectedSubAsset = flatAllAssets?.find((a) => a.id == id);
  return selectedSubAsset;
};
export const getAllChannelList = async (
  userProfileData,
  isArchived = false,
) => {
  if (!userProfileData?.id) {
    return;
  }
  const { id: userId, HotelId: hotelId, hotelUsersIds = [] } = userProfileData;
  let memberIds: string[] = hotelUsersIds;
  if (!memberIds?.length) {
    const existMember = await queryStreamUsers({
      userid: userId,
      hotelId,
    });
    memberIds = existMember?.users.map((u) => u.id) || [];
  }

  const { GROUP, DIRECT_MESSAGE, FEED_STREAM } = ConstantChannelTypeString;
  const channelTypes = [GROUP, DIRECT_MESSAGE, FEED_STREAM];
  const promises = map(channelTypes, async (channelType) => {
    const members =
      channelType === FEED_STREAM ? [userId, ...memberIds] : [userId];
    let channels: any = [];
    let offset: any = 0;
    const limit = 30;
    let _channels: any = [];
    do {
      _channels = await getStreamChannels({
        members,
        isArchived,
        hotelId,
        type: [streamChatType.MESSAGING, streamChatType.TEAM],
        channelType,
        offset,
        limit,
      });
      channels = [...channels, ..._channels];
      offset = channels.length;
    } while (_channels.length >= limit);
    return channels;
  });

  const [res1, res2, res3] = await Promise.all(promises);
  return uniqBy([...res1, ...res2, ...res3], (ch) => ch.id);
};
export const getHotelId = () => {
  const paths = window.location.pathname.split('/');
  let workspaceId: any = paths?.[paths?.indexOf('ws') + 1];
  if (!workspaceId) {
    const params = new URLSearchParams(window.location.search);
    workspaceId = params.get('hotelId');
    if (!workspaceId) {
      workspaceId = params.get('HotelId');
    }
  }
  return workspaceId;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getChannelUniqueId = (currentUserId, createType) => {
  return `${capitalizeFirstLetter(createType)}_${
    currentUserId?.substring(0, 4) as string
  }${Math.random().toString(36).slice(2)}_${Date.now()}`;
};

export const formatDate = (
  date,
  format = DATE_FORMAT,
  emptyFormat = '',
  tz = DEFAULT_TIMEZONE,
) => {
  const formatted = date && DateTime.fromUTCString(date);
  if (!date || !formatted.isValid()) return emptyFormat;
  return formatted.toTzString(tz, format);
};

export const getDeviceToken = async () => {
  try {
    const tokenNew: any = await requestForToken();
    return tokenNew as string;
  } catch (error) {
    console.error('requestForToken --> ', error);
    return '';
  }
};

export const getImageNameFromUrl = (imageurl) => {
  const index = imageurl.lastIndexOf('/') + 1;
  return imageurl.substr(index);
};

export const getUrlExtension = (url) => {
  const ext = url.split(/[#?]/)[0].split('.').pop().trim();
  return ext?.toLowerCase();
};

export const getTimeString = (
  days,
  hours,
  minutes,
  seconds,
  timerFormat = true,
) => {
  if (timerFormat) {
    return `${days > 0 ? `${days}${days > 1 ? 'days:' : 'day:'}` : ''}${hours
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }
  return `${days > 0 ? `${days} days ` : ''}${
    hours > 0 ? `${hours} hours ` : ''
  }${minutes > 0 ? `${minutes} minutes ` : ''}${
    seconds > 0 ? `${seconds} seconds` : ''
  }`;
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isAndroidOrIOS = () => {
  const isAndroid = /(android)/i.test(navigator.userAgent);
  const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

  if (isAndroid) {
    return 'android';
  } else if (isIOS) {
    return 'ios';
  } else {
    return '';
  }
};

export const navigateToStore = () => {
  const deviceType = isAndroidOrIOS();
  if (deviceType === 'android') {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.xenia.xeniaApp&hl=en_US&gl=US';
    return true;
  }
  if (deviceType === 'ios') {
    window.location.href =
      'https://apps.apple.com/us/app/xenia-team/id1605760701';
    return true;
  }

  return false;
};

export const downloadFile = (url, filename, onSuccessCallback: any = null) => {
  if (isMobileApp) {
    postToMobileApp('downloadFile', {
      fileUrl: url,
    });
    onSuccessCallback?.();
    return;
  }

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();

      onSuccessCallback?.();

      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        link.remove();
      }, 0);
    });
};

export const getFileName = (url) => {
  const splitData = url?.split('/');
  const nameExt = splitData?.[splitData?.length - 1]?.split('.');
  return nameExt?.[0];
};

export const getFileExtensionIcon = (
  type: 'pdf' | 'image' | 'doc' | 'video' | 'svg',
  styles?: any,
) => {
  switch (type) {
    case 'pdf':
      return <AdobeAcrobatPdfIcon sx={styles} />;
    case 'image':
    case 'svg':
      return <ImageFileIcon sx={styles} />;
    case 'doc':
      return <DocxFileIcon sx={styles} />;
    case 'video':
      return <VideoFileIcon sx={styles} />;
    default:
      return <></>;
  }
};

export const fileExtensionMap = {
  pdf: ['pdf'],
  image: ['jpg', 'jpeg', 'webp', 'png', 'svg'],
  doc: ['doc', 'docx'],
  video: ['mp4', 'webm'],
  svg: ['svg'],
};

export const getFileExtensionType = (filePath: string) => {
  const extension = filePath.split('.').pop();
  const fileExtension = Object.keys(fileExtensionMap).find((key) =>
    fileExtensionMap[key].includes(extension),
  );
  return fileExtension ?? 'doc';
};

export const transformLayouts = (layout, initialLayout, threshold) => {
  const transformedLayout = initialLayout?.map((InitialLayoutItem) => {
    const existingLayout = layout?.find(
      (layoutItem) => layoutItem?.i == InitialLayoutItem?.i,
    );

    if (existingLayout) {
      if (
        existingLayout?.h < threshold?.minH ||
        existingLayout?.w < threshold?.minW
      ) {
        return InitialLayoutItem;
      } else {
        return existingLayout;
      }
    }

    if (!existingLayout) {
      return InitialLayoutItem;
    }
  });

  return transformedLayout;
};

export const convertHeicToJpeg = async (file) => {
  const convertedFile = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 0.7,
  });
  return convertedFile;
};

export const prepareFileFormData = async (data) => {
  const formData = new FormData();
  const promises = map(data, async (file) => {
    if (file.type === 'image/heic') {
      const convertedBlob = await convertHeicToJpeg(file);
      const blob = new Blob([convertedBlob as BlobPart], {
        type: 'image/jpeg',
      });
      const convertedFile = new File(
        [blob],
        `${file.name.substring(0, file.name.lastIndexOf('.'))}.jpeg`,
        {
          type: 'image/jpeg',
          lastModified: new Date().getTime(),
        },
      );
      formData.append('file', convertedFile);
    } else {
      formData.append('file', file);
    }
  });

  await Promise.all(promises);
  return formData;
};

export const postToMobileApp = (action: string, data: any) => {
  globalThis.ReactNativeWebView?.postMessage(
    JSON.stringify({
      action,
      data,
    }),
  );
};

export const exportToPng = (elementRef, fileName) => {
  html2canvas(elementRef)
    .then((canvas) => {
      // Convert the canvas to a data URL representing a PNG image
      const dataUrl = canvas.toDataURL('image/png');
      // Create a download link and trigger a download
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${fileName}.png`;
      link.click();
    })
    .catch((error) => {
      console.error('Error exporting chart to image:', error);
    });
};

function formatSeconds(duration) {
  const seconds = Math.floor(duration.asSeconds());
  return `${seconds} second${seconds !== 1 ? 's' : ''}`;
}

function formatMinutes(duration) {
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${
    seconds !== 1 ? 's' : ''
  }`;
}

function formatHours(duration) {
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${
    minutes !== 1 ? 's' : ''
  }`;
}

function formatDays(duration) {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  return `${days} day${days !== 1 ? 's' : ''} ${hours} hour${
    hours !== 1 ? 's' : ''
  } ${minutes} minute${minutes !== 1 ? 's' : ''}`;
}

function formatMonths(duration) {
  const months = duration.months();
  const days = duration.days();
  return `${months} month${months !== 1 ? 's' : ''} ${days} day${
    days !== 1 ? 's' : ''
  }`;
}

export const formatDateDifference = (date) => {
  const givenDate = moment(date);
  const now = moment();
  const duration = moment.duration(now.diff(givenDate));

  switch (true) {
    case duration.asSeconds() < 60:
      return formatSeconds(duration);
    case duration.asMinutes() < 60:
      return formatMinutes(duration);
    case duration.asHours() < 24:
      return formatHours(duration);
    case duration.asDays() < 30:
      return formatDays(duration);
    default:
      return formatMonths(duration);
  }
};

export const minutesToHoursAndMinuteString = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const mins = duration.minutes();
  const secs = duration.seconds();

  let result = '';

  result += hours ? `${hours}h  ` : '';
  result += mins ? `${mins}m  ` : '';
  result += !hours && secs ? `${secs}s ` : '';

  return result.trim();
};

export const smartRoundToOneDecimalPlace = (num) => {
  const roundedNum = Math.round(num * 10) / 10;
  if (Number.isInteger(roundedNum)) {
    return roundedNum;
  } else {
    return roundedNum.toFixed(1);
  }
};

export const getTimeZone = () => {
  return (
    (store?.getState()?.authData?.user?.timezone ??
      store?.getState()?.workspace?.activeWorkspace?.Hotel?.timezone) ||
    DEFAULT_TIMEZONE
  );
};

export const getDateRangeCalendar = () => {
  const parsedDate = moment();

  // Get the first day of the previous two-month period
  const fromDate = parsedDate
    ?.clone()
    ?.subtract(1, 'months')
    ?.startOf('month')
    ?.format('YYYY-MM-DDTHH:mm:ss');

  // Get the last day of the previous two-month period
  const toDate = parsedDate
    ?.clone()
    ?.add(1, 'months')
    ?.endOf('month')
    ?.format('YYYY-MM-DDTHH:mm:ss');

  const dateRange = {
    from: fromDate,
    to: toDate,
  };

  return dateRange;
};
