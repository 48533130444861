import { v4 as uuid } from 'uuid';

export const DEFAULT_CONDITION = {
  id: uuid(),
  ChecklistItemId: '',
  logic: null,
  value: null,
  order: 0,
};

export const getChecklistItemsHash = (items: any[]) => {
  if (!items) return {};
  const hash: any = {};
  items?.forEach((item) => {
    hash[item.id] = item;
    if (item?.ChildItems) {
      Object.assign(hash, getChecklistItemsHash(item?.ChildItems));
    }
  });
  return hash;
};

export const generateOrder = (items, parentOrder = '', orders = {}) => {
  let number = 0;
  items?.forEach((item) => {
    if (item?.type !== 'header') {
      number++;
      const currentOrder = parentOrder
        ? `${parentOrder}.${number}`
        : `${number}`;
      orders[item?.id] = currentOrder;

      if (item?.ChildItems && item?.ChildItems?.length > 0) {
        generateOrder(item?.ChildItems, currentOrder, orders);
      }
    }
  });
  return orders;
};

export const convertAutomationIntoCondition = (automation) => {
  return {
    condition: {
      logic: automation?.conditions?.[0]?.logic,
      value: automation?.conditions?.[0]?.value,
    },
    context: 'isFilled',
    contextId: automation?.EntityId,
  };
};
