import { Box, Stack, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 10,
    height: 69,
    padding: '10px 16px 22px 16px',
    borderRadius: 12,
    backgroundColor: 'rgba(235, 234, 255, 1)',

    '& .title': {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '100%',
      color: 'rgba(78, 72, 250, 1)',
    },
    '& .subTitle': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '100%',
      color: 'rgba(33, 33, 33, 1)',
    },
  },

  '& .triggerContent': {
    marginTop: '-10px',
    backgroundColor: 'white',
    padding: '20px 16px 20px 16px',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 12,
    minHeight: 112,
  },
});

export const DueDateCnt = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
    marginBottom: 5,
  },

  '& .boxContainer': {
    height: 32,
  },

  '& .arrowIcon': {
    width: '22px !important',
    height: 'unset !important',
  },

  '& .inner': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& .statement': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.14px',
      color: '#000000',
    },

    '& .closeIcon': {
      cursor: 'pointer',
      height: 18,
      width: 18,
      '& path': {
        color: '#EF5350',
      },
    },
  },
});

export const TimeDurationCnt = styled(Box)({
  '& .MuiFormControl-root': {
    width: '70px !important',

    '& input': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#000000',
    },
  },
});

export const TimeIntervalCnt = styled(Box)({
  '& .selectedLabel': {
    letterSpacing: '0.14px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  '& .arrowIcon': {
    color: '#9E9E9E',
  },
  '& label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const InfoWrapperCnt = styled(Stack)({
  padding: 0,
  marginBottom: 20,
});

export const InfoWrapper = styled(Stack)({
  backgroundColor: '#E1F5FE',
  padding: '8px 6px 8px 6px',
  borderRadius: '6px',
  gap: '8px',

  '& .infoIcon': {
    color: '#0288D1',
    height: 20,
    width: 20,
  },

  '& .infoText': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },
});
