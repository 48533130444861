import { Box, Typography } from '@mui/material';
import { MainContainer } from './style';
import AdvancedFilterBuilder from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/AdvancedFilterBuilder';
import { useEffect, useRef } from 'react';

const Trigger = (props) => {
  const { data, setData } = props;

  const dataRef = useRef(data);

  // Update the ref whenever data changes
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const hideFilters =
    'taskStatuses templates assignees assets createdDate futureStartDate futureDueDate startDate dueDate completedDate completedBy archived taskTypes isOverDue scheduleFrequency creators taskTemplates projects schedules';

  const handleClearFilters = () => {
    console.log('handle clear filters');
  };

  const onChange = (filters) => {
    setData({
      ...dataRef.current,
      triggers: [filters],
    });
  };

  const handleClose = () => {
    console.log('handle close');
  };

  return (
    <MainContainer>
      <Box className="header">
        <Typography className="title">Trigger</Typography>
        <Typography className="subTitle">When requester selects</Typography>
      </Box>

      <Box className="triggerContent">
        <AdvancedFilterBuilder
          hideHeader={true}
          hideWhere={true}
          addAnotherLabel="Add another trigger"
          hideFilters={hideFilters}
          handleClearFilters={handleClearFilters}
          handleSetFiltersCb={(filters) => {
            onChange({
              condition: filters[0].conditional.toUpperCase(),
              filters,
            });
          }}
          handleClose={handleClose}
          filters={data?.triggers?.[0]?.filters}
        />
      </Box>
    </MainContainer>
  );
};

export default Trigger;
