import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt, FooterWrapper, SelectedChecklist } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';

import selectors from 'store/selectors';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { useMemo, useState } from 'react';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import CustomButton from 'components/Button/CustomButton';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { showMessageNotification } from 'utils/globalFunction';

const SubmissionApprovalDialog = () => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const { isMobileDeviceMd } = useResponsive();

  const dialogState = useSelector(selectors.getDialogState);

  const { updateSubmissionApproval } = useWorkflows();

  const { open, data } =
    dialogState?.[DIALOGS?.SUBMISSION_APPROVAL_DIALOG] ?? {};

  const { type, approvalId, submission, successCallback } = data || {};

  const [payload, setPayload] = useState({
    message: {
      text: '',
      attachments: [],
    },
    status: type,
  });

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.SUBMISSION_APPROVAL_DIALOG,
      }),
    );
  };

  const updateDescription = (event) => {
    setPayload({
      ...payload,
      message: { ...payload?.message, text: event?.target?.value },
    });
  };

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const isDisabledBtn = useMemo(() => {
    return payload.message?.text?.length === 0;
  }, [payload.message]);

  const submitHandler = async () => {
    setIsSaving(true);
    const response = await updateSubmissionApproval(payload, approvalId);

    // Logic to have api call
    setIsSaving(false);

    if (response) {
      handleClose();
      dispatch(
        setDialog({
          open: true,
          dialogId: DIALOGS.SUBMISSION_APPROVAL_SUBMITTED_DIALOG,
          data: {
            type,
            successCallback,
          },
        }),
      );
    } else {
      showMessageNotification(
        'Looks like an action is already performed or try again !!',
        'info',
      );
      successCallback?.();
      handleClose();
    }
  };

  return (
    <CustomDialog
      header={
        <CustomDialogHeader
          onClose={handleClose}
          other={{
            style: { border: '1px solid #E0E0E0', cursor: 'pointer' },
          }}
        >
          {type === 'Changes Requested' && 'Request Changes'}
          {type === 'Approved' && 'Approve'}
          {type === 'Rejected' && 'Reject'}
        </CustomDialogHeader>
      }
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: !!isMobileDeviceMd,
      }}
    >
      <ContentCnt>
        <SelectedChecklist>
          <div className="leftCnt">
            <span className="checklistIcon" style={{ background: '#FFA681' }}>
              <MuiIcon
                name={submission?.icon?.icon}
                style={{ height: '20px', width: '20px' }}
              />
            </span>

            <div className="checklistNameCnt">
              <span className="checklistName">
                {submission?.Checklist?.name}
              </span>
              <span className="submittedBy">
                Submitted by: {submission?.Updater?.fullName || 'Anonymous'}
              </span>
            </div>
          </div>
        </SelectedChecklist>
        <CustomInput
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-input': {
              fontSize: isMobileDeviceMd ? 16 : 14,
              fontWeight: 600,
            },
          }}
          muiLabel={false}
          fieldProps={{
            placeholder: 'Add your message here',
            onChange: updateDescription,
            multiline: true,
            rows: 3,
            value: payload?.message?.text,
          }}
        />
      </ContentCnt>
      <FooterWrapper
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{
          '& .saveBtn': {
            '&:hover': {
              backgroundColor:
                type === 'Rejected'
                  ? 'rgba(239, 83, 80, 1)'
                  : type === 'Approved'
                  ? '#4CAF50'
                  : '#FF9800',
            },
          },
        }}
      >
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          disabled={isDisabledBtn}
          color={'primary'}
          onClick={submitHandler}
          buttonLoader={isSaving}
          className="saveBtn"
          sx={{
            backgroundColor:
              type === 'Rejected'
                ? 'rgba(239, 83, 80, 1)'
                : type === 'Approved'
                ? '#4CAF50'
                : '#FF9800',
          }}
        >
          {type === 'Changes Requested' && 'Request Changes'}
          {type === 'Approved' && 'Approve'}
          {type === 'Rejected' && 'Reject'}
        </CustomButton>
      </FooterWrapper>
    </CustomDialog>
  );
};

export default SubmissionApprovalDialog;
