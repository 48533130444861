import React, { FC, useMemo } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { Stack, Typography } from '@mui/material';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import BranchIcon from 'components/Icons/branchIcon';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { LocationChip } from 'components/TaskInputs/assignTo/roles/style';

interface IProps {
  onChange?: (value: string | string[]) => void;
  answer?: any | null;
  compact?: boolean;
  isMulti?: boolean;
  showTabs?: boolean;
}

const View = (props) => {
  const { location, readonly, compact } = props;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  return (
    <Stack
      border="1px solid #BDBDBD"
      borderRadius="6px"
      p={compact ? '8px' : '12px'}
      width="100%"
      flexDirection="row"
      gap={'6px'}
      flexWrap={'wrap'}
    >
      {location || location?.length > 0 ? (
        <>
          {Array.isArray(location) ? (
            <>
              {location.slice(0, 2).map((loc, index) => (
                <LocationChip sx={{ height: '25px' }} key={index}>
                  <Typography className="label">{loc?.name}</Typography>
                </LocationChip>
              ))}
              {location.length > 2 && (
                <LocationChip sx={{ height: '25px' }}>
                  <Typography className="label">
                    +{location.length - 2}
                  </Typography>
                </LocationChip>
              )}
            </>
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              {location?.ParentId && (
                <BranchIcon style={{ fontSize: '16px' }} />
              )}
              <Typography>{location?.name}</Typography>
              {readonly && (
                <CustomButton
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    const path = location.ParentId
                      ? `/locations/${location?.ParentId}/sl/${location?.id}`
                      : `/locations/${location?.id}`;

                    navigateWithWorkspaceUrl(path);
                  }}
                >
                  View Location
                </CustomButton>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Typography color="#9E9E9E">Select Location</Typography>
      )}
    </Stack>
  );
};

const LocationStep: FC<IProps> = (props) => {
  const {
    onChange,
    answer,
    compact,
    isMulti = false,
    showTabs = false,
  } = props;

  const locationsMap = useLocationsMap();

  const selectedLocation = useMemo(() => {
    if (answer?.value && isMulti) {
      if (Array.isArray(answer?.value))
        return answer?.value?.map((value) => locationsMap[value]) || null;
      else return [locationsMap[answer?.value]];
    }
    return locationsMap[answer?.value] || null;
  }, [answer]);

  if (!onChange)
    return <View compact={compact} location={selectedLocation} readonly />;

  return (
    <AssigneeDropdown
      buttonRendererProps={{
        style: { width: '100%' },
        disabled: true,
      }}
      buttonRenderer={<View compact={compact} location={selectedLocation} />}
      handleChangeCallback={(data) => {
        const { currentSelected, selectedList } = data;
        if (isMulti) {
          if (selectedList && selectedList?.locations) {
            onChange?.(
              selectedList?.locations?.length ? selectedList?.locations : '',
            );
          } else {
            onChange?.('');
          }
        } else {
          if (currentSelected && selectedList !== answer?.value) {
            onChange?.(currentSelected);
          } else {
            onChange?.('');
          }
        }
      }}
      showTabs={showTabs}
      listProps={{
        locations: {
          showInlineSelection: true,
          isSearchable: true,
          isMulti: isMulti,
          hideActions: true,
          selected: answer?.value
            ? Array.isArray(answer?.value)
              ? answer?.value
              : [answer?.value]
            : [],
        },
      }}
      displayOptions={{
        all: false,
        people: false,
        teams: false,
        sites: true,
        locations: true,
      }}
      popperProps={{ disablePortal: false }}
      allSelected={false}
      isMobileStylesApply={true}
    />
  );
};

export default LocationStep;
