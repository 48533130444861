// React
import { useState, useMemo, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Utilities
import {
  formatTemplateLibraryData,
  filterTemplates,
  flattenTemplateObject,
} from './utils';
import xeniaApi from 'api/index';
import DIALOGS from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { includes } from 'lodash';

export const useTemplateLibrary = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [isLoading, setIsLoading] = useState(true);
  const dialogId = DIALOGS.TEMPLATE_LIBRARY;
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const templates = useSelector(selectors.getPublicChecklists);
  const features = useSelector(selectors.getUserHotelFeatures);

  const isXeniaAdmin = useMemo(
    () => includes(features, 'Publish Checklists'),
    [features],
  );

  // fetch public templates on mount
  useEffect(() => {
    xeniaApi.getPublicChecklists({}).then((response) => {
      dispatch(actions.setPublicChecklists(response));
      setIsLoading(false);
    });
    dispatch(actions.getChecklistTypes());
    dispatch(actions.getChecklistIndustries());
  }, []);

  //   close the modal inside the store
  const handleCloseModal = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  // formatted template library data as hashed object
  const data = useMemo(() => {
    if (templates) {
      const filtered = filterTemplates(modalState?.data, templates);
      return formatTemplateLibraryData(filtered);
    }
    return {};
  }, [templates, modalState?.data?.filters, modalState?.data?.searchTerm]);

  // open template preview modal
  const handleOpenTemplatePreviewModal = (template) => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TEMPLATE_PREVIEW,
        open: true,
        data: {
          listofTemplatesId: flattenTemplateObject(data)?.map(
            (item) => item?.id,
          ),
          selectedTemplate: template,
        },
      }),
    );
  };

  const handleClickEdit = (checklistId) => {
    navigateWithWorkspaceUrl(`/checklist/${checklistId}`);
    dispatch(actions.closeDialog(dialogId));
  };

  const handleClickArchive = (checklistId) => {
    dispatch(actions.archiveChecklistApiCall(checklistId));
  };

  return {
    modalState,
    templates,
    data,
    isXeniaAdmin,
    isLoading,
    handleCloseModal,
    handleOpenTemplatePreviewModal,
    handleClickEdit,
    handleClickArchive,
  };
};
