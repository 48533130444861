import { useEffect, useMemo, useRef, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import {
  CreateTaskDialogHeader,
  TaskFormCnt,
  BannerCnt,
  TemplateBannerCnt,
} from 'components/AllDialogs/tasks/editTask/createTask.style';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import CreateTaskInput from 'components/AllDialogs/tasks/editTask/createTaskInput.cmp';
import TaskAssigneeDropdown from 'components/AllDialogs/tasks/editTask/taskAssigneeDropdown.cmp';
import TaskDescriptionInput from 'components/AllDialogs/tasks/editTask/taskDescriptionInput.cmp';

import RepeatTaskToggle from 'components/AllDialogs/tasks/editTask/repeatTaskToggle.cmp';
import TaskAttachment from 'components/AllDialogs/tasks/editTask/taskAttachment.cmp';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { getTimeZone } from 'utils/globalFunction';
import AddChecklist from 'components/AllDialogs/tasks/editTask/addChecklist.cmp';
import useCreateTask from 'utils/CustomHooks/useCreateTask';
import xeniaApi from 'api/index';
import TaskLocationDropdown from 'components/AllDialogs/tasks/editTask/locationDropdown.cmp';
import moment from 'moment-timezone';
import classNames from 'classnames';
import actions from 'store/actions';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useTaskEdit } from 'controller/useTaskEdit';
import { isEqual } from 'lodash';
import CTypography from 'components/CTypography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SubmissionAttachment from './submissionLink.cmp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Stack, Divider, Typography, Box } from '@mui/material';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import TaskAssigneeForTemplates from './taskAssigneeForTemplates.cmp';
import CrossIcon from 'components/Icons/crossIcon';
import AdditionalOptionsInTask from './additionalOptions.cmp';
import ShowMoreLess from './showMoreLess.cmp';
import { ROLES, USER_AND_TEAMS } from 'components/TaskInputs/assignTo/utils';

const CreateTaskHeader = ({ closeAction }) => {
  return (
    <CreateTaskDialogHeader>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        className="titleCnt"
      >
        <Typography className="title">Edit</Typography>
        <CrossIcon className="icon" onClick={closeAction} />
      </Stack>

      <Box className="inputCnt">
        <CreateTaskInput />
      </Box>
    </CreateTaskDialogHeader>
  );
};

function EditTask() {
  const tz: string = getTimeZone();
  const { getTaskAssigneesIds } = useAssigneesUtils();
  const [newTask, setNewTask] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [uploadsChange, setUploadsChange] = useState<boolean>(false);
  const [taskTimeChange, setTaskTimeChange] = useState<boolean>(false);
  const [showLess, setShowLess] = useState<any>(true);
  const [taskTime, setTaskTime] = useState<any>({ startTime: '', dueTime: '' });
  const [showRepeatTask, setShowRepeatTask] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<[]>([]);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { handleUpdateTask, handleUpdateProject } = useTaskEdit(newTask);
  const { createTask } = useCreateTask();
  const prevTaskRef = useRef<any>({});
  const [assigneeType, setAssigneeType] = useState(USER_AND_TEAMS);

  const { getDateInTz, getUtcToTzTime, getUtcToTz } = useDateTime();

  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);
  const [attachChecklistOpen, setAttachChecklistOpen] =
    useState<boolean>(false);
  const [attachedChecklist, setAttachedChecklist] = useState(null);
  const [isChecklistRequired, setIsChecklistRequired] = useState(null);
  const [isWeekTimeEdit, setIsWeekTimeEdit] = useState<any>({
    startTime: true,
    endTime: true,
  });
  const [isMonthTimeEdit, setIsMonthTimeEdit] = useState<any>({
    startTime: true,
    endTime: true,
  });
  const dialogState = useSelector(getDialogState);
  const initialData = useRef(dialogState?.editTask?.data?.taskData);
  const [editSeriesType] = useState(
    dialogState?.editTask?.data?.editSeriesType,
  );
  const [recurType, setRecurType] = useState<any>(null);
  const { hideElements, removeSubmitValidation, taskTemplate, project } =
    dialogState?.editTask?.data?.dialogState || {};
  const actionBtn = dialogState?.editTask?.data?.actionBtn;

  const dispatch = useDispatch();

  //Function to reset task form
  const resetTaskForm = () => {
    setNewTaskObj({});
    setErrors({});
    setShowLess(true);
    setShowRepeatTask(false);
    setAttachments([]);
    setAttachChecklistOpen(false);
  };
  const setPreSelectedTaskTime = () => {
    const preFilledTaskData = dialogState?.editTask?.data?.taskData;
    const preFilledTaskTime = dialogState?.editTask?.data?.taskTime;
    prevTaskRef.current = preFilledTaskData;
    const selectedStartTime = preFilledTaskTime
      ? preFilledTaskTime?.startTime
      : getUtcToTzTime(preFilledTaskData?.startTime);
    const selectedDueTime = preFilledTaskTime
      ? preFilledTaskTime?.dueTime
      : getUtcToTzTime(preFilledTaskData?.dueTime);
    setTaskTime({ startTime: selectedStartTime, dueTime: selectedDueTime });
    setRecurType(translateRecurType(preFilledTaskData));
  };

  const translateRecurType = (task: any) => {
    let requestThisTask = task.requestThisTask;
    if (
      task.requestThisTask === 'Weekly' &&
      task.intervalWeek.length === 7 &&
      task.recurringByEvery === 'Week1'
    ) {
      requestThisTask = 'Daily';
    }
    const translations = {
      Weekly: 'weekly',
      Monthly: 'monthly',
      Daily: 'daily',
      OneOff: 'oneOff',
    };

    if (!requestThisTask) return translations['OneOff'];
    return translations[requestThisTask];
  };

  const members = useMemo(() => {
    const TaskAssignees =
      dialogState?.editTask?.data?.taskData?.TaskAssignees ?? [];
    if (TaskAssignees?.length > 0) {
      return getTaskAssigneesIds(TaskAssignees);
    }
    return [];
  }, [dialogState?.editTask?.data?.taskData?.TaskAssignees]);

  const transformService = (preFilledTaskData) => {
    if (preFilledTaskData) {
      return {
        ...preFilledTaskData,
        ...(preFilledTaskData?.ServiceType && {
          ServiceId: preFilledTaskData?.ServiceType?.id,
        }),
      };
    }
    return preFilledTaskData;
  };

  //Reset task form on unmount
  useEffect(() => {
    let preFilledTaskData = dialogState?.editTask?.data?.taskData;
    const showLessState = dialogState?.editTask?.data?.dialogState?.viewLess;
    const isRepeatTaskVisible =
      dialogState?.editTask?.data?.dialogState?.showRepeatTask;
    setShowLess(showLessState != false);
    setPreSelectedTaskTime();
    setShowRepeatTask(isRepeatTaskVisible);

    preFilledTaskData = transformService(preFilledTaskData);
    preFilledTaskData &&
      updateTask({
        ...preFilledTaskData,
        assignees: members,
      });

    setTaskTime({
      startTime: preFilledTaskData?.startTime
        ? getUtcToTzTime(preFilledTaskData?.startTime)
        : '',
      dueTime: preFilledTaskData?.dueTime
        ? getUtcToTzTime(preFilledTaskData?.dueTime)
        : '',
    });
    return () => {
      resetTaskForm();
    };
  }, []);

  const getStartDate = () => {
    const { startTime } = newTask;
    const formatTime = taskTime.startTime
      ? moment(taskTime.startTime, 'HH:mm A').format('HH:mm')
      : '';
    const startDateFormat = moment(startTime).format('MMM DD YYYY');
    let dateAndTime;
    if (!startTime && taskTime?.startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (taskTime?.startTime && startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (startTime && !taskTime?.startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    }

    const taskStartDate = dateAndTime
      ? getDateInTz?.(dateAndTime)?.format()
      : null;
    if (!startTime && taskTime?.startTime) {
      const timeWithDummyDate = moment(taskTime.startTime, 'HH:mm A').format(
        'YYYY-MM-DDTHH:mm:ss',
      );
      return getDateInTz?.(timeWithDummyDate)?.format();
    }
    if (!startTime) return null;
    return taskStartDate;
  };
  const getDueDate = () => {
    const { dueTime } = newTask;
    //taskTime only works with the start and due Time selected by user, not date
    const formatTime = taskTime?.dueTime
      ? moment(taskTime.dueTime, 'HH:mm A').format('HH:mm')
      : '';
    const dueDateFormat = dueTime ? moment(dueTime)?.format('MMM DD YYYY') : '';
    let dateAndTime;
    if (!dueTime && taskTime?.dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (taskTime?.dueTime && dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (dueTime && !taskTime?.dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    const taskDueDate = dateAndTime
      ? getDateInTz?.(dateAndTime)?.format()
      : null;
    if (!dueTime && taskTime?.dueTime) {
      const timeWithDummyDate = moment(taskTime.dueTime, 'HH:mm A').format(
        'YYYY-MM-DDTHH:mm:ss',
      );
      return getDateInTz?.(timeWithDummyDate)?.format();
    }
    if (!dueTime) return null;
    return taskDueDate;
  };

  const scheduleRecurrTasks = (scheduledDateTime, startTime, dueTime) => {
    let scheduledDate = scheduledDateTime
      ? moment(scheduledDateTime)
      : moment();

    if (project?.id && scheduledDateTime) {
      scheduledDate = moment.tz(scheduledDateTime, tz);
    }
    let newStartTime: any = null;
    let newDueTime: any = null;
    if (startTime) {
      newStartTime = getUtcToTz(startTime)?.set({
        year: scheduledDate?.year(),
        month: scheduledDate?.month(),
        date: scheduledDate?.date(),
      });
    }

    if (dueTime) {
      newDueTime = getUtcToTz(dueTime)?.set({
        year: scheduledDate?.year(),
        month: scheduledDate?.month(),
        date: scheduledDate?.date(),
      });
    }

    return {
      startTime: newStartTime?.format() || null,
      dueTime: newDueTime?.format() || null,
    };
  };

  const filterOneOffAsSimpleTask = (newTask: any) => {
    const isRecurring = recurType !== 'oneOff';
    const {
      editInstance,
      instanceCadence,
      intervalWeek,
      recurringByEvery,
      recurringTask,
      requestThisTask,
      recPatternText,
      editSeriesType,
      scheduledDateTime,
      ...rest
    } = newTask;
    if (isRecurring) {
      return newTask;
    }
    return {
      ...rest,
      recurringTask: false,
    };
  };

  const getSubmissionAfterDueValue = (newTask: any) => {
    if (newTask?.isTimeBound !== undefined) {
      return newTask?.isTimeBound;
    }
    if (showRepeatTask) {
      if (recurType === 'oneOff') {
        return !newTask?.dueTime;
      } else {
        return !taskTime?.dueTime;
      }
    }
    return !newTask?.dueTime;
  };

  // This function is as requested by Backend
  // We need to send dateFrom property when we are editing
  // We need to send it only when user has changed the schedule starting from
  // We need to make sure DateFrom contains YYYY-MM-DD from starting from and rest of the things would be from start date coming in response
  // Backend will auto update it with regards to start time property if that is present.

  const updateDateFrom = (scheduledDateTime: string, newTask: any) => {
    const utcScheduledDateTime = scheduledDateTime
      ? moment(scheduledDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      : moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    const scheduledDateTimeYMD = moment
      .utc(utcScheduledDateTime)
      .format('YYYY-MM-DD');

    const startDateYMD = moment.utc(newTask?.startDate).format('YYYY-MM-DD');

    if (startDateYMD == scheduledDateTimeYMD) return undefined;

    const time = moment(newTask?.startDate).utc().format('THH:mm:ss.SSS[Z]');
    const newDateFrom = scheduledDateTimeYMD + time;

    return newDateFrom;
  };

  const editTask = async (data = {}) => {
    const {
      assignees,
      title,
      checklistId,
      AssociatedLog,
      scheduledDateTime,
      recurringTask,
      notification,
      assets,
    } = newTask;

    const hasAddedSchedule =
      !prevTaskRef.current.recurringTask && recurringTask;

    let dates = {
      startTime: getStartDate(),
      dueTime: getDueDate(),
    };

    if (recurringTask && recurType !== 'oneOff') {
      dates = scheduleRecurrTasks(
        scheduledDateTime,
        dates?.startTime,
        dates?.dueTime,
      );
    }

    const simpleObj = {
      ...(assignees && { assignees }),
      title,
      editInstance: editSeriesType,
      ...(checklistId && { checklistId, isChecklistRequired }),
      ChecklistLogId: AssociatedLog?.id || null,
      notification,
    };

    // Calculating Date From object
    const dateFrom = updateDateFrom(scheduledDateTime, newTask);

    const completeObj = filterOneOffAsSimpleTask({
      ...newTask,
      dateFrom,
      ...data,
      ...dates,
      ...(editSeriesType && { editInstance: editSeriesType }),
      ChecklistLogId: AssociatedLog?.id || null,
      ...(newTask?.ServiceId && {
        serviceTypeId: newTask?.ServiceId,
      }),
      ...(newTask?.ChecklistId && {
        checklistId: newTask?.ChecklistId,
        isChecklistRequired: !!newTask?.isChecklistRequired,
      }),
      scheduledDateTime: undefined,
      AssociatedLog: undefined,
      ...(assignees && { assignees }),
      isTimeBound: getSubmissionAfterDueValue(newTask),
      notification,
      ...(project?.id && {
        assets,
      }),
    });

    //In case of show less only minimized version of task should be created, other selected values will not be considered
    const postObj = hasAddedSchedule ? simpleObj : completeObj;

    const taskResponse = project?.id
      ? await handleUpdateProject(project?.id, postObj, false)
      : await handleUpdateTask(postObj, false, false, true);

    if (project?.id && taskResponse?.data) {
      dialogState?.editTask?.data?.onSuccessCallBack?.();

      const isTasksTableURL =
        window?.location?.href.includes('tasks/list') ||
        window?.location?.href.includes('filtered-tasks');

      if (isTasksTableURL) {
        dispatch(
          actions.setForceUpdate({ forceUpdate: true, pushUpdate: false }),
        );
      }

      dispatch(
        setDialog({
          open: false,
          dialogId: 'editTask',
        }),
      );
      return;
    }

    if (hasAddedSchedule) {
      await createTask(completeObj);
    }
    if (taskResponse) {
      const isRecur = editSeriesType === 'All';
      dialogState?.editTask?.data?.onSuccessCallBack?.(
        taskResponse?.data?.[0]?.id || '',
        false,
        isRecur,
      );
      dispatch(actions.bulkUpdateTasks(taskResponse?.data));
      dispatch(
        setDialog({
          open: false,
          dialogId: 'editTask',
        }),
      );

      const isTasksTableURL =
        window?.location?.href.includes('tasks/list') ||
        window?.location?.href.includes('filtered-tasks');

      if (isTasksTableURL) {
        dispatch(
          actions.setForceUpdate({ forceUpdate: true, pushUpdate: false }),
        );
      }
    } else {
      setSubmitButtonLoading(false);
    }
  };

  //Task creation with attachments
  const editTaskWithAttachment = async () => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(attachments);

    if (response) {
      const attachedList = [
        ...(Array.isArray(newTask.attachment) ? newTask.attachment : []),
        ...response.filePath.map((item) => response.baseUrl + item),
      ];

      editTask({ attachment: attachedList });
    }
  };
  //Function passed to the context to update the root state of new create task
  const updateTask: any = (value) => {
    const updateObj = { ...newTask, ...value };
    setNewTask(updateObj);
  };
  //Function passed to the context to replace the new task object in state
  const setNewTaskObj: any = (value) => {
    setNewTask(value);
  };
  //Submit create form
  const handleTaskFormSubmit = () => {
    //Title field is required, show error if title field is empty
    if (!newTask.title) {
      setErrors({
        ...errors,
        title: VALIDATION_ERROR_MESSAGES.TASK_TITLE_REQUIRED,
      });
      return;
    }

    if (actionBtn?.clickHandler) {
      const finalTaskData = !showLess
        ? { ...newTask, ...(attachments?.length > 0 && { attachments }) }
        : {
            ...(newTask?.assignees && { assignees: newTask?.assignees }),
            title: newTask?.title,
            ...(newTask?.checklistId && {
              checklistId: newTask?.checklistId,
              isChecklistRequired: !!newTask?.isChecklistRequired,
            }),
          };

      actionBtn?.clickHandler?.(
        finalTaskData,
        dialogState?.editTask?.data?.taskTemplateInfo,
        taskTemplate?.isEdit,
      );
      return;
    }

    setSubmitButtonLoading(true);
    if (attachments?.length && !showLess) {
      editTaskWithAttachment();
    } else {
      editTask();
    }
  };
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );
  };

  const updateWatchers = (
    watchers: string[],
    type: 'statusChange' | 'overdue',
  ) => {
    setNewTask((prevTask) => ({
      ...prevTask,
      notification: {
        ...prevTask.notification,
        [type]: {
          recipients: watchers,
        },
      },
    }));
  };

  const updateAssigneeType = (type) => {
    setAssigneeType(type);
  };

  const AssociatedLog = dialogState?.editTask?.data?.taskData?.AssociatedLog;
  // const disableRecurringEdit = newTask?.recurringTask;
  // const hideSchedule = !newTask?.recurringTask;
  // const disableDateSelection = newTask?.recurringTask;
  const state: any = {
    updateTask,
    newTask,
    setNewTask,
    showLess,
    setShowLess,
    showRepeatTask,
    setShowRepeatTask,
    attachments,
    setAttachments,
    errors,
    setErrors,
    attachChecklistOpen,
    setAttachChecklistOpen,
    setNewTaskObj,
    taskTime,
    setTaskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    isMonthTimeEdit,
    setIsMonthTimeEdit,
    attachedChecklist,
    setAttachedChecklist,
    isChecklistRequired,
    setIsChecklistRequired,
    AssociatedLog,
    recurType,
    setRecurType,
    updateWatchers,
    project,
    taskTemplate,
    hideElements,
    updateAssigneeType,
    editSeriesType,
  };

  useEffect(() => {
    if (newTask?.assigneesRoles?.roles?.length > 0) {
      updateAssigneeType(ROLES);
    }
  }, [newTask]);

  const isStateChanged = dialogState?.editTask?.data?.isStateChanged;
  const isTemplate = dialogState?.editTask?.data?.isTemplate;

  const showSubmissionAfterDue = useMemo(() => {
    if (showRepeatTask) {
      if (recurType === 'oneOff') {
        return newTask?.dueTime;
      } else {
        return taskTime?.dueTime;
      }
    }
    return newTask?.dueTime;
  }, [newTask, taskTime, recurType, showRepeatTask]);

  const hideAssets = useMemo(() => {
    return newTask?.isAutoTagLocation || assigneeType === ROLES;
  }, [newTask, assigneeType]);

  return (
    <CreateTaskContext.Provider value={state}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: handleTaskFormSubmit,
            buttonLoader: submitButtonLoading,
            disabled:
              !removeSubmitValidation &&
              isEqual(initialData?.current, newTask) &&
              !uploadsChange &&
              !taskTimeChange &&
              !isStateChanged,
          },
          secondaryBtnProps: { onClick: closeAction },
        }}
        primaryBtnText={actionBtn?.text || 'Save'}
        header={
          <>
            {newTask?.AssociatedLog && (
              <BannerCnt>
                <InfoIcon className="infoIcon" />
                <CTypography className="content">
                  Created from submission:
                </CTypography>
                <CTypography className="action">
                  {newTask?.AssociatedLog?.name}
                </CTypography>
              </BannerCnt>
            )}

            {taskTemplate?.isEdit && (
              <TemplateBannerCnt>
                <InfoIcon className="infoIcon" />
                <CTypography className="content">
                  Editing this template will affect all users and active
                  templates everywhere in your team.
                </CTypography>
              </TemplateBannerCnt>
            )}

            <CreateTaskHeader closeAction={closeAction} />
          </>
        }
        dialogProps={{
          open: dialogState?.editTask?.open,
          fullWidth: true,
          fullScreen: isMobileDevice,
          sx: {
            '& .MuiDialog-paper': {
              maxWidth: '650px',
            },
          },
        }}
      >
        <TaskFormCnt>
          <AddChecklist />
          {taskTemplate?.isEdit ? (
            <TaskAssigneeForTemplates
              hideElements={hideElements}
              updateWatchers={updateWatchers}
            />
          ) : (
            <TaskAssigneeDropdown
              hideElements={hideElements}
              updateWatchers={updateWatchers}
            />
          )}

          {assigneeType != ROLES && <TaskLocationDropdown />}

          {newTask?.AssociatedLog && <SubmissionAttachment />}

          <div
            className={classNames({
              showLess: false,
            })}
          >
            {!hideElements?.schedule && (
              <div style={{ marginTop: '16px' }}>
                <RepeatTaskToggle
                  editSeriesType={editSeriesType}
                  isTemplate={isTemplate}
                  {...state}
                />
              </div>
            )}
          </div>

          {!showLess && (
            <AdditionalOptionsInTask
              hideAssets={hideAssets}
              showSubmissionAfterDue={showSubmissionAfterDue}
            />
          )}

          <div className={classNames({ showLess: showLess })}>
            <TaskDescriptionInput />
            <TaskAttachment
              setUploadsChange={setUploadsChange}
              preFilledFiles={newTask?.preFilledFiles}
            />
          </div>

          {!project?.id && showLess && <ShowMoreLess />}
        </TaskFormCnt>
      </CustomDialog>
    </CreateTaskContext.Provider>
  );
}

export default EditTask;
