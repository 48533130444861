import { useSelector, useDispatch } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import SubmissionListSummary from 'pages/Widgets/SubmissionsListing/SubmissionListView/submissionsListSummary';
import { getDialogState } from 'store/selectors/dialogSelectors';

const TemplateSubmissionsDialog = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const state = dialogState?.[DIALOGS.TEMPLATE_SUBMISSIONS_DIALOG];
  const { open, data } = state;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.TEMPLATE_SUBMISSIONS_DIALOG,
      }),
    );
  };

  return (
    <CustomDialog
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>
          Template Submissions
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <SubmissionListSummary
          filters={data?.filters}
          isLogs={data?.isLogs}
          isScoring={data?.isScoring}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default TemplateSubmissionsDialog;
