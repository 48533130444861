import actionsTypes from 'store/actionTypes';

export const setRoutingRules = (data) => ({
  type: actionsTypes.SET_ROUTING_RULES,
  payload: data,
});

export const addRoutingRule = (data) => ({
  type: actionsTypes.ADD_ROUTING_RULE,
  payload: data,
});

export const updateRoutingRule = (data) => ({
  type: actionsTypes.UPDATE_ROUTING_RULE,
  payload: data,
});

export const removeRoutingRule = (data) => ({
  type: actionsTypes.REMOVE_ROUTING_RULE,
  payload: data,
});
