import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Divider, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RecurringIcon from 'public/assets/img/recurring-v2.svg';
import { includes } from 'lodash';
import { DATE_FORMAT, TASK_STATUS } from 'utils/globalVariable';
import { getTimeString, formatDate, getTimeZone } from 'utils/globalFunction';
import { statusDropdownData } from 'helper/task/dropdownData';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import DateTime from 'utils/DateTime';
import CustomButton from 'components/Button/CustomButton';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import AvatarPopover from 'components/AvatarPopover';
import { CategoryChip } from 'components/Dropdown/Task/Category/taskCategoryDropdown.style';
import {
  getAuthLocationsHash,
  getLocationsHash,
} from 'store/selectors/locations';
import { COLORS_LIST } from 'utils/constants';
import {
  DetailTabWrapper,
  ValueCnt,
  RowCnt,
  Dot,
  AvatarCnt,
  TeamLabel,
  DateTimeOverDue,
  OverDueCnt,
  ValueText,
  BtnLink,
  AssetsLocationCnt,
  AssetsLocationParentCnt,
  CategoryCnt,
  PriorityContent,
  ByCnt,
} from './styles';
import { HasPermission } from 'components/HasPermission';
import TrackCost from 'components/TrackCost';
import InfoIcon from '@mui/icons-material/Info';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { Alert } from '@mui/material';
import { getAssetsState, getAuthAssetsState } from 'store/selectors/assets';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import Stack from '@mui/material/Stack';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MissedIcon from 'components/Icons/missedIcon';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import useResponsive from 'utils/CustomHooks/useResponsive';
import CorrectiveAnswer from './CorrectiveAnswer';
import AutomationIconRounded from 'components/Icons/automationIconRounded';
import { updateInstanceActivationTime } from './utils';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface IProps {
  task: any;
  options: any;
  filters?: any;
  ChecklistLogItem: any;
  viewSubmissionReport: any;
  locationsMap: any;
  automationText: any;
  handleTaskStatusChangeClick: any;
  handleViewLocationClick: any;
  handleViewAssetClick: any;
  taskDetailCallBack: any;
  initialTime: any;
  handleStartChecklistClick: any;
  handleNextTaskStatusChangeClick: any;
}

const TaskDetailTab: React.FC<IProps> = (props) => {
  const {
    task,
    options = {},
    filters = null,
    ChecklistLogItem,
    viewSubmissionReport,
    automationText,
    handleTaskStatusChangeClick,
    handleViewLocationClick,
    handleViewAssetClick,
    taskDetailCallBack,
    initialTime,
    handleStartChecklistClick,
    handleNextTaskStatusChangeClick,
  } = props;
  const { getDateInTz, getUtcToTzTime } = useDateTime();
  const authLocationsHash = useSelector(getAuthLocationsHash);
  const locationsHash = useSelector(getLocationsHash);
  const { PERMISSIONS } = useCustomPermission();
  const assetsList = useSelector(getAssetsState);
  const authAssetsList = useSelector(getAuthAssetsState);
  const tz: string = getTimeZone();
  const currentTaskChecklistData = useSelector(selectors.getChecklistLog);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedWorkspace } = useWorkspaceHook();
  const { Hotel } = selectedWorkspace();
  const {
    isMobileDeviceXs,
    isMobileDeviceMd,
    isMobileDeviceLg,
    isMobileDeviceXl,
  } = useResponsive();

  const hasMissedTask = useMemo(
    () => task?.taskStatus === TASK_STATUS.Missed,
    [task],
  );

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (hasMissedTask || !task?.canCurrentUserUpdateTask || !task?.taskNumber)
      return;
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const taskStatusObj = useMemo(
    () => statusDropdownData.find((s) => s.id === task?.taskStatus),
    [task],
  );

  const taskStart =
    task?.startTime ||
    (task?.recurringTask && task?.startDate
      ? moment(task?.startDate).tz(tz)
      : null);

  const activationDateTime = useMemo(() => {
    if (task) {
      return updateInstanceActivationTime(task, Hotel, tz)?.format(
        'MMM DD YYYY [at] hh:mm A',
      );
    }
  }, [task]);

  const taskDue = task?.dueTime || (task?.recurringTask ? task?.dueDate : null);

  const selectedCategory = useMemo(() => {
    if (task?.ServiceType?.id) {
      return COLORS_LIST?.find(
        (item) => item?.color === task?.ServiceType?.color,
      );
    }
    return null;
  }, [task]);

  const selectedLocationObj = useMemo(() => {
    return locationsHash?.[task?.Location?.id || task?.Asset?.Location?.id];
  }, [locationsHash, task]);

  const getParentAsset = useCallback(
    (assetId) => {
      return assetsList.find((item) => item.id === assetId);
    },
    [task?.Asset],
  );

  const assetExists = useMemo(() => {
    return authAssetsList?.find((item) => {
      if (item.id === task?.Asset?.id) return item;

      const subAssetObj = item.SubAssets?.find((subAssetItem) => {
        return subAssetItem?.id === task?.Asset?.id;
      });

      return subAssetObj;
    });
  }, [task?.Asset, authAssetsList]);

  const parentLocation = useMemo(() => {
    const parentNode =
      authLocationsHash?.[
        task?.Location?.ParentId || task?.Asset?.Location?.ParentId
      ];

    return parentNode?.authorized ? parentNode : null;
  }, [authLocationsHash, task]);

  const parentAsset = task?.Asset?.ParentId
    ? getParentAsset(task?.Asset?.ParentId)
    : null;

  const secondsToDaysHoursMinutesSeconds = (sec) => {
    let days: any = '',
      hours: any = '',
      minutes: any = '',
      seconds: any = '';
    days = Math.floor(sec / (60 * 60 * 24));
    hours = Math.floor(sec / (60 * 60)) % 24;
    minutes = Math.floor(sec / 60) % 60;
    seconds = sec % 60;
    return { days, hours, minutes, seconds };
  };

  const getContent = ({ label, content }) => {
    return (
      <Stack gap="4px">
        <Typography
          color="rgba(97, 97, 97, 1)"
          fontSize="14px"
          fontWeight={700}
        >
          {label}
        </Typography>
        {content}
      </Stack>
    );
  };

  const renderCreatorAndAssignee = (data, isTeam = false) => {
    if (data) {
      return (
        <AvatarCnt>
          {isTeam ? (
            <TeamAvatar width={28} height={28} teamId={data?.id} />
          ) : (
            <AvatarPopover
              nameStyle={{
                fontWeight: 500,
                fontSize: '14px',
                color: '#000',
              }}
              data={data}
            />
          )}
          {isTeam && <TeamLabel>{getFullName(data, isTeam)}</TeamLabel>}
        </AvatarCnt>
      );
    }
  };

  const getFullName = (user, isTeam) => {
    return isTeam ? `${user.name}` : `${user?.firstName} ${user?.lastName}`;
  };

  const formatTaskDates = (date) => {
    const fullFormat = 'MMM DD YYYY [at] hh:mm A';
    const timeOnly = '[at] hh:mm A';
    let dayString = '';

    const isToday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime().toTz(tz).toTzString(tz, DATE_FORMAT);
    const isYesterday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime()
        .toTz(tz)
        .transform({ subtract: [1, 'day'] })
        .toTzString(tz, DATE_FORMAT);

    if (isToday) {
      dayString = 'Today';
    } else if (isYesterday) {
      dayString = 'Yesterday';
    }
    return dayString.length
      ? `${dayString} ${formatDate(date, timeOnly, '', tz)}`
      : formatDate(date, fullFormat, '', tz);
  };

  const stoppedTime = () => {
    if (includes(['paused', 'finished'], task?.taskTime?.timerState)) {
      const { days, hours, minutes, seconds } =
        secondsToDaysHoursMinutesSeconds(task?.taskTime?.secondsSpent);
      return getTimeString(days, hours, minutes, seconds, true);
    }
  };

  const isDaily = useMemo(() => {
    if (task) {
      if (
        task?.requestThisTask === 'Weekly' &&
        task?.intervalWeek?.length === 7 &&
        task?.recurringByEvery === 'Week1'
      ) {
        return 'Daily';
      }
    }
  }, [task]);

  return (
    <DetailTabWrapper isScroll={options.inline}>
      {task?.isArchive === 'Yes' && (
        <RowCnt>
          <Alert sx={{ width: '100% !important' }} severity="error">
            This task has been archived
          </Alert>
        </RowCnt>
      )}

      {task?.AssociatedLog && (
        <Stack width="100%" gap="10px">
          <Stack
            bgcolor={'rgba(225, 245, 254, 1)'}
            gap="6px"
            alignItems={'center'}
            direction="row"
            borderRadius={'6px'}
            p="6px 8px"
            width="100%"
          >
            <InfoIcon
              sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '20px' }}
            />
            <Stack
              flexWrap={'wrap'}
              gap="4px"
              alignItems={'center'}
              direction="row"
              flex={1}
              sx={{
                overflowWrap: 'anywhere',
              }}
            >
              <Typography fontSize={'14px'} fontWeight={'600'} color="#212121">
                Created from submission:
              </Typography>
              <Stack
                flexWrap={'wrap'}
                direction={'row'}
                gap="4px"
                alignItems={'center'}
              >
                <Typography fontSize={'14px'} fontWeight={'700'} color="#000">
                  {task?.AssociatedLog?.name} on{' '}
                </Typography>
                <Typography fontSize={'14px'} fontWeight={'700'} color="#000">
                  {`${getDateInTz(
                    task?.AssociatedLog?.createdAt as string,
                    'MMM DD YYYY',
                  )}`}
                </Typography>
                <Stack
                  height={'5px'}
                  width={'5px'}
                  borderRadius={'5px'}
                  bgcolor={'#000'}
                />
                <Typography
                  component={'span'}
                  fontSize={'14px'}
                  fontWeight={'700'}
                  color="#000"
                >
                  {`${getUtcToTzTime(
                    task?.AssociatedLog?.createdAt as string,
                  )}`}
                </Typography>
              </Stack>
            </Stack>
            <CustomButton
              variant="contained"
              sx={{
                bgcolor: '#fff',
                fontWeight: 700,
                color: 'rgba(104, 104, 254, 1)',
                border: '1px solid rgba(104, 104, 254, 1)',
                borderRadius: '6px',
                height: '28px',
                marginLeft: 'auto',
                '&:hover': {
                  bgcolor: 'rgba(104, 104, 254, 1)',
                  color: '#fff',
                },
              }}
              onClick={viewSubmissionReport}
            >
              View
            </CustomButton>
          </Stack>

          {task?.AssociatedLog && task?.ChecklistLogItem && (
            <CorrectiveAnswer answerItem={task?.ChecklistLogItem} />
          )}

          {ChecklistLogItem &&
            !task?.isManualCorrectiveTask &&
            task.Automation && (
              <Stack
                p="8px 12px"
                borderRadius="8px"
                bgcolor="rgba(235, 234, 255, 1)"
                direction="row"
                alignItems="center"
                gap="6px"
              >
                <AutomationIconRounded
                  sx={{ color: 'rgba(78, 72, 250, 1)', fontSize: '16px' }}
                />
                <Typography color="#000" fontSize="13px" fontWeight={'500'}>
                  {automationText}
                </Typography>
              </Stack>
            )}

          <Divider sx={{ mt: '6px' }} />
        </Stack>
      )}

      {hasMissedTask && (
        <Stack
          gap="10px"
          direction={'row'}
          borderRadius={'8px'}
          p="8px 14px"
          alignItems={'center'}
          bgcolor={'#FAE6E6'}
        >
          <MissedIcon sx={{ fontSize: '18px' }} />
          <Typography color="#D32F2F" fontSize={'14px'} fontWeight={'500'}>
            The due time for this task has passed, and completion is no longer
            possible.
          </Typography>
        </Stack>
      )}
      {getContent({
        label: 'Status',
        content: (
          <CustomDropdown
            clickaway={true}
            onClickawayCallback={() => setAnchorEl(null)}
            buttonRenderer={
              <Stack
                direction="row"
                gap="2px"
                sx={{
                  pointerEvents: task?.taskNumber ? 'unset' : 'none',
                }}
              >
                <Stack
                  onClick={handleDropDownOpen}
                  gap="12px"
                  alignItems={'center'}
                  p="10px 10px 10px 14px"
                  fontSize={'14px'}
                  fontWeight={'700'}
                  bgcolor={
                    task?.taskNumber &&
                    !hasMissedTask &&
                    task?.canCurrentUserUpdateTask
                      ? taskStatusObj?.backgroundColor
                      : '#d3d3d36b'
                  }
                  color={
                    task?.taskNumber &&
                    !hasMissedTask &&
                    task?.canCurrentUserUpdateTask
                      ? taskStatusObj?.color
                      : 'darkGray'
                  }
                  direction={'row'}
                  sx={{
                    borderRadius: '8px 0px 0px 8px',
                    cursor:
                      hasMissedTask ||
                      !task?.canCurrentUserUpdateTask ||
                      !task?.taskNumber
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  flex={1}
                >
                  {taskStatusObj?.startIcon}
                  {taskStatusObj?.label}
                </Stack>
                <Stack
                  bgcolor={
                    task?.taskNumber &&
                    !hasMissedTask &&
                    task?.canCurrentUserUpdateTask
                      ? task?.taskStatus === TASK_STATUS.Completed
                        ? '#f5f5f5'
                        : taskStatusObj?.backgroundColor
                      : '#d3d3d36b'
                  }
                  color={
                    task?.taskNumber &&
                    !hasMissedTask &&
                    task?.canCurrentUserUpdateTask
                      ? task?.taskStatus === TASK_STATUS.Completed
                        ? '#bbb'
                        : taskStatusObj?.color
                      : 'darkGray'
                  }
                  p="10px 14px"
                  sx={{
                    borderRadius: '0px 8px 8px 0px',
                    cursor:
                      task?.taskStatus === TASK_STATUS.Completed ||
                      hasMissedTask ||
                      !task?.canCurrentUserUpdateTask ||
                      !task?.taskNumber
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  onClick={
                    task?.taskStatus === TASK_STATUS.Completed ||
                    hasMissedTask ||
                    !task?.canCurrentUserUpdateTask ||
                    !task?.taskNumber
                      ? null
                      : handleNextTaskStatusChangeClick
                  }
                >
                  <ChevronRightRoundedIcon />
                </Stack>
              </Stack>
            }
            popperProps={{
              sx: {
                '& .MuiPaper-root': {
                  width: '638px !important',
                  padding: '16px',
                },
              },
              open: Boolean(anchorEl),
              anchorEl: anchorEl,
              content: (
                <Stack gap="4px">
                  {statusDropdownData
                    .filter((s) => s.id !== 'Missed')
                    .map((taskStatus) => (
                      <Stack
                        color={taskStatus.color}
                        onClick={() => {
                          handleTaskStatusChangeClick(taskStatus);
                          setAnchorEl(null);
                        }}
                        gap="12px"
                        alignItems={'center'}
                        direction={'row'}
                        fontSize={'14px'}
                        fontWeight={'500'}
                        padding={'6px 0px'}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#F5F5F5',
                            borderRadius: '8px',
                          },
                        }}
                      >
                        {taskStatus.startIcon}
                        <span style={{ color: '#000' }}>
                          {taskStatus.label}
                        </span>
                      </Stack>
                    ))}
                </Stack>
              ),
            }}
          />
        ),
      })}
      {task?.description && (
        <>
          {getContent({
            label: 'Description',
            content: <ValueText>{task?.description}</ValueText>,
          })}
        </>
      )}
      {!!task?.attachment?.length && (
        <>
          {getContent({
            label: 'Attachments',
            content: (
              <Stack
                maxWidth={
                  isMobileDeviceXs
                    ? '320px'
                    : isMobileDeviceMd
                    ? '400px'
                    : isMobileDeviceLg
                    ? '600px'
                    : isMobileDeviceXl
                    ? '700px'
                    : '800px'
                }
              >
                <RenderAttachments attachments={task?.attachment} />
              </Stack>
            ),
          })}
        </>
      )}
      {task?.ChecklistId && (
        <>
          {getContent({
            label: 'Template',
            content: (
              <Stack gap="8px">
                <Stack
                  p="10px 14px"
                  direction={'row'}
                  sx={{ borderRadius: '8px' }}
                  alignItems={'center'}
                  bgcolor={'#EBEAFF'}
                >
                  {task?.Checklist?.icon && (
                    <ChecklistIcon
                      width={36}
                      height={36}
                      icon={task?.Checklist?.icon}
                    />
                  )}
                  <Stack maxWidth={'75%'} ml="10px">
                    <Typography
                      sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre',
                      }}
                      fontSize={'16px'}
                      fontWeight={'700'}
                      color="#000"
                    >
                      {task?.Checklist?.name}
                    </Typography>
                    {currentTaskChecklistData && (
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'500'}
                        color="#616161"
                      >
                        {currentTaskChecklistData?.progress?.completedCount} of{' '}
                        {currentTaskChecklistData?.progress?.count} items
                      </Typography>
                    )}
                  </Stack>
                  {!hasMissedTask && (
                    <Stack ml="auto">
                      <CustomButton
                        onClick={handleStartChecklistClick}
                        sx={{ fontWeight: 700, borderRadius: '6px' }}
                        variant="contained"
                        disabled={
                          !task?.taskNumber || !task?.canCurrentUserUpdateTask
                        }
                      >
                        {currentTaskChecklistData ? 'View' : 'Start'}
                      </CustomButton>
                    </Stack>
                  )}
                </Stack>
                {hasMissedTask && (
                  <Stack direction={'row'} alignItems={'center'} gap="8px">
                    <InfoRoundedIcon sx={{ color: '#0288D1', fontSize: 16 }} />
                    <Typography
                      fontSize={'12px'}
                      fontWeight={'600'}
                      color="#424242"
                    >
                      Submission cannot be started as the due time of this task
                      has passed.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            ),
          })}
        </>
      )}
      {!task?.taskNumber && activationDateTime && (
        <Stack direction={'row'} alignItems={'center'} gap="8px">
          <InfoRoundedIcon sx={{ color: '#0288D1', fontSize: 16 }} />
          <Typography fontSize={'12px'} fontWeight={'600'} color="#424242">
            This task is not available yet. It can be started on{' '}
            <b>{activationDateTime}</b>.
          </Typography>
        </Stack>
      )}

      <Divider />

      {getContent({
        label: 'Created by',
        content: (
          <ByCnt>
            {renderCreatorAndAssignee(task?.Creator, false)}

            <Dot>•</Dot>
            <ValueText customcolor="#757575">
              {formatTaskDates(task?.createdAt)}
            </ValueText>
          </ByCnt>
        ),
      })}
      {task?.TaskAssignees?.length > 0 && (
        <>
          {getContent({
            label: 'Assigned to',
            content: (
              <AssigneesView
                type={task?.TaskAssignees?.length === 1 ? 'single' : 'avatars'}
                data={task?.TaskAssignees}
              />
            ),
          })}
        </>
      )}
      {task?.taskStatus === 'Completed' && (
        <>
          {getContent({
            label: 'Completed by',
            content: (
              <ByCnt>
                {renderCreatorAndAssignee(task?.Finisher, false)}
                <Dot>•</Dot>
                <ValueText customcolor="#757575">
                  {formatTaskDates(task?.endTime)}
                </ValueText>
              </ByCnt>
            ),
          })}
        </>
      )}

      <Divider />

      {taskStart && (
        <>
          {getContent({
            label: 'Start Date/Time',
            content: <ValueText>{formatTaskDates(taskStart)}</ValueText>,
          })}
        </>
      )}
      {taskDue && (
        <>
          {getContent({
            label: 'Due Date/Time',
            content: (
              <DateTimeOverDue>
                <ValueText>{formatTaskDates(taskDue)}</ValueText>
                {task?.isOverDue && !hasMissedTask && (
                  <OverDueCnt>
                    <AccessTimeIcon style={{ fontSize: 16, marginRight: 3 }} />
                    Overdue
                  </OverDueCnt>
                )}
                {hasMissedTask && (
                  <OverDueCnt>
                    <MissedIcon
                      style={{ fontSize: 16, marginRight: 3, fill: 'none' }}
                    />
                    Missed
                  </OverDueCnt>
                )}
              </DateTimeOverDue>
            ),
          })}
        </>
      )}
      {task?.recurringTask && (
        <>
          {getContent({
            label: 'Schedule',
            content: (
              <ValueCnt>
                <img
                  src={RecurringIcon}
                  style={{ marginRight: 10 }}
                  alt="Recurring task"
                  title="Recurring"
                  width="16"
                  height="16"
                />

                <ValueText>
                  {'Recurs '}
                  {isDaily || task?.recPatternText.replace(' until never', '')}
                  {task?.parentTask?.startDate &&
                    ` starting from ${moment(
                      task?.parentTask?.startDate,
                    ).format('MMM D, YYYY')}`}
                </ValueText>
              </ValueCnt>
            ),
          })}
        </>
      )}

      {(taskStart || taskDue || task?.recurringTask) && <Divider />}

      {getContent({
        label: 'Priority',
        content: (
          <PriorityContent>
            <div
              className={`icon-priority-${task?.priority?.toLowerCase()}`}
              style={{ marginRight: 10 }}
            />
            <ValueText>{task?.priority}</ValueText>
          </PriorityContent>
        ),
      })}
      {task?.ServiceType && (
        <>
          {getContent({
            label: 'Category',
            content: (
              <CategoryCnt>
                <CategoryChip
                  bg={task?.ServiceType?.color}
                  color={selectedCategory?.textColor}
                  shouldDisplayFlex={true}
                  bordercolor={selectedCategory?.borderColor}
                >
                  {task?.ServiceType?.type?.charAt(0)?.toUpperCase() +
                    task?.ServiceType?.type?.slice(1)}
                </CategoryChip>
              </CategoryCnt>
            ),
          })}
        </>
      )}
      {(task?.Location || task?.Asset?.Location) && (
        <>
          {getContent({
            label: 'Location',
            content: (
              <AssetsLocationParentCnt>
                <AssetsLocationCnt>
                  <ValueText className="name">
                    {task?.Location?.name || task?.Asset?.Location?.name}
                  </ValueText>
                  {selectedLocationObj && globalThis?.platform !== 'mobile' && (
                    <HasPermission
                      permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}
                    >
                      <BtnLink variant="text" onClick={handleViewLocationClick}>
                        View Details
                      </BtnLink>
                    </HasPermission>
                  )}
                </AssetsLocationCnt>
                {(task?.Location?.ParentId ||
                  task?.Asset?.Location?.ParentId) &&
                parentLocation ? (
                  <div className="parentName">
                    Parent: {parentLocation.name}
                  </div>
                ) : null}
              </AssetsLocationParentCnt>
            ),
          })}
        </>
      )}
      {(task?.Location?.address || task?.Asset?.Location?.address) && (
        <>
          {getContent({
            label: 'Address',
            content: (
              <ValueText>
                {task?.Location?.address || task?.Asset?.Location?.address}
              </ValueText>
            ),
          })}
        </>
      )}
      {task?.Asset && (
        <>
          {getContent({
            label: 'Asset',
            content: (
              <AssetsLocationParentCnt>
                <AssetsLocationCnt>
                  <ValueText className="name">{task?.Asset?.name}</ValueText>
                  {assetExists && globalThis?.platform !== 'mobile' && (
                    <HasPermission
                      permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}
                    >
                      <BtnLink variant="text" onClick={handleViewAssetClick}>
                        View Details
                      </BtnLink>
                    </HasPermission>
                  )}
                </AssetsLocationCnt>

                {task?.Asset?.ParentId && parentAsset ? (
                  <div className="parentName">Parent: {parentAsset.name}</div>
                ) : null}
              </AssetsLocationParentCnt>
            ),
          })}
        </>
      )}

      <Divider />

      {!!task?.TaskTimeLogs?.length && (
        <>
          {getContent({
            label: 'Time Tracked',
            content: <ValueText> {stoppedTime() || initialTime} </ValueText>,
          })}
        </>
      )}

      {task?.taskNumber !== null && (
        <>
          {getContent({
            label: 'Task ID',
            content: (
              <ValueText>
                {'# '}
                {task?.taskNumber}
              </ValueText>
            ),
          })}
        </>
      )}

      <Divider />

      {globalThis.platform === 'mobile' ? null : (
        <TrackCost
          task={task}
          filters={filters}
          onChange={taskDetailCallBack}
        />
      )}
    </DetailTabWrapper>
  );
};

export default TaskDetailTab;
