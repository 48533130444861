import config from 'config/index';

const useVerisoul = () => {
  const initVerisoul = () => {
    const script = document.createElement('script');
    script.async = true;
    const env = config.REACT_APP_ENVIRONMENT;

    console.log('--- env:', env);
    const verisoulEnv = env === 'production' ? 'prod' : 'sandbox';

    const verisoulProjectId =
      env === 'production'
        ? '911693b4-44c6-4246-a5fe-1b82bdb6e769'
        : '72908c59-a465-4231-944d-dd234d92c4b7';

    script.src = `https://js.verisoul.ai/${verisoulEnv}/bundle.js`;

    script.setAttribute('verisoul-project-id', verisoulProjectId);

    document.body.appendChild(script);
  };

  return { initVerisoul };
};

export default useVerisoul;
