import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getReports = async () => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + 'hotel/workspaces' + `/${hotelId}/reports`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getReport = async (reportId) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'hotel/workspaces' +
        `/${hotelId}/reports/` +
        reportId,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const updateReport = async (reportId, payload) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'hotel/workspaces' +
        `/${hotelId}/reports/` +
        reportId,
      payload,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getTaskStatusCounts = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-count-by-status`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getTasksList = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + 'task/workspaces' + `/${hotelId}/tasks-list`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (error) {
    if (error) {
      return error;
    } else {
      throw new Error('Failed to fetch data');
    }
  }
};
export const getTaskCountsByAssignee = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-count-by-assignees`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getCompletedTaskByEmployee = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-count-completed-by-employees`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getTasksCompletedByTeamMember = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-count-completed-by-team-members`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getTaskCountsByCategories = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/tasks-count-by-categories`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getTemplateStatusCounts = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/submissions-count-by-status`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getSubmissionList = async (
  data,
  isDetailed = true,
): Promise<{ data: any; totalCount: number }> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/template-submissions${!isDetailed ? '?detail=false' : ''}`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return {
      data: response?.data?.data,
      totalCount: response?.data?.meta?.totalCount,
    };
  } catch (error) {
    if (error) {
      return error as any;
    } else {
      throw new Error('Failed to fetch data');
    }
  }
};
export const getComplianceReport = async (payload) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/daily-compliance-report`,
      payload,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
