import { createSvgIcon } from '@mui/material';

const ConnectorIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1 1C1 1 1.00005 18.5 1.00003 31C1.00001 39.5 12 37 12 37"
        stroke="#E0E0E0"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </>,
    'ConnectorIcon',
  );
  return (
    <IconCmp
      viewBox="0 0 13 39"
      width="13"
      height="39"
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default ConnectorIcon;
