import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import {
  callApi,
  getItem,
  showMessageNotification,
  getHotelId,
} from 'utils/globalFunction';
import appConstants from 'utils/appConstants';
import axios from 'axios';
import { NotificationsTriggerPayload } from 'components/TemplateDashboard/TemplateSettingsModal/types';
/*******************
 @Purpose : Used to get checklist listing
 @Parameter :
 @Author : INIC
 ******************/
export const getChecklistListing = async (payload): Promise<any> => {
  try {
    const checklistList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklists,
      payload,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistList !== null) {
      return checklistList.data ? checklistList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getPublicChecklists = async (payload): Promise<any> => {
  try {
    const checklistList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.publicChecklists,
      payload,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistList !== null) {
      return checklistList.data ? checklistList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getPublicChecklistById = async (id): Promise<any> => {
  try {
    const checklistList = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.publicChecklists}/${id}`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistList !== null) {
      return checklistList.data ? checklistList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getSuggestedChecklists = async (payload = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${hotelId}/checklist-suggestions`,
      payload,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistCategories = async (): Promise<any> => {
  try {
    const checklistCategories = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklistCategories,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistCategories !== null) {
      return checklistCategories.data ? checklistCategories.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistTypes = async (): Promise<any> => {
  try {
    const checklistTypes = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklistTypes,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistTypes !== null) {
      return checklistTypes.data ? checklistTypes.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistIndustries = async (): Promise<any> => {
  try {
    const checklistIndustries = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklistIndustries,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistIndustries !== null) {
      return checklistIndustries.data ? checklistIndustries.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createChecklist = async (postData: any): Promise<any> => {
  try {
    const createdChecklist = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklists,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (createdChecklist !== null) {
      return createdChecklist.data ? createdChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateChecklist = async (
  checklistId: any,
  showNotification: boolean,
  putData: any,
): Promise<any> => {
  try {
    const updatedChecklist = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklists + `/${checklistId}`,
      putData,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      showNotification
        ? `${appConstants.checklist.singular.base} updated successfully!`
        : '',
      true,
      '',
      false,
    );

    if (updatedChecklist !== null) {
      return updatedChecklist.data ? updatedChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const duplicateChecklist = async (payload: {
  checklistId: string;
  saveToLibrary: boolean;
  disableToast: boolean;
}): Promise<any> => {
  try {
    const duplicatedChecklist = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${payload.checklistId}/${
          payload.saveToLibrary ? 'save-to-library' : 'duplicate'
        }`,
      null,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `${
        !payload.disableToast
          ? payload.saveToLibrary
            ? 'Saved to My Templates'
            : `${appConstants.checklist.singular.base} duplicated successfully!`
          : ''
      }`,
      true,
    );

    if (duplicatedChecklist !== null) {
      return duplicatedChecklist.data ? duplicatedChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const publishChecklist = async (checklistId: any): Promise<any> => {
  try {
    const publishedChecklist = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/publish`,
      null,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `${appConstants.checklist.singular.base} published successfully!`,
      true,
    );

    if (publishedChecklist !== null) {
      return publishedChecklist.data ? publishedChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const archiveChecklist = async (checklistId: any): Promise<any> => {
  try {
    const archivedChecklist = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklists + `/${checklistId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `${appConstants.checklist.singular.base} archived successfully!`,
      true,
    );

    if (archivedChecklist !== null) {
      return archivedChecklist.data ? archivedChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklist = async (
  checklistId: any,
  taskId = '',
): Promise<any> => {
  try {
    const url = `${config.REACT_APP_BASE_URL}${
      API_ROUTES.checklists
    }/${checklistId}${taskId ? `?taskId=${taskId}` : ''}`;
    const createdChecklist = await callApi(
      url,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (createdChecklist !== null) {
      return createdChecklist.data ? createdChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getPublicChecklist = async (checklistId: string): Promise<any> => {
  try {
    const createdChecklist = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.publicChecklists}/${checklistId}`,
      null,
      'get',
      false,
      false,
      '',
      '',
      false,
    );

    if (createdChecklist !== null) {
      return createdChecklist.data ? createdChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getTemplatesGroup = async (groupId: string): Promise<any> => {
  try {
    const group = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.templateGroupById(groupId)}`,
      null,
      'get',
      false,
      false,
      '',
      '',
      false,
    );

    if (group !== null) {
      return group.data ? group.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createChecklistItem = async (
  checklistId: any,
  postData: any,
): Promise<any> => {
  try {
    const createdChecklistItem = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/items`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (createdChecklistItem !== null) {
      return createdChecklistItem.data ? createdChecklistItem.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistItems = async (checklistId: any): Promise<any> => {
  try {
    const checklistItems = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/items`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistItems !== null) {
      return checklistItems.data ? checklistItems.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getSubmissionsLogs = async (
  checklistId,
  hotelId,
  payload,
): Promise<any> => {
  try {
    const { postData, queryString = '' } = payload;
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/checklists/${checklistId}/logs${queryString}`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistLogs = async ({
  checklistId,
  hotelId,
  postData,
  detailed = true,
  filtered = false,
  trimmed = false,
}: any): Promise<any> => {
  try {
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${
        API_ROUTES.hotels
      }/${hotelId}/checklists/${checklistId}/logs${
        detailed
          ? `${filtered ? '?items=filtered' : ''}`
          : `?detail=false${filtered ? '&items=filtered' : ''}`
      }${trimmed ? '&trimmed=true' : ''}`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getAllChecklistLogs = async (
  postData: any = {},
  isFlagged = false,
  isDetailed = true, // can be used where we need optomised response
  trigger = false,
  isScoring = false,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${
        API_ROUTES.hotels
      }/${hotelId}/checklists/logs${isFlagged ? '?flagged=true' : ''}${
        trigger ? '?trigger=true' : ''
      }${isScoring ? '?isScoring=true' : ''}`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getAllChecklistLogsAttachments = async (
  postData: any = {},
  isFlagged = false,
  isDetailed = true, // can be used where we need optomised response
  trigger = false,
  isScoring = false,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${
        API_ROUTES.hotels
      }/${hotelId}/checklists/logs/attachments${
        isFlagged ? '?flagged=true' : ''
      }${trigger ? '?trigger=true' : ''}${isScoring ? '?isScoring=true' : ''}`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteChecklistLogs = async ({
  checklistId,
  hotelId,
  postData,
}): Promise<any> => {
  try {
    const deletedLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/checklists/${checklistId}/delete-logs`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (deletedLogs !== null) {
      return deletedLogs.data ? deletedLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getChecklistLog = async ({
  logId,
  hotelId,
  viewReport = false,
  filtered = false,
  sectioned = false,
  comments = false,
  approval = false,
  defaultLocation = false,
}): Promise<any> => {
  try {
    const checklistLog = await callApi(
      `${config.REACT_APP_BASE_URL}${
        API_ROUTES.hotels
      }/${hotelId}/logs/${logId}?viewReport=${viewReport}&items=${
        filtered ? 'filtered' : 'all'
      }&sectioned=${sectioned}&comments=${comments}&approvals=${approval}&defaultLocation=${defaultLocation}`,
      null,
      'get',
      false,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLog !== null) {
      return checklistLog.data ? checklistLog.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const downloadChecklistLogsExcel = async ({
  checklistId,
  hotelId,
  postData,
}): Promise<any> => {
  try {
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/checklists/${checklistId}/logs-excel`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const submitChecklistLog = async ({
  logId,
  hotelId,
  customHeaders = {},
}): Promise<any> => {
  try {
    const checklistLog = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/logs/${logId}/submit`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `${appConstants.checklist.singular.base} submission completed successfully!`,
      true,
      '',
      true,
      customHeaders,
    );

    if (checklistLog !== null) {
      return checklistLog.data ? checklistLog.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const submitPublicTemplateLog = async ({
  logId,
  hotelId,
  customHeaders = {},
  templateId,
}): Promise<any> => {
  try {
    const checklistLog = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/checklists/${templateId}/public-logs/${logId}/submit`,
      null,
      'put',
      false,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `${appConstants.checklist.singular.base} submission completed successfully!`,
      true,
      '',
      true,
      customHeaders,
    );

    if (checklistLog !== null) {
      return checklistLog.data ? checklistLog.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createChecklistLog = async ({
  checklistId,
  hotelId,
  postData = {},
}): Promise<any> => {
  try {
    const checklistLog = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.hotels}/${hotelId}/checklists/${checklistId}/start?sectioned=false`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLog !== null) {
      return checklistLog.data ? checklistLog.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const modifyChecklistItem = async (
  checklistId: any,
  checklistItemId: any,
  putData: any,
): Promise<any> => {
  try {
    const updatedChecklistItem = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/items/${checklistItemId}`,
      putData,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (updatedChecklistItem !== null) {
      return updatedChecklistItem.data ? updatedChecklistItem.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteChecklistItem = async (
  checklistId: any,
  checklistItemId: any,
): Promise<any> => {
  try {
    const deletedChecklistItem = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/items/${checklistItemId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (deletedChecklistItem !== null) {
      return deletedChecklistItem.data ? deletedChecklistItem.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const toggleChecklistPublishState = async (
  checklistId: any,
  successMessage = '',
): Promise<any> => {
  try {
    const checklist = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${checklistId}/toggle-publish`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      successMessage,
      true,
    );

    return checklist?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const getTemplateActivityLog = async (
  templateId: string,
  dateFilters: {
    fromDate?: string;
    toDate?: string;
  } = {},
  page,
  perPage,
): Promise<any> => {
  let url =
    config.REACT_APP_BASE_URL +
    API_ROUTES.checklists +
    `/${templateId}/activity`;

  if (page && perPage) {
    url = `${url}?page=${page}&perPage=${perPage}`;
  }
  try {
    const activity = await callApi(
      url,
      dateFilters,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const downloadTemplateLogPdf = async ({
  templateId,
  workspaceId,
  postData,
}: {
  templateId: string;
  workspaceId: string;
  postData: {
    logIds?: string[];
    separateFiles?: boolean;
  };
}): Promise<any> => {
  try {
    const headers = {
      platform: 'webapp',
      'workspace-id': workspaceId,
      Authorization: getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    };
    const response = await axios(
      `${config.REACT_APP_BASE_URL}task/workspaces/${workspaceId}/checklists/${templateId}/logs-pdf`,
      {
        headers,
        method: 'POST',
        data: postData,
        responseType: 'blob', //Force to receive data in a Blob Format
      },
    );
    return response?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const getTemplateEmailTriggers = async (
  templateId: string,
): Promise<any> => {
  try {
    const activity = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${templateId}/notifications`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const addTemplateEmailTrigger = async ({
  templateId,
  payload,
}: {
  templateId: string;
  payload: NotificationsTriggerPayload;
}): Promise<any> => {
  try {
    const activity = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${templateId}/notifications`,
      { ...payload },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const editTemplateEmailTrigger = async ({
  templateId,
  payload,
  triggerId,
}: {
  templateId: string;
  payload: NotificationsTriggerPayload;
  triggerId: string;
}): Promise<any> => {
  try {
    const activity = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${templateId}/notifications/${triggerId}`,
      { ...payload },
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const deleteTemplateEmailTrigger = async ({
  templateId,
  triggerId,
}: {
  templateId: string;
  triggerId: string;
}): Promise<any> => {
  try {
    const activity = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.checklists +
        `/${templateId}/notifications/${triggerId}`,
      {},
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const patchChecklist = async ({
  checklistId,
  showNotification,
  data,
}: {
  checklistId: string;
  showNotification?: boolean;
  data: any;
}): Promise<any> => {
  try {
    const updatedChecklist = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklists + `/${checklistId}`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      showNotification
        ? `${appConstants.checklist.singular.base} updated successfully!`
        : '',
      true,
    );

    if (updatedChecklist !== null) {
      return updatedChecklist.data ? updatedChecklist.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getTemplateSubmissionCount = async ({
  workspaceId,
  templateId,
}: {
  workspaceId: string;
  templateId: string;
}): Promise<any> => {
  try {
    const activity = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${workspaceId}/checklists/${templateId}/logs-count`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return activity?.data || null;
  } catch (error: any) {
    return null;
  }
};

export const getInProgressPublicLog = async ({
  templateId,
  logId,
}: {
  templateId: string;
  logId?: string;
}): Promise<any> => {
  try {
    const headers = {
      platform: 'webapp',
      anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID),
    };
    const response = await axios(
      `${
        config.REACT_APP_BASE_URL
      }task/checklists/${templateId}/in-progress-public-log?${
        logId ? `logId=${logId}` : ''
      }`,
      {
        headers,
        method: 'GET',
      },
    );
    return response?.data || null;
  } catch (error: any) {
    // showMessageNotification(
    //   error?.response?.data?.error?.message ?? 'Something went wrong',
    //   'error',
    // );
    return null;
  }
};

export const startPublicTemplateLog = async ({
  templateId,
  data = {},
}: {
  templateId: string;
  data: {
    submitterName?: string;
    submitterEmail?: string;
  };
}): Promise<any> => {
  try {
    const headers = {
      platform: 'webapp',
      anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID),
    };
    const response = await axios(
      `${config.REACT_APP_BASE_URL}task/checklists/${templateId}/start-public-log`,
      {
        headers,
        method: 'POST',
        data: data,
      },
    );
    return response?.data || null;
  } catch (error: any) {
    showMessageNotification(error?.response?.data?.message);
    return {
      data: null,
      errorCode: error?.response?.status,
    };
  }
};
//Get Folders
export const getTemplateFolders = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${hotelId}/folders`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
//get Single Folder
export const getSingleFolderCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${hotelId}/folders/` + id,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
//Get Folders
export const updateFolderCall = async (data, id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${hotelId}/folders/${id}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

//Get Folders
export const addFolderCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${hotelId}/folders`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const addTemplateToFolderCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/update-folder`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const moveTemplateOutOfFolderCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/unset-folder`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const archiveFolderCall = async (id, data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/folders/${id}/archive`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const unArchiveFolderCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/folders/${id}/unarchive`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const unArchiveTemplateCall = async (id): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/checklists/${id}/unarchive`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteFolderCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const templateFoldersResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${hotelId}/folders/${id}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (templateFoldersResponse !== null) {
      return templateFoldersResponse.data ? templateFoldersResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getSpreadsheetSubmissions = async ({
  templateId,
  hotelId,
  postData = {},
}: {
  templateId: string;
  hotelId?: string;
  postData?: any;
}): Promise<any> => {
  const workspaceId = hotelId ?? getHotelId();
  try {
    const checklistLogs = await callApi(
      `${config.REACT_APP_BASE_URL}task/workspaces/${workspaceId}/checklists/${templateId}/sheet-view-data`,
      postData,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistLogs !== null) {
      return checklistLogs.data ? checklistLogs.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const getTemplatesFoldersBySearch = async (
  searchText: string,
): Promise<any> => {
  const wsId = getHotelId() as string;
  try {
    const templatesList = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${wsId}/checklists/search?searchText=${searchText}`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return templatesList?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const saveChecklistState = async ({
  logId,
  data,
}: {
  logId: string;
  data: any;
}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const saveResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/hotels/${hotelId}/checklist-logs/${logId}/items`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (saveResponse !== null) {
      return saveResponse.data ? saveResponse.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
