// React
import { useState } from 'react';

// Custom components
import EmailInput from '../EmailInput';

// Icons
import EditIcon2 from 'components/Icons/editIcon2';
import DeleteIcon from 'components/Icons/deleteIcon';

// Styled
import { LineWrapper, Email, EditAndDeleteWrapper, Divider } from './styled';

// Types
import {
  EmailTriggerType,
  NotificationsTriggerPayload,
} from 'components/TemplateDashboard/TemplateSettingsModal/types';

interface EmailWithEditPropTypes {
  data: any;
  editEmailTrigger: (
    email: NotificationsTriggerPayload,
    triggerId: string,
  ) => void;
  deleteEmailTrigger: (triggerId: string) => void;
}

const EmailWithEdit = ({
  data,
  editEmailTrigger,
  deleteEmailTrigger,
}: EmailWithEditPropTypes) => {
  const [isEdit, setIsEdit] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(data.email);

  const handleSaveEmail = (email: string) => {
    setCurrentEmail(email);
    setIsEdit(false);
    editEmailTrigger({ email: email, type: 'EMAIL' }, data.id);
  };

  const handleCancelEdit = () => {
    setCurrentEmail(data.email);
    setIsEdit(false);
  };

  return (
    <>
      {isEdit ? (
        <LineWrapper>
          <EmailInput
            defaultEmail={currentEmail}
            onClickAdd={handleSaveEmail}
            onClickCancel={handleCancelEdit}
          />
        </LineWrapper>
      ) : (
        <LineWrapper>
          <Email>{currentEmail}</Email>
          <EditAndDeleteWrapper>
            <EditIcon2
              onClick={() => setIsEdit(true)}
              style={{ stroke: 'rgba(158, 158, 158, 1)', fontSize: '16px' }}
              sx={{
                fill: 'none',
                '& path': {
                  stroke: 'rgba(158, 158, 158, 1) !important',
                },
              }}
            />
            <DeleteIcon
              onClick={() => deleteEmailTrigger(data.id)}
              sx={{
                color: 'rgba(211, 47, 47, 1)',
                fontSize: '12px',
                '& path': {
                  strokeWidth: '2px',
                },
              }}
            />
          </EditAndDeleteWrapper>
        </LineWrapper>
      )}
      <Divider />
    </>
  );
};

export default EmailWithEdit;
