import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import CheckCircleIcon from 'components/Icons/checkCircleIcon';
import TaskIcon from 'components/Icons/taskIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { HeadingCnt, MainContainer } from './style';

interface WorkflowOption {
  id: string;
  title: string;
  description: string;
  icon: React.ComponentType<any>;
  iconColor: string;
  iconBgColor: string;
  onClick: () => void;
}

const WorkflowOptionCard = ({
  title,
  description,
  icon: Icon,
  iconColor,
  iconBgColor,
  onClick,
}: Omit<WorkflowOption, 'id'>) => (
  <Grid item xs={12}>
    <Stack
      alignItems="center"
      direction="row"
      gap="20px"
      className="boxContainer"
      onClick={onClick}
      sx={{
        '& path': {
          stroke: Icon === TaskIcon ? iconColor : 'white',
          height: Icon === TaskIcon ? '32px' : '28px',
          width: Icon === TaskIcon ? '32px' : '28px',
          ...(Icon === CheckCircleIcon && { fill: iconColor }),
        },
        '&:hover': {
          '& .actionTitle': {
            textDecoration: 'underline',
          },
        },
        cursor: 'pointer',
      }}
    >
      <div className="iconCnt" style={{ backgroundColor: iconBgColor }}>
        <Icon />
      </div>
      <Stack direction="column" gap="2px">
        <Typography
          className="actionTitle"
          sx={{
            transition: 'text-decoration 0.2s',
          }}
        >
          {title}
        </Typography>
        <Typography className="statement">{description}</Typography>
      </Stack>
    </Stack>
  </Grid>
);

const DialogContent = ({ options }: { options: WorkflowOption[] }) => (
  <Box sx={{ width: '650px' }}>
    <MainContainer>
      <Grid container spacing={2}>
        {options?.map((option) => (
          <WorkflowOptionCard
            key={option.id}
            title={option.title}
            description={option.description}
            icon={option.icon}
            iconColor={option.iconColor}
            iconBgColor={option.iconBgColor}
            onClick={option.onClick}
          />
        ))}
      </Grid>
    </MainContainer>
  </Box>
);

const CreateNewWorkflowDialog = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dialogState = useSelector(selectors.getDialogState);
  const { open = false, data = {} } =
    dialogState?.[DIALOGS?.CREATE_NEW_WORKFLOW_DIALOG] || {};
  const { checklistId } = data;

  const handleClose = useCallback(() => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_NEW_WORKFLOW_DIALOG,
      }),
    );
  }, [dispatch]);

  const workflowOptions = useMemo(
    (): WorkflowOption[] => [
      {
        id: 'approval',
        title: 'Approval Workflow',
        description: 'Automate your approval process instantly.',
        icon: CheckCircleIcon,
        iconColor: 'rgba(104, 104, 254, 1)',
        iconBgColor: 'rgba(235, 234, 255, 1)',
        onClick: () => {
          handleClose();
          dispatch(
            setDialog({
              open: true,
              dialogId: DIALOGS?.WORKFLOW_BUILDER_DIALOG,
              data: {
                noURLUpdate: true,
              },
            }),
          );
        },
      },
      {
        id: 'task',
        title: 'Create a Corrective Task/Work Order',
        description:
          'Automatically generate a corrective task or work order based on step responses.',
        icon: TaskIcon,
        iconColor: 'rgba(102, 187, 106, 1)',
        iconBgColor: 'rgba(232, 245, 233, 1)',
        onClick: () => {
          handleClose();
          dispatch(
            setDialog({
              open: true,
              dialogId: DIALOGS?.TEMPLATE_WORKFLOW_BUILDER_DIALOG,
              data: {
                checklistId,
                noURLUpdate: true,
              },
            }),
          );
        },
      },
    ],
    [handleClose],
  );

  const dialogHeader = useMemo(
    () => (
      <CustomDialogHeader
        onClose={handleClose}
        other={{
          style: {
            alignItems: 'start',
          },
        }}
      >
        <HeadingCnt>
          <Typography className="title">Create a New Workflow</Typography>
          <Typography className="subTitle">
            Choose a workflow type to automate corrective task / work order
          </Typography>
        </HeadingCnt>
      </CustomDialogHeader>
    ),
    [handleClose],
  );

  return (
    <CustomDialog
      primaryBtnText="Create"
      hideActions={true}
      header={dialogHeader}
      dialogProps={{
        open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <DialogContent options={workflowOptions} />
    </CustomDialog>
  );
};

export default CreateNewWorkflowDialog;
