import { Box, Stack, Typography } from '@mui/material';
import {
  CreateActionBox,
  MainContainer,
  SelectedActionContainer,
} from './style';
import TaskIcon from 'components/Icons/taskIcon';
import ToolsIcon from 'components/Icons/sidebarIcons/toolsIcon';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useMemo } from 'react';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { v4 as uuid } from 'uuid';
import selectors from 'store/selectors';
import { getTaskTemplatesState } from 'store/selectors/taskTemplates';
import SingleUserIcon from 'components/Icons/singleUserIcon';
import EditIcon from 'components/Icons/editIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import Notifications from './notifications';
const Actions = (props) => {
  const { data, setData } = props;
  const dispatch = useDispatch();
  const lookupData = useSelector(selectors.getLookupData);

  const { users, roles, teams } = lookupData || {};

  const taskTemplatesData = useSelector(getTaskTemplatesState);
  const { list: taskTemplates } = taskTemplatesData || {};

  const { addNewAssignees } = useAssigneesUtils();

  const handleTemplateTrigger = (triggerType, taskTemplateId = undefined) => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.ATTACH_TASK_TEMPLATE_MODAL,
        open: true,
        data: {
          templateId: taskTemplateId,
          dialogState: {
            hideWhenTaskCreate: true,
          },
          onConfirm: (taskTemplateData) => {
            setData({
              ...data,
              actions: [
                {
                  type: triggerType,
                  id: uuid(),
                  data: {
                    when: 'onSubmit',
                    taskTemplateId: taskTemplateData?.taskTemplateId,
                  },
                },
              ],
            });
          },
        },
      }),
    );
  };

  const type = useMemo(() => {
    return data?.actions?.[0]?.type;
  }, [data?.actions]);

  const title = useMemo(() => {
    if (type === 'CREATE_TASK_FROM_TEMPLATE') return 'Create Corrective Task';
    if (type === 'CREATE_WORK_ORDER_FROM_TEMPLATE') return 'Create Work Order';

    return '';
  }, [type]);

  const actionIcon = useMemo(() => {
    if (type === 'CREATE_TASK_FROM_TEMPLATE')
      return (
        <Box className="correctiveIconCnt">
          <TaskIcon />
        </Box>
      );
    if (type === 'CREATE_WORK_ORDER_FROM_TEMPLATE')
      return (
        <Box className="workOrderIconCnt">
          <ToolsIcon />
        </Box>
      );

    return '';
  }, [type]);

  const taskTemplate = useMemo(() => {
    const id = data?.actions?.[0]?.data?.taskTemplateId;
    const taskTemplate = taskTemplates?.find((item) => item?.id === id);
    return taskTemplate?.templateTitle;
  }, [data?.actions, taskTemplates]);

  const assignees = useMemo(() => {
    const id = data?.actions?.[0]?.data?.taskTemplateId;
    const taskTemplate = taskTemplates?.find((item) => item?.id === id);
    return addNewAssignees(taskTemplate?.assignees ?? []) ?? [];
  }, [data?.actions, taskTemplates, users, roles, teams]);

  const editHandler = () => {
    handleTemplateTrigger(type, data?.actions?.[0]?.data?.taskTemplateId);
  };

  const deleteHandler = () => {
    setData({
      ...data,
      actions: [],
    });
  };

  return (
    <MainContainer>
      <Typography className="mainTitle">Action</Typography>

      {data?.actions?.length > 0 ? (
        <>
          <SelectedActionContainer>
            <Stack direction="row">
              <Box className="actionIconCnt">{actionIcon}</Box>
              <Stack direction="column">
                <Typography className="title">{title}</Typography>
                <Typography className="taskTemplate">{taskTemplate}</Typography>
                {assignees?.length > 0 && (
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    marginTop={'10px'}
                  >
                    <SingleUserIcon className="singleUserIcon" />
                    <Typography className="assigneeTitle">
                      Assignee(s):
                    </Typography>
                    {assignees?.length == 1 && (
                      <AssigneesView
                        type="single"
                        data={assignees}
                        singleAssigneeProps={{
                          width: 22,
                          height: 22,
                        }}
                      />
                    )}

                    {assignees?.length > 1 && (
                      <AssigneesView
                        type="avatars"
                        data={assignees}
                        assigneesProps={{
                          isMenuToShow: true,
                          width: 22,
                          height: 22,
                        }}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Stack direction="row" gap={1} position="absolute" right={20}>
              <Box
                className="actionCnt"
                onClick={editHandler}
                sx={{
                  '& path': {
                    stroke: 'rgba(104, 104, 254, 1)',
                    strokeWidth: 0.5,
                  },
                }}
              >
                <EditIcon />
              </Box>

              <Box
                className="actionCnt"
                onClick={deleteHandler}
                sx={{
                  fontSize: 14,
                  '& path': {
                    stroke: 'red',
                    strokeWidth: 1.3,
                  },
                }}
              >
                <DeleteIcon />
              </Box>
            </Stack>
          </SelectedActionContainer>
          <Notifications data={data} setData={setData} />
        </>
      ) : (
        <Stack direction="row" gap={2} width="100%">
          <CreateActionBox
            onClick={() => handleTemplateTrigger('CREATE_TASK_FROM_TEMPLATE')}
          >
            <Stack direction="row" gap={2} alignItems="center">
              <Box className="correctiveIconCnt">
                <TaskIcon />
              </Box>

              <Typography className="title">
                Create a Corrective Task
              </Typography>
            </Stack>
          </CreateActionBox>

          <CreateActionBox
            onClick={() =>
              handleTemplateTrigger('CREATE_WORK_ORDER_FROM_TEMPLATE')
            }
          >
            <Stack direction="row" gap={2} alignItems="center">
              <Box className="workOrderIconCnt">
                <ToolsIcon />
              </Box>
              <Typography className="title">Create a Work Order</Typography>
            </Stack>
          </CreateActionBox>
        </Stack>
      )}
    </MainContainer>
  );
};

export default Actions;
