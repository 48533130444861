import React, { useContext, useMemo } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationIcon from 'components/Icons/locationIcon';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import CustomButton from 'components/Button/CustomButton';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';

const View = (props) => {
  const { location, readonly, compact } = props;

  return (
    <CustomButton
      variant="outlined"
      buttonType="grayWhite"
      endIcon={
        <StyledEndIconCnt>
          <KeyboardArrowDownIcon style={{ fontSize: 24, color: '#00000099' }} />
        </StyledEndIconCnt>
      }
      startIcon={<LocationIcon />}
      sx={{
        '& .MuiButton-endIcon': {
          position: 'absolute',
          right: 13,
        },
      }}
      style={{
        width: '100%',
        padding: '6px 12px 6px 10px',
        height: 44,
        borderRadius: 10,
        justifyContent: 'start',
      }}
    >
      {location || location?.length > 0 ? (
        location.name
      ) : (
        <Typography
          fontWeight="500"
          fontSize="14px"
          lineHeight="19.12px"
          color="#424242"
        >
          {' '}
          Select Location
        </Typography>
      )}
    </CustomButton>
  );
};
const TaskLocationDropdown = () => {
  const { updateRequest, newRequest, setNewRequestObj, editing } =
    useContext(CreateRequestContext);
  const locationsMap = useLocationsMap();

  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newRequest;
    setNewRequestObj({ ...rest, LocationId: values, AssetId: null });
  };

  const handleUpdateLocationEdit = (values) => {
    const { ...rest } = newRequest;
    setNewRequestObj({
      ...rest,
      LocationId: values || null,
      AssetId: null,
    });
  };

  const selectedLocation = useMemo(() => {
    return locationsMap[newRequest?.LocationId] || null;
  }, [newRequest?.LocationId]);
  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <AssigneeDropdown
        buttonRendererProps={{
          style: { width: '100%' },
          disabled: true,
        }}
        buttonRenderer={<View compact={false} location={selectedLocation} />}
        handleChangeCallback={(data) => {
          const { currentSelected, selectedList } = data;
          editing
            ? handleUpdateLocationEdit(currentSelected)
            : handleUpdateLocationCreate(currentSelected);
        }}
        showTabs={true}
        listProps={{
          locations: {
            showInlineSelection: true,
            isSearchable: true,
            isMulti: false,
            hideActions: true,
            selected: newRequest?.LocationId ? [newRequest?.LocationId] : [],
          },
        }}
        displayOptions={{
          all: false,
          people: false,
          teams: false,
          sites: true,
          locations: true,
        }}
        popperProps={{ disablePortal: false }}
        allSelected={false}
        isMobileStylesApply={true}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
