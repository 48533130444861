import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  sx?: any;
}

const ScheduledWorkSummaryTableIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.75 8.25H19.25M2.75 13.75H19.25M7.15 2.75H14.85C16.3901 2.75 17.1602 2.75 17.7485 3.04973C18.2659 3.31338 18.6866 3.73408 18.9503 4.25153C19.25 4.83978 19.25 5.60986 19.25 7.15V14.85C19.25 16.3901 19.25 17.1602 18.9503 17.7485C18.6866 18.2659 18.2659 18.6866 17.7485 18.9503C17.1602 19.25 16.3901 19.25 14.85 19.25H7.15C5.60986 19.25 4.83978 19.25 4.25153 18.9503C3.73408 18.6866 3.31338 18.2659 3.04973 17.7485C2.75 17.1602 2.75 16.3901 2.75 14.85V7.15C2.75 5.60986 2.75 4.83978 3.04973 4.25153C3.31338 3.73408 3.73408 3.31338 4.25153 3.04973C4.83978 2.75 5.60986 2.75 7.15 2.75Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ScheduledWorkSummaryTableIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M18.6994 6.41634C19.2257 6.41634 19.4889 6.41634 19.6877 6.31297C19.8594 6.22367 20.0048 6.07419 20.0894 5.90005C20.1873 5.69848 20.1804 5.44891 20.1667 4.94975C20.1617 4.7675 20.1528 4.58556 20.138 4.40424C20.1084 4.04184 20.043 3.68138 19.8663 3.33454C19.6027 2.81709 19.182 2.39639 18.6645 2.13274C18.3177 1.95601 17.9572 1.89064 17.5948 1.86103C17.2515 1.83298 16.8346 1.83299 16.3514 1.83301L5.64738 1.83301C5.16418 1.83299 4.74729 1.83298 4.40394 1.86103C4.04154 1.89064 3.68109 1.95601 3.33424 2.13274C2.81679 2.39639 2.3961 2.81709 2.13245 3.33454C1.95572 3.68138 1.89035 4.04183 1.86074 4.40423C1.84592 4.58556 1.83709 4.7675 1.83208 4.94975C1.81837 5.44891 1.81151 5.69848 1.90938 5.90005C1.99393 6.07419 2.13936 6.22367 2.31111 6.31297C2.50991 6.41634 2.77307 6.41634 3.29938 6.41634L18.6994 6.41634Z"
        fill="#4E48FA"
      />
      <path
        d="M3.29938 8.24968C2.786 8.24968 2.52931 8.24968 2.33322 8.34959C2.16074 8.43747 2.02051 8.5777 1.93263 8.75018C1.83271 8.94627 1.83271 9.20296 1.83271 9.71634L1.83271 12.283C1.83271 12.7964 1.83271 13.0531 1.93263 13.2492C2.02051 13.4216 2.16074 13.5619 2.33322 13.6498C2.52931 13.7497 2.786 13.7497 3.29938 13.7497L18.6994 13.7497C19.2128 13.7497 19.4695 13.7497 19.6655 13.6498C19.838 13.5619 19.9783 13.4216 20.0661 13.2492C20.166 13.0531 20.166 12.7964 20.166 12.283V9.71634C20.166 9.20296 20.166 8.94627 20.0661 8.75018C19.9783 8.5777 19.838 8.43747 19.6655 8.34959C19.4695 8.24968 19.2128 8.24968 18.6994 8.24968H3.29938Z"
        fill="#4E48FA"
      />
      <path
        d="M20.1667 17.0496C20.1804 16.5504 20.1873 16.3009 20.0894 16.0993C20.0048 15.9252 19.8594 15.7757 19.6877 15.6864C19.4889 15.583 19.2257 15.583 18.6994 15.583L3.29938 15.583C2.77307 15.583 2.50991 15.583 2.31111 15.6864C2.13936 15.7757 1.99393 15.9252 1.90938 16.0993C1.81151 16.3009 1.81837 16.5504 1.83208 17.0496C1.83709 17.2319 1.84592 17.4138 1.86074 17.5951C1.89035 17.9575 1.95572 18.318 2.13245 18.6648C2.3961 19.1823 2.81679 19.603 3.33424 19.8666C3.68109 20.0433 4.04154 20.1087 4.40394 20.1383C4.74729 20.1664 5.16415 20.1664 5.64736 20.1663H16.3514C16.8346 20.1664 17.2515 20.1664 17.5948 20.1383C17.9572 20.1087 18.3177 20.0433 18.6645 19.8666C19.182 19.603 19.6027 19.1823 19.8663 18.6648C20.043 18.318 20.1084 17.9575 20.138 17.5951C20.1528 17.4138 20.1617 17.2319 20.1667 17.0496Z"
        fill="#4E48FA"
      />
    </>,
    'ScheduledWorkSummaryTableIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  );
};
export default ScheduledWorkSummaryTableIcon;
