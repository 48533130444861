import actionTypes from 'store/actionTypes';
import { forceUpdateStateData } from 'store/initialStateData';

export const forceUpdateReducer = (
  state: any = forceUpdateStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_FORCE_UPDATE: {
      return {
        ...state,
        ...(action.payload || {}),
      };
    }

    default:
      return state;
  }
};
