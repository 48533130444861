import { createSvgIcon } from '@mui/material';

const LocationIcon = ({
  primary = 'black',
  secondary = '#D9D9D9',
  style = {},
  filled = false,
  ...rest
}) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M11 11.917C12.5188 11.917 13.75 10.6858 13.75 9.16699C13.75 7.64821 12.5188 6.41699 11 6.41699C9.48122 6.41699 8.25 7.64821 8.25 9.16699C8.25 10.6858 9.48122 11.917 11 11.917Z"
        fill={primary}
        fill-opacity="0.54"
      />
      <path
        d="M11.0001 11.9163C12.5189 11.9163 13.7501 10.6851 13.7501 9.16634C13.7501 7.64756 12.5189 6.41634 11.0001 6.41634C9.4813 6.41634 8.25008 7.64756 8.25008 9.16634C8.25008 10.6851 9.4813 11.9163 11.0001 11.9163Z"
        stroke={primary}
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0001 20.1663C14.6667 16.4997 18.3334 13.2164 18.3334 9.16634C18.3334 5.11625 15.0502 1.83301 11.0001 1.83301C6.94999 1.83301 3.66675 5.11625 3.66675 9.16634C3.66675 13.2164 7.33341 16.4997 11.0001 20.1663Z"
        stroke={primary}
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LocationIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 8.33301C2.5 4.19087 5.85786 0.833008 10 0.833008C14.1421 0.833008 17.5 4.19087 17.5 8.33301C17.5 10.4382 16.5443 12.2832 15.2436 13.9722C14.1566 15.3835 12.7653 16.7628 11.3755 18.1405L11.3746 18.1415C11.1121 18.4017 10.8496 18.6619 10.5893 18.9223C10.2638 19.2477 9.73618 19.2477 9.41074 18.9223C9.15022 18.6617 8.887 18.4008 8.6243 18.1404C7.23455 16.7627 5.84337 15.3835 4.75643 13.9722C3.45569 12.2832 2.5 10.4382 2.5 8.33301ZM12.5 8.33301C12.5 9.71372 11.3807 10.833 10 10.833C8.61929 10.833 7.5 9.71372 7.5 8.33301C7.5 6.9523 8.61929 5.83301 10 5.83301C11.3807 5.83301 12.5 6.9523 12.5 8.33301Z"
        fill="#6868FE"
      />
    </>,
    'LocationIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default LocationIcon;
