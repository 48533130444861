import { TOP_BAR_NAVIGATION_LINKS } from 'components/layout/navbar/utils';
import { PERMISSIONS } from './constants';

export const NAVIGATION_ACTION_ROUTES = {
  'filters=taskTypes|is|requested': TOP_BAR_NAVIGATION_LINKS.FACILITIES,
  '/facilities': TOP_BAR_NAVIGATION_LINKS.FACILITIES,
  '/requests': TOP_BAR_NAVIGATION_LINKS.FACILITIES,
  '/assets': TOP_BAR_NAVIGATION_LINKS.FACILITIES,
  '/routing-rules': TOP_BAR_NAVIGATION_LINKS.FACILITIES,

  '/checklist': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/tasks': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/filtered-tasks': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/documents': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/agenda-view': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/scheduled-work': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/projects': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/messages': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,

  '/dashboards': TOP_BAR_NAVIGATION_LINKS.REPORTING,
  PULSE: TOP_BAR_NAVIGATION_LINKS.REPORTING,
  '/gallery': TOP_BAR_NAVIGATION_LINKS.REPORTING,

  '/locations': TOP_BAR_NAVIGATION_LINKS.LOCATIONS,

  '/settings': TOP_BAR_NAVIGATION_LINKS.SETTINGS,
  '/users-and-teams': TOP_BAR_NAVIGATION_LINKS.SETTINGS,
};

export const permissionBasedOperationsNavigation = (
  hasPermission,
  handleNavClick,
) => {
  if (hasPermission(PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION)) {
    handleNavClick('/checklist?view=grid');
  } else if (hasPermission(PERMISSIONS.CAN_VIEW_TASKS)) {
    handleNavClick('/tasks/list');
  } else if (hasPermission(PERMISSIONS.CAN_VIEW_TASK_REQUESTS)) {
    handleNavClick('/requests');
  } else if (hasPermission(PERMISSIONS.CAN_USE_CHAT)) {
    handleNavClick('/messages');
  } else if (hasPermission(PERMISSIONS.CAN_MANAGE_ASSETS)) {
    handleNavClick('/assets');
  } else if (hasPermission(PERMISSIONS.CAN_VIEW_SOP_AND_FILES)) {
    handleNavClick('/documents/my-documents');
  }
};

export const permissionBasedFacilitiesNavigation = (
  hasPermission,
  handleNavClick,
) => {
  if (hasPermission(PERMISSIONS.CAN_VIEW_TASKS)) {
    handleNavClick('/filtered-tasks/?filters=taskTypes|is|requested');
  } else if (hasPermission(PERMISSIONS.CAN_VIEW_TASK_REQUESTS)) {
    handleNavClick('/requests');
  } else {
    handleNavClick('/routing-rules');
  }
};

export const permissionBasedSettingsNavigation = (
  hasPermission,
  handleNavClick,
) => {
  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageRoles = hasPermission(PERMISSIONS.CAN_MANAGE_ROLES);

  if (canManageUsers || canManageTeams || canManageRoles) {
    handleNavClick(`/users-and-teams`);
  } else {
    handleNavClick('/settings/my-profile');
  }
};

export const PERMISSIONS_NAVIGATION_ACTION_ROUTES = {
  '/checklist': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/tasks': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/filtered-tasks': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/documents': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/agenda-view': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/scheduled-work': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/projects': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/requests': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/messages': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,
  '/assets': TOP_BAR_NAVIGATION_LINKS.OPERATIONS,

  '/dashboards': TOP_BAR_NAVIGATION_LINKS.REPORTING,

  '/locations': TOP_BAR_NAVIGATION_LINKS.LOCATIONS,

  '/settings': TOP_BAR_NAVIGATION_LINKS.SETTINGS,
  '/users-and-teams': TOP_BAR_NAVIGATION_LINKS.SETTINGS,
};
