const useAggridSSRM = () => {
  const refreshServerSide = (ids) => {
    const isTasksTableURL =
      window?.location?.href.includes('tasks/list') ||
      window?.location?.href.includes('filtered-tasks');

    if (isTasksTableURL && globalThis?.isTableView) {
      const api = globalThis?.taskListGrid?.api;
      const attemptedKeys: any = [];
      ids?.forEach((id) => {
        const node = api?.getRowNode(id)?.parent;

        if (node?.group && node?.key) {
          if (attemptedKeys?.includes(node?.key)) return;
          attemptedKeys?.push(node?.key);

          api.refreshServerSideStore({
            route: [node?.key],
            purge: false,
          });
        } else {
          api.refreshServerSideStore({
            route: [],
            purge: false,
          });
        }
      });
    }
  };

  return { refreshServerSide };
};
export default useAggridSSRM;
