import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import CostItem from './costItem';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import xeniaApi from 'api/index';
import { v4 as uuid } from 'uuid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  IconButton,
} from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { Container } from './styled';
import actions from 'store/actions';
import { generateAssigneeDropdownData } from 'helper/task/dropdownData';
import { cloneDeep } from 'lodash';
import { useCurrency, useLoggedInUser } from 'customhooks/index';
import Upload from 'components/Upload';
import useUsers from 'utils/CustomHooks/useUsers';
import useTaskList from 'utils/CustomHooks/useTasksList';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface IProps {
  dialogId: string;
}

const CostDialog: FC<IProps> = (props) => {
  const { dialogId } = props;
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const user = useLoggedInUser();
  const { getTasks } = useTaskList();
  const { currency } = useCurrency();

  const dialogSlice = useSelector(selectors.getDialogState);
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;
  const { task, onSave, filters = null } = dialogData || {};

  const [saving, setSaving] = useState(false);
  const [costs, setCosts] = useState<any>([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const { taskAssignees } = useUsers();

  useEffect(() => {
    if (task?.WorkOrderCosts?.length) {
      setCosts(task?.WorkOrderCosts);
      setAttachments(task?.WorkOrderCosts?.[0]?.attachments);
    } else {
      setCosts([
        {
          id: uuid(),
          amount: '',
          description: '',
          CostCreatedBy: user?.id,
        },
      ]);
    }
  }, [task?.id]);

  const handleClose = () => {
    dispatch(actions.setDialog({ dialogId, open: false }));
    setCosts([]);
    setAttachments([]);
  };

  const totalCost = useMemo(
    () =>
      costs.reduce((acc, cost) => acc + Number(cost.amount || 0), 0).toFixed(2),
    [costs],
  );

  const userList = useSelector(selectors.getUserAdmin);
  const userOptions = useMemo(() => {
    return generateAssigneeDropdownData(taskAssignees());
  }, [userList]);

  const handleChanges = useCallback(
    (index, key, value) => {
      const newCosts = cloneDeep(costs);
      newCosts[index][key] = value;

      setCosts(newCosts);
    },
    [costs, setCosts],
  );

  const handleSave = useCallback(async () => {
    setSaving(true);

    await xeniaApi.createWorkOrderCosts({
      taskId: task.id,
      costs: costs
        .filter((cost) => cost.amount)
        .map((cost) => ({
          id: cost.id,
          amount: cost.amount,
          description: cost.description,
          CostCreatedBy: cost.CostCreatedBy,
        })),
      attachments,
    });

    onSave?.();
    setSaving(false);
    handleClose();

    const isTasksTableURL =
      window?.location?.href.includes('tasks/list') ||
      window?.location?.href.includes('filtered-tasks');

    if (isTasksTableURL) {
      dispatch(
        actions.setForceUpdate({ forceUpdate: true, pushUpdate: false }),
      );
    } else {
      await getTasks(filters);
    }
  }, [task?.id, costs, attachments, setSaving, handleClose]);

  const handleDelete = useCallback(
    async (index) => {
      const cost = costs[index];
      await xeniaApi.deleteWorkOrderCost({
        taskId: task.id,
        costId: cost.id,
      });

      dispatch(actions.getSingleTaskApiCall({ id: task.id }));

      const newCosts = cloneDeep(costs);
      newCosts.splice(index, 1);
      setCosts(newCosts);
    },
    [task, costs, setCosts],
  );

  const handleUpload = useCallback(async (files) => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(files);
    const urls = response.filePath.map((path) => `${response.baseUrl}${path}`);
    setAttachments((prevAttachments) => [...prevAttachments, ...urls]);
  }, []);

  const handleRemove = useCallback(
    async (url) => {
      setAttachments((prevAttachments) =>
        prevAttachments.filter((item) => item !== url),
      );
    },
    [setAttachments],
  );

  return (
    <Dialog
      open={dialogState?.open}
      fullScreen={isMobileDeviceSm ?? true}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '680px',
            borderRadius: isMobileDeviceSm ? 0 : 4,
          },
        },
      }}
    >
      <Box
        sx={{
          pr: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DialogTitle sx={{ fontWeight: 700 }}>Add Costs</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Container>
          <div>
            {costs.map((cost, index) => (
              <CostItem
                key={cost.id}
                cost={cost}
                users={userOptions}
                index={index}
                onChange={handleChanges}
                onDelete={handleDelete}
                symbol={currency?.symbol}
              />
            ))}
          </div>
          <Box className="total-wrapper">
            <CustomButton
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setCosts((prevCosts) => [
                  ...prevCosts,
                  {
                    id: uuid(),
                    amount: '',
                    description: '',
                    CostCreatedBy: user?.id,
                  },
                ]);
              }}
            >
              Add Cost
            </CustomButton>
            <div className="total">
              Total: {currency.symbol}
              {totalCost}
            </div>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <p>{attachments?.length ? 'Upload' : 'Add'} Documents</p>
            <Upload
              files={attachments}
              onRemove={handleRemove}
              onUpload={handleUpload}
            />
          </Box>
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2 }}>
        <CustomButton variant={'text'} onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant={'contained'}
          color={'primary'}
          onClick={handleSave}
          buttonLoader={saving}
        >
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CostDialog;
