import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CustomButton from 'components/Button/CustomButton';
import CTabs from 'components/CTabs';
import SettingToggle from 'components/SettingToggle';
import useResponsive from 'utils/CustomHooks/useResponsive';
import useTasksTemplates from 'utils/CustomHooks/useTasksTemplates';
import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { setDialog } from 'store/actions/dialogActions';
import TimeInterval from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeInterval';
import TimeDuration from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeDuration';
import { CreateTaskAttchmentFilesApi } from 'api/tasksApi';

import {
  ContentWrapper,
  TabsWrapper,
  CutomFormControlLabel,
  CustomModalWrapper,
  DueDateCnt,
  FooterWrapper,
  NoteItem,
  NotesCnt,
  TemplateBannerCnt,
  TimeDurationCnt,
  TimeIntervalCnt,
} from './style';
import { cloneDeep } from 'lodash';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
// import TemplateTypeContainer from './templateType';

const TasksTemplateDialog = () => {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG];
  const { taskTemplate, isEdit } = modalState?.data || {};
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const { createTasksTemplate, updateTasksTemplate } = useTasksTemplates();

  // create state

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG));
  };

  const [isSaving, setIsSaving] = useState(false);

  const [data, setData] = useState<any>({
    templateTitle: '',
    //templateType: 'TASK',
    templateDescription: '',
    config: {
      location: undefined,
      imageCapture: false,
    },
  });

  const [metaData, setMetaData] = useState<any>({});

  const { addNewAssignees } = useAssigneesUtils();

  useEffect(() => {
    if (taskTemplate?.metaData) {
      setMetaData(taskTemplate?.metaData);
    }

    if (taskTemplate?.templateInfo) {
      setData(taskTemplate?.templateInfo);
    }
  }, [taskTemplate]);

  const titleChangeHandler = (event) => {
    setData({
      ...data,
      templateTitle: event?.target?.value,
    });
  };

  const descriptionChangeHandler = (event) => {
    setData({
      ...data,
      templateDescription: event?.target?.value,
    });
  };

  const dataTransformation = () => {
    const payload = {
      title: metaData?.title,
      description: metaData?.description,
      priority: metaData?.priority,
      LocationId: metaData?.locationIds?.[0],
      AssetId: metaData?.AssetId,
      ServiceTypeId: metaData?.serviceTypeId,
      ChecklistId: metaData?.checklistId,
      assignees: metaData?.assignees,
      assigneesRoles: metaData?.assigneesRoles,
      notification: {
        overdue: { recipients: [] },
        statusChange: {
          recipients: metaData?.notification?.statusChange?.recipients || [],
        },
      },
      templateTitle: data?.templateTitle,
      //templateType: data?.templateType,
      templateDescription: data?.templateDescription,
      dueAfterCreationTime: data?.dueAfterCreationTime,
      config: data?.config,
    };

    return payload;
  };

  const saveHandler = async () => {
    const payload = dataTransformation();

    setIsSaving(true);

    let attachedList;

    if (metaData?.attachment?.length > 0) {
      attachedList = metaData?.attachment?.map((item) => {
        const result = item.replace(/^.*?\/\/[^/]+\//, '');
        return result;
      });
    }

    if (metaData?.attachments?.length > 0) {
      const attachmentsResponse = await CreateTaskAttchmentFilesApi(
        metaData?.attachments,
      );

      if (attachmentsResponse) {
        attachedList = [
          ...(attachedList?.length > 0 ? attachedList : []),
          ...(attachmentsResponse.filePath ? attachmentsResponse.filePath : []),
        ];
      }
    }

    if (!payload?.templateDescription) {
      delete payload?.templateDescription;
    }

    try {
      const response = await createTasksTemplate({
        ...payload,
        ...(attachedList && {
          attachment: attachedList,
        }),
      });
      modalState?.data?.onSuccessCallBack?.(response);
      setIsSaving(false);
      handleClose();
    } catch (e) {
      console.log('error:', e);
    }
  };

  const editHandler = async () => {
    const payload = dataTransformation();
    setIsSaving(true);

    let attachedList;
    if (metaData?.attachments?.length > 0) {
      const attachmentsResponse = await CreateTaskAttchmentFilesApi(
        metaData?.attachments,
      );

      if (attachmentsResponse) {
        attachedList = [
          ...metaData.attachment,
          ...attachmentsResponse.filePath.map(
            (item) => attachmentsResponse.baseUrl + item,
          ),
        ];
      }
    }

    const response = await updateTasksTemplate(
      {
        ...payload,
        ...(attachedList && {
          attachment: attachedList,
        }),
      },
      metaData?.id,
    );
    modalState?.data?.onSuccessCallBack?.(response);
    setIsSaving(false);
    handleClose();
  };

  const nextHandler = (metaData, templateInfo, isEdit) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );

    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
        data: {
          taskTemplate: {
            metaData,
            templateInfo,
          },
          isEdit,
          onSuccessCallBack: modalState?.data?.onSuccessCallBack,
        },
      }),
    );
  };

  const formattedTaskHandler = (task) => {
    let clonedTask = cloneDeep(task);

    if (clonedTask?.assignees?.length > 0) {
      clonedTask = {
        ...clonedTask,
        TaskAssignees: addNewAssignees(clonedTask?.assignees),
      };
    }

    if (clonedTask?.serviceTypeId) {
      clonedTask = {
        ...clonedTask,
        ServiceId: clonedTask?.serviceTypeId,
      };
    }

    if (!isEdit && clonedTask?.attachments?.length > 0) {
      clonedTask = {
        ...clonedTask,
        preFilledFiles: clonedTask?.attachments,
      };
    }

    return clonedTask;
  };

  const goBackHandler = () => {
    const formattedTask = formattedTaskHandler(metaData);

    if (modalState?.data?.goBackCustomHandler) {
      modalState?.data?.goBackCustomHandler?.(formattedTask);
      handleClose();
      return;
    }

    dispatch(
      setDialog({
        open: true,
        dialogId: 'editTask',
        data: {
          dialogState: {
            viewLess: false,
            hideElements: {
              dueDate: true,
              schedule: true,
              isRequiredChecklistCheckbox: true,
            },
            removeSubmitValidation: true,
            taskTemplate: {
              isEdit,
            },
          },
          actionBtn: {
            text: 'Next',
            clickHandler: nextHandler,
          },
          taskData: formattedTask,
          taskTemplateInfo: data,
        },
      }),
    );

    handleClose();
  };

  const changeDurationHandler = (_, duration) => {
    if (duration?.length > 4) return;
    setData({
      ...data,
      dueAfterCreationTime: { ...data?.dueAfterCreationTime, value: duration },
    });
  };

  const changeIntervalHandler = (_, interval) => {
    setData({
      ...data,
      dueAfterCreationTime: { ...data?.dueAfterCreationTime, unit: interval },
    });
  };

  // Is Disable Check for the Save Button
  const isEnableSaveBtn = useMemo(() => {
    return data?.templateTitle;
  }, [data]);

  const showNotes = useMemo(() => {
    return (
      metaData?.isMultiTasks ||
      metaData?.isAutoTagLocation ||
      metaData?.notification?.statusChange?.recipients?.filter(
        (item) => item != userProfileData?.id,
      )?.length > 0 ||
      metaData?.recurringTask ||
      metaData?.isTimeBound === true
    );
  }, [metaData, userProfileData]);

  const statusNotifications = useMemo(() => {
    return metaData?.notification?.statusChange?.recipients?.filter(
      (item) => item != userProfileData?.id,
    );
  }, [metaData, userProfileData]);

  const templateInfo = () => {
    return (
      <ContentWrapper gap="20px">
        <CustomInput
          topLabelInput={true}
          muiLabel={false}
          label="Template Title"
          style={{
            marginBottom: 0,
          }}
          fieldProps={{
            value: data?.templateTitle,
            placeholder: 'Please provide template title here',
            onChange: titleChangeHandler,
          }}
        />

        {/* <TemplateTypeContainer data={data} setData={setData} /> */}

        <CustomInput
          topLabelInput={true}
          muiLabel={false}
          label="Enter description"
          fieldProps={{
            multiline: true,
            maxRows: 3,
            minRows: 3,
            placeholder: 'Enter a description here',
            onChange: descriptionChangeHandler,
            value: data?.templateDescription,
          }}
        />

        {showNotes && (
          <NotesCnt>
            <Stack direction="row" gap="5px" alignItems="center">
              <ErrorIcon className="icon" />
              <Typography className="title">
                Following features are not applicable for the task template:
              </Typography>
            </Stack>

            <Box style={{ marginLeft: 28 }}>
              <Stack direction="column" gap="2px">
                {metaData?.isMultiTasks && (
                  <NoteItem direction="row" alignItems="center" gap="8px">
                    <div className="dot"></div>
                    <Typography className="text">Bulk Task Creation</Typography>
                  </NoteItem>
                )}

                {metaData?.isAutoTagLocation && (
                  <NoteItem direction="row" alignItems="center" gap="8px">
                    <div className="dot"></div>
                    <Typography className="text">Auto Tag Location</Typography>
                  </NoteItem>
                )}

                {metaData?.isTimeBound === true && (
                  <NoteItem direction="row" alignItems="center" gap="8px">
                    <div className="dot"></div>
                    <Typography className="text">
                      Mark task as missed after due-time passes
                    </Typography>
                  </NoteItem>
                )}

                {metaData?.recurringTask && (
                  <NoteItem direction="row" alignItems="center" gap="8px">
                    <div className="dot"></div>
                    <Typography className="text">Scheduling</Typography>
                  </NoteItem>
                )}

                {statusNotifications?.length > 0 && (
                  <NoteItem direction="row" alignItems="center" gap="8px">
                    <div className="dot"></div>
                    <Typography className="text">
                      Notifications Settings
                    </Typography>
                  </NoteItem>
                )}
              </Stack>
            </Box>
          </NotesCnt>
        )}
      </ContentWrapper>
    );
  };

  const imageCaptureSettings = (
    <RadioGroup
      name={'task-template-location'}
      value={
        data?.config?.location?.userLocation
          ? 'userLocation'
          : 'submissionLocation'
      }
      onChange={(event) => {
        setData({
          ...data,
          config: {
            ...data?.config,
            location: {
              [event.target.value]: true,
            },
          },
        });
      }}
    >
      <CutomFormControlLabel
        value="userLocation"
        control={<Radio />}
        label="Pre-fill User Default Location"
      />
      <CutomFormControlLabel
        value="submissionLocation"
        control={<Radio />}
        label="Pre-fill Submission Default Location"
      />
    </RadioGroup>
  );

  const dueDateSettings = (
    <DueDateCnt>
      {/* <Typography className="label">Due Date</Typography> */}
      <div className="inner">
        <TimeDurationCnt>
          <TimeDuration
            value={data?.dueAfterCreationTime?.value}
            handleUpdateSchedule={changeDurationHandler}
          />
        </TimeDurationCnt>
        <TimeIntervalCnt>
          <TimeInterval
            value={data?.dueAfterCreationTime?.unit}
            handleUpdateSchedule={changeIntervalHandler}
          />
        </TimeIntervalCnt>
        <Typography className="statement"> after task is created</Typography>
      </div>
    </DueDateCnt>
  );

  const templateSettings = () => {
    return (
      <div>
        <SettingToggle
          title={'Due Date'}
          text={'Set a relative due date for the instances of this template.'}
          handleSettingToggle={() => {
            setData({
              ...data,
              dueAfterCreationTime: !data?.dueAfterCreationTime
                ? { value: 30, unit: 'minute' }
                : null,
            });
          }}
          checked={data?.dueAfterCreationTime}
          settings={dueDateSettings}
        ></SettingToggle>
        <SettingToggle
          title={'Location'}
          text={
            'Pre-fill the location field for the instance of this template.'
          }
          handleSettingToggle={() => {
            setData({
              ...data,
              config: {
                ...data?.config,
                location: !data?.config?.location
                  ? { userLocation: true }
                  : undefined,
              },
            });
          }}
          checked={data?.config?.location}
          settings={imageCaptureSettings}
        ></SettingToggle>
        <SettingToggle
          title={'Require Image Capture'}
          text={
            'Prompt for the image capture when instance of this template is created through automation.'
          }
          handleSettingToggle={() => {
            setData({
              ...data,
              config: {
                ...data?.config,
                imageCapture: !data?.config?.imageCapture,
              },
            });
          }}
          checked={data?.config?.imageCapture}
        />
      </div>
    );
  };

  const taskTemnplateTabs = [
    {
      label: 'Template Info',
      content: <div>{templateInfo()}</div>,
    },
    {
      label: 'Settings',
      content: <div>{templateSettings()}</div>,
    },
  ];

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      {isEdit && (
        <TemplateBannerCnt>
          <InfoIcon className="infoIcon" />
          <Typography className="content">
            Editing this template will affect all users and active templates
            everywhere in your team.
          </Typography>
        </TemplateBannerCnt>
      )}

      <TabsWrapper gap="20px">
        <CTabs data={taskTemnplateTabs} />
      </TabsWrapper>

      <FooterWrapper
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={goBackHandler}
          className="goBackBtn"
          startIcon={<ArrowBackIcon />}
        >
          Go back
        </CustomButton>

        <div>
          <CustomButton
            variant={'text'}
            color={'primary'}
            onClick={handleClose}
            className="cancelBtn"
          >
            Cancel
          </CustomButton>

          <CustomButton
            variant={'contained'}
            disabled={!isEnableSaveBtn}
            color={'primary'}
            onClick={isEdit ? editHandler : saveHandler}
            buttonLoader={isSaving}
            className="saveBtn"
          >
            {isEdit ? 'Update Template' : 'Save Template'}
          </CustomButton>
        </div>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default TasksTemplateDialog;
