import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Outlet, useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Header from 'components/layout/header/header';
import Sidebar from 'components/layout/sidebar/sidebar';
import styled from '@mui/system/styled';
import { useInitialDataLoad } from 'utils/CustomHooks/useInitialDataLoad';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import AllDialogs from 'components/AllDialogs';
import { PAGE_TITLES } from 'utils/globalVariable';
import { useReactRouter } from 'controller/useReactRouter';
import UploadChunk from 'components/AllDialogs/UploadChunks';
import { NAVIGATION_ACTION_ROUTES } from 'utils/navigation';
import { setNavigationSelection } from 'store/actions/navigationActions';
import { getNavigationData } from 'store/selectors/navigation';
import { TOP_BAR_NAVIGATION_LINKS } from './navbar/utils';

const Main = styled('main', {
  shouldForwardProp: (prop) =>
    !['open', 'supresstopmargin', 'topBar'].includes(prop),
})(({ theme, open, supresstopmargin, topBar }) => ({
  marginTop: supresstopmargin ? 0 : 48,
  ...(topBar && !supresstopmargin && { marginTop: 95 }),
  flexGrow: 1,
  zIndex: 999,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}));

export default function Layout() {
  const { handleGetPathName } = useReactRouter();
  const { workspaceId } = useWorkspaceHook();
  const orgHotelName: any = useSelector(selectors.getPropertyName);
  const appState = useSelector(selectors.getAppState);
  const open = useSelector(selectors.getMenuState);
  const isChecklistRoute = appState?.viewTitle === PAGE_TITLES.newChecklist;
  const isNotificationRoute = handleGetPathName().includes('/my-notifications');
  const { loadData, isLoading } = useInitialDataLoad(workspaceId as string);
  const topAlertState = useSelector(selectors.getTopAlertState);
  const currentPath = useLocation().pathname;
  const currentQueryParams = useLocation().search;

  const navigationDetails = useSelector(getNavigationData);
  const { selected } = navigationDetails || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (orgHotelName && orgHotelName != '') {
      document.title = `Xenia - ${orgHotelName}`;
    }
  }, [orgHotelName]);

  useEffect(() => {
    workspaceId && loadData();
  }, [workspaceId]);

  useEffect(() => {
    const selectedPath = Object.keys(NAVIGATION_ACTION_ROUTES)?.find(
      (path) =>
        currentPath?.includes(path) || currentQueryParams?.includes(path),
    );

    if (selectedPath) {
      const mainAction = NAVIGATION_ACTION_ROUTES[selectedPath];
      if (mainAction) {
        dispatch(setNavigationSelection(mainAction));
      }
    }
  }, [currentPath]);

  const showSideBar = useMemo(() => {
    const { OPERATIONS, FACILITIES, REPORTING, SETTINGS } =
      TOP_BAR_NAVIGATION_LINKS;
    return [OPERATIONS, FACILITIES, REPORTING, SETTINGS].includes(selected);
  }, [selected]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AllDialogs />
      {/* uploading higherorder component */}
      {/* this component is responsible for handeling upload files in chunks */}
      <UploadChunk />
      {globalThis?.platform !== 'mobile' && <Header open={open} />}
      {globalThis?.platform !== 'mobile' && showSideBar && (
        <Sidebar open={open} />
      )}
      {!isLoading && (
        <Main
          open={open}
          supresstopmargin={
            globalThis?.platform === 'mobile' ||
            isChecklistRoute ||
            isNotificationRoute
          }
          topBar={topAlertState.show}
        >
          <Outlet />
        </Main>
      )}
    </Box>
  );
}
