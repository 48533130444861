// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Components
import Toast from 'components/Toast';

// Utils
import xeniaApi from 'api/index';
import { showMessageNotification } from 'utils/globalFunction';

// Hooks
import { setDialog } from 'store/actions/dialogActions';

function useCreateTask() {
  const dispatch = useDispatch();
  const handleOpenTask = (taskId) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          taskId: taskId,
        },
      }),
    );
  };
  const handleOpenTaskDrawer = (taskId: string) => {
    handleOpenTask(taskId);
  };

  const createTask = async (payload, options?: any) => {
    const { onClickViewWorkOrder } = options || {};
    const taskApiCall = await xeniaApi.CreateTaskApi(payload, false);

    if (taskApiCall) {
      if (payload?.isProject) {
        showMessageNotification(
          `Project: ${payload?.title} created successfully!`,
          'success',
        );
        return taskApiCall;
      }

      showMessageNotification(
        <Toast
          text="Task created"
          actionButtonText="View Task"
          handleClick={() => {
            onClickViewWorkOrder && onClickViewWorkOrder(taskApiCall[0]);
            handleOpenTaskDrawer(taskApiCall[0].id);
          }}
        />,
      );

      dispatch(actions.createTaskHotel(taskApiCall));

      return taskApiCall;
    }
    return null;
  };

  const createWorkOrder = async (payload, options?: any) => {
    const { onClickViewWorkOrder } = options || {};
    const taskApiCall = await xeniaApi.CreateWorkOrderApi(payload, false);

    if (taskApiCall) {
      showMessageNotification(
        <Toast
          text="Work Order created"
          actionButtonText="View Task"
          handleClick={() => {
            onClickViewWorkOrder && onClickViewWorkOrder(taskApiCall[0]);
            handleOpenTaskDrawer(taskApiCall[0].id);
          }}
        />,
      );

      dispatch(actions.createTaskHotel(taskApiCall));

      return taskApiCall;
    }
    return null;
  };

  const createTaskQuick = async (payload) => {
    const taskApiCall = await xeniaApi.CreateTaskApi(payload, true);
    if (taskApiCall) {
      return taskApiCall;
    }
    return null;
  };

  return { createTask, createWorkOrder, createTaskQuick };
}

export default useCreateTask;
