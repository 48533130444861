// React
import React, { useEffect, useRef, useState, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';

// MUI
import { Box, Chip, Stack, Button } from '@mui/material';

// Components
import ChecklistProgress from './Progress';
import WeightedScore from './WeightedScore';
import ChecklistTitleAndAttachments from './ChecklistTitleAndAttachments';
import ActionConfirmationModal from './shared/actionConfirmationModal';
import TemplateItem from 'components/TaskChecklist/Items/TemplateItem';
import CheckListSkeleton from './Skeleton/CheckListSkeleton';
import Dropdown from './Filter';
import CustomButton from 'components/Button/CustomButton';
import GlobalNotes from './Items/TemplateItem/GlobalNotes';
import ReviewMode from './ReviewMode';
import ReviewItemsBanner from './ReviewItemsBanner';

// Utils
import { CHECKLIST_ITEM_TYPE } from 'utils/globalVariable';
import { validateItemAutomations } from 'xeniautils';
import xeniaApi from 'api/index';
import { each, filter, find, groupBy, orderBy } from 'lodash';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import { getAutomationString } from 'xeniautils';

// Hooks
import useChecklistLog from 'controller/useChecklistLog';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import FullScreenRoundedIcon from 'components/Icons/fullScreenRoundedIcon';
import { showMessageNotification } from 'utils/globalFunction';

import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { ToastAutomationRendererOnSubmit } from 'components/ToastAutomation/renderer';
import FlashIcon from 'components/Icons/flashIcon';

import TemplateBody from 'components/TemplateSubmissionPreview/TemplateBody';

import ChecklistAttachments from './ChecklistAttachments';
import { STEP_TYPES } from 'utils/templates';
import { validateItemCondition } from './utils';
import { getLocationGroupsState } from 'store/selectors/locationGroups';
import { getLocationsHash } from 'store/selectors/locations';

const TaskChecklist = ({
  checklistId,
  logId = undefined,
  taskId = undefined,
  isLogSubmitted = false,
  handleGoBack,
  handleSubmit,
  hideBottomButtons = false,
  triggerSubmission,
  CustomFooter = undefined,
  customFooterProps = {},
  handleChangeLog,
  showFullScreenButton = false,
  isActionsAllowed = true,
  onChecklistLoadSuccess,
  handleChangeFormattedData,
  taskDetail = {},
}: {
  checklistId: string;
  logId?: string;
  taskId?: string;
  isLogSubmitted?: boolean;
  handleGoBack?: any;
  handleSubmit?: any;
  hideBottomButtons?: boolean;
  triggerSubmission?: boolean;
  CustomFooter?: React.ReactElement;
  customFooterProps?: any;
  handleChangeLog?: any;
  showFullScreenButton?: boolean;
  isActionsAllowed?: boolean;
  onChecklistLoadSuccess?: () => void;
  handleChangeFormattedData?: (data: any) => void;
  taskDetail?: any;
}) => {
  const dispatch = useDispatch();
  const [checklistDefaultLocation, setChecklistDefaultLocation] = useState<
    string | null
  >(null);
  const { isMobileDeviceMd } = useResponsive();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasFeature, FEATURES } = useCustomPermission();
  const [reviewMode, setReviewMode] = useState(false);
  const [showRequiredBanner, setShowRequiredBanner] = useState(false);
  const [actionableItemsCount, setActionableItemsCount] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [respondedItem, setRespondedItem] = useState<any>({});
  const locationGroups = useSelector(getLocationGroupsState);
  const locationHashes = useSelector(getLocationsHash);

  const [sections, setSections] = useState<any>({
    sections: [],
    nonSection: [],
  });
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [requireImageModal, setRequireImageModal] = useState<{
    open: boolean;
    actionType: 'image' | 'task';
    onAction?: () => void;
    item?: any;
  } | null>(null);
  const [requireTaskModal, setRequireTaskModal] = useState<{
    open: boolean;
    actionType: 'image' | 'task';
    onAction?: () => void;
    item?: any;
  } | null>(null);
  const [requireAutomationImageModal, setRequireAutomationImageModal] =
    useState<{
      open: boolean;
      actionType: 'image' | 'task';
      onAction?: () => void;
      item?: any;
    } | null>(null);

  const checklistTopRef = useRef<any>(null);
  const lastUpdatedRef = useRef<HTMLDivElement>(null);

  const {
    loading,
    localChecklist = {},
    checklist = {},
    childItemsMap,
    automations,
    conditionVisibility,
    checkListLogHashes,
    itemErrors,
    requiredItems,
    requiredItemCount,
    checklistProgAndScore,
    resetItemErrors,
    checkErrorsBeforeSubmission,
    handleStatus,
    handleNote,
    handleUpdateNote,
    handleChecklistNote,
    handleUpdateChecklistNote,
    handleDeleteChecklistNote,
    handleDeleteChecklistItemNote,
    createCorrectiveTaskCb,
  } = useChecklistLog({
    logId,
    taskId,
    checklistId,
    onChecklistLoadSuccess,
    handleChangeFormattedData,
  });

  useEffect(() => {
    handleChangeLog?.(localChecklist);
  }, [localChecklist]);

  const mapSectionItems = (items) => {
    const sectionItems = groupBy(items, 'sectionId');
    const nonSection = sectionItems?.['undefined']?.filter(
      (item) => item.type !== 'header',
    );

    const sections = items
      ?.filter((item) => item.type === 'header')
      .sort((a, b) => a?.order - b?.order)
      .map((item) => ({
        ...item,
        items: sectionItems[item.id],
      }));

    return {
      nonSection,
      sections,
    };
  };

  const allItems = () => {
    const checklistItems = localChecklist?.TaskChecklistItems || [];

    const itemsToShow = filter(checklistItems, (item) => {
      if (item.ParentId === null) {
        return true;
      } else {
        return false;
      }
    });
    return orderBy(itemsToShow, ['order'], ['asc']);
  };

  const passedItems = () => {
    const checklistItems = allItems();
    return filter(
      checklistItems,
      (item) =>
        (item?.type === CHECKLIST_ITEM_TYPE.PASS_FAIL &&
          item?.answers?.value === 'pass') ||
        item.type === 'header',
    );
  };

  const failedItems = () => {
    const checklistItems = allItems();
    return filter(
      checklistItems,
      (item) =>
        (item?.type === CHECKLIST_ITEM_TYPE.PASS_FAIL &&
          item?.answers?.value === 'fail') ||
        item.type === 'header',
    );
  };

  const completedItems = () => {
    const checklistItems = allItems();
    return filter(
      checklistItems,
      (item) => !!item?.answers?.value || item.type === 'header',
    );
  };

  const remainingItems = () => {
    const checklistItems = allItems();
    return filter(
      checklistItems,
      (item) =>
        !item?.answers || !item?.answers?.value || item.type === 'header',
    );
  };

  const getChecklistDefaultLocation = () => {
    if (checklistDefaultLocation) return checklistDefaultLocation;

    const locationItem = find(checklist.TaskChecklistItems, {
      type: CHECKLIST_ITEM_TYPE.LOCATION,
      options: {
        isDefaultLocation: true,
      },
    });

    setChecklistDefaultLocation(locationItem?.answers?.value || null);

    return locationItem?.answers?.value || null;
  };

  const onResponseToItem = async (item, value) => {
    const response = {
      answers: {
        value,
      },
    };
    const conditionData = {
      answer: response.answers.value,
      itemType: item.type,
      automations: automations?.[item.ChecklistItemId],
    };

    if (item?.type === STEP_TYPES.DROPDOWN) {
      if (item?.answers?.value?.length > value?.length) {
        conditionData.answer = [];
      } else {
        const lastSelected =
          response?.answers?.value && response?.answers?.value?.length > 0
            ? [response?.answers?.value[response?.answers?.value?.length - 1]]
            : [];

        conditionData.answer = lastSelected;
      }
    }

    const updatedChecklistLog = {
      ...localChecklist,
      TaskChecklistItems: localChecklist.TaskChecklistItems.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            answers: {
              value,
            },
          };
        } else {
          return i;
        }
      }),
    };

    const actions = validateItemAutomations(conditionData);

    const updatedItem = {
      ...item,
      answers: {
        value,
      },
    };

    let totalTasksCreated = 0;
    let createTask = false;
    let requireImage = false;

    if (
      item?.type === CHECKLIST_ITEM_TYPE.LOCATION &&
      item?.options?.isDefaultLocation
    ) {
      setChecklistDefaultLocation(value);
    }

    actions.forEach((action: any) => {
      switch (action.type) {
        case AUTOMATION_ACTIONS.REQUIRE_IMAGE:
          setRequireImageModal({
            open: true,
            actionType: 'image',
            item: updatedItem,
          });
          break;

        case AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE:
          createTask = true;

          if (action?.data?.when === 'immediate') {
            totalTasksCreated = totalTasksCreated + 1;
          }

          if (action?.data?.template?.config?.imageCapture) {
            requireImage = true;

            setRequireAutomationImageModal({
              open: true,
              actionType: 'image',
              item: updatedItem,
            });

            setRespondedItem({
              item,
              response,
              totalTasksCreated,
            });
          }

          break;
        case AUTOMATION_ACTIONS.REQUITE_TASK:
          {
            const automation = automations?.[item?.ChecklistItemId]?.find(
              (a) => a.id === action?.automationId,
            );

            const automationText = getAutomationString(item, automation);

            const checklistDefaultLocationId =
              item?.type === CHECKLIST_ITEM_TYPE.LOCATION &&
              item?.options?.isDefaultLocation
                ? value
                : getChecklistDefaultLocation();

            setRequireTaskModal({
              open: true,
              actionType: 'task',
              onAction: () => {
                dispatch(
                  setDialog({
                    open: true,
                    dialogId: 'createEditTask',
                    data: {
                      onSuccessCallBack: (taskData) => {
                        setRequireTaskModal(null);
                        createCorrectiveTaskCb({
                          task: taskData?.length > 0 ? taskData[0] : taskData,
                          item,
                          checklistLog: updatedChecklistLog,
                        });
                      },
                      dialogState: {
                        viewLess: false,
                      },
                      taskData: {
                        AssociatedLog: checklist,
                        LogItemId: item.id,
                        LogItemAnswer: response.answers.value,
                        automationId: automation?.id,
                        automationText,
                        ...(checklistDefaultLocationId && {
                          locationIds: [checklistDefaultLocationId],
                        }),
                      },
                    },
                  }),
                );
              },
            });
          }
          break;
      }
    });

    if (!requireImage) {
      const { formattedData } = await handleStatus({
        item,
        response,
        checklistDefaultLocation: createTask
          ? getChecklistDefaultLocation()
          : null,
      });

      if (hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK)) {
        if (totalTasksCreated > 0) {
          showMessageNotification(
            <ToastAutomationRendererOnSubmit
              toastContent={{
                totalCreated: totalTasksCreated,
              }}
            />,
            undefined,
            1500,
            undefined,
            'automation-trigger',
            <FlashIcon />,
          );
        }
      }
    }
  };

  const handleUploadImageToItem = async (acceptedFiles: any[]) => {
    setUploading(true);
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(acceptedFiles);
    const attachments = response.filePath.map(
      (attachment: any) => response.baseUrl + attachment,
    );
    await handleNote({
      item: requireImageModal?.item,
      note: '',
      attachments,
    });
    setRequireImageModal(null);
    setUploading(false);
    return attachments;
  };

  const handleUploadImageToAutomation = async (acceptedFiles: any[]) => {
    setUploading(true);
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(acceptedFiles);
    const attachments = response.filePath.map(
      (attachment: any) => response.baseUrl + attachment,
    );

    if (respondedItem) {
      const { formattedData } = await handleStatus({
        item: respondedItem?.item,
        response: respondedItem?.response,
        attachment: attachments,
        checklistDefaultLocation: getChecklistDefaultLocation(),
      });
    }

    if (hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK)) {
      if (
        respondedItem?.totalTasksCreated &&
        respondedItem?.totalTasksCreated > 0
      ) {
        showMessageNotification(
          <ToastAutomationRendererOnSubmit
            toastContent={{
              totalCreated: respondedItem?.totalTasksCreated,
            }}
          />,
          undefined,
          1500,
          undefined,
          'automation-trigger',
          <FlashIcon />,
        );
      }
    }

    setRequireAutomationImageModal(null);
    setUploading(false);
    setRespondedItem(null);
  };

  const onNotePostToItem = async ({
    item,
    note = '',
    attachments = [],
    response = null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
  }) => {
    const result = await handleNote({ item, note, attachments, response });
    try {
      callback();
    } catch (error) {
      console.log('error', error);
    }
    return result;
  };

  const handleUpdateItemNote = async ({
    item,
    note = '',
    attachments = [],
    response = null,
    noteId,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
  }) => {
    const result = await handleUpdateNote({
      item,
      note,
      attachments,
      response,
      noteId,
    });
    try {
      callback();
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return result;
  };

  const onNotePostToChecklist = async ({ note = '', attachments = [] }) => {
    const result = await handleChecklistNote({ note, attachments });
    return result;
  };

  const onFilterSelect = (filter) => {
    switch (filter) {
      case 'all':
        setSections(() => mapSectionItems(allItems()) as any);
        setFilteredItems(allItems());
        break;
      case 'passed':
        setSections(() => mapSectionItems(passedItems()) as any);
        setFilteredItems(passedItems());
        break;
      case 'failed':
        setSections(() => mapSectionItems(failedItems()) as any);
        setFilteredItems(failedItems());
        break;
      case 'completed':
        setSections(() => mapSectionItems(completedItems()) as any);
        setFilteredItems(completedItems());
        break;
      case 'remaining':
        setSections(mapSectionItems(remainingItems()) as any);
        setFilteredItems(remainingItems());
        break;
    }
  };

  const handleCreateCorrectiveTask = ({ item, answer }) => {
    const checklistDefaultLocationId = getChecklistDefaultLocation();

    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: (taskData) => {
            setRequireTaskModal(null);
            createCorrectiveTaskCb({
              task: taskData?.length > 0 ? taskData[0] : taskData,
              item,
            });
          },
          dialogState: {
            viewLess: false,
          },
          taskData: {
            AssociatedLog: checklist,
            LogItemId: item.id,
            LogItemAnswer: answer ?? null,
            ...(checklistDefaultLocationId && {
              locationIds: [checklistDefaultLocationId],
            }),
          },
        },
      }),
    );
  };

  const handleChecklistSubmit = (checklist) => {
    setShowRequiredBanner(false);
    const canSubmit = checkErrorsBeforeSubmission();
    if (canSubmit) {
      handleSubmit(
        {
          ...checklist,
          progress: {
            ...checklistProgAndScore?.progress,
            scorePercentage: checklistProgAndScore?.score?.percentage,
          },
        },
        automations,
      );
    } else {
      setShowRequiredBanner(true);
      checklistTopRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleShowUploadModal = (item: any) => {
    setRequireImageModal({
      open: true,
      actionType: 'image',
      item,
    });
  };

  const handleClickFullScreen = () => {
    let URL = `/in-progress-checklist/${checklistId}/?`;
    if (logId) {
      URL += `logId=${logId}`;
    }
    if (logId && taskId) {
      URL += `&taskId=${taskId}`;
    }
    if (logId || taskId) {
      URL += `&prevUrl=/tasks/list`;
    } else {
      URL += `prevUrl=/tasks/list`;
    }
    navigateWithWorkspaceUrl(URL);
  };

  useEffect(() => {
    each(localChecklist?.TaskChecklistItems, (item) => {
      if (item.type !== CHECKLIST_ITEM_TYPE.HEADER) {
        setActionableItemsCount((prev) => prev + 1);
      }
    });
    localChecklist;
  }, [localChecklist]);

  useEffect(() => {
    const result = allItems();
    setSections(mapSectionItems(result));
    setFilteredItems(result);
  }, [checklist?.TaskChecklistItems, localChecklist?.TaskChecklistItems]);

  useEffect(() => {
    if (
      !scrolled &&
      lastUpdatedRef?.current &&
      localChecklist?.status === 'In Progress'
    ) {
      lastUpdatedRef?.current?.scrollIntoView({ behavior: 'smooth' });
      setScrolled(true);
    }
  }, [lastUpdatedRef?.current, sections, localChecklist]);

  useEffect(() => {
    triggerSubmission && handleChecklistSubmit(localChecklist);
  }, [triggerSubmission]);

  // this function is use to check the conditional visibility of checklist item
  const locationGroupHash = useMemo(() => {
    const locationGroupHashes = {};
    locationGroups.forEach((item) => {
      locationGroupHashes[item?.id] = item?.LocationGroupLocations.flatMap(
        (l) => {
          const sublocations = locationHashes?.[l.LocationId]?.Sublocations;
          if (sublocations) {
            const sublocationKeys = Object.keys(sublocations);
            return [l?.LocationId, ...sublocationKeys];
          }
          return l?.LocationId;
        },
      );
    });
    return locationGroupHashes;
  }, [locationGroups]);

  const checkVisibility = (item) => {
    try {
      if (
        conditionVisibility &&
        item?.ChecklistItemId &&
        conditionVisibility?.[item?.ChecklistItemId]
      ) {
        let thisCondition = conditionVisibility[item?.ChecklistItemId][0];

        const defaultAction = thisCondition.action !== 'SHOW' ? true : false;
        const contextType = thisCondition.context;
        const contextItem = checkListLogHashes[thisCondition.contextId];
        if (!contextItem?.answers?.value) {
          return defaultAction;
        }
        if (contextType === 'LocationGroup') {
          const locationsOfConditionalGroup =
            locationGroupHash[thisCondition?.condition?.value];
          thisCondition = {
            ...thisCondition,
            condition: {
              ...thisCondition.condition,
              value: locationsOfConditionalGroup,
            },
          }; // update location group with there locations here
        }
        const conditionData = {
          answer: contextItem?.answers?.value,
          itemType: contextItem.type,
          conditions: [thisCondition],
          contextType: contextType,
        };

        const validation = validateItemCondition(conditionData);
        if (validation) {
          return validation === 'SHOW' ? true : false;
        } else {
          return defaultAction;
        }
      }
      return true;
    } catch (error) {
      return true;
    }
  };

  return (
    <Stack height={'100%'}>
      <Stack
        bgcolor="#F5F5F5"
        p="8px 24px"
        borderBottom="1px solid #E0E0E0"
        gap="8px"
        ref={checklistTopRef}
      >
        <Stack
          flexWrap={'wrap'}
          direction={isMobileDeviceMd ? 'column' : 'row'}
          bgcolor="#F5F5F5"
          spacing={isMobileDeviceMd ? 1 : 2}
        >
          <Box
            className="d-flex justify-content-center align-items-center"
            sx={{
              flex: 1,
              borderBottom: '1px solid #E0E0E0',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <ChecklistProgress
                actionableItemsCount={actionableItemsCount}
                progressObj={checklistProgAndScore?.progress}
              />
            </Box>
          </Box>
          {localChecklist?.Checklist?.isShowScoreInProgress && (
            <Box
              className="d-flex justify-content-center align-items-center"
              sx={{
                flex: 1,
                borderBottom: '1px solid #E0E0E0',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <WeightedScore score={checklistProgAndScore?.score} />
              </Box>
            </Box>
          )}
        </Stack>
        {requiredItemCount > 0 && !reviewMode && showRequiredBanner && (
          <ReviewItemsBanner
            setReviewMode={(mode) => {
              setReviewMode(mode);
              resetItemErrors();
            }}
            requiredItemCount={requiredItemCount}
          />
        )}
      </Stack>
      {!loading && !reviewMode && (
        <Box
          className="d-flex align-items-center"
          sx={{
            p: '8px 24px',
            background: '#F5F5F5',
            boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Stack width="100%" direction="row" justifyContent={'space-between'}>
            <Stack direction="row" gap="8px" alignItems={'center'}>
              <Stack
                direction="row"
                width={isMobileDeviceMd ? '100%' : '165px'}
              >
                <Dropdown onFilterSelect={onFilterSelect} />
              </Stack>
              {localChecklist?.attachments &&
                localChecklist?.attachments?.length > 0 && (
                  <ChecklistAttachments checklist={localChecklist} />
                )}
            </Stack>

            {showFullScreenButton && (
              <Button
                sx={{
                  borderRadius: '8px',
                  height: '36px',
                  width: '130px',
                  color: '#212121',
                  fontWeight: 500,
                  fontSize: '14px',
                  border: '1px solid #E0E0E0',
                }}
                startIcon={<FullScreenRoundedIcon />}
                variant="outlined"
                onClick={handleClickFullScreen}
              >
                Full screen
              </Button>
            )}
          </Stack>
        </Box>
      )}
      <div id="checklist-body" className="px-4 pt-2 pb-4">
        {!localChecklist && (
          <div className="mt-3 mb-3">
            <ChecklistTitleAndAttachments checklist={localChecklist} />
          </div>
        )}
        {loading ? (
          <CheckListSkeleton />
        ) : !reviewMode ? (
          <Stack gap={'16px'}>
            {sections?.nonSection?.map((item) => {
              if (!checkVisibility(item)) return null;
              return (
                <Box
                  key={item.id}
                  ref={
                    item?.updatedAt === localChecklist?.lastItemUpdatedAt
                      ? lastUpdatedRef
                      : null
                  }
                >
                  <TemplateItem
                    showTimeStamp={localChecklist?.Checklist?.isShowTimestamp}
                    isLogSubmitted={isLogSubmitted}
                    onChangeCb={onResponseToItem}
                    item={item}
                    automations={automations}
                    childItemsMap={childItemsMap}
                    lastUpdatedRef={lastUpdatedRef}
                    onNotePostToItem={onNotePostToItem}
                    handleUpdateItemNote={handleUpdateItemNote}
                    handleDeleteChecklistItemNote={
                      handleDeleteChecklistItemNote
                    }
                    handleCreateCorrectiveTask={handleCreateCorrectiveTask}
                    itemErrors={itemErrors}
                    handleShowUploadModal={handleShowUploadModal}
                    isActionsAllowed={isActionsAllowed}
                    taskLocation={taskDetail.LocationId}
                  />
                </Box>
              );
            })}

            {!isLogSubmitted &&
              sections?.sections?.map((section: any) => {
                const sectionScores = checklistProgAndScore.sectionScores;
                const { progress, score } = sectionScores[section.id];
                if (!checkVisibility(section)) return null;
                return (
                  <Accordion
                    sx={{
                      width: '100% !important',
                      boxShadow: 'none',
                      '&:before': {
                        display: 'none',
                      },
                    }}
                    TransitionProps={{ unmountOnExit: true }}
                    defaultExpanded
                    disableGutters
                    square={false}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        background: '#EBEAFF',
                        height: 'fit-content !important',
                        minHeight: 'initial !important',
                        borderRadius: '8px',
                      }}
                    >
                      <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap={'wrap'}
                        gap={isMobileDeviceMd ? '8px' : '0px'}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize="18px"
                          color="#000000DE"
                        >
                          {section.description} ({progress?.completed || '0'} of{' '}
                          {progress?.total || '0'})
                        </Typography>
                        {localChecklist?.Checklist?.isShowScoreInProgress && (
                          <Chip
                            label={`Score: ${score.percentage}% (${score.earned} of ${score.total} pts)`}
                            size="small"
                            sx={{
                              background: '#CCCBFF',
                              fontWeight: 700,
                              fontSize: '13px',
                            }}
                          />
                        )}
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 0, pb: 0 }}>
                      <Stack spacing={2}>
                        {section?.items?.map((item) => {
                          if (!checkVisibility(item)) return null; // Skip rendering if the condition is not matched
                          return (
                            <Box
                              key={item.id}
                              ref={
                                item?.updatedAt ===
                                localChecklist?.lastItemUpdatedAt
                                  ? lastUpdatedRef
                                  : null
                              }
                            >
                              <TemplateItem
                                showTimeStamp={
                                  localChecklist?.Checklist?.isShowTimestamp
                                }
                                isLogSubmitted={isLogSubmitted}
                                onChangeCb={onResponseToItem}
                                item={item}
                                automations={automations}
                                childItemsMap={childItemsMap}
                                lastUpdatedRef={lastUpdatedRef}
                                onNotePostToItem={onNotePostToItem}
                                handleUpdateItemNote={handleUpdateItemNote}
                                handleDeleteChecklistItemNote={
                                  handleDeleteChecklistItemNote
                                }
                                handleCreateCorrectiveTask={
                                  handleCreateCorrectiveTask
                                }
                                itemErrors={itemErrors}
                                handleShowUploadModal={handleShowUploadModal}
                                isActionsAllowed={isActionsAllowed}
                                taskLocation={taskDetail.LocationId}
                              />
                            </Box>
                          );
                        })}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            {isLogSubmitted && filteredItems && (
              <TemplateBody
                templateLog={{
                  ...localChecklist,
                  isShowTimestamp: localChecklist?.Checklist?.isShowTimestamp,
                }}
                logChecklistItems={filteredItems}
                isPreview
              />
            )}
            {!isLogSubmitted && (
              <GlobalNotes
                isLogSubmitted={isLogSubmitted}
                onNotePostToChecklist={onNotePostToChecklist}
                handleUpdateChecklistNote={handleUpdateChecklistNote}
                handleDeleteChecklistNote={handleDeleteChecklistNote}
                globalNotes={localChecklist?.TaskChecklistNotes}
              />
            )}
          </Stack>
        ) : (
          <ReviewMode
            requiredItems={requiredItems}
            onNotePostToItem={onNotePostToItem}
            handleUpdateItemNote={handleUpdateItemNote}
            handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
            handleCreateCorrectiveTask={handleCreateCorrectiveTask}
            itemErrors={itemErrors}
            setReviewMode={(mode) => {
              setReviewMode(mode);
              resetItemErrors();
            }}
            isLogSubmitted={isLogSubmitted}
            localChecklist={localChecklist}
            onResponseToItem={onResponseToItem}
            automations={automations}
            childItemsMap={childItemsMap}
            lastUpdatedRef={lastUpdatedRef}
            requiredItemCount={requiredItemCount}
            handleShowUploadModal={handleShowUploadModal}
          />
        )}
        {checklist?.status === 'In Progress' && !hideBottomButtons && (
          <Box mt="16px" className="d-flex justify-content-end">
            <CustomButton
              variant="outlined"
              className="mr-2"
              disableElevation
              onClick={handleGoBack}
            >
              Go Back
            </CustomButton>
            <CustomButton
              variant="contained"
              disableElevation
              onClick={() => handleChecklistSubmit(localChecklist)}
            >
              Submit
            </CustomButton>
          </Box>
        )}
      </div>
      {CustomFooter &&
        React.cloneElement(CustomFooter, {
          handleSubmit: () => handleChecklistSubmit(localChecklist),
          ...customFooterProps,
        })}
      {requireImageModal?.open && (
        <ActionConfirmationModal
          open={requireImageModal.open}
          actionType={requireImageModal.actionType}
          onAction={requireImageModal.onAction}
          onClose={() => setRequireImageModal(null)}
          onUploadCallback={handleUploadImageToItem}
          loading={uploading}
        />
      )}
      {requireTaskModal?.open && (
        <ActionConfirmationModal
          open={requireTaskModal.open}
          actionType={requireTaskModal.actionType}
          onAction={requireTaskModal.onAction}
          onClose={() => setRequireTaskModal(null)}
          onUploadCallback={handleUploadImageToItem}
          loading={uploading}
        />
      )}
      {requireAutomationImageModal?.open && (
        <ActionConfirmationModal
          open={requireAutomationImageModal.open}
          actionType={requireAutomationImageModal.actionType}
          onAction={requireAutomationImageModal.onAction}
          onUploadCallback={handleUploadImageToAutomation}
          loading={uploading}
        />
      )}
    </Stack>
  );
};

export default TaskChecklist;
