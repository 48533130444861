import { colors } from 'components/theme/constants';
import styled from '@mui/system/styled';
import { styled as barStyled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const PlannedTaskIdChip = styled('span')({
  border: '1px solid rgba(104,104,254,1)',
  padding: '3px 10px',
  color: 'rgba(104,104,254,1)',
  fontSize: 12,
  borderRadius: 4,
});
export const TasksListSummaryCnt = styled('div')({
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 12,
  height: '100%',
});
export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    marginLeft: 8,
    color: 'rgba(0,0,0,0.87) !important',
  },
});
export const GroupRowRendererCnt = styled('div')({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  '.childCount': {
    background: 'rgba(0, 0, 0, 0.06)',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '157%',
    marginLeft: 8,
    padding: '4px 6px',
  },
});
export const AssigneeGroupRowRendererCnt = styled('div')({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  display: 'flex',
  alignItems: 'center',
  '.assigneeValueCnt': {
    display: 'flex',
    alignItems: 'center',
  },
  '.childCount': {
    background: 'rgba(0, 0, 0, 0.06)',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '157%',
    marginLeft: 8,
    padding: '4px 6px',
  },
});

export const ScheduleCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    gap: 6,
    background: '#6868FE',
    borderRadius: 20,
    '& svg': {
      color: '#6868FE',
      height: 16,
      width: 16,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18.59px',
      letterSpacing: '0.17px',
      color: colors.white,
    },
  },
});

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 300,
      fontSize: 14,
      color: colors.black,
      opacity: 0.87,
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'ease all 0.2s',
        fontWeight: 700,
      },
    },
  },
});

export const StatusCellCnt = styled('div')({
  textAlign: 'center',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const PriorityCellCnt = styled('div')({
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const CategoryChip = styled('span')({
  color: 'rgba(0,0,0, 0.87)',
  fontWeight: 400,
  padding: '8px 12px',
  borderRadius: 4,
});
export const DateCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: 'rgba(0,0,0, 0.87)',
    display: 'inline-block',
    marginLeft: 5,
  },
  '& svg': {
    color: 'rgba(0,0,0, 0.87)',
    fontSize: 18,
  },
});
export const ActionsCellCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& .checklistCount': {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    height: 22,
    marginRight: 4,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#6868fe1F',
    },
    '& .countText': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 3,
    },

    '& svg': {
      fontSize: 17,
    },
  },
  '& .commentsCount': {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    height: 22,
    display: 'flex',
    padding: '0 4px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#6868fe1F',
    },
    '& .countText': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 3,
    },
    '& .unreadCountText': {
      color: '#6868fe',
    },
    '& svg': {
      fontSize: 17,
    },
  },
});

export const CellContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: 'max-content',
});

export const AddContentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: '#F0F0FF',
  paddingLeft: 24,
  '& svg': {
    marginRight: 10,
    color: colors.white,
    background: colors.primary.main,
    borderRadius: '50%',
    padding: 3,
  },
  '& .content': {
    fontSize: 14,
    fontWeight: 500,
    color: colors.primary.main,
  },
});
export const CreateTask = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '0px 5px 0px 0',
  '& .inputCnt': {
    '& input': {
      fontSize: 15,
      padding: '10.5px 14px 10.5px 11px',
    },
  },
  '& .addBtn': {
    width: 70,
    height: 30,
    fontWeight: 500,
    fontSize: 13,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    color: colors.primary.main,
  },
  borderBottom: `1px solid ${colors.primary.main}`,
});

export const StatusCell = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  display: 'flex',
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: 14,
  letterSpacing: '0.4px',
  color: 'rgba(0,0,0, 0.87)',
  gap: 8,
  alignItems: 'center',
  '& .statusDot': {
    width: 10,
    height: 10,
    borderRadius: 10,
    background: color,
  },
  '& .statusText': {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
    color: 'rgba(0,0,0, 0.87)',
  },
}));

export const TemplateIconAndNameCnt = styled('div')(() => ({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  '& .name': {
    fontWeight: 400,
    fontSize: 14,
    color: 'rgba(0,0,0, 0.87)',
    letterSpacing: 0.17,
    width: 'calc(100% - 42px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const IconContainer = styled('div', {
  shouldForwardProp: (prop) => !['bgcolor'].includes(prop),
})(({ bgcolor }) => ({
  width: 30,
  height: 30,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AnonymousAvatarCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  height: '100%',
  '& .submitter': {
    fontWeight: 400,
    fontSize: 14,
    color: 'rgba(0,0,0, 0.87)',
    letterSpacing: 0.17,
  },
}));

export const AnonymousAvatar = styled('div')(({ theme }) => ({
  height: 28,
  width: 28,
  borderRadius: 28,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ItemsCnt = styled('div')({
  fontSize: '12px',
  fontWeight: 500,
});

export const BorderLinearProgress = barStyled(LinearProgress)(
  ({ theme, progressBarBg, progressBarFg }) => {
    return {
      height: 6,
      borderRadius: 3,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: progressBarBg,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 3,
        backgroundColor: `${progressBarFg} !important`,
      },
    };
  },
);

export const TaskId = styled('p')({
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: 0.17,
  color: 'rgba(104, 104, 254, 1)',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const DateAndTimeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: 14,
  letterSpacing: 0.17,
  color: 'rgba(0,0,0, 0.87)',
  gap: 4,
  height: '100%',
});

export const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: 13,
  gap: 6,
  height: '100%',
  '& svg': {
    color: 'rgba(117, 117, 117, 1)',
  },
});
