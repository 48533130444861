export const TOP_BAR_NAVIGATION_LINKS = {
  HOME: 'HOME',
  OPERATIONS: 'OPERATIONS',
  FACILITIES: 'FACILITIES',
  REPORTING: 'REPORTING',
  LOCATIONS: 'LOCATIONS',
  SETTINGS: 'SETTINGS',
  ADDONS: 'ADDONS',
  NOTIFICATIONS: 'NOTIFICATIONS',
};
