import { FC } from 'react';
import TimerIcon from 'components/Icons/timerIcon';
import CTypography from 'components/CTypography';
import MissedIcon from 'components/Icons/missedIcon';
import { OverDueCnt } from './overdue.style';
import { Stack } from '@mui/material';
import moment from 'moment-timezone';
import { getTimeZone } from 'utils/globalFunction';

const Overdue: FC<any> = (props) => {
  const { date, isMissed } = props;
  const tz: string = getTimeZone();

  return (
    <OverDueCnt>
      {isMissed ? <MissedIcon /> : <TimerIcon />}
      <CTypography className="label">
        {isMissed ? 'Missed' : 'Overdue'}
      </CTypography>
      <Stack direction="row" alignItems={'center'} gap="4px">
        <CTypography>
          ({moment.tz(date, tz).format('MMM DD YYYY hh:mm A')})
        </CTypography>
      </Stack>
    </OverDueCnt>
  );
};

export default Overdue;
