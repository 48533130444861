import { clone } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialReportsStateData } from 'store/initialStateData';

export const reportsListReducer = (
  state: any = initialReportsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_REPORTS: {
      return {
        ...state,
        reportsList: action.payload,
      };
    }
    case actionTypes.SET_REPORTS_FETCHED_INFO: {
      const fetchedInfo = action.payload;
      return { ...state, fetched: fetchedInfo };
    }
    default:
      return state;
  }
};
