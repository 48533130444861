// Custom Icons
import CalendarIcon from 'components/Icons/calendarIcon';

import { DateCnt } from './taskListView.style';
import { getTimeZone } from 'utils/globalFunction';
import moment, { Moment } from 'moment-timezone';

const DateRenderer = ({ date, isGenerated = false }) => {
  const tz: string = getTimeZone();

  let localTime: Moment | null = null;
  let formattedTime = '';

  if (date) {
    localTime = moment.tz(date, tz);

    // Check if DST is active
    if (isGenerated && localTime?.isDST()) {
      localTime = localTime?.subtract(1, 'hour');
    }

    formattedTime = localTime?.format('MMM DD, YYYY [at] h:mm A');
  }

  return (
    <DateCnt>
      <CalendarIcon />
      <span>{formattedTime || '-'}</span>
    </DateCnt>
  );
};

export default DateRenderer;
