import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { Chip } from '@mui/material';
import React from 'react';
import GroupInput from 'components/GroupInput';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import LocationStep from 'components/LocationStep';
import ProcedureIcon from 'components/Icons/templateStepIcons2/procedureIcon';
import PassFailIcon from 'components/Icons/templateStepIcons2/passFailIcon';
import TakePhotoIcon from 'components/Icons/templateStepIcons2/takePhotoIcon';
import NumberIcon from 'components/Icons/templateStepIcons2/numberIcon';
import TemperatureIcon from 'components/Icons/templateStepIcons2/temperatureIcon';
import CostIcon from 'components/Icons/templateStepIcons2/costIcon';
import McqIcon from 'components/Icons/templateStepIcons2/mcqIcon';
import SignatureIcon from 'components/Icons/templateStepIcons2/signatureIcon';
import DateTimeIcon from 'components/Icons/templateStepIcons2/dateTimeIcon';
import LongDescriptionIcon from 'components/Icons/templateStepIcons2/longDescriptionIcon';
import GeoStampIcon from 'components/Icons/templateStepIcons2/geoStampIcon';
import DropdownIcon from 'components/Icons/templateStepIcons2/dropdownIcon';
import LocationIcon from 'components/Icons/templateStepIcons2/locationIcon';
import PhotoAnnotateIcon from 'components/Icons/templateStepIcons2/photoAnnotateIcon';
import TextFieldIcon from 'components/Icons/templateStepIcons2/textFieldIcon';
import SectionIcon from 'components/Icons/templateStepIcons2/sectionIcon';
import LocationsGroupsContainer from 'components/LocationsGroups/Items/container';
import LocationGroupStep from 'components/LocationStep/locationGroup';

const entityConditionInput = {
  checklistItem: (field) => {
    switch (field.type) {
      case 'passFail':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = field.options.map((option) => ({
                  key: option.value,
                  value: option.value,
                  label: (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      color={option.type}
                    />
                  ),
                }));

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    options={options}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };
      case 'multipleChoice':
      case 'dropdown':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = field.options.map((option) => ({
                  key: option.id,
                  value: option.id,
                  flag: option.flag,
                  label: (
                    <Chip
                      variant={
                        field.type === 'multipleChoice' ? 'outlined' : 'filled'
                      }
                      label={option.label}
                      size="small"
                      sx={{
                        borderColor: option.color,
                        color:
                          field.type === 'multipleChoice'
                            ? option.color
                            : '#fff',
                        backgroundColor:
                          field.type === 'multipleChoice'
                            ? '#fff'
                            : option.color,
                      }}
                    />
                  ),
                }));

                let selected: any = undefined;
                if (props.value) {
                  selected = options.find((op) => op.value === props.value);
                } else {
                  selected = options.find((op) => op.flag);
                  if (selected?.value) {
                    props.onChange(selected.value);
                  }
                }

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };
      case 'location':
        return (condition, context) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                return (
                  <div style={{ width: '260px' }}>
                    {context === 'LocationGroup' ? (
                      <LocationGroupStep
                        compact
                        onChange={(value) => {
                          props?.onChange(value);
                        }}
                        answer={{ value: props?.value }}
                      />
                    ) : (
                      <LocationStep
                        compact
                        onChange={(value) => {
                          props?.onChange(value);
                        }}
                        answer={{ value: props?.value }}
                        isMulti={true}
                      />
                    )}
                  </div>
                );
              };
          }
        };
      case 'procedure':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = [
                  {
                    key: 'checked',
                    value: true,
                    label: 'Checked',
                  },
                ];

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };

      case 'takePhoto':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = [
                  {
                    key: 'taken',
                    value: 'taken',
                    label: 'Photo Taken',
                  },
                ];

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };

      case 'textField':
      case 'number':
      case 'cost':
      case 'temperature':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
              return (props) => (
                <>
                  <CustomInput
                    fieldProps={{
                      autoFocus: true,
                      defaultValue: props.value,
                      onChange: (e) => {
                        props.onChange(e.target.value);
                      },
                    }}
                    sx={{ width: '120px' }}
                  />
                  {field.unit && <span>{field.unit}</span>}
                </>
              );
            case 'btw':
            case 'nbtw':
              return (props) => (
                <>
                  <GroupInput
                    value={props.value}
                    onChange={(value) => {
                      props.onChange(value);
                    }}
                  />
                  {field.unit && <span>{field.unit}</span>}
                </>
              );
          }
        };
      case 'dateTime':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
              return (props) => (
                <DatePickerDropdown
                  onDateSelect={props.onChange}
                  mode={field.unit}
                  selected={props.value}
                  placeholder={
                    field.unit === 'dateTime'
                      ? 'MM/DD/YYYY - HH:MM A'
                      : 'MM/DD/YYYY'
                  }
                />
              );
          }
        };
    }
  },
};

export const getConditionInput = (
  entity: string,
  field: any,
  condition: string,
  context: string,
) => {
  return entityConditionInput[entity](field)(condition, context);
};

export const stepMap = (key) => {
  const maps = {
    procedure: {
      icon: <ProcedureIcon />,
      label: 'Procedure',
      bgColor: 'rgba(232, 245, 233, 1)',
    },
    passFail: {
      icon: <PassFailIcon />,
      label: 'Pass/Fail',
      bgColor: 'rgba(225, 245, 254, 1)',
    },
    takePhoto: {
      icon: <TakePhotoIcon />,
      label: 'Take Photo',
      bgColor: 'rgba(255, 249, 196, 1)',
    },
    textField: {
      icon: <TextFieldIcon />,
      label: 'Text Answer',
      bgColor: 'rgba(224, 242, 241, 1)',
    },
    number: {
      icon: <NumberIcon />,
      label: 'Number',
      bgColor: 'rgba(236, 239, 241, 1)',
    },
    temperature: {
      icon: <TemperatureIcon />,
      label: 'Temperature',
      bgColor: 'rgba(252, 228, 236, 1)',
    },
    cost: {
      icon: <CostIcon />,
      label: 'Cost',
      bgColor: 'rgba(255, 243, 224, 1)',
    },
    multipleChoice: {
      icon: <McqIcon />,
      label: 'Multiple Choice',
      bgColor: 'rgba(224, 247, 250, 1)',
    },
    signature: {
      icon: <SignatureIcon />,
      label: 'Signature',
      bgColor: 'rgba(254, 235, 238, 1)',
    },
    dateTime: {
      icon: <DateTimeIcon />,
      label: 'Date & Time',
      bgColor: 'rgba(240, 244, 195, 1)',
    },
    instruction: {
      icon: <LongDescriptionIcon />,
      label: 'Long Description',
      bgColor: 'rgba(251, 233, 231, 1)',
    },
    geoStamp: {
      icon: <GeoStampIcon />,
      label: 'Geo Stamp',
      bgColor: 'rgba(239, 235, 233, 1)',
    },
    dropdown: {
      icon: <DropdownIcon />,
      label: 'Dropdown',
      bgColor: 'rgba(241, 248, 233, 1)',
    },
    location: {
      icon: <LocationIcon />,
      label: 'Location',
      bgColor: 'rgba(224, 242, 241, 1)',
    },
    photoAnnotate: {
      icon: <PhotoAnnotateIcon />,
      label: 'Photo Annotate',
      bgColor: 'rgba(243, 229, 245, 1)',
    },
    header: {
      icon: <SectionIcon style={{ color: '#6868FE', fill: 'none' }} />,
      label: 'Section',
      bgColor: '#6868FE',
    },
  };
  return maps[key] ? maps[key] : null;
};

export const generateTemplateItemsData = (data, ordreHash = {}) => {
  let number = 0;
  const ddData = data
    ? data.map((t, i) => {
        let index = '';
        if (t.type !== 'header') {
          number++;
          index = ordreHash[t.id] || number;
        }
        const transformedItem = {
          label: `${t.description}`,
          value: t.description,
          type: t.type,
          id: t.id,
          obj: t,
          startIcon: (
            <span
              style={{
                display: 'flex',
                minWidth: 20,
                height: 20,
                padding: '0px 7px',
                WebkitFlexDirection: 'column',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#4E48FA',
                fontSize: 11,
                fontWeight: 700,
                background: '#EBEAFF',
                borderRadius: '50px',
              }}
            >
              {index}
            </span>
          ),
          itemOrder: index,
          itemIcon: stepMap(t.type).icon,
          listItemProps: {
            styleSelected: true,
            selectedStyles: {
              padding: '7px 16px',
              background: 'rgb(227, 227, 255)',
              color: '#4E48FA',
            },
            style: { borderRadius: 5, margin: '0px 10px' },
          },
        };

        if (t.ChildItems && t.ChildItems.length > 0) {
          return {
            ...transformedItem,
            child: generateTemplateItemsData(t.ChildItems, ordreHash),
          };
        }
        return transformedItem;
      })
    : [];

  return ddData;
};
