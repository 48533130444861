// React
import { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';

// Custom components
import TemplateItem from 'components/TemplateSubmissionPreview/TemplateItem/TemplateItem';

// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';

// Styled
import {
  Footer,
  Logo,
  PageWrapper,
  SubmitButton,
  TemplateBody,
  TemplateHeader,
  TemplateNameText,
  TemplateWrapper,
  Header,
} from './styled';

// Types
import {
  SubmissionItemType,
  SubmissionReportType,
} from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Utils
import xeniaApi from 'api/index';
import { publicUploadFile, updateTemplateLogItem } from 'api/checklistLogApi';
import { getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

// Constant
import DIALOGS from 'utils/dialogIds';
import { filter, groupBy } from 'lodash';
import selectors from 'store/selectors';
import CustomButton from 'components/Button/CustomButton';
import SubmissinShareDialog from 'components/AllDialogs/submissionShare';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { validateItemAutomations } from 'xeniautils';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import { showMessageNotification } from 'utils/globalFunction';

interface SubmissionPropTypes {
  log: SubmissionReportType;
  workspaceId: string;
  logo?: string;
  onSubmitSuccess: () => void;
  hotelTemplateId?: string;
}

const PublicTemplateLog = ({
  log,
  workspaceId,
  logo,
  onSubmitSuccess,
  hotelTemplateId,
}: SubmissionPropTypes) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(log.progress);
  const [currentLog, setCurrentLog] = useState(log);
  const [itemErrors, setItemErrors] = useState({});

  const automations = useSelector(selectors.getChecklistItemsAutomations);
  const dialogState = useSelector(getDialogState);

  useEffect(() => {
    dispatch(
      actions.fetchChecklistItemsAutomations(workspaceId, log.ChecklistId),
    );
  }, [log?.ChecklistId, workspaceId]);

  useEffect(() => {
    if (currentLog) {
      setItemErrors(getInitialErrors(currentLog));
    }
  }, [currentLog]);

  const childItemsMap = useMemo(() => {
    currentLog.TaskChecklistItems;

    return groupBy(
      filter(currentLog?.TaskChecklistItems, (item) => item?.ParentId),
      'ParentId',
    );
  }, [currentLog]);

  const submitApiCall = async () => {
    setLoading(true);
    await xeniaApi.submitPublicTemplateLog({
      logId: log.id,
      hotelId: workspaceId,
      templateId: log.ChecklistId,
      customHeaders: {
        [LOCALSTORAGE_KEYS.ANON_ID]: getItem(LOCALSTORAGE_KEYS.ANON_ID),
      },
    });
    setLoading(false);
    onSubmitSuccess();
  };

  const closeConfirmation = () => {
    dispatch(actions.closeDialog(DIALOGS.CONFIRMATION));
  };

  const getInitialErrors = (checklist) => {
    const errors = {};
    checklist?.TaskChecklistItems?.forEach((cItem) => (errors[cItem.id] = []));
    return errors;
  };

  const getAutomationActions = (item) => {
    const conditionData = {
      answer: item?.answers?.value,
      itemType: item?.type,
      automations: automations?.[item.ChecklistItemId],
    };
    return validateItemAutomations(conditionData);
  };

  const getAutomationErrors = (item) => {
    let actions = getAutomationActions(item);
    actions = actions.filter(
      (a) => a.type !== AUTOMATION_ACTIONS.SEND_NOTIFICATION,
    );
    const errObj: {
      hasError: boolean;
      error: string | null;
      isAutomation: boolean;
    } = {
      hasError: false,
      error: null,
      isAutomation: actions?.length > 0,
    };

    // for (let i = 0; i < actions.length; i++) {
    //   const element = actions[i];
    //   if (element.type === AUTOMATION_ACTIONS.REQUITE_TASK) {
    //     if (item.Tasks.length > 0) {
    //       errObj['hasError'] = false;
    //       errObj['error'] = null;
    //     } else {
    //       errObj['hasError'] = true;
    //       errObj['error'] =
    //         'Your response requires you to create a task for completion';
    //     }
    //   }
    //   if (element.type === AUTOMATION_ACTIONS.REQUIRE_IMAGE) {
    //     if (
    //       item.TaskChecklistItemNotes.some(
    //         (note) => note.attachments.length > 0,
    //       )
    //     ) {
    //       errObj['hasError'] = false;
    //       errObj['error'] = null;
    //     } else {
    //       errObj['hasError'] = true;
    //       errObj['error'] = 'Your response requires you to add an image';
    //     }
    //   }
    // }

    return errObj;
  };

  const getItemError = (cItem: any) => {
    const automationErrors = getAutomationErrors(cItem);
    if (automationErrors.hasError) {
      return automationErrors.error;
    }
    const hasAnswers = cItem.answers?.value;
    if (!hasAnswers) {
      if (cItem.required) {
        return 'This step is required';
      } else if (
        cItem.type === 'instruction' &&
        cItem.options.requireAcknowledgement
      ) {
        return 'This step requires acknowledgement';
      }
    }
    // if (!hasAnswers) {
    //   if (imageRequired) {
    //     return 'This step requires an Image';
    //   } else if (cItem.required) {
    //     return 'This step is required';
    //   } else if (
    //     cItem.type === 'instruction' &&
    //     cItem.options.requireAcknowledgement
    //   ) {
    //     return 'This step requires acknowledgement';
    //   }
    // } else if (imageRequired) {
    //   return 'This step requires an Image';
    // }
    return null;
  };

  const getFollowupQuestions = (item) => {
    if (!item?.answers?.value) return null;

    const conditionData = {
      answer: item.answers.value,
      itemType: item.type,
      automations: automations?.[item.ChecklistItemId],
    };

    const actions = validateItemAutomations(conditionData);

    const followupAction: any = actions.find(
      (action: any) => action.type === AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
    );

    if (!followupAction || !childItemsMap?.[item?.ChecklistItemId]?.length)
      return null;

    const childs = childItemsMap[item.ChecklistItemId];
    const childItemsGroupByAutomation = groupBy(childs, 'RelatedAutomationId');

    return childItemsGroupByAutomation[followupAction?.automationId];
  };

  const getRequiredStepsErrors = (log) => {
    const items = log.TaskChecklistItems;
    const _errors = getInitialErrors(log);

    const appendError = (itemId: string, error: string) => {
      _errors[itemId] = [..._errors[itemId], error];
    };

    // write logic here for required checklistLog item and set errors
    // does not check for follow up item
    items.forEach((cItem) => {
      if (cItem.type !== 'header' && cItem.ParentId === null) {
        const error = getItemError(cItem);
        if (error) {
          appendError(cItem.id, error);
        }
      }
    });

    // check for follow up questions only
    items.forEach((cItem) => {
      const _followUps: any = getFollowupQuestions(cItem);
      if (cItem.type !== 'header' && _followUps?.length > 0) {
        _followUps.forEach((followUp) => {
          const error = getItemError(followUp);
          if (error) {
            appendError(followUp.id, error);
          }
        });
      }
    });

    return {
      errors: _errors,
      hasErrors: Object.values(_errors).some((e: any) => e.length > 0),
    };
  };

  const checkErrorsBeforeSubmission = () => {
    const { errors, hasErrors } = getRequiredStepsErrors(currentLog);
    if (!hasErrors) {
      return true;
    } else {
      setItemErrors(errors);
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!checkErrorsBeforeSubmission()) {
      showMessageNotification('Please complete all required items', 'error');
      return;
    }
    if (
      progress.progress !== 100 &&
      progress?.count - progress?.completedCount > 0
    ) {
      dispatch(
        actions.setDialog({
          dialogId: 'confirmationDialog',
          data: {
            title: 'Submit Incomplete?',
            description: `Are you sure you want to Submit, you have ${
              progress?.count - progress?.completedCount
            } steps remaining`,
            cancellationText: 'No, continue submission',
            confirmationText: 'Yes, submit',
            onCancelCallback: closeConfirmation,
            onConfirmCallback: () => {
              submitApiCall();
              closeConfirmation();
            },
            confirmButtonProps: {
              color: 'error',
              sx: {
                textTransform: 'capitalize',
              },
            },
          },
          open: true,
        }),
      );
      return;
    }
    await submitApiCall();
  };

  const handleUpdateCurrentLog = (item: SubmissionItemType) => {
    setCurrentLog((prevState) => ({
      ...prevState,
      TaskChecklistItems: prevState.TaskChecklistItems.map((oldItem, idx) => {
        if (oldItem.id === item.id) return item;
        return oldItem;
      }),
    }));
  };

  const handleFormatUploadResponse = (data: any) => {
    return data.filePath.map((path: string) => `${data.baseUrl}${path}`);
  };

  const handleTakePhoto = async (files: File[]) => {
    const data = await publicUploadFile({
      files,
      templateId: log.ChecklistId,
      workspaceId: workspaceId,
      logId: log.id,
    });
    return handleFormatUploadResponse(data);
  };

  const handleUpdateItem = useCallback(
    async (item: SubmissionItemType, value: any) => {
      let newValue = value;
      const updateData = {
        logId: log.id,
        hotelId: workspaceId,
        itemId: item.id,
        templateId: log.ChecklistId,
        data: {
          answers: {
            value,
          },
        },
        customHeaders: {
          [LOCALSTORAGE_KEYS.ANON_ID]: getItem(LOCALSTORAGE_KEYS.ANON_ID),
        },
      };

      if (item.type === 'takePhoto') {
        const newFilesPaths = await handleTakePhoto(value);
        const allFilePaths = [
          ...(item.answers?.value ? (item.answers.value as string[]) : []),
          ...newFilesPaths,
        ];
        updateData.data.answers.value = allFilePaths;
        newValue = allFilePaths;
      }

      if (item.type === 'photoAnnotate') {
        const newFilesPaths = await handleTakePhoto(value);
        updateData.data.answers.value = newFilesPaths;
        newValue = newFilesPaths;
      }

      handleUpdateCurrentLog({
        ...item,
        answers: {
          value: newValue,
        },
      });
      const result = await updateTemplateLogItem(updateData);
      if (result?.data) {
        setProgress(result.data.data.Checklist.progress);
      } else {
        handleUpdateCurrentLog(item);
      }
    },
    [currentLog],
  );

  return (
    <PageWrapper>
      <Header
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Logo src={logo && logo !== '' ? logo : XeniaLogo} />
        <CustomButton
          variant="contained"
          onClick={() => {
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.SUBMISSION_SHARE_DIALOG,
                open: true,
                data: {
                  shareUrl: `${window.location.origin}/public-report/${hotelTemplateId}?logId=${currentLog?.id}`,
                  templateName: currentLog?.name,
                },
              }),
            );
          }}
        >
          Share
        </CustomButton>
      </Header>
      <TemplateWrapper>
        <TemplateHeader>
          <TemplateNameText>{currentLog.name}</TemplateNameText>
        </TemplateHeader>
        <TemplateBody>
          {currentLog?.TaskChecklistItems?.filter((item) => !item.ParentId).map(
            (item) => (
              <TemplateItem
                key={item.id}
                item={item}
                childItemsMap={childItemsMap}
                automations={automations}
                onChangeCb={(itm, value) => handleUpdateItem(itm, value)}
                hideCorrectiveTask
                error={itemErrors?.[item.id]}
                allErrors={itemErrors}
                isPreview={false}
                isPublic
                hideFeedback={true}
              />
            ),
          )}
        </TemplateBody>
      </TemplateWrapper>
      <Footer>
        <SubmitButton
          onClick={handleSubmit}
          buttonLoader={loading}
          variant="contained"
          disabled={loading}
        >
          Submit
        </SubmitButton>
      </Footer>
      {dialogState?.[DIALOGS.SUBMISSION_SHARE_DIALOG]?.open && (
        <SubmissinShareDialog dialogId={DIALOGS.SUBMISSION_SHARE_DIALOG} />
      )}
    </PageWrapper>
  );
};

export default PublicTemplateLog;
