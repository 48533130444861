import { getPaginatedTasksApi, getTasksApi } from 'api/tasksApi';
import { useReactRouter } from 'controller/useReactRouter';
import { TaskManagementStateModel } from 'models/taskModel';
import {
  DEFAULT_TASKS_GROUP_BY_PROPERTY,
  GROUP_BY_PROPERTIES,
  SORTING_PROPERTIES,
} from 'pages/constants';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { formatFilters } from 'utils/Task/filters';
import { useAssigneesUtils } from './useAssigneesUtil';
import { sortBy } from 'lodash';

export const initialParams: any = {
  offset: 0,
  limit: 1000000,
  searchText: '',
  hideCompleted: false,
  advanceFilters: null,
  view: 'list',
  viewType: 'current',
};

export const initialPaginatedParams: any = {
  offset: 0,
  limit: 100,
  searchText: '',
  hideCompleted: false,
  advanceFilters: null,
  view: 'list',
  viewType: 'current',
};

export const initialCalendarParams: any = {
  ...initialParams,
  view: 'calendar',
  viewType: '',
};
function useTaskList() {
  const { handleGetPathName } = useReactRouter();

  const dispatch = useDispatch();
  const { addNewAssigneesUsingLookup } = useAssigneesUtils();
  const taskFilters = useSelector(selectors.getTaskListFilters);
  const showTaskListLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksList', value: true }));
  };
  const showTaskCalendarLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: true }));
  };
  const hideTaskCalendarLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: false }));
  };

  const hideTaskListLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksList', value: false }));
  };

  const isCalendarView: () => boolean = (): boolean =>
    handleGetPathName()?.includes('/tasks/calendar');

  const getPostObj = (filters: any, params: any) => {
    const customTaskFiltersObj = filters ? formatFilters(filters) : null;
    const filtersObj = taskFilters ? formatFilters(taskFilters) : null;
    const filtersPostObj = {
      ...initialParams,
      ...params,
      advanceFilters: customTaskFiltersObj || filtersObj,
    };
    return filtersPostObj;
  };

  const getPostPaginatedObj = (filters: any, params: any) => {
    const customTaskFiltersObj = filters ? formatFilters(filters) : null;
    const filtersObj = taskFilters ? formatFilters(taskFilters) : null;
    const filtersPostObj = {
      ...initialPaginatedParams,
      ...params,
      advanceFilters: customTaskFiltersObj || filtersObj,
    };
    return filtersPostObj;
  };

  const getTasks = async (
    filters: any = null,
    params = {},
    loader = true,
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      loader && showTaskListLoader();

      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskListLoader();
    }
  };
  const getCalendarTasks = async (
    filters: any = null,
    params = {},
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      showTaskCalendarLoader();
      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskCalendarLoader();
    }
  };

  const getTasksCommon = async (
    filters: any = null,
    params = {},
    loader = true,
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      loader && showTaskListLoader();
      showTaskCalendarLoader();

      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskListLoader();
      hideTaskCalendarLoader();
    }
  };

  const getPaginatedTasks = async (
    filters: any = null,
    params = {},
    currentTaskList: any = [],
    loader = false,
  ) => {
    let data;
    const filtersPostObj = getPostPaginatedObj(filters, params);

    try {
      loader && showTaskListLoader();
      const taskAdminResponse = await getPaginatedTasksApi(filtersPostObj);

      data = {
        ...taskAdminResponse,
        data: {
          ...taskAdminResponse?.data,
          rows: [
            ...(currentTaskList ?? []),
            ...(taskAdminResponse?.data?.rows ?? []),
          ],
        },
      };

      dispatch(actions.setTaskListData(data));
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskListLoader();
      hideTaskCalendarLoader();
    }
    return data;
  };

  //Clear task list filters
  const handleClearFilter = (forcedFilters: any = null) => {
    dispatch(actions.setTaskListFilters(null));
    dispatch(actions.setTaskCalendarFilters(null));
    getTasks(forcedFilters, initialParams, true, true);
  };
  const handleClearCalendarFilter = (forcedFilters: any = null) => {
    dispatch(actions.setTaskCalendarFilters(null));
    getCalendarTasks(forcedFilters, initialCalendarParams, true);
  };

  // Get Group By Property
  const getGroupByProperty = (dbFilters: any, currentTab: string) => {
    const columnsData = dbFilters?.tabs?.[currentTab]?.columns;

    if (!columnsData) return DEFAULT_TASKS_GROUP_BY_PROPERTY;

    const groupBy = columnsData?.find((col) => col?.rowGroup)?.colId;

    return GROUP_BY_PROPERTIES[groupBy];
  };

  const getSortByProperty = (dbFilters: any, currentTab: string) => {
    const columnsData = dbFilters?.tabs?.[currentTab]?.columns;

    if (!columnsData)
      return {
        orderBy: undefined,
        orderDirection: undefined,
      };

    const sortedColumn = columnsData?.find((col) => col?.sort);

    return sortedColumn
      ? {
          orderBy: SORTING_PROPERTIES?.[sortedColumn?.colId],
          orderDirection: sortedColumn?.sort,
        }
      : {
          orderBy: undefined,
          orderDirection: undefined,
        };
  };

  // Transform task list
  const transformTaskList = (
    tasks,
    commentChannelsHash,
    checkList,
    projects,
    schedules,
    lookupData,
  ) => {
    try {
      const {
        locations,
        assets,
        users,
        teams,
        roles,
        checklists,
        taskTemplates,
      } = lookupData?.data || {};
      const transformed =
        tasks?.map((t) => {
          const channelId = `task_comments_${t.id}`;
          const channel = commentChannelsHash[channelId];
          const commentsCount = channel?.state;
          const attachedCheckList = checklists?.[t.ChecklistId];

          const project = projects?.list?.find(
            (item) => item?.id === t?.ProjectId,
          );

          const schedule = schedules?.list?.find(
            (item) => item?.id === t?.taskUniqueId,
          );

          const location = locations?.[t?.LocationId];
          const asset = assets?.[t?.AssetId];
          const creator = users?.[t?.CreatedBy];
          const finisher = users?.[t?.CompletedBy];
          const taskTemplate = taskTemplates?.[t?.TaskTemplateId];

          return {
            ...t,
            commentsCount: commentsCount,
            attachedCheckList,
            project,
            schedule,
            Location: location,
            Asset: asset,
            Creator: creator,
            Finisher: finisher,
            TaskTemplate: taskTemplate,
            TaskAssignees: addNewAssigneesUsingLookup(
              t?.assignees,
              users,
              teams,
              roles,
              locations,
            )?.filter((item) => Object.keys(item)?.length > 0),
          };
        }) || [];

      return transformed;
    } catch (err) {
      console.log('error in transformTaskList:', err);
      return [];
    }
  };

  const statusOrder = ['Open', 'In Progress', 'On Hold', 'Completed', 'Missed'];

  const generateGroupingsForTable = (
    data,
    currentGroupKey,
    startRow,
    endRow,
  ) => {
    let keys = Object.keys(data).slice(startRow, endRow);

    if (currentGroupKey === 'status') {
      const sortedKeys = sortBy(keys, (key) => statusOrder?.indexOf(key));
      keys = sortedKeys;
    }

    let transformedKeys = keys?.map((key, index) => {
      // Create the base group object
      const groupRow: any = {
        group: true,
        id: index + 1,
      };

      if (currentGroupKey === 'location') {
        groupRow.Location = data?.[key]?.[0]?.Location;
      } else if (currentGroupKey === 'asset') {
        groupRow.Asset = data?.[key]?.[0]?.Asset;
      } else if (currentGroupKey === 'assigned_to') {
        groupRow.TaskAssignees = data?.[key]?.[0]?.TaskAssignees;
        groupRow.assignees = data?.[key]?.[0]?.assignees;
        const ids: string[] = [];
        const names =
          groupRow?.TaskAssignees?.map((item) => {
            if (item?.Assigned) {
              ids.push(item?.Assigned?.id);
              return item?.Assigned?.firstName;
            }
            if (item?.Team) {
              ids.push(item?.Team?.id);
              return item?.Team?.name;
            }
            if (item?.Location) {
              ids.push(item?.Location?.id);
              return item?.Location?.name;
            }
            if (item?.Role) {
              ids.push(item?.Role?.id);
              return item?.Role?.title;
            }
            return '';
          }) ?? [];

        groupRow.names = names;
        groupRow.ids = ids;
      } else {
        // For all other grouping types, use the standard mapping
        groupRow[GROUP_BY_PROPERTIES[currentGroupKey]] = key;
      }

      return groupRow;
    });

    if (currentGroupKey === 'assigned_to') {
      transformedKeys = transformedKeys?.filter(
        (item) => !(item?.assignees?.length > 0 && item?.ids?.length === 0),
      );
    }

    return transformedKeys;
  };

  const preDataGroupingForTable = (data, currentGroupKey) => {
    const groupedData = {};

    data.forEach((row) => {
      // Determine the key based on currentGroupKey
      let key;

      if (currentGroupKey === 'location') {
        key = row?.Location?.id ?? null;
      } else if (currentGroupKey === 'asset') {
        key = row?.Asset?.id ?? null;
      } else if (currentGroupKey === 'assigned_to') {
        key = row?.assignees?.length > 0 ? row?.assignees : null;
      } else {
        // For all other grouping types, use the standard mapping
        key = row[GROUP_BY_PROPERTIES[currentGroupKey]];
      }

      // Initialize the array if this is the first item for this key
      if (!groupedData[key]) {
        groupedData[key] = [];
      }

      // Add the row to the appropriate group
      groupedData[key].push(row);
    });

    return groupedData;
  };

  return {
    getTasks,
    isCalendarView,
    handleClearFilter,
    handleClearCalendarFilter,
    getCalendarTasks,
    getTasksCommon,
    getPaginatedTasks,
    getGroupByProperty,
    transformTaskList,
    generateGroupingsForTable,
    preDataGroupingForTable,
    getPostPaginatedObj,
    getSortByProperty,
  };
}
export default useTaskList;
