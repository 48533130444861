import {
  createTemplateWorkflowCall,
  getTemplateWorkflowsCall,
  editTemplateWorkflowCall,
  getTemplateWorkflowCall,
  deleteTemplateWorkflowCall,
} from 'api/templateWorkflows';

import { orderBy } from 'lodash';

const useRoutingRules = () => {
  const getTemplateWorkflows = async (checklistId) => {
    const response = await getTemplateWorkflowsCall(checklistId);

    const transformedData = orderBy(
      response?.data ?? [],
      ['updatedAt'],
      ['desc'],
    );

    return transformedData;
  };

  const createTemplateWorkflow = async (request = {}) => {
    const response = await createTemplateWorkflowCall(request);
    return response?.data;
  };

  const editTemplateWorkflow = async (request = {}, showToast = true) => {
    const response = await editTemplateWorkflowCall(request, showToast);
    return response?.data;
  };

  const getTemplateWorkflow = async (workflowId) => {
    const response = await getTemplateWorkflowCall(workflowId);
    return response?.data;
  };

  const deleteTemplateWorkflow = async (data) => {
    const response = await deleteTemplateWorkflowCall(data);
    return response?.data;
  };

  const transformWorkflowData = (data) => {
    const updatedData: any = {
      id: data?.id,
      name: data?.name,
      description: '',
      isActive: true,
      operator: data?.operator?.toUpperCase() ?? 'AND',
      conditions: [],
      actions: data?.actions,
      notification: data?.notification,
    };
    const conditions: any[] = [];

    data?.conditions?.forEach((condition) => {
      conditions?.push({
        ChecklistItemId: condition?.contextId,
        // context: condition?.context,
        conditions: {
          logic: condition?.condition?.logic,
          value: condition?.condition?.value,
        },
      });
    });

    delete updatedData.notification;
    updatedData.conditions = conditions;
    return updatedData;
  };

  const transformResponseToWorkflowData = (data, hasedChecklistItems) => {
    const conditions: any[] = [];
    data?.Conditions?.forEach((condition) => {
      conditions.push({
        id: condition?.id,
        contextId: condition?.ChecklistItemId,
        parentId: hasedChecklistItems?.[condition?.ChecklistItemId]?.ParentId,
        condition: {
          logic: condition?.conditions?.logic,
          value: condition?.conditions?.value,
        },
      });
    });

    data.conditions = conditions;
    return data;
  };

  return {
    createTemplateWorkflow,
    editTemplateWorkflow,
    getTemplateWorkflows,
    getTemplateWorkflow,
    deleteTemplateWorkflow,
    transformWorkflowData,
    transformResponseToWorkflowData,
  };
};

export default useRoutingRules;
