import { cloneDeep } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialRoutingRulesStateData } from 'store/initialStateData';

export const routingRulesReducer = (
  state: any = initialRoutingRulesStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_ROUTING_RULES: {
      return {
        ...state,
        routingRules: action.payload,
        initialLoad: false,
        loading: false,
      };
    }

    case actionTypes.ADD_ROUTING_RULE: {
      let newList = cloneDeep(state?.routingRules ?? []);
      newList = [action.payload, ...newList];
      return {
        ...state,
        routingRules: newList,
      };
    }

    case actionTypes.UPDATE_ROUTING_RULE: {
      let newList = cloneDeep(state?.routingRules ?? []);
      newList = newList?.map((item) => {
        if (item?.id === action?.payload?.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });

      return {
        ...state,
        routingRules: newList,
      };
    }

    case actionTypes.REMOVE_ROUTING_RULE: {
      let newList = cloneDeep(state?.routingRules ?? []);
      newList = newList?.filter((item) => item?.id !== action?.payload);
      return {
        ...state,
        routingRules: newList,
      };
    }

    default:
      return state;
  }
};
