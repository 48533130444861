import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { colors } from 'components/theme/constants';

interface IProps {
  condition: any;
  field: any;
}

const NumberTypePreview: FC<IProps> = (props) => {
  const { condition, field } = props;

  return Array.isArray(condition?.value) ? (
    <>
      <Typography color={colors.primary.main} fontSize="12px" fontWeight={700}>
        {field.type === 'cost' ? field.unit : null}
        {condition?.value?.[0]}
        {field.type !== 'cost' ? field.unit : null}
      </Typography>
      <Typography fontSize="12px" color="#424242">
        and
      </Typography>
      <Typography color={colors.primary.main} fontSize="12px" fontWeight={700}>
        {field.type === 'cost' ? field.unit : null}
        {condition?.value?.[1]}
        {field.type !== 'cost' ? field.unit : null}
      </Typography>
    </>
  ) : (
    <Typography color={colors.primary.main} fontSize="12px" fontWeight={700}>
      {field.type === 'cost' ? field.unit : null}
      {condition?.value}
      {field.type !== 'cost' ? field.unit : null}
    </Typography>
  );
};

export const NumberTypeText = (condition, field) => {
  return Array.isArray(condition?.value)
    ? `${field.type === 'cost' ? field.unit : null}
        ${condition?.value?.[0]}
        ${field.type !== 'cost' ? field.unit : null} &  ${
        field.type === 'cost' ? field.unit : null
      }
        ${condition?.value?.[1]}
        ${field.type !== 'cost' ? field.unit : null}`
    : `${field.type === 'cost' ? field.unit : null}
      ${condition?.value}
      ${field.type !== 'cost' ? field.unit : null}`;
};
export default NumberTypePreview;
