// MUI components
import { Stack } from '@mui/material';
import { CrossIconBtn, HeaderContainer } from './style';
import CustomButton from 'components/Button/CustomButton';
import CrossIcon from 'components/Icons/crossIcon';
const BuilderHeader = ({
  cancelHandler,
  data,
  createHandler,
  editHandler,
  isDirtyChanges,
  isSaving = false,
  handleClose,
}) => {
  const isDataValid = () => {
    if (!data?.name) return false;
    if (!(data?.actions?.length > 0)) return false;
    if (!(data?.conditions?.length > 0)) return false;
    return true;
  };

  const isDisabledBtn = !isDataValid();

  return (
    <HeaderContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: 'unset !important',
          },
        }}
      >
        <CrossIconBtn className="closeIcon" onClick={handleClose}>
          <CrossIcon style={{ height: 20 }} />
        </CrossIconBtn>
        <Stack direction="row" alignItems="center" gap="10px">
          <CustomButton
            variant="outlined"
            className="cancelBtn"
            onClick={cancelHandler}
          >
            Cancel
          </CustomButton>

          {data?.id && (
            <CustomButton
              variant="contained"
              disabled={isDisabledBtn || isSaving || !isDirtyChanges}
              className="saveChangesBtn"
              onClick={() => editHandler()}
              buttonLoader={isSaving}
            >
              Save Changes
            </CustomButton>
          )}

          {!data?.id && (
            <CustomButton
              variant="contained"
              className="createBtn"
              disabled={isDisabledBtn || isSaving || !isDirtyChanges}
              buttonLoader={isSaving}
              onClick={createHandler}
            >
              Create Workflow
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </HeaderContainer>
  );
};

export default BuilderHeader;
