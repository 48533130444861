import moment from 'moment-timezone';

const alternateFields = {
  startTime: 'startDate',
  dueTime: 'dueDate',
};

const defaultInstanceCadence = {
  field: 'beginningOfWorkDay',
  unit: 'hour',
  value: 0,
};

export const updateInstanceActivationTime = (task, hotel, timezone) => {
  try {
    if (!task) return null;
    const { instanceCadence, instanceActivationTime } = task;

    if (instanceActivationTime)
      return moment.tz(instanceActivationTime, timezone);

    const { field, unit, value } = instanceCadence || defaultInstanceCadence;

    const { Shift: shift } = hotel || {};

    const altField = alternateFields[field];
    const date = task?.[field] || task?.[altField] || task?.startDate;

    let activationTime = instanceActivationTime;

    if (field === 'beginningOfWorkDay') {
      const startTime = shift?.startTime || '12:00 am';
      const day = moment.tz(date, timezone).startOf(value).format('YYYY-MM-DD');
      activationTime = moment
        .tz(`${day} ${startTime}`, 'YYYY-MM-DD hh:mm a', timezone)
        .subtract(value, unit);
    } else {
      activationTime = moment.tz(date, timezone).subtract(value, unit);
    }
    return activationTime;
  } catch (error) {
    return null;
  }
};
