/* eslint-disable no-empty */
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import { groupBy, map } from 'lodash';

/*******************
  @Purpose : Used for get checklist list
  @Parameter :
  @Author : INIC
  ******************/
function* getChecklistListApiCall(getChecklistsParams): any {
  try {
    const checklistListApiResponse = yield call(
      xeniaApi.getChecklistListing,
      getChecklistsParams.payload,
    );
    if (checklistListApiResponse) {
      yield put(actions.setChecklistList(checklistListApiResponse));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getChecklistCompleteList(getChecklistsParams): any {
  try {
    const checklistListApiResponse = yield call(
      xeniaApi.getChecklistListing,
      getChecklistsParams.payload,
    );
    if (checklistListApiResponse) {
      yield put(actions.setChecklistCompleteList(checklistListApiResponse));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPublicChecklists(getChecklistsParams): any {
  try {
    const checklistListApiResponse = yield call(
      xeniaApi.getPublicChecklists,
      getChecklistsParams.payload,
    );
    if (checklistListApiResponse) {
      yield put(actions.setPublicChecklists(checklistListApiResponse));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getChecklistCategories() {
  try {
    const checklistCategories = yield call(xeniaApi.getChecklistCategories);
    if (checklistCategories) {
      yield put(actions.setChecklistCategories(checklistCategories));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getChecklistTypes() {
  try {
    const checklistTypes = yield call(xeniaApi.getChecklistTypes);
    if (checklistTypes) {
      yield put(actions.setChecklistTypes(checklistTypes));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getChecklistIndustries() {
  try {
    const checklistIndustries = yield call(xeniaApi.getChecklistIndustries);
    if (checklistIndustries) {
      yield put(actions.setChecklistIndustries(checklistIndustries));
    }
  } catch (error) {
    console.log(error);
  }
}

function* createChecklistApiCall(action: any): any {
  try {
    const createChecklistApiResponse = yield call(
      xeniaApi.createChecklist,
      action.payload,
    );
    if (createChecklistApiResponse) {
      createChecklistApiResponse.meta.created = true;
      yield put(actions.setChecklist(createChecklistApiResponse));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to update checklist
  @Parameter :
  @Author : INIC
  ******************/
function* updateChecklistApiCall(action: any): any {
  try {
    const checklistUpdateResponse = yield call(
      xeniaApi.updateChecklist,
      action.payload.checklistId,
      action.payload.showNotification,
      action.payload.data,
    );
    yield put(actions.setChecklist(checklistUpdateResponse));
    yield put(actions.getPublicChecklists());
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to duplicate checklist
  @Parameter :
  @Author : INIC
  ******************/
function* duplicateChecklistApiCall(action: any): any {
  try {
    const duplicateChecklistApiResponse = yield call(
      xeniaApi.duplicateChecklist,
      action.payload,
    );
    if (duplicateChecklistApiResponse) {
      yield put(
        actions.getChecklistListApiCall({
          detailed: true,
          folderId: action?.payload?.folder || 'root',
        }),
      );

      if (action.payload.onboarding) {
        yield put(
          actions.setPreviewChecklistData(duplicateChecklistApiResponse.data),
        );
        yield put(
          actions.setPreviewChecklistModalConfig({
            open: true,
            activeTab: 'preview',
            checklistScope: 'myTemplates',
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* publishChecklist(action: any): any {
  try {
    const response = yield call(
      xeniaApi.publishChecklist,
      action.payload.checklistId,
    );
    if (response) {
      yield put(actions.getPublicChecklists({}));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to archive checklist
  @Parameter :
  @Author : INIC
  ******************/
function* archiveChecklistApiCall(action: any): any {
  try {
    const archiveChecklistApiResponse = yield call(
      xeniaApi.archiveChecklist,
      action.payload.checklistId,
    );
    if (archiveChecklistApiResponse) {
      yield put(
        actions.getChecklistListApiCall({
          detailed: true,
          folderId: action?.payload?.selectedFolder || 'root',
        }),
      );
      yield put(actions.getPublicChecklists({}));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to get checklist
  @Parameter :
  @Author : INIC
  ******************/
function* getChecklistApiCall(action: any): any {
  try {
    const checklist = yield call(
      xeniaApi.getChecklist,
      action.payload.checklistId,
      action.payload.taskId,
    );

    if (checklist) {
      yield put(actions.setChecklist(checklist));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to upload checklist attachments
  @Parameter :
  @Author : INIC
  ******************/
function* uploadChecklistAttachmentsApiCall(action: any): any {
  try {
    const checklistAttachments = yield call(
      xeniaApi.CreateTaskAttchmentFilesApi,
      action.payload.attachments,
    );
    if (checklistAttachments) {
      yield put(actions.setChecklistAttachments(checklistAttachments));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to get checklist items
  @Parameter :
  @Author : INIC
  ******************/
function* getChecklistItemsApiCall(action: any): any {
  try {
    const checklistItems = yield call(
      xeniaApi.getChecklistItems,
      action.payload.checklistId,
    );

    if (checklistItems) {
      yield put(actions.setChecklistItems(checklistItems));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getChecklistLogs(action: any): any {
  try {
    const checklistLogs = yield call(xeniaApi.getChecklistLogs, action.payload);
    if (checklistLogs) {
      yield put(actions.setChecklistLogs(checklistLogs));
      yield put(actions.setLoader({ type: 'checkListLogs', value: false }));
      yield put(
        actions.setLoader({ type: 'checkListLogsFilters', value: false }),
      );
    }
  } catch (error) {
    yield put(actions.setLoader({ type: 'checkListLogs', value: false }));
    yield put(
      actions.setLoader({ type: 'checkListLogsFilters', value: false }),
    );
    console.log(error);
  }
}

function* getChecklistLog(action: any): any {
  try {
    const checklistLog = yield call(xeniaApi.getChecklistLog, action.payload);
    if (checklistLog) {
      yield put(actions.setChecklistLog(checklistLog));
      yield put(actions.setLoader({ type: 'checkList', value: false }));
    }
  } catch (error) {
    yield put(actions.setLoader({ type: 'checkList', value: false }));
    console.log(error);
  }
}

function* submitChecklistLog(action: any): any {
  try {
    const { onChecklistSubmitCb, ...rest } = action.payload;
    const checklistLog = yield call(xeniaApi.submitChecklistLog, rest);
    if (checklistLog) {
      yield put(actions.setChecklistLog(checklistLog));
      yield put(actions.setChecklistLogSubmitted(true));
      yield put(
        actions.getChecklistCompleteList({
          detailed: true,
          includeArchived: true,
        }),
      );
    }
    onChecklistSubmitCb?.(checklistLog);
  } catch (error) {
    console.log(error);
  }
}

function* createChecklistLog(action: any): any {
  try {
    const checklistLog = yield call(
      xeniaApi.createChecklistLog,
      action.payload,
    );
    if (checklistLog) {
      yield put(actions.setChecklistLog(checklistLog));
      yield put(
        actions.getChecklistCompleteList({
          detailed: true,
          includeArchived: true,
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to update checklist item
  @Parameter :
  @Author : INIC
  ******************/
function* updateChecklistItemApiCall(action: any): any {
  try {
    yield call(
      xeniaApi.modifyChecklistItem,
      action.payload.checklistId,
      action.payload.checklistItemId,
      action.payload.data,
    );

    yield put({
      type: actionTypes.GET_CHECKLIST_ITEMS,
      payload: { checklistId: action.payload.checklistId },
    });
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to delete checklist item
  @Parameter :
  @Author : INIC
  ******************/
function* deleteChecklistItemApiCall(action: any): any {
  try {
    if (action.payload.checklistItemId) {
      const deletedChecklistItem = yield call(
        xeniaApi.deleteChecklistItem,
        action.payload.checklistId,
        action.payload.checklistItemId,
      );
      if (deletedChecklistItem) {
        yield put(
          actions.spliceChecklistItem(action.payload.checklistItemIndex),
        );
      }
    } else {
      yield put(actions.spliceChecklistItem(action.payload.checklistItemIndex));
    }
  } catch (error) {
    console.log(error);
  }
}

/*******************
  @Purpose : Used to upload checklist item attachments
  @Parameter :
  @Author : INIC
  ******************/
function* uploadChecklistItemAttachmentsApiCall(action: any): any {
  try {
    const checklistItemAttachments = yield call(
      xeniaApi.CreateTaskAttchmentFilesApi,
      action.payload.attachments,
    );
    if (checklistItemAttachments) {
      yield put(
        actions.setChecklistItemAttachments(
          action.payload.checklistItemIndex,
          checklistItemAttachments,
        ),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchChecklistItemsAutomations(action: any): any {
  try {
    const { workspaceId, checklistId } = action.payload;

    const automations = yield call(xeniaApi.getChecklistAutomations, {
      workspaceId,
      checklistId,
    });

    if (automations) {
      const groupByAutomations = groupBy(
        map(automations, (automation) => ({
          id: automation.id,
          conditions: automation.conditions,
          actions: automation.actions,
          EntityId: automation.EntityId,
          entityType: automation.entityType,
        })),
        'EntityId',
      );

      yield put({
        type: actionTypes.SET_CHECKLIST_ITEMS_AUTOMATIONS,
        payload: groupByAutomations,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* checklistV2Watcher() {
  yield takeLatest(actionTypes.GET_CHECKLIST_LIST, getChecklistListApiCall);
  yield takeLatest(
    actionTypes.GET_CHECKLIST_COMPLETE_LIST,
    getChecklistCompleteList,
  );
  yield takeLatest(actionTypes.GET_PUBLIC_CHECKLISTS, getPublicChecklists);
  yield takeLatest(
    actionTypes.GET_CHECKLIST_CATEGORIES,
    getChecklistCategories,
  );
  yield takeLatest(actionTypes.GET_CHECKLIST_TYPES, getChecklistTypes);
  yield takeLatest(
    actionTypes.GET_CHECKLIST_INDUSTRIES,
    getChecklistIndustries,
  );
  yield takeLatest(actionTypes.CREATE_CHECKLIST, createChecklistApiCall);
  yield takeLatest(actionTypes.UPDATE_CHECKLIST, updateChecklistApiCall);
  yield takeLatest(actionTypes.GET_CHECKLIST, getChecklistApiCall);
  yield takeLatest(
    actionTypes.FETCH_CHECKLIST_ITEMS_AUTOMATIONS,
    fetchChecklistItemsAutomations,
  );
  yield takeLatest(actionTypes.DUPLICATE_CHECKLIST, duplicateChecklistApiCall);
  yield takeLatest(actionTypes.PUBLISH_CHECKLIST, publishChecklist);
  yield takeLatest(actionTypes.ARCHIVE_CHECKLIST, archiveChecklistApiCall);
  yield takeLatest(
    actionTypes.UPLOAD_CHECKLIST_ATTACHMENTS,
    uploadChecklistAttachmentsApiCall,
  );
  yield takeLatest(actionTypes.GET_CHECKLIST_ITEMS, getChecklistItemsApiCall);
  yield takeLatest(actionTypes.GET_CHECKLIST_LOGS, getChecklistLogs);
  yield takeLatest(actionTypes.GET_CHECKLIST_LOG, getChecklistLog);
  yield takeLatest(actionTypes.SUBMIT_CHECKLIST_LOG, submitChecklistLog);
  yield takeLatest(actionTypes.CREATE_CHECKLIST_LOG, createChecklistLog);
  yield takeLatest(
    actionTypes.MODIFY_CHECKLIST_ITEM,
    updateChecklistItemApiCall,
  );
  yield takeLatest(
    actionTypes.DELETE_CHECKLIST_ITEM,
    deleteChecklistItemApiCall,
  );
  yield takeLatest(
    actionTypes.UPLOAD_CHECKLIST_ITEM_ATTACHMENTS,
    uploadChecklistItemAttachmentsApiCall,
  );
}
