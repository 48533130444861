// React
import React from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import LocationStep from 'components/LocationStep';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';
import { Stack, Typography } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface IProps {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: string | string[]) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const LocationField = (props: IProps) => {
  const {
    item,
    answers = null,
    onChangeCb,
    templateLog,
    hideCorrectiveTask,
    onCorrectiveTaskCreationCb,
    isPreview,
  } = props;

  const locationsMap = useLocationsMap();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const activeLocation = locationsMap[answers?.value];

  return (
    <FieldNameRenderer
      item={item}
      isAnswered={answers?.value?.length > 0}
      templateLog={templateLog}
      fieldName={item.description}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <LocationStep showTabs answer={answers} onChange={onChangeCb} />
      )}
      {isPreview && !answers?.value && <Unanswered />}
      {isPreview && answers?.value && activeLocation && (
        <Stack direction="row" gap="10px" alignItems={'center'}>
          <Typography fontSize={'15px'} fontWeight={'700'}>
            {activeLocation.name}
          </Typography>
          <Typography
            sx={{
              cursor: 'pointer',
            }}
            color="rgba(104, 104, 254, 1)"
            fontSize={'14px'}
            fontWeight={'700'}
            onClick={() => {
              const path = activeLocation.ParentId
                ? `/locations/${activeLocation?.ParentId}/sl/${activeLocation?.id}`
                : `/locations/${activeLocation?.id}`;

              navigateWithWorkspaceUrl(path);
            }}
          >
            View Location
          </Typography>
        </Stack>
      )}
    </FieldNameRenderer>
  );
};

export default LocationField;
