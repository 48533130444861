// React
import { useState, Fragment, useEffect } from 'react';

// Custom components
import SubItemsList from './SubItemsList';

// Utils
import { getReports } from 'api/reporting';
import { sortBy } from 'lodash';
import useReportList from 'pages/ReportingV2/Reports/useReportsList';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarDashboards = (props: PropTypes) => {
  const { sidebarOpen } = props;

  const [predefinedReports, setPredefinedReports] = useState<any>([]);
  const { getReportsList } = useReportList();

  const customOrder = [
    'schedule-report',
    'scheduled-work-summary-table',
    'daily-compliance-report',
    'task-summary',
    'employee-productivity',
    'completed-work-report',
  ];

  const getReportsData = async () => {
    const reports = await getReportsList();
    if (reports) {
      setPredefinedReports(reports);
    }
  };

  useEffect(() => {
    getReportsData();
  }, []);

  return (
    <Fragment>
      <SubItemsList
        predefinedReports={predefinedReports ?? []}
        sidebarOpen={sidebarOpen}
      />
    </Fragment>
  );
};

export default SidebarDashboards;
