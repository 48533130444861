import styled from '@mui/system/styled';

export const Content = styled('div')({
  padding: '0px 15px',
});

export const AssigneeDropdownContentCnt = styled('div')({
  minWidth: 400,
  '& .tabsCnt': {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderBottom: '1px solid #E0E0E0',
    overflowX: 'auto',

    '& .tab': {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'capitalize',
      marginRight: 8,
      borderRadius: 100,
      cursor: 'pointer',
      color: '#212121',
      padding: '8px 12px 8px 12px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #E0E0E0',
      lineHeight: '16.39px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)',
      },
      '& .tabCount': {
        fontWeight: 500,
        fontSize: 12,
        color: '#616161',
        lineHeight: '16.39px',
      },
    },
    '& .tab.selectedTab': {
      background: '#6868FE',
      padding: '8px 12px 8px 12px',
      color: '#fff',
      '& .tabCount': {
        color: '#FFCCBC',
      },
    },
  },
  '& .addNewUserListItem': {
    padding: '10px 24px',
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});

export const UserStatusChip = styled('span')({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  padding: '4px 10px',
  borderRadius: '34px',
  textTransform: 'capitalize',
  color: '#000000DE',
});
