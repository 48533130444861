import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getRoles } from 'store/selectors/roles';

export function useAssigneesUtils() {
  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const roles = useSelector(getRoles);
  const { hashRoles } = roles;

  const getTaskAssigneesIds = (assignees) => {
    const ids = assignees?.map((item) => {
      if (item?.Assigned) return item?.Assigned?.id;
      if (item?.Team) return item?.Team?.id;
      if (item?.Location) return item?.Location?.id;
      if (item?.Role) return item?.Role?.id;
    });

    return ids ?? [];
  };

  const splitAssigneesByIds = (assignees, config) => {
    const peoplesList: string[] = [];
    const teamsList: string[] = [];
    const locationsList: string[] = [];
    const rolesList: string[] = [];

    if (assignees?.length > 0) {
      for (let index = 0; index <= assignees?.length - 1; index++) {
        const people = usersHash?.[assignees?.[index]];
        if (people) {
          peoplesList?.push(assignees?.[index]);
          continue;
        }

        const team = teamsHash?.[assignees?.[index]];
        if (team) {
          teamsList?.push(assignees?.[index]);
          continue;
        }

        const location = locationsHash?.[assignees?.[index]];
        if (location) {
          locationsList?.push(assignees?.[index]);
          continue;
        }

        const role = hashRoles?.[assignees?.[index]];
        if (role) {
          rolesList?.push(assignees?.[index]);
          continue;
        }
      }
    }

    return {
      ...(config?.people && { people: peoplesList }),
      ...(config?.teams && { teams: teamsList }),
      ...(config?.locations && { locations: locationsList }),
      ...(config?.roles && { roles: rolesList }),
    };
  };

  const splitAssigneesForTasks = (assignees, config) => {
    const peoplesList: string[] = [];
    const teamsList: string[] = [];
    const locationsList: string[] = [];
    const rolesList: string[] = [];

    if (assignees?.length > 0) {
      for (let index = 0; index <= assignees?.length - 1; index++) {
        if (assignees?.[index]?.Assigned) {
          peoplesList?.push(assignees?.[index]?.Assigned?.id);
        }
        if (assignees?.[index]?.Team) {
          teamsList?.push(assignees?.[index]?.Team?.id);
        }
        if (assignees?.[index]?.Location) {
          locationsList?.push(assignees?.[index]?.Location?.id);
        }
        if (assignees?.[index]?.Role) {
          rolesList?.push(assignees?.[index]?.Role?.id);
        }
      }
    }
    return {
      ...(config?.people && { people: peoplesList }),
      ...(config?.teams && { teams: teamsList }),
      ...(config?.locations && { locations: locationsList }),
      ...(config?.roles && { locations: rolesList }),
    };
  };

  const updateAssignees = (assignees, id) => {
    const isExistsAlready = assignees?.find(
      (item) =>
        item?.Assigned?.id == id ||
        item?.Team?.id == id ||
        item?.Location?.id == id,
    );

    if (isExistsAlready) {
      let updatedAssignees = assignees?.filter(
        (item) => item?.Assigned?.id != id,
      );
      updatedAssignees = updatedAssignees?.filter(
        (item) => item?.Team?.id != id,
      );
      updatedAssignees = updatedAssignees?.filter(
        (item) => item?.Location?.id != id,
      );
      return updatedAssignees;
    }

    if (!isExistsAlready) {
      const newObject = {
        ...(usersHash?.[id] && {
          Assigned: {
            ...usersHash?.[id],
          },
        }),

        ...(teamsHash?.[id] && {
          Team: {
            ...teamsHash?.[id],
          },
        }),
        ...(locationsHash?.[id] && {
          Location: {
            ...locationsHash?.[id],
          },
        }),
      };

      return [...assignees, newObject];
    }
  };

  const addNewAssignees = (selectedIds) => {
    let allAssigness: any = [];

    for (let i = 0; i < selectedIds?.length; i++) {
      const id = selectedIds[i];
      const newObject = {
        ...(usersHash?.[id] && {
          Assigned: {
            ...usersHash?.[id],
          },
        }),

        ...(teamsHash?.[id] && {
          Team: {
            ...teamsHash?.[id],
          },
        }),
        ...(locationsHash?.[id] && {
          Location: {
            ...locationsHash?.[id],
          },
        }),
        ...(hashRoles?.[id] && {
          Role: {
            ...hashRoles?.[id],
          },
        }),
      };
      allAssigness = [...allAssigness, newObject];
    }
    return allAssigness;
  };

  const addNewAssigneesUsingLookup = (
    selectedIds,
    users,
    teams,
    roles,
    locations,
  ) => {
    let allAssigness: any = [];

    for (let i = 0; i < selectedIds?.length; i++) {
      const id = selectedIds[i];

      const user = users?.[id];
      const team = teams?.[id];
      const role = roles?.[id];
      const location = locations?.[id];

      const newObject = {
        ...(user && {
          Assigned: {
            ...(user ?? {}),
          },
        }),

        ...(team && {
          Team: {
            ...(team ?? {}),
          },
        }),
        ...(location && {
          Location: {
            ...(location ?? {}),
          },
        }),
        ...(role && {
          Role: {
            ...(role ?? {}),
          },
        }),
      };
      allAssigness = [...allAssigness, newObject];
    }
    return allAssigness;
  };

  const convertRoleIdIntoDetails = (roleId) => {
    return hashRoles?.[roleId];
  };

  return {
    getTaskAssigneesIds,
    addNewAssignees,
    updateAssignees,
    splitAssigneesForTasks,
    splitAssigneesByIds,
    convertRoleIdIntoDetails,
    addNewAssigneesUsingLookup,
  };
}
