import {
  deleteRoutingRuleCall,
  getRoutingRulesCall,
  createRoutingRuleCall,
  editRoutingRuleCall,
  getRoutingRuleCall,
} from 'api/routingRules';
import { orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRoutingRule,
  removeRoutingRule,
  setRoutingRules,
  updateRoutingRule,
} from 'store/actions/routingRulesActions';
import selectors from 'store/selectors';

const useRoutingRules = () => {
  const dispatch = useDispatch();
  const routingRulesState = useSelector(selectors.getRoutingRules);
  const { initialLoad, loading } = routingRulesState || {};

  const getRoutingRules = async (forcedLoad = false) => {
    if (forcedLoad || (!initialLoad && loading)) {
      const response = await getRoutingRulesCall({});

      const transformedData = orderBy(
        response?.data ?? [],
        ['updatedAt'],
        ['desc'],
      );
      dispatch(setRoutingRules(transformedData ?? []));
    }
  };

  const createRoutingRule = async (request = {}) => {
    const response = await createRoutingRuleCall(request);
    if (response?.data) {
      dispatch(addRoutingRule(response?.data));
    }
    return response?.data;
  };

  const editRoutingRule = async (request = {}, showToast = true) => {
    const response = await editRoutingRuleCall(request, showToast);
    if (response?.data) {
      dispatch(updateRoutingRule(response?.data));
    }
    return response?.data;
  };

  const getRoutingRule = async (request = {}, ruleId) => {
    const response = await getRoutingRuleCall(request, ruleId);
    return response?.data;
  };

  const deleteRoutingRule = async (data) => {
    const { routingIds } = data ?? {};
    const response = await deleteRoutingRuleCall(data);
    if (response?.data) {
      dispatch(removeRoutingRule(routingIds?.[0]));
    }
    return response?.data;
  };

  const transformWatchers = (watchers, lookupData) => {
    const { users, teams, roles } = lookupData?.data ?? {};

    const data: any = {
      users: [],
      teams: [],
      roles: [],
    };

    watchers?.forEach((watcher) => {
      if (users?.[watcher]) {
        data?.users?.push(watcher);
      }

      if (roles?.[watcher]) {
        data?.roles?.push(watcher);
      }

      if (teams?.[watcher]) {
        data?.teams?.push(watcher);
      }
    });

    return data;
  };

  const reverseTransformWatchers = (data) => {
    const { users, roles, teams } = data ?? {};
    const combined = [...(users ?? []), ...(roles ?? []), ...(teams ?? [])];

    return combined;
  };

  const transformFilters = (arr) => {
    return arr?.map((item) => {
      const { condition, filters = [], ...others } = item || {};

      const rules = filters?.map((f) => {
        const { filterName, comparator, conditional, ...rest } = f;

        let type;
        if (filterName === 'categories') {
          type = 'CategoryId';
        } else if (filterName === 'locations') {
          type = 'LocationId';
        } else if (filterName === 'priorities') {
          type = 'Priority';
        } else {
          type = filterName;
        }

        return {
          type,
          operator: comparator,
          condition: conditional,
          ...rest,
        };
      });

      return {
        condition,
        rules,
        ...others,
      };
    });
  };

  const reverseTransformFilters = (arr) => {
    return arr?.map((item) => {
      const { condition, rules = [], ...others } = item || {};

      const filters = rules?.map((r) => {
        const { type, operator, condition: cond, ...rest } = r;

        let filterName;
        if (type === 'CategoryId') {
          filterName = 'categories';
        } else if (type === 'LocationId') {
          filterName = 'locations';
        } else if (type === 'Priority') {
          filterName = 'priorities';
        } else {
          filterName = type;
        }

        return {
          filterName,
          comparator: operator,
          conditional: cond,
          ...rest,
        };
      });

      return {
        condition,
        filters,
        ...others,
      };
    });
  };

  return {
    createRoutingRule,
    editRoutingRule,
    getRoutingRules,
    getRoutingRule,
    deleteRoutingRule,
    transformFilters,
    reverseTransformFilters,
    reverseTransformWatchers,
    transformWatchers,
  };
};

export default useRoutingRules;
