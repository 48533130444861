// React
import { useState, Fragment, useEffect } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarScheduledWork.style';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import { getReports } from 'api/reporting';
import RepeatIconMultiColor from 'components/Icons/sidebarIcons/repeatIconMultiColor';
import useReportList from 'pages/ReportingV2/Reports/useReportsList';
import { useSelector } from 'react-redux';
import { getReportsState } from 'store/selectors/reports';

const SidebarScheduledWorkDropdown = (props) => {
  const { sidebarOpen } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { getReportsList } = useReportList();
  const reportList = useSelector(getReportsState);
  const [reportId, setReportsId] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleClickAccordion = (
    event: React.SyntheticEvent,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  const getAndSetReportsId = async () => {
    if (reportList) {
      const report = reportList?.find((r) => r?.slug === 'schedule-report');
      setReportsId(report?.id);
    } else {
      await getReportsList();
    }
  };

  useEffect(() => {
    getAndSetReportsId();
  }, [reportList]);

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SidebarItem
              title={'Scheduled Work'}
              icon={<RepeatIconMultiColor />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
              onClick={() => navigateWithWorkspaceUrl('/scheduled-work')}
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList reportId={reportId} />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper reportId={reportId} />
      )}
    </Fragment>
  );
};

export default SidebarScheduledWorkDropdown;
