import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CountCnt } from '../styled';
import { generateTemplateItemsData } from '../utils/getConditionInput';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import NestedSelectItemsList from 'components/Dropdown/SelectItemsList/NestedSlectItemsList';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';

interface ItemOrdersHash {
  [key: string]: number | string;
}
interface IProps {
  contextId: string;
  context: string;
  onChange(item: any): void;
  condition: any;
  checkListItems: any;
  setSelectedContext(item: string): void;
  ordreHash: ItemOrdersHash;
}

function ConditionItems(props) {
  const {
    options,
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    isAllSelected,
    setIsAllSelected,
    ordreHash,
  } = props;

  const CheckListitems = useMemo(() => {
    if (options) {
      return generateTemplateItemsData(options, ordreHash);
    }
    return [];
  }, [options]);

  const handleClickItem = (values: any[]) => {
    let finalValues = values;
    const isAllIncludedBefore = isAllSelected;
    const isAllIncludedNow = values.some((v) => v.id === 'all');

    if (isAllIncludedBefore && !isAllIncludedNow) {
      finalValues = [];
      setIsAllSelected(false);
    } else if (!isAllIncludedBefore && isAllIncludedNow) {
      finalValues = CheckListitems;
      setIsAllSelected(true);
    } else if (isAllIncludedBefore && isAllIncludedNow) {
      finalValues = values.filter((v) => v.id !== 'all');
      setIsAllSelected(false);
    }
    setSelectedOption(finalValues);
    onChangeCallback?.(finalValues);
  };

  const selectedTemplates = useMemo(() => {
    if (isAllSelected) {
      return [...selectedOption];
    }
    return selectedOption;
  }, [isAllSelected, selectedOption]);

  return (
    <>
      <NestedSelectItemsList
        searchFieldProps={{
          autoFocus: false,
          sx: {
            background: 'rgba(242, 245, 247, 1)',
          },
        }}
        isMulti={false}
        selectedOptions={selectedTemplates}
        controlled={true}
        styleSelected={true}
        checkbox={false}
        canSearch={true}
        options={CheckListitems}
        canSelectParent={true}
        checkboxProps={{ sx: { pr: '16px' } }}
        handleChangeCallback={handleClickItem}
        labelRenderer={(option) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                  marginRight: '10px',
                  fontSize: 16,
                },
              }}
            >
              {option.itemIcon}
              {option.label}
            </Box>
          );
        }}
      />
    </>
  );
}

const options = [
  { value: 'isEmpty', label: 'Is Empty' },
  { value: 'isFilled', label: 'Is Filled' },
  { value: 'Locations', label: 'Locations' },
  { value: 'LocationGroup', label: 'Groups' },
];
const ContextDropdown: FC<IProps> = (props) => {
  const {
    contextId,
    context,
    checkListItems,
    ordreHash,
    onChange,
    setSelectedContext,
  } = props;
  const [itemDropdownRef, setItemDropdownRef] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedContext, setContext] = useState<any>(null);
  const flattenItems = (items) => {
    return items.flatMap((item) => {
      if (item.ChildItems && Array.isArray(item.ChildItems)) {
        const childItems = flattenItems(item.ChildItems).map((child) => ({
          ...child,
          ParentId: item.id,
        }));
        return [{ ...item, ChildItems: null }, ...childItems];
      }
      return item;
    });
  };
  //should be invoked on first render
  useEffect(() => {
    if (contextId) {
      const flattArray = flattenItems(checkListItems);
      const itemselectedItem = flattArray.find((item) => item.id === contextId);
      if (itemselectedItem) {
        const item = generateTemplateItemsData([itemselectedItem], ordreHash);
        setSelectedContext(item[0]);
        setSelectedItem(item);
      }
    }
    setContext(options.find((item) => item.value === context));
  }, []);

  const handleSelectOption = (selectedOption) => {
    setSelectedContext(selectedOption[0]);
    setSelectedItem(selectedOption);
    onChange({
      contextId: selectedOption[0].id,
      condition: { logic: 'eq', value: '' },
      context: 'isFilled',
    });
    setContext({ value: 'isFilled', label: 'Is Filled' });
    setItemDropdownRef(null);
  };
  const isLocation = useMemo(() => {
    return selectedItem?.length && selectedItem[0]?.type == 'location';
  }, [selectedItem]);
  return (
    <Box>
      <CustomDropdown
        clickaway
        popperProps={{
          open: Boolean(itemDropdownRef),
          content: (
            <ConditionItems
              options={checkListItems}
              onChangeCallback={handleSelectOption}
              setSelectedOption={setSelectedItem}
              selectedOption={selectedItem}
              ordreHash={ordreHash}
            />
          ),
          placement: 'bottom-start',
          style: {
            width: 470,
          },
          anchorEl: itemDropdownRef,
        }}
        onClickawayCallback={() => {
          if (itemDropdownRef) {
            setItemDropdownRef(null);
          }
        }}
        buttonRenderer={
          <Stack
            borderRadius={'6px'}
            border="1px solid #cccccc"
            p="0 8px"
            width="100%"
            alignItems={'center'}
            gap="8px"
            direction={'row'}
            height="40px"
            sx={{
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setItemDropdownRef(itemDropdownRef ? null : e.currentTarget);
            }}
          >
            {selectedItem?.length ? (
              <>
                <CountCnt>
                  <span className="stepCount">
                    {ordreHash[selectedItem[0]?.id]}
                  </span>
                </CountCnt>
                {React.cloneElement(selectedItem[0].itemIcon ?? <></>, {
                  sx: { fontSize: '20px' },
                })}
                <Typography color="#000" fontSize="14px" fontWeight="500">
                  {selectedItem[0]?.label}
                </Typography>
              </>
            ) : (
              <Typography color="#000" fontSize="14px" fontWeight="500">
                -- Select --
              </Typography>
            )}
            <KeyboardArrowDownRounded
              sx={{ marginLeft: 'auto', color: '#616161' }}
            />
          </Stack>
        }
      />
      {isLocation ? (
        <Box mt={'20px'} mb={'-7px'}>
          <CustomSelect
            grayInput={false}
            isSearchable={false}
            isMulti={false}
            closeMenuOnSelect
            selectedOption={selectedContext}
            options={options}
            onChange={(item) => {
              onChange({
                condition: { logic: 'eq', value: '' },
                context: item.value,
              });
              setContext(item);
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ContextDropdown;
