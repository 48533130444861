import { FC, useCallback, useState } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import { MenuItem, Chip } from '@mui/material';
import EditIcon from 'components/Icons/editIcon';
import { Overflow } from './styled';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { IMultipleChoiceOption } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/option';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SaveIcon from 'components/Icons/saveIcon';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { DebouncedFunc, has } from 'lodash';

interface IProps {
  onSelect(options: IMultipleChoiceOption[]): void;
  onChange: DebouncedFunc<(index: number, key: string, value: any) => void>;
  handleSyncOptions(): void;
  isScoring: boolean;
  isUploading: boolean;
}

const renderItem = (option) => (
  <Chip
    variant="outlined"
    label={option.label}
    size="small"
    className="mr-1"
    sx={{
      borderColor: option.color,
      color: option.color,
    }}
  />
);
const renderRest = (items) => (
  <Typography color="#424242" fontSize="13px">
    +{items.length}
  </Typography>
);

const SavedOptions: FC<IProps> = (props) => {
  const { onSelect, onChange, handleSyncOptions, isScoring, isUploading } =
    props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const savedOptions = useSelector(selectors.getSavedOptions);

  const openEditDialog = useCallback((data) => {
    setAnchorEl(null);
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.SAVED_OPTIONS,
        open: true,
        data: { savedOptions: data, isUploading },
      }),
    );
  }, []);

  const handleSelect = useCallback(
    (groupId) => {
      const selectedGroup = savedOptions.find((group) => group.id === groupId);
      let options = selectedGroup?.options;
      if (isScoring && options) {
        options = options.map((o) => ({
          ...o,
          score: !has(o, 'score') ? 1 : o?.score,
        }));
      }
      if (selectedGroup) onSelect(options);
    },
    [savedOptions, onSelect],
  );

  return savedOptions?.length ? (
    <CustomDropdown
      onClickawayCallback={() => {
        if (anchorEl) {
          handleSyncOptions();
        }
        setAnchorEl(null);
      }}
      popperProps={{
        content: (
          <Stack maxHeight={'280px'} overflow={'scroll'}>
            {savedOptions.map((group) => (
              <MenuItem
                key={group.id}
                value={group.id}
                sx={{
                  width: '360px',
                  color: '#757575',
                }}
                onClick={() => {
                  handleSelect(group.id);
                  setAnchorEl(null);
                }}
              >
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="100%">
                    <Overflow
                      data={group.options}
                      renderItem={renderItem}
                      renderRest={renderRest}
                      itemKey="label"
                      maxCount={'responsive'}
                    />
                  </Box>

                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      openEditDialog(group);
                    }}
                  >
                    Edit
                  </Button>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        ),
        anchorEl,
        open: Boolean(anchorEl),
      }}
      buttonRenderer={
        <Stack
          border="1px solid #E0E0E0"
          direction="row"
          alignItems="center"
          gap="8px"
          p="0px 8px"
          height="32.75px"
          borderRadius={'6px'}
          onClick={(e) => {
            if (isUploading) return;
            onChange.cancel();
            setAnchorEl(anchorEl ? null : e.currentTarget);
            if (anchorEl) {
              handleSyncOptions();
            }
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <SaveIcon sx={{ color: '#757575', fontSize: '20px', fill: 'none' }} />
          <Typography
            color={'#424242'}
            fontWeight={500}
            fontSize="13px"
            display="flex"
            alignItems="center"
          >
            View Saved Options
          </Typography>
          <KeyboardArrowDownRoundedIcon
            sx={{ color: '#757575', fontSize: '20px' }}
          />
        </Stack>
      }
    />
  ) : null;
};

export default SavedOptions;
