import React, { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import BranchIcon from 'components/Icons/branchIcon';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import { useSelector } from 'react-redux';
import { getLocationGroupsState } from 'store/selectors/locationGroups';

interface IProps {
  condition: any;
}

const LocationTypePreview: FC<IProps> = (props) => {
  const { condition } = props;
  const locationsMap = useLocationsMap();
  const groupList = useSelector(getLocationGroupsState);

  const location = useMemo(() => {
    if (Array.isArray(condition?.value)) {
      return condition?.value.map((loc) => locationsMap[loc]);
    }
    return locationsMap[condition?.value];
  }, [condition.value]);

  const selectedLocationGroup = useMemo(() => {
    return groupList.find((loc) => loc.id === condition?.value);
  }, [groupList, condition.value]);

  const items = location || selectedLocationGroup;
  return (
    <>
      {items ? (
        <>
          {Array.isArray(items) ? (
            items.map((loc, index) => (
              <>
                {index === items.length - 1 && items.length > 1 && (
                  <Typography fontWeight={600} fontSize="12px" color="#424242">
                    or
                  </Typography>
                )}
                <Stack
                  key={index}
                  border="1px solid #BDBDBD"
                  borderRadius="6px"
                  px="4px"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{loc?.name}</Typography>
                  </Stack>
                </Stack>
              </>
            ))
          ) : (
            <Stack border="1px solid #BDBDBD" borderRadius="6px" px="4px">
              <Stack direction="row" spacing={1} alignItems="center">
                {items?.ParentId && <BranchIcon style={{ fontSize: '16px' }} />}
                <Typography>{items?.name}</Typography>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <Stack border="1px solid #BDBDBD" borderRadius="6px" px="4px">
          <Typography color="#9E9E9E">Select Location</Typography>
        </Stack>
      )}
    </>
  );
};
export const LocationTypeText = (condition) => {
  const locationsMap = useLocationsMap();
  const groupList = useSelector(getLocationGroupsState);

  const location = useMemo(() => {
    if (Array.isArray(condition?.value)) {
      return condition?.value.map((loc) => locationsMap[loc]);
    }
    return locationsMap[condition?.value];
  }, [condition.value]);

  const selectedLocationGroup = useMemo(() => {
    return groupList.find((loc) => loc.id === condition?.value);
  }, [groupList, condition.value]);

  const items = location || selectedLocationGroup;
  return items
    ? Array.isArray(items)
      ? items
          .map(
            (loc, ind) =>
              `${ind === items.length - 1 && items.length > 1 ? 'or ' : ''}${
                loc?.name
              }`,
          )
          .join(', ')
      : items?.name
    : '';
};
export default LocationTypePreview;
