import { useEffect, useState } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';
import { Box, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getRoles } from 'store/selectors/roles';

const AssignToUsersAndTeams = (props) => {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const {
    handleChangeCallback,
    currentSelected,
    hideUsersAndTeamsLocations,
    hideRoles = true,
    hideUsersAndTeamsIcon = false,
    hideActions = false,
    hideAll = false,
    isMulti = true,
    closeOnSelect = false,
    placeholderUsersAndTeams = '',
    popperProps = {
      disablePortal: false,
      style: {
        zIndex: 1300,
        width: 575,
      },
    },
    showSelectedLimit = 4,
    assigneesProps,
  } = props;

  const { addNewAssignees, splitAssigneesByIds } = useAssigneesUtils();

  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const roles = useSelector(getRoles);

  const [taskAssignees, setTaskAssignees] = useState([]);

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    roles: false,
    locations: false,
  });

  useEffect(() => {
    if (!hideUsersAndTeamsLocations) {
      setDropdownConfig({
        ...dropdownConfig,
        locations: config?.advancedLocationBasedAssignment ?? false,
      });
    }
    if (!hideRoles) {
      setDropdownConfig({
        ...dropdownConfig,
        roles: true,
      });
    }
  }, [config, hideUsersAndTeamsLocations, hideRoles]);

  const [selected, setSelected] = useState({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.roles && { roles: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
  });

  const handleSelect = (data: any) => {
    if (!isMulti) {
      const mergedArray = [data?.currentSelected];

      const updatedAssignees = addNewAssignees(mergedArray);

      setTaskAssignees(updatedAssignees);
      const selectedList = splitAssigneesByIds(mergedArray, {
        people: true,
        teams: true,
        roles: dropdownConfig?.roles ?? false,
        locations: dropdownConfig?.locations ?? false,
      });
      setSelected(selectedList);
      handleChangeCallback?.(mergedArray);
      return;
    }
    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.roles ?? []),
      ...(data?.selectedList?.locations ?? []),
    ];

    const updatedAssignees = addNewAssignees(mergedArray);

    setTaskAssignees(updatedAssignees);

    setSelected(data?.selectedList);
    handleChangeCallback?.(mergedArray);
  };

  useEffect(() => {
    const splittedAssignees = splitAssigneesByIds(currentSelected ?? [], {
      people: true,
      teams: true,
      roles: dropdownConfig?.roles ?? false,
      locations: dropdownConfig?.locations ?? false,
    });

    const mergedArray = [
      ...(splittedAssignees?.people ?? []),
      ...(splittedAssignees?.teams ?? []),
      ...(splittedAssignees?.roles ?? []),
      ...(splittedAssignees?.locations ?? []),
    ];

    const updatedAssignees = addNewAssignees(mergedArray);
    setTaskAssignees(updatedAssignees);

    setSelected(splittedAssignees);
  }, [
    currentSelected,
    dropdownConfig,
    usersHash,
    teamsHash,
    locationsHash,
    roles,
  ]);

  return (
    <AssigneeDropdown
      buttonRenderer={
        <CustomButton
          variant="outlined"
          buttonType="grayWhite"
          endIcon={
            <Box className="endIconCnt">
              <KeyboardArrowDownIcon
                style={{ fontSize: 24, color: '#00000099' }}
              />
            </Box>
          }
          style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '6px 12px 6px 10px',
            height: 44,
            borderRadius: 10,
          }}
          sx={{
            '& .placeholder': {
              fontWeight: '500',
              fontSize: '14px',
            },
            '& .selectedAssigneeLabel': {
              fontWeight: '600 !important',
              fontSize: '13px !important',
              lineHeight: '17.76px !important',
              color: '#000000 !important',
            },
          }}
        >
          <Stack direction="row" gap="10px" alignItems="center">
            {!hideUsersAndTeamsIcon && (
              <UsersAndTeamsIcon
                sx={{
                  fill: 'none',
                  height: 18,
                  width: 18,
                  '& path': {
                    stroke: '#323131',
                  },
                }}
              />
            )}

            {taskAssignees?.length >= 1 ? (
              <Stack direction="row" alignItems="center">
                <AssigneesView
                  type="chips"
                  assigneesProps={{
                    clearOption: false,
                    ...assigneesProps,
                  }}
                  data={
                    taskAssignees?.length > showSelectedLimit
                      ? taskAssignees?.slice(0, showSelectedLimit)
                      : taskAssignees
                  }
                />

                {taskAssignees?.length > showSelectedLimit && (
                  <Box
                    style={{
                      borderRadius: 25,
                      padding: 7,
                      border: '1px solid lightGray',
                      marginLeft: 3,
                      width: 52,
                      fontWeight: 600,
                      fontSize: 13,
                      lineHeight: '17.76px',
                      color: '#000000',
                    }}
                  >
                    + {`${taskAssignees?.length - showSelectedLimit}`}
                  </Box>
                )}
              </Stack>
            ) : (
              <Typography className="placeholder">
                {placeholderUsersAndTeams ||
                  'Select people, teams or locations'}
              </Typography>
            )}
          </Stack>
        </CustomButton>
      }
      handleChangeCallback={handleSelect}
      showTabs={true}
      listProps={{
        ...(!hideAll && {
          all: {
            showInlineSelection: false,
            isSearchable: true,
            isMulti,
            hideActions,
            placeholder: 'Type to Search',
            showMeOnTop: true,
            partition: true,
            selected: [
              ...(selected?.people ?? []),
              ...(selected?.teams ?? []),
              ...(selected?.roles ?? []),
              ...(selected?.locations ?? []),
            ],
          },
        }),
        people: {
          showMeOnTop: true,
          showInlineSelection: false,
          isSearchable: true,
          isMulti,
          hideActions,
          partition: true,
          selected: selected?.people ?? [],
        },
        teams: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti,
          hideActions,
          selected: selected?.teams ?? [],
        },
        ...(dropdownConfig?.locations &&
          !hideUsersAndTeamsLocations && {
            locations: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti,
              hideActions,
              selected: selected?.locations ?? [],
              addLocationByTag: true,
              selectOnly: true,
            },
          }),
        ...(dropdownConfig?.roles &&
          !hideRoles && {
            roles: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti,
              hideActions,
              selected: selected?.roles ?? [],
            },
          }),
      }}
      displayOptions={{
        ...(!hideAll && { all: true }),
        ...dropdownConfig,
      }}
      blacklistedIds={getBacklistUsersByPermission(
        PERMISSIONS.CAN_CHANGE_TASK_STATUS,
        true,
      )}
      popperProps={popperProps}
      allSelected={false}
      closeOnSelect={closeOnSelect}
    />
  );
};

export default AssignToUsersAndTeams;
