// React
import { Fragment, useMemo, useState, useEffect } from 'react';

// React Router
import { useLocation, useSearchParams } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Icons
import AllTemplatesIcon from 'components/Icons/allTemplatesIcon';
import OneOffIcon from 'components/Icons/sidebarIcons/oneOffIcon';
import CorrectiveIcon from 'components/Icons/sidebarIcons/correctiveIcon';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import RequestedIcon from 'components/Icons/sidebarIcons/requestsIcon';
import { HasPermission } from 'components/HasPermission';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSelector } from 'react-redux';

// Utilities
import xeniaApi from 'api/index';
import TaskTemplateIcon from 'components/Icons/taskTemplateIcon';
import selectors from 'store/selectors';
import { PERMISSIONS } from 'utils/constants';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
}

const SubItemsList = ({ onClickItemCallback }: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const loaders = useSelector(selectors.getLoaders);
  const [searchParams] = useSearchParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasFeature, FEATURES } = useCustomPermission();

  const activeItem = useMemo(() => {
    const filters = searchParams.get('filters');
    if (filters) {
      if (filters.includes('taskTypes|is|oneOff')) {
        return 'oneOffTasks';
      } else if (filters.includes('taskTypes|is|corrective')) {
        return 'correctiveTasks';
      } else if (filters.includes('taskTypes|is|scheduled')) {
        return 'scheduledTasks';
      } else if (filters.includes('taskTypes|is|requested')) {
        return 'reqeustedTasks';
      } else if (filters.includes('taskTypes|is|overdue')) {
        return 'overdueTasks';
      }
    } else {
      if (currentPath.includes('/tasks/savedtasktemplates')) {
        return 'savedTaskTemplates';
      }
      if (currentPath.includes('/tasks/list')) {
        return 'allTasks';
      } else {
        return '';
      }
    }
  }, [currentPath, searchParams]);

  const handleClickSubItem = (
    itemType:
      | 'allTasks'
      | 'oneOffTasks'
      | 'correctiveTasks'
      | 'scheduledTasks'
      | 'savedTaskTemplates'
      | 'reqeustedTasks',
  ) => {
    if (loaders?.tasksList?.isLoading) return;
    switch (itemType) {
      case 'allTasks':
        navigateWithWorkspaceUrl('/tasks/list');
        break;
      case 'oneOffTasks':
        navigateWithWorkspaceUrl('/tasks/list?filters=taskTypes|is|oneOff');
        break;
      case 'correctiveTasks':
        navigateWithWorkspaceUrl(
          '/filtered-tasks?filters=taskTypes|is|corrective',
        );
        break;
      case 'scheduledTasks':
        navigateWithWorkspaceUrl('/tasks/list?filters=taskTypes|is|scheduled');
        break;
      case 'reqeustedTasks':
        navigateWithWorkspaceUrl('/tasks/list?filters=taskTypes|is|requested');
        break;

      case 'savedTaskTemplates':
        navigateWithWorkspaceUrl('/tasks/savedtasktemplates');
        break;

      default:
        break;
    }
    onClickItemCallback?.();
  };

  return (
    <Fragment>
      <SubItem
        title="All Tasks"
        isSelected={activeItem === 'allTasks'}
        icon={<AllTemplatesIcon filled={activeItem === 'allTasks'} />}
        onClick={() => handleClickSubItem('allTasks')}
      />

      <SubItem
        title="Corrective"
        isSelected={activeItem === 'correctiveTasks'}
        icon={<CorrectiveIcon filled={activeItem === 'correctiveTasks'} />}
        onClick={() => handleClickSubItem('correctiveTasks')}
      />

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
        <SubItem
          title="Saved Templates"
          isSelected={activeItem === 'savedTaskTemplates'}
          icon={
            <TaskTemplateIcon filled={activeItem === 'savedTaskTemplates'} />
          }
          onClick={() => handleClickSubItem('savedTaskTemplates')}
        />
      </HasPermission>
    </Fragment>
  );
};

export default SubItemsList;
