import actionTypes from 'store/actionTypes';
import { initialLookupStateData } from 'store/initialStateData';

export const LookupReducer = (state: any = initialLookupStateData, action) => {
  switch (action.type) {
    case actionTypes.SET_LOOKUP_DATA: {
      return {
        ...state,
        data: action.payload,
        initialLoad: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
