import { Box, Button, Stack, styled } from '@mui/material';

export const MainContainer = styled(Box)({
  backgroundColor: 'rgba(235, 234, 255, 1)',
  padding: '16px 20px',
  borderRadius: 8,
  border: '1px solid rgba(204, 203, 255, 1)',
  marginBottom: 20,

  '& .correctiveIconCnt': {
    borderRadius: 10,
    height: 36,
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(227, 242, 253, 1)',
    '& svg': {
      height: '27px',
      width: '27px',
      '& path': {
        fill: 'rgb(56 166 255)',
        stroke: 'beige',
      },
    },
  },
  '& .workOrderIconCnt': {
    borderRadius: 10,
    height: 36,
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(232, 245, 233, 1)',
    '& path': {
      fill: 'rgba(76, 175, 80, 1)',
      stroke: 'rgba(76,175,80,1)',
    },
  },
  '& .mainTitle': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: 15,
  },
  '& .subTitle': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(33, 33, 33, 1)',
  },

  '& .triggerContent': {
    marginTop: '-10px',
    backgroundColor: 'white',
    padding: '20px 16px 20px 16px',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 12,
    minHeight: 112,
  },
});

export const DueDateCnt = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
    marginBottom: 5,
  },

  '& .boxContainer': {
    height: 32,
  },

  '& .arrowIcon': {
    width: '22px !important',
    height: 'unset !important',
  },

  '& .inner': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& .statement': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.14px',
      color: '#000000',
    },

    '& .closeIcon': {
      cursor: 'pointer',
      height: 18,
      width: 18,
      '& path': {
        color: '#EF5350',
      },
    },
  },
});

export const TimeDurationCnt = styled(Box)({
  '& .MuiFormControl-root': {
    width: '70px !important',

    '& input': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#000000',
    },
  },
});

export const TimeIntervalCnt = styled(Box)({
  '& .selectedLabel': {
    letterSpacing: '0.14px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  '& .arrowIcon': {
    color: '#9E9E9E',
  },
  '& label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const InfoWrapperCnt = styled(Stack)({
  padding: 0,
  marginBottom: 20,
});

export const InfoWrapper = styled(Stack)({
  backgroundColor: '#E1F5FE',
  padding: '8px 6px 8px 6px',
  borderRadius: '6px',
  gap: '8px',

  '& .infoIcon': {
    color: '#0288D1',
    height: 20,
    width: 20,
  },

  '& .infoText': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },
});

export const CreateActionBox = styled(Stack)({
  width: '100%',
  justifyContent: 'center',
  gap: 10,
  height: 64,
  backgroundColor: 'rgba(255, 255, 255, 1)',
  borderRadius: 12,
  border: '1px solid rgba(224, 224, 224, 1)',
  padding: '14px 16px',
  cursor: 'pointer',

  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },
});

export const SelectedActionContainer = styled(Stack)({
  height: 97,
  borderRadius: 12,
  padding: '14px 16px',
  border: '1px solid rgba(224, 224, 224, 1)',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  position: 'relative',
  justifyContent: 'center',
  display: 'flex',

  '& .actionIconCnt': {
    marginRight: 10,
  },

  '& .singleUserIcon': {
    fontSize: 22,
  },

  '& .actionCnt': {
    cursor: 'pointer',
    border: '1px solid rgba(224, 224, 224, 1)',
    height: 34,
    width: 34,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .assigneeTitle': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(78, 72, 250, 1)',
    margin: '3px 0px 6px 0px',
  },

  '& .taskTemplate': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },
});

export const NotificationsContainer = styled(Stack)({
  borderRadius: 12,
  padding: '14px 16px',
  border: '1px solid rgba(224, 224, 224, 1)',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  marginTop: 20,

  '& .separator': {
    margin: '15px 0px',
  },
  '& .title': {
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },

  '& .subTitle': {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
  },

  '& .emailInputWrapper': {
    margin: '0px 0px 10px 0px',
  },

  '& .additionalTitle': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '100%',
    color: 'rgba(0, 0, 0, 1)',
    marginTop: 20,
  },
});

export const AddEmailButton = styled(Button)({
  borderRadius: 6,
  marginRight: 'auto',

  fontWeight: 600,
  fontSize: 13,
  lineHeight: '100%',
  letterSpacing: 0,
  color: 'rgba(78, 72, 250, 1)',
});

export const AdditionalWrapper = styled(Box)({
  display: 'flex',
  gap: 14,
  flexDirection: 'column',
  marginTop: '14px',
});
