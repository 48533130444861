// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

export const SettingsIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid rgba(189, 189, 189, 1)',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8,
  cursor: 'pointer',
  width: 36,
  height: 36,
  '&:hover': {
    background: 'rgba(189, 189, 189, 0.3)',
    transition:
      'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  transition:
    'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSolidBtn',
})(({ theme, isSolidBtn }) => ({
  borderRadius: 6,
  height: 36,
  fontSize: 13,
  color: isSolidBtn ? theme.palette.common.white : 'rgba(66, 66, 66, 1)',
  ...(!isSolidBtn
    ? {
        border: '1px solid rgba(189, 189, 189, 1)',
        '&:hover': {
          background: 'rgba(189, 189, 189, 0.3)',
          border: '1px solid rgba(189, 189, 189, 1)',
        },
      }
    : {
        '& svg': {
          '& path': {
            stroke: theme.palette.common.white,
            strokeOpacity: 1,
          },
        },
      }),
}));

export const DownloadReportButton = styled(CustomButton)({
  borderRadius: '6px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',

  '& svg': {
    '& path': {
      fill: 'white',
      fillOpacity: 1,
    },
  },
});
