import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  sx?: any;
}

const PulseDashboardIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M10.9993 12.8337C12.0119 12.8337 12.8327 12.0128 12.8327 11.0003C12.8327 9.9878 12.0119 9.16699 10.9993 9.16699C9.98683 9.16699 9.16602 9.9878 9.16602 11.0003C9.16602 12.0128 9.98683 12.8337 10.9993 12.8337Z"
        fill="black"
      />
      <path
        d="M14.8897 7.11128C17.0376 9.25916 17.0376 12.7416 14.8897 14.8895M7.11156 14.8894C4.96368 12.7415 4.96368 9.25913 7.11156 7.11125M4.51884 17.4822C0.939033 13.9024 0.939033 8.09836 4.51884 4.51855M17.4825 4.5186C21.0623 8.0984 21.0623 13.9024 17.4825 17.4822M12.834 11.0004C12.834 12.0129 12.0132 12.8337 11.0007 12.8337C9.98813 12.8337 9.16732 12.0129 9.16732 11.0004C9.16732 9.98784 9.98813 9.16703 11.0007 9.16703C12.0132 9.16703 12.834 9.98784 12.834 11.0004Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PulseDashboardIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2403 6.46277C14.5982 6.10479 15.1786 6.10479 15.5366 6.46277C18.0425 8.96864 18.0425 13.0314 15.5366 15.5373C15.1786 15.8953 14.5982 15.8953 14.2403 15.5373C13.8823 15.1793 13.8823 14.5989 14.2403 14.2409C16.0302 12.451 16.0302 9.54904 14.2403 7.75914C13.8823 7.40115 13.8823 6.82075 14.2403 6.46277Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.75844 6.46274C8.11642 6.82072 8.11642 7.40112 7.75844 7.7591C5.96854 9.54901 5.96854 12.451 7.75844 14.2409C8.11642 14.5989 8.11642 15.1793 7.75844 15.5373C7.40046 15.8953 6.82006 15.8953 6.46208 15.5373C3.95622 13.0314 3.95622 8.96861 6.46208 6.46274C6.82006 6.10476 7.40046 6.10476 7.75844 6.46274Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.16572 3.87005C5.5237 4.22803 5.5237 4.80843 5.16572 5.16641C1.94389 8.38824 1.94389 13.6118 5.16572 16.8337C5.5237 17.1917 5.5237 17.7721 5.16572 18.13C4.80774 18.488 4.22734 18.488 3.86936 18.13C-0.0684313 14.1922 -0.0684313 7.80783 3.86936 3.87005C4.22734 3.51207 4.80774 3.51207 5.16572 3.87005Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.833 3.87009C17.191 3.51211 17.7714 3.51211 18.1293 3.87009C22.0671 7.80788 22.0671 14.1923 18.1293 18.1301C17.7714 18.4881 17.191 18.4881 16.833 18.1301C16.475 17.7721 16.475 17.1917 16.833 16.8337C20.0548 13.6119 20.0548 8.38828 16.833 5.16645C16.475 4.80847 16.475 4.22807 16.833 3.87009Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.24935 11C8.24935 9.48126 9.48057 8.25004 10.9993 8.25004C12.5181 8.25004 13.7493 9.48126 13.7493 11C13.7493 12.5188 12.5181 13.75 10.9993 13.75C9.48057 13.75 8.24935 12.5188 8.24935 11Z"
        fill="#4E48FA"
      />
    </>,
    'PulseDashboardIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  );
};
export default PulseDashboardIcon;
