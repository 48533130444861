import { FC, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Content } from './styled';
import ConditionItem from './components/conditionItem';
import ContextDropdown from './components/context';
import { conditionalVisibilityInterface } from './utils/constants';
import AndOr from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/AndOr';
import { Box } from '@mui/material';

interface ItemOrdersHash {
  [key: string]: number | string;
}
interface IProps {
  id?: string;
  entity: string;
  checkListItems: any;
  automations: conditionalVisibilityInterface;
  onChange(automations: conditionalVisibilityInterface): void;
  ordreHash: ItemOrdersHash;
  disable?: boolean;
  deleteHandler?: any;
  followUplevel?: number;
  index: number;
  operator: 'and' | 'or' | '';
  onOperatorChange: (operator: 'and' | 'or' | '') => void;
  showAddOr?: boolean;
}
const VisibilityContent: FC<IProps> = (props) => {
  const {
    id,
    checkListItems,
    automations,
    ordreHash,
    disable,
    onChange,
    deleteHandler,
    followUplevel,
    index,
    operator = 'and',
    onOperatorChange,
    showAddOr = true,
  } = props;
  const { condition, contextId, context } = automations;
  const [selectedContext, setSelectedContext] = useState<any>(null);
  const [isLocationType, setIsLocationType] = useState<boolean>(false);

  const hideElements = [
    'header',
    'takePhoto',
    'signature',
    'photoAnnotate',
    'instruction',
    'geoStamp',
  ];

  const allCheckListItems = useMemo(() => {
    const checkListItemsArray = Object.values(checkListItems) as any;
    return (
      (checkListItemsArray?.length &&
        checkListItemsArray?.filter(
          (item) =>
            !hideElements.includes(item?.type) &&
            (item?.ParentId === null || item.ParentId === undefined),
        )) ||
      []
    );
  }, [checkListItems]);

  const handleChangeFilter = (_, operator) => {
    onOperatorChange?.(operator);
  };

  const itemSelectionWidth = useMemo(() => {
    if (followUplevel !== undefined && followUplevel === 0) {
      return index === 0 ? 400 : 320;
    }

    if (followUplevel !== undefined && followUplevel >= 0) {
      return index === 0 ? 400 - followUplevel * 30 : 320 - followUplevel * 30;
    }

    if (index === 0) {
      return 400;
    }
    if (index > 0) {
      return 320;
    }
  }, [index, disable, followUplevel]);

  return (
    <Content>
      <Stack direction="row" position="relative">
        {showAddOr && (
          <Box
            sx={{
              marginRight: '10px',
              '& button': {
                padding: '9px',
              },
            }}
          >
            <AndOr
              disabled={index > 1}
              value={operator?.toLowerCase() as 'and' | 'or'}
              onSelect={handleChangeFilter}
            />
          </Box>
        )}
        <ContextDropdown
          id={id}
          context={context}
          contextId={contextId || ''}
          checkListItems={allCheckListItems}
          condition={condition}
          onChange={onChange}
          ordreHash={ordreHash}
          setSelectedContext={setSelectedContext}
          disable={disable}
          itemSelectionWidth={itemSelectionWidth}
          setIsLocationType={setIsLocationType}
        />
        <ConditionItem
          id={id}
          context={context}
          checkListItems={allCheckListItems}
          condition={condition}
          onChange={onChange}
          selectedContext={selectedContext}
          disable={disable}
          deleteHandler={deleteHandler}
          isLocationType={isLocationType}
        />
      </Stack>
    </Content>
  );
};

export default VisibilityContent;
